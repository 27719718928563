import React, { useEffect, useState, useRef } from 'react';

import StarsIcon from '@mui/icons-material/Stars';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import TvIcon from '@mui/icons-material/Tv';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SavingsIcon from '@mui/icons-material/Savings';
import GroupsIcon from '@mui/icons-material/Groups';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';

import EnvVars from './envVars.js';

export function convertIntToCurrency(value, mobileDevice, bold){
  if(isNaN(value)){
    return "$0";
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  let currencyValue = 'N/A';

  const condenseCurrencyValue = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
    return 0;
  };

  if (value !== null) {
    currencyValue = formatter.format(Math.floor(value));
  }
  
  if (bold && !/^(\$0|N\/A)$/.test(currencyValue)) {
    if(mobileDevice){
      return (
        <b key="cashValue">
          {condenseCurrencyValue(Math.floor(value))}
        </b>
      );
    }else{
      return [
        <b key="cashValue">
          {currencyValue}
        </b>
      ];
    }
  }else {
    return mobileDevice ? "$" + condenseCurrencyValue(Math.floor(value)) : currencyValue;
  }
}

export function timeStamp(timeStamp, mobile) {
  timeStamp = Date.parse(timeStamp);
  timeStamp = timeStamp / 1000;

  const seconds = Math.floor((new Date().getTime() / 1000) - timeStamp);

  let prompt;
  if (seconds < 0) {
    prompt = " from now";
  } else {
    prompt = " ago";
  }

  const absSeconds = Math.abs(seconds);

  if (absSeconds >= 31536000) {
    const years = Math.floor(absSeconds / 31536000);
    return !mobile ? years + " yrs" + prompt : years + " yrs";
  }

  let interval = Math.floor(absSeconds / 2592000);
  if (interval > 1) return !mobile ? interval + " mo" + prompt : interval + " mo";

  interval = Math.floor(absSeconds / 86400);
  if (interval >= 1) {
    if (interval === 1) {
      return !mobile ? "1 day" + prompt : "1 day";
    } else {
      return !mobile ? interval + " days" + prompt : interval + " days";
    }
  }

  interval = Math.floor(absSeconds / 3600);
  if (interval >= 1) return !mobile ? interval + "h" + prompt : interval + "h";

  interval = Math.floor(absSeconds / 60);
  if (interval > 1) return !mobile ? interval + "m" + prompt : interval + "m";

  return !mobile ? absSeconds + "s" + prompt : absSeconds + "s" + prompt;
}

export function dateFormat(dateString) {
  const inputDate = new Date(dateString);
  if (!inputDate || isNaN(inputDate.getTime())) {
      return dateString;
  }

  const formattedDate = new Date(inputDate.getTime() + Math.abs(inputDate.getTimezoneOffset() * 60000));
  const day = formattedDate.getDate().toString().padStart(2, '0');
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
  const year = formattedDate.getFullYear();

  return `${month}/${day}/${year}`;
}

export function parseJWT (sessionToken) {
  if(!sessionToken){
    return;
  }

  try {
      var base64Url = sessionToken.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      return JSON.parse(jsonPayload);
  } catch (e) {
      console.log(e);
      return "Unauthorized";
  }
}

export function detectHomePage(views){
  if(views?.includes("Home")){
    return "home";
  }

  if(views?.includes("Leads")){
    return "leads";
  }

  if(views?.includes("Bids")){
    return "bids";
  }

  return "home";
}

export function useMobileDetect() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const resizeWindow = () => {
      const { innerWidth, innerHeight } = window;
      if (innerWidth < 769 || innerHeight < 600) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return isMobile || document.body.classList.contains("mobile");
}

export function leadSourceTag(leadSource, data){
  let icon = <ShareOutlinedIcon/>;
  let color = "#e65100";
  let backgroundColor = "#fff3e0";
  let className = "other";

  if(leadSource === "Abacus Pays"){
    className = "abacusPays";
    color = "#1b5e20";
    backgroundColor = "#c8e6c9";
    icon = <StarsIcon/>;
  }
  if(leadSource === "Television"){
    className = "abacusPays";
    color = "#1b5e20";
    backgroundColor = "#c8e6c9";
    icon = <TvIcon/>;
  }
  if(leadSource === "Digital"){
    className = "abacusPays";
    color = "#1b5e20";
    backgroundColor = "#c8e6c9";
    icon = <DevicesOutlinedIcon/>;
  }
  if(leadSource === "Phone"){
    className = "abacusPays";
    color = "#1b5e20";
    backgroundColor = "#c8e6c9";
    icon = <PhoneIphoneIcon/>;
  }
  if(leadSource === "Marketplace" || leadSource === "Direct"){
    icon = <LoyaltyIcon/>;
    color = "#4a148c";
    backgroundColor = "#e1bee7";
    className = "direct";
  }
  if(leadSource === "Serviced Policy"){
    icon = <LibraryBooksOutlinedIcon/>;
    color = "#0d47a1";
    backgroundColor = "#e3f2fd";
    className = "serviced";
  }

  if(leadSource === "Agent"){
    className = "agent";
    color = "#f57f17";
    backgroundColor = "#FFEFA9";
    icon = <SupportAgentIcon/>;
  }
  if(leadSource === "Insured"){
    className = "clientDirect";
    color = "#880e4f";
    backgroundColor = "#fce4ec";
    icon = <GroupsIcon/>;
  }
  if(leadSource === "Broker"){
    className = "broker";
    color = "#1a237e";
    backgroundColor = "#e8eaf6";
    icon = <SavingsIcon/>;
  }

  if(leadSource === "Client Direct"){
    className = "clientDirect";
    color = "#880e4f";
    backgroundColor = "#fce4ec";
    icon = <PhoneForwardedIcon/>;
  }

  if(data){
    return {
      className : className,
      icon : icon,
      color : color,
      backgroundColor : backgroundColor
    }
  }

  return (
    <div className={"source cL g bR dP oH " + className}>
      {icon}
      <div className="gCW f">
        {leadSource}
      </div> 
    </div>
  )

}

export function ForceLogout(props){
  props.setSessionUser({currentPage : "login"});
  window.sessionStorage.clear();
  window.sessionStorage.setItem('sessionUser', JSON.stringify({currentPage : "login" }));
}