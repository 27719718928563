import React from 'react';
import { useState, useEffect, useRef } from 'react';

import './CaseOptions.css';

// import { convertIntToCurrency, toggleFilters } from '../envFunctions.js';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

function CaseOptions(props){
    const [caseOptionType, setCaseOptionType] = useState("assigned");
    const [assignedUsers, setAssignedUsers] = useState(props?.caseInfo?.assignedUsersIDs);
    const [updatedAssignedUsers, setUpdatedAssignedUsers] = useState(props?.caseInfo?.assignedUsersIDs);
    const [isSaveButtonActive, setIsSaveButtonActive] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);

    const handleToggleTeamMember = (userID) => {
        setUpdatedAssignedUsers((prevAssignedUsers = []) => {
          const isUserAssigned = prevAssignedUsers?.includes(userID?.toString());
          const updatedUsers = isUserAssigned
            ? prevAssignedUsers?.filter((id) => id !== userID?.toString())
            : [...prevAssignedUsers, userID?.toString()];
      
          setIsSaveButtonActive(!arrayEquality(updatedUsers, assignedUsers));
          return updatedUsers;
        });
      };

    const arrayEquality = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
          return false;
        }
        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) {
            return false;
          }
        }
        return true;
    };

    const updateCase = () => {
        setLoadingStatus(true);

        const paramVals = {
          taskID: props?.caseInfo?.[props?.recordKey],
          type: "assign",
          assignedUsersIDs: updatedAssignedUsers,
        };

        props.session?.env?.functions?.buildFetchRequest("marketplace/updateBidStatus", paramVals)
          .then((response) => response.json())
          .then((resData) => {
            setLoadingStatus(false);
            if (resData.status === 200) {
                setIsSaveButtonActive(false);

                if (Object.keys(props?.caseList)?.length > 0) {
                    const currentCaseIndex = props?.caseList.findIndex(
                    (currCase) => currCase.recordID === props.caseInfo.recordID
                    );
                    const updatedCaseList = [...props.caseList];
                    const currentCase = updatedCaseList[currentCaseIndex];
                    currentCase.assignedUsersIDs = updatedAssignedUsers;
                    props.caseUpdater(updatedCaseList);
                }
            }

            if (resData.status === 500) {
              console.log("Unable to update case");
            }
          });
    };

    function assignedHandler(props) {
        if (!props?.caseInfo || !props?.sessionUser) {
          return "Loading";
        }

        const returningBlock = props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.teamList
          .filter((teamMember) => !teamMember?.isTestAccount)
          .map((teamMember) => (
            <div
                key={teamMember?.email}
                className={`row ${updatedAssignedUsers?.includes(teamMember?.userID?.toString()) ? "active" : ""} ns p`}
                onClick={() => handleToggleTeamMember(teamMember?.userID)}
            >
              <div className="cL bold s oH">
                <div className="gCW">{`${teamMember?.firstName} ${teamMember?.lastName}`}</div>
              </div>
              <div className="cC oH">
                <div className="gCW">{teamMember?.email}</div>
              </div>
              <div className="cC">
                {updatedAssignedUsers?.includes(teamMember?.userID?.toString()) ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
              </div>
            </div>
        ));

        return (
          <div className="list g f oH">
            <div className="f cC">{displayAssignedPrompt(updatedAssignedUsers, props?.sessionUser)}</div>
            <div className="listOptions g f bR tO dP">
              <div className="rows g">{returningBlock}</div>
            </div>
          </div>
        );
    }

    useEffect(() => {
        setAssignedUsers(props?.caseInfo?.assignedUsersIDs);
    }, [props?.caseInfo?.assignedUsersIDs]);

    useEffect(() => {

    }, []);

    return (
        <div className="caseOptions moduleBlock wAction g cC fC oH bR">
            <div className="header g f cC fR">
                <div className="gC2 f cC">
                    Case Options
                </div>
                <div className="btn close cC p" onClick={()=>{props?.closeFunction(false)}}>
                    <CloseIcon/>
                </div>
            </div>
            <div className="body g f tC fC fR s e b oH">
                <div className="optionsNav g">
                    <div className="optionHeader f bold cL">
                        Assigned
                    </div>
                </div>
                <div className="content f cC oH">
                    {caseOptionType === "assigned" &&
                        assignedHandler(props)
                    }
                </div>
            </div>
            <div className="actionBar g f fR cC">
                {loadingStatus ?
                    <div className="btn loading g cC fMP bR p gC4">
                        <CircularProgress/>
                    </div>
                :
                    <div className={`btn${isSaveButtonActive ? ' p' : " inactive"} default g cC fMP bR gC4 ns bold`} onClick={updateCase}>
                        <div className="f cC">
                            Save
                        </div>
                        <SaveIcon/>
                    </div>    
                }
            </div>
        </div>
    )
}

export default CaseOptions;

export function displayAssignedPrompt(assignedList, sessionUser) {
    const assignedUserCount = assignedList?.length;
  
    if (assignedUserCount === 0) {
      return "Not assigned";
    }

    const sessionUserIncluded = assignedList?.includes(sessionUser?.userID.toString());

    if (assignedUserCount === 1 && sessionUserIncluded) {
        return (
            <span>
                Assigned to <span className="bold">you</span>
            </span>
        )
    }
    
    if (sessionUserIncluded) {
      const remainingUserCount = assignedUserCount - 1;
      const prompt = remainingUserCount === 1 ? "other" : "others";
      return (
            <span>
                <span className="bold">You</span> and <span className="bold">{remainingUserCount}</span> {prompt}
            </span>
      );
    } else {
      return (
        <span>
          Assigned to <span className="bold">{assignedUserCount}</span> other{assignedUserCount !== 1 ? "s" : ''}
        </span>
      );
    }
}