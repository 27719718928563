import React from 'react';
import { useState, useEffect } from 'react';

import LeadBlock from './LeadBlock';

import CloseIcon from '@mui/icons-material/Close';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import ForwardIcon from '@mui/icons-material/Forward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AddCommentIcon from '@mui/icons-material/AddComment';

import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

function UpdateLeadPanel(props){
    const [followUpTime, setFollowUpTime] = useState(null);
    const [followUpTimeError, setFollowUpTimeError] = useState('');
    const [followUpNote, setFollowUpNote] = useState(null);
    const [showFollowUpNote, setShowFollowUpNote] = useState(false);
    const   initialTime = new Date();
            initialTime.setMinutes(initialTime.getMinutes() + 30);
    const handleFollowUpTimeChange = (timestamp) => {
        setFollowUpTime(timestamp);
    };

  
    function convertTimeStamp(timeStamp) {

        timeStamp = Date.parse(timeStamp);
        timeStamp = timeStamp/1000;

        var seconds = Math.floor(((new Date().getTime()/1000) - timeStamp)),
        interval = Math.floor(seconds / 31536000);

        if (interval > 1) return interval + " yrs";

        interval = Math.floor(seconds / 2592000);
        if (interval > 1) return interval + " mo";

        interval = Math.floor(seconds / 86400);
        if (interval >= 1) return interval + " days";

        interval = Math.floor(seconds / 3600);
        if (interval >= 1) return interval + "h";

        interval = Math.floor(seconds / 60);
        if (interval > 1) return interval + "m ";

        return Math.floor(seconds) + "s";
    }

    function toggleLeadQuickView(view, currentLeadInfo){
        if(props.showLeadOptions){
            props.setCurrentLeadInfo(props.leadsList.find(lead=>{ return lead.leadID == props.currentLeadInfo.leadID }));
            props.setShowLeadOptions(false);
        }else{
            props.setShowLeadOptions(view);
            props.setCurrentLeadInfo(currentLeadInfo);
        }
        setFollowUpTime(null);
    }

    function currentQuickAccess(tab){
        if(tab === props.showLeadOptions){
            return " selected";
        }

        return '';
    }

    function detectLeadInfoChange(type){
        var initialLeadInfo = props.leadsList.find(lead=>{ return lead.leadID == props.currentLeadInfo.leadID });

        const initialLeadObj = JSON.parse(JSON.stringify(initialLeadInfo));
        const currentLeadObj = JSON.parse(JSON.stringify(props.currentLeadInfo));

        delete initialLeadObj["lastUpdateStatusTimeRaw"];
        delete currentLeadObj["lastUpdateStatusTimeRaw"];

        if(JSON.stringify(initialLeadObj) === JSON.stringify(currentLeadObj) && followUpTime === null){
            return " inactive";
        }

        return ''
    }

    function createFollowUp(){
        const paramVals = {
            "leadID" : props.currentLeadInfo.leadID,
            "nameOfSender" : props.sessionUser.firstName + " " + props.sessionUser.lastName,
            "emailOfSender" : props.sessionUser.email,
            "content" : followUpNote,
            "followUpTime" : followUpTime.toISOString(),
            "nameOfSender" : props.sessionUser.firstName + " " + props.sessionUser.lastName
        };

        props.session?.env?.functions?.buildFetchRequest("createFollowUp", paramVals)
            .then(response => response.json())
            .then(resData => {
                setFollowUpTime(null);
                setFollowUpNote(null);
                if(resData.status === 200){
                    resData.data.type = "Follow Up";
                    resData.data.key = props.timelineKeyTracker + 1;
                    if(props.insertNewTimelinePost?.(resData.data)){
                        props.insertNewTimelinePost(resData.data)
                    }
                }else{
                    console.log("Failed to create follow up");
                }
        });
    }

    function updateLead(){
        if(followUpTime && !confirmValidFollowUpTime()){
            return;
        }

        toggleLeadQuickView();

        var sendingLeadBlock = {...props.currentLeadInfo};
        var sendingFollowUpTime = followUpTime;

        delete sendingLeadBlock.currentPosition;
        delete sendingLeadBlock.dateCreated;

        const paramVals = {
            "leadBlock" : sendingLeadBlock,
            "nameOfSender" : props.sessionUser.firstName + " " + props.sessionUser.lastName
        };

        props.session?.env?.functions?.buildFetchRequest("updateLead", paramVals)
            .then(response => response.json())
            .then(resData => {
                if(resData.status === 200){
                    if(props.currentLeadInfo.uploadError !== undefined){
                        props.setCurrentLeadInfo(currentLeadInfo => ({
                            ...currentLeadInfo, uploadError: undefined
                        }));
                    }

                    if(props.insertNewTimelinePost !== undefined){
                        var now = new Date();
                        var isoString = now.toISOString();
                
                        var newTimelineObj = {
                            "timeStamp" : isoString,
                            "type" : "Status",
                            "status" : sendingLeadBlock.leadStatus,
                            "key" : props.timelineKeyTracker,
                            "nameOfSender" : props.sessionUser.firstName + " " + props.sessionUser.lastName
                            }
                        props.insertNewTimelinePost(newTimelineObj);
                    }
                    
                    if(followUpTime){
                        if(props.currentLeadInfo.nextFollowUp === '' || (Date.parse(props.currentLeadInfo.nextFollowUp) < new Date().getTime())){
                            props.setCurrentLeadInfo(currentLeadInfo => ({
                                ...currentLeadInfo, nextFollowUp: sendingFollowUpTime
                            }));
                        }

                        createFollowUp();
                    }

                    props.setLeadsList(prevList => {
                        const updatedList = [...prevList];
                        const index = updatedList.findIndex(item => item.leadID === props.currentLeadInfo.leadID);
                        updatedList[index] = { ...props.currentLeadInfo };
                        if(followUpTime){
                            updatedList[index].nextFollowUp = sendingFollowUpTime;
                        }
                        return updatedList;
                    });
                }else{
                    var errorLeadBlock = {...props.currentLeadInfo};
                    errorLeadBlock.uploadError = true;

                    props.setLeadsList(prevList => {
                        const updatedList = [...prevList];
                        const index = updatedList.findIndex(item => item.leadID === props.currentLeadInfo.leadID);
                        updatedList[index] = { ...errorLeadBlock };
                        return updatedList;
                    });
                }
            }
        );
    }

    function confirmUpdateLeadBlock(){
        props.setLeadsList(prevList => {
            const updatedList = [...prevList];
            const index = updatedList.findIndex(item => item.leadID === props.currentLeadInfo.leadID);
            updatedList[index] = { ...props.currentLeadInfo };
            return updatedList;
        });

        updateLead();

        return;
    }

    function updateLeadBlock(newLeadStatus, selectionStatus){
        var leadStatus = newLeadStatus;
        var currentContactAttempt = props.currentLeadInfo.contactAttemptCount;

        var currentPosition = props.showLeadOptions;
        var newPosition = props.showLeadOptions;

        if(currentPosition === "closed" && selectionStatus === " selected"){
            leadStatus = "Lead";
            newPosition = "lead";
            props.setShowLeadOptions("lead");
        }

        if(currentPosition === "contactAttempted"){
            var countInt = null;
            leadStatus = "Contact Attempted ";
            if(newLeadStatus === "Contact Attempted 1"){
                if(selectionStatus === " selected"){
                    countInt = 0;
                }else{
                    countInt = 1;
                }
            }
            if(newLeadStatus === "Contact Attempted 2"){
                if(selectionStatus === " selected"){
                    countInt = 1;
                }else{
                    countInt = 2;
                }
            }
            if(newLeadStatus === "Contact Attempted 3"){
                if(selectionStatus === " selected"){
                    countInt = 2;
                }else{
                    countInt = 3;
                }
            }
            if(countInt === 0){
                leadStatus = "Lead";
                newPosition = "lead";
                props.setShowLeadOptions("lead");
            }else{
                if(countInt === 3){
                    leadStatus = "Failed to Contact";
                    newPosition = "closed";
                }else{
                    leadStatus += countInt;
                }
            }
            currentContactAttempt = countInt;
        }

        if(currentPosition === "inProgress" && selectionStatus === " selected"){
            leadStatus = "Lead";
            newPosition = "lead";
            props.setShowLeadOptions("lead");
        }

        var now = new Date();
        var isoString = now.toISOString();
        
        props.setCurrentLeadInfo(currentLeadInfo => ({
            ...currentLeadInfo,
            leadStatus : leadStatus,
            currentPosition : newPosition,
            lastUpdateStatusTime : convertTimeStamp(isoString),
            lastUpdateStatusTimeRaw : isoString,
            contactAttemptCount : currentContactAttempt
        }));
        return;
    }

    function printQuickTab(tab){
        var returnBlock = [];
        if(tab === "closed"){
            function handleSelection(option){
                if(props.currentLeadInfo.leadStatus === option){
                    return " selected";
                }
    
                return '';
            }

            returnBlock = [
                <div key="closeOption11" className={"leadQuickChangeBtn ns closed" + handleSelection("Unqualified")}
                    onClick={()=>{updateLeadBlock("Unqualified", handleSelection("Unqualified"))}}>
                    <DoNotDisturbOnIcon/>
                    Unqualified
                </div>,
                <div key="seperatorClose1" className="leadQuickOptionsActionSeperator open">
                </div>,
                <div key="closeOption12" className={"leadQuickChangeBtn ns closed" + handleSelection("Client not Interested")}
                    onClick={()=>{updateLeadBlock("Client not Interested", handleSelection("Client not Interested"))}}>
                    <PersonOffIcon/>
                    Not Interested
                </div>,
                <div key="seperatorClose2" className="leadQuickOptionsActionSeperator open">
                </div>,
                <div key="closeOption13" className={"leadQuickChangeBtn ns closed" + handleSelection("Failed to Contact")}
                    onClick={()=>{updateLeadBlock("Failed to Contact", handleSelection("Failed to Contact"))}}>
                    <PhoneDisabledIcon/>
                    Failed to Contact
                </div>];
        }

        if(tab === "contactAttempted"){
            function contactAttemptTracker(count){
                if(props.currentLeadInfo.contactAttemptCount >= count){
                    return " selected";
                }

                return ' available';
            }

            returnBlock = [
                <div key="contactAttempt1" className={"leadQuickOptionsActionsBtn ns contactAttempted" + contactAttemptTracker(1)}
                    onClick={()=>{updateLeadBlock("Contact Attempted 1", contactAttemptTracker(1))}}>
                    <AddIcCallIcon/>
                    Called 1
                </div>,
                <div key="seperatorContactAttemptOption1" className={"leadQuickOptionsActionSeperator contactAttempted" + contactAttemptTracker(2)}>
                </div>,
                <div key="contactAttempt2" className={"leadQuickOptionsActionsBtn ns open contactAttempted" + contactAttemptTracker(2)}
                    onClick={()=>{updateLeadBlock("Contact Attempted 2", contactAttemptTracker(2))}}>
                    <AddIcCallIcon/>
                    Called 2
                </div>,
                <div key="seperatorContactAttemptOption2" className={"leadQuickOptionsActionSeperator contactAttempted" + contactAttemptTracker(3)}>
                </div>,
                <div key="contactAttempt3" className={"leadQuickOptionsActionsBtn ns contactAttempted" + contactAttemptTracker(3)}
                    onClick={()=>{updateLeadBlock("Contact Attempted 3", contactAttemptTracker(3))}}>
                    <AddIcCallIcon/>
                    Called 3
                </div>];
        }

        if(tab === "inProgress"){
            function handleSelection(option){
                if(props.currentLeadInfo.leadStatus === option){
                    return " selected";
                }

                return '';
            }

            returnBlock = [
                <div key="inProgressOption1" className={"leadQuickChangeBtn ns inProgress" + handleSelection("Application Sent")}
                    onClick={()=>{updateLeadBlock("Application Sent", handleSelection("Application Sent"))}}>
                    <ForwardIcon/>
                    Apps Out
                </div>,
                <div key="seperatorClose1" className="leadQuickOptionsActionSeperator open">
                </div>,
                <div key="closeOption12" className={"leadQuickChangeBtn ns inProgress" + handleSelection("Application Received")}
                    onClick={()=>{updateLeadBlock("Application Received", handleSelection("Application Received"))}}>
                    <CallReceivedIcon/>
                    Apps In
                </div>,
                <div key="seperatorClose2" className="leadQuickOptionsActionSeperator open">
                </div>,
                <div key="closeOption13" className={"leadQuickChangeBtn ns inProgress" + handleSelection("Follow Up")}
                    onClick={()=>{updateLeadBlock("Follow Up", handleSelection("Follow Up"))}}>
                    <AccessAlarmIcon/>
                    Follow Up
                </div>];
        }

        return returnBlock;
    }

    function confirmValidFollowUpTime(){
        function isIsoDate(str) {
            if(str === null || str === undefined || str === ''){
                return false;
            }

            try {
                if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
                const d = new Date(str); 
                return d instanceof Date && !isNaN(d) && d.toISOString()===str;
            }catch(error){
                return false;
            } 
        }

        try{
            if(Date.parse(followUpTime) < new Date().getTime()){
                if(followUpTimeError === ''){
                    setFollowUpTimeError(" error");
                    return false;
                }
            }else{
                if(isIsoDate(followUpTime.toISOString())){
                    if(followUpTimeError === " error"){
                        setFollowUpTimeError('');
                    }
                    return true;
                }else{
                    if(followUpTimeError === ''){
                        setFollowUpTimeError(" error");
                        return false;
                    }
                }
            }
        }catch(error){
            if(followUpTimeError === ''){
                setFollowUpTimeError(" error");
                return false;
            }
        }
    }

    function leadQuickOptionsActionBar(){
        if(props.currentLeadInfo.leadStatus === "Follow Up" && props.showLeadOptions === "inProgress"){
            return "leadQuickOptionsActionBar extended";
        }

        return "leadQuickOptionsActionBar";
    }

    function resetFollowUpTimer(){
        setFollowUpTime(null);
        setFollowUpTimeError(" error");
    }

    function toggleShowNotes(){
        if(showFollowUpNote){
            setShowFollowUpNote(false);
        }else{
            setShowFollowUpNote(true);
        }
    }

    const handleNoteChange = (event) => {
        setFollowUpNote(event.target.value);
    };

    useEffect(() => {
        confirmValidFollowUpTime();
    },[followUpTime]);

    useEffect(() => {
        if(props.currentLeadInfo.leadStatus !== "Follow Up" || props.showLeadOptions !== "inProgress"){
            if(showFollowUpNote){
                setShowFollowUpNote(false);
            }
        }

    },[props.showLeadOptions, props.currentLeadInfo.leadStatus]);

    return (
        <div className="leadOptionsBoard" onClick={(evt)=> {props.handleClose(evt)}}>
            <div className="leadOptionsBoardInnerWrap moduleBlock">
                <div className="miniModuleHeader">
                    <div className="notificationHeaderTitle">
                        Update Lead Status
                    </div>
                    <div className="closeBtn" onClick={()=> {toggleLeadQuickView()}}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className="miniModuleBody">
                    <div className="leadQuickOptionsNav">
                        <div className={"leadQuickTopBtn ns" + currentQuickAccess("closed")} onClick={()=>{props.setShowLeadOptions("closed")}}>
                            Close
                        </div>
                        <div className={"leadQuickTopBtn ns" + currentQuickAccess("contactAttempted")} onClick={()=>{props.setShowLeadOptions("contactAttempted")}}>
                            Contact Attempted
                        </div>
                        <div className={"leadQuickTopBtn ns" + currentQuickAccess("inProgress")} onClick={()=>{props.setShowLeadOptions("inProgress")}}>
                            In Progress
                        </div>
                    </div>
                    <div className="leadQuickOptions">
                        <div className={"leadQuickOptionsCard " + props.showLeadOptions}>
                            {showFollowUpNote ? 
                                <textarea
                                    className="quickCreateNoteBlock"
                                    placeholder="Create a note with your follow up"
                                    onChange={handleNoteChange}
                                    />
                                :
                                <LeadBlock
                                    leadBlockInfo={props.currentLeadInfo}
                                    changeActivityView={props.changeActivityView}
                                    leadBlockType={props.showLeadOptions}
                                    detailedView={true}
                                    singleLeadView={props.singleLeadView}
                                    followUpTime={followUpTime}
                                    resetFollowUpTimer={resetFollowUpTimer}/>
                            }

                        </div>
                        {props.showLeadOptions === "default" || props.showLeadOptions === "lead" ?
                            props.showLeadOptions === "default" ?
                                <div key="defaultPrompt" className={"defaultLeadprompt ns"}>
                                    Select a Status
                                </div>
                            :
                            <div key="defaultPrompt" className={"defaultLeadprompt ns"}>
                                Currently in Lead Queue
                            </div>
                        :
                        <div className="leadQuickOptionsActionsPanel">
                            { printQuickTab(props.showLeadOptions) }
                        </div>
                        }
                    </div>
                    <div className={leadQuickOptionsActionBar()}>
                        { props.currentLeadInfo.leadStatus === "Follow Up" && props.showLeadOptions === "inProgress" ?
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label={followUpTimeError ? "Invalid Time Selected" : followUpTime === null || followUpTime === '' ? "Select Follow Up Time" : "Follow Up Time"}
                                    value={followUpTime ? followUpTime : initialTime}
                                    disablePast={true}
                                    className={followUpTime ? "followUpDateTimeSelector" + followUpTimeError : "followUpDateTimeSelector awaiting"}
                                    onChange={handleFollowUpTimeChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        : null
                        }
                        { props.currentLeadInfo.leadStatus === "Follow Up" && props.showLeadOptions === "inProgress" ?
                            <div className={followUpNote ? "quickAccessAddNote" : "quickAccessAddNote inactive"} onClick={()=>{toggleShowNotes()}}><AddCommentIcon/></div>
                        : null
                        }
                        {   props.currentLeadInfo.leadStatus === "Follow Up" && !followUpTime || props.currentLeadInfo.leadStatus === "Follow Up" &&  followUpTimeError === " error" ?
                            <div className={"updateLeadQuickAccess ns inactive"}>
                                Update
                            </div>
                        :
                            <div className={"updateLeadQuickAccess ns " + detectLeadInfoChange()} onClick={detectLeadInfoChange() === " inactive" ? null : ()=>{confirmUpdateLeadBlock()}}>
                                Update
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default UpdateLeadPanel;