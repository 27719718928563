import React from 'react';
import { useState, useEffect } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InboxIcon from '@mui/icons-material/Inbox';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import CampaignIcon from '@mui/icons-material/Campaign';

function BidsKanbanHeader(props){
    const filterHandler = props?.sessionVars?.envVars?.filterHandler;

    useEffect(() => {
        //console.log(props);
    }, []);

    return (
        <div className="headerBar fR">
            <div className="leadCategory lead">
                <div className="kanbanNotifications cC">
                    {props.bidBlockKanbanData?.stage1TotalUnreadRequests > 0 &&
                        <div
                            className={"missedFollowUpsBubble" + props.notificationStatus("bids", "notification1", "stage1")}
                            onClick={()=>{filterHandler?.functions?.updateFilterAttr(null, "bids", "notification1", "stage1")}}
                        >
                            {props.bidBlockKanbanData?.stage1TotalUnreadRequests}
                        </div>
                    }
                    {props.bidBlockKanbanData?.stage1TotalUnreadBidding > 0 &&
                        <div
                            className={"activeFollowUpsBubble" + props.notificationStatus("bids", "notification2", "stage1")}
                            onClick={()=>{filterHandler?.functions?.updateFilterAttr(null, "bids", "notification2", "stage1")}}
                        >
                            {props.bidBlockKanbanData?.stage1TotalUnreadBidding}
                        </div>
                    }
                </div>
                <div className="kanbanHeaderCategory">
                    New
                </div>
                <div className="leadCategoryIcon">
                    <InboxIcon/>
                </div>
            </div>
            <div className="leadCategory contactAttempted">
                <div className="kanbanNotifications">
                    {props.bidBlockKanbanData?.stage2TotalUnreadRequests > 0 &&
                        <div
                            className={"missedFollowUpsBubble" + props.notificationStatus("bids", "notification1", "stage2")}
                            onClick={()=>{filterHandler?.functions?.updateFilterAttr(null, "bids", "notification1", "stage2")}}
                        >
                            {props.bidBlockKanbanData?.stage2TotalUnreadRequests}
                        </div>
                    }
                    {props.bidBlockKanbanData?.stage2TotalUnreadBidding > 0 &&
                        <div
                            className={"activeFollowUpsBubble" + props.notificationStatus("bids", "notification2", "stage2")}
                            onClick={()=>{filterHandler?.functions?.updateFilterAttr(null, "bids", "notification2", "stage2")}}
                        >
                            {props.bidBlockKanbanData?.stage2TotalUnreadBidding}
                        </div>
                    }
                </div>
                <div className="kanbanHeaderCategory">
                    Under Review
                </div>
                <div className="leadCategoryIcon">
                    <FlagCircleIcon/>
                </div>
            </div>
            <div className="leadCategory inProgress">
                <div className="kanbanNotifications">
                    {props.bidBlockKanbanData?.stage3TotalUnreadRequests > 0 &&
                        <div
                            className={"missedFollowUpsBubble" + props.notificationStatus("bids", "notification1", "stage3")}
                            onClick={()=>{filterHandler?.functions?.updateFilterAttr(null, "bids", "notification1", "stage3")}}
                        >
                            {props.bidBlockKanbanData?.stage3TotalUnreadRequests}
                        </div>
                    }
                    {props.bidBlockKanbanData?.stage3TotalUnreadBidding > 0 &&
                        <div
                            className={"activeFollowUpsBubble" + props.notificationStatus("bids", "notification2", "stage3")}
                            onClick={()=>{filterHandler?.functions?.updateFilterAttr(null, "bids", "notification2", "stage3")}}
                        >
                            {props.bidBlockKanbanData?.stage3TotalUnreadBidding}
                        </div>
                    }
                </div>
                <div className="kanbanHeaderCategory">
                    {props?.bidVerbiage === "Interest" ?
                        "Interests"
                    :
                        "Bidding"
                    }
                </div>
                <div className="leadCategoryIcon">
                    <CampaignIcon/>
                </div>
            </div>
            <div className="leadCategory closed">
                <div></div>
                <div className="kanbanHeaderCategory">
                    Completed
                </div>
                <div className="leadCategoryIcon">
                    <CheckCircleIcon/>
                </div>
            </div>
        </div>
    )
}

export default BidsKanbanHeader;