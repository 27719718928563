import React, { useEffect, useState } from 'react';

function PolicyVars(props){
    const localPolicies = JSON.parse(localStorage.getItem('policies')) || {};
    const localOrigination = localPolicies.origination || {};
    const localServicing = localPolicies.servicing || {};

    const policiesReport = {
        "recordID": 8954,
        "active": true,
        "relatedUserID": 1,
        "userFullName": "Matthew Olivos",
        "userAccountID": 1,
        "query": `((recordID not_blank 'true'))`,
        // "query": `((recordID not_blank 'true') AND (
        //     policyStatus match 'Application Sent' OR
        //     policyStatus match 'Bids' OR
        //     policyStatus match 'Client Deceased' OR
        //     policyStatus match 'Client Not Interested' OR
        //     policyStatus match 'Closed Lost' OR
        //     policyStatus match 'Closed Lost no bid' OR
        //     policyStatus match 'Closed Lost to Competitor' OR
        //     policyStatus match 'Closed Lost with Bid' OR
        //     policyStatus match 'Closed Won' OR
        //     policyStatus match 'Contact Attempted' OR
        //     policyStatus match 'Failed to Contact' OR
        //     policyStatus match 'Invoicing' OR
        //     policyStatus match 'Lead' OR
        //     policyStatus match 'Matured During Rescission' OR
        //     policyStatus match 'Meds/Illustration Ordered' OR
        //     policyStatus match 'On Hold' OR
        //     policyStatus match 'On Hold/Pre-Priced' OR
        //     policyStatus match 'Pricing / Valuation' OR
        //     policyStatus match 'Rescission'
        //     policyStatus match 'Send to 3rd Party'
        //     policyStatus match 'Shipped'
        //     policyStatus match 'Unqualified Closed'
        // ))`,
        "stem": ["origination", "servicing"],
        "branch": "policies",
        "criteria": {
            "existing": [
                {
                    "attr": "recordID",
                    "criterion": {
                        "not_blank": true
                    },
                    "formType": "int",
                    "groupID": 1,
                    "groupOperator": "AND",
                    "inlineOperator": "AND"
                }
            ],
            "groupBy": ""
        },
        "columns": [
            {
                "id": "_9s43jrd5l",
                "columnName": "policyStatus",
                "friendlyTerm": "Policy Status",
                "frozen": false,
                "editable": true,
                "width": 152
            },
            {
                "id": "_je0e3oy9e",
                "columnName": "policyNumber",
                "friendlyTerm": "Policy Number",
                "frozen": false,
                "editable": true,
                "width": 152
            },
            {
                "id": "_yq0vfwexj",
                "columnName": "primaryFirstName",
                "friendlyTerm": "Primary First Name",
                "frozen": false,
                "editable": true,
                "width": 152
            },
            {
                "id": "_hqfet4uup",
                "columnName": "primaryLastName",
                "friendlyTerm": "Primary Last Name",
                "frozen": false,
                "editable": true,
                "width": 152
            },
            {
                "id": "_jy8rvq275",
                "columnName": "deathBenefit",
                "friendlyTerm": "Death Benefit",
                "frozen": false,
                "editable": true,
                "width": 152
            },
            {
                "id": "_e79gufxsf",
                "columnName": "insuranceCompany",
                "friendlyTerm": "Insurance Company",
                "frozen": false,
                "editable": true,
                "width": 152
            }
        ],
        "generated": true,
        "editable": "false",
        "scheduled": false,
        "selectAll": true,
        "groupID": 0,
        "referenceRecordID": null,
        "details": {
            "name": "All Policies",
            "dateCreated": "2024-04-19T18:43:06Z",
            "description": "All Policies",
            "system": false,
            "startDate": "",
            "endDate": "",
            "scrollType": "infinite",
            "displayType": "",
            "viewAccessList": [
                "owner"
            ],
            "editAccessList": [
                "owner"
            ]
        },
        "specialColumns": {
            "rowIndex": false,
            "select": false,
            "view": false
        },
        "selectionState": {
            "selectedRows": [],
            "lastClickedRowIndex": null
        },
        "totalFound": undefined,
        "filteredList":[],
        "loaded": false,
        "list": [],
        "lastRecordID": 8954,
        "search": ""
    }

    const [data, setData] = useState({
        origination : {
            selectedReport : undefined,
            downloadFileType : localOrigination.downloadFileType || "XLSX",
        },
        servicing : {
            selectedReport : undefined,
            downloadFileType : localServicing.downloadFileType || "XLSX",
            preferredPremiumsFormat : localServicing.preferredPremiumsFormat || "schedule",
        },
        selectedReport : policiesReport,
    });

    const functions = {

    }

    const policyVars = {
        data,
        setData,
        functions,
    }

    return policyVars;
};

export default PolicyVars;