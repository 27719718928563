import React, { useEffect, useState, useRef } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CreateTwoToneIcon from '@mui/icons-material/CreateTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

function PropertiesView({
    session,
    buildQuery,
    setShowReadoutAlt,
    showReadoutAlt,
    attributeForm,
    friendlyQuery,
    reportDetails,
    queryArray
  }) {

    const reporting = session?.reporting;
    const selectedReport = reporting?.data?.selectedReport;
    
    const reportShareOptions = [
        { value: ["owner"], label: "Only Me" },
        { value: ["team"], label: "My Team" },
    ];

    const reportEditOptions = [
        { value: ["owner"], label: "Only Me" },
        { value: ["team"], label: "My Team" },
    ];

    const displayTypeOptions = [
        { value: "dynamic", label: "Dynamic", icon: <ViewDayIcon/> },
        { value: "legacy", label: "Legacy", icon: <TableRowsIcon/> },
    ];

    const scrollTypeOptions = [
        { value: "pagination", label: "Pagination", icon: <SwitchLeftIcon/> },
        { value: "infinite", label: "Infinite", icon: <AllInclusiveIcon/> },
    ];

    return (
        <>
            <div className="subHeader bold g f cC fC">
                Details
            </div>
            <div className="reportDetails bR oA g">
                <div className="preferences g oH dP nS fC">
                    <div className="inputRow g f s active">
                        <div className="f cL bold">
                        {reportDetails?.data?.name ? "Report Name" : "*Report Name"}
                        </div>
                        <input
                            className="inputMatch"
                            value={reportDetails?.data?.name ?? ''}
                            onChange={(e) => {
                                reportDetails?.set((prev) => ({
                                    ...prev,
                                    name: e?.target?.value,
                                }));
                            }}
                        />
                    </div>
                    <div className="spacer">
                    </div>
                    <div className="f cL s bold" onClick={()=>{console.log(selectedReport)}}>
                        Description
                    </div>
                    <div className="f cC">
                        <textarea
                            className="multilineInput"
                            placeholder="Create a description of this report for reference to you and any others."
                            value={reportDetails?.data?.description}
                            rows="4"
                            resizable="false"
                            onChange={(e) => {
                                reportDetails?.set((prev) => ({
                                    ...prev,
                                    description: e?.target?.value,
                                }));
                            }}
                        >
                        </textarea>
                    </div>
                    <div className="spacer">
                    </div>
                    <div className="f cL s bold">
                        Query
                        <div className="f cR g">
                            <div
                                className={`btnWIcon bR f cC nS s${buildQuery(attributeForm) ? " p" : " inactive"}`}
                                onClick={() => buildQuery(attributeForm) && setShowReadoutAlt(!showReadoutAlt)}
                            >
                                {showReadoutAlt && buildQuery(attributeForm) ? "Hide" : "Show"} Readout
                                {showReadoutAlt ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </div>
                        </div>
                    </div>
                    {buildQuery(attributeForm) && showReadoutAlt &&
                        <div className="queryStatement f bR dP oA tO">
                            {friendlyQuery(queryArray)}
                        </div>
                    }
                    <div className="spacer">
                    </div>
                    <div className="f cL s bold">
                        Who can view?
                    </div>
                    <div className="trackBar cC g fR">
                        <div className="f cC">
                            <VisibilityTwoToneIcon/>
                        </div>
                        {reportShareOptions.map((option, index) => (
                            <div
                                key={option.value + index}
                                className={`option bold f cC ${option?.inactive ? " inactive" : " p"}${
                                    JSON.stringify(reportDetails?.data.viewAccessList) === JSON.stringify(option.value) ? " active" : ""
                                }`}
                                onClick={option?.inactive ? null : () => {
                                    reportDetails?.set((prev) => ({
                                        ...prev,
                                        viewAccessList: option.value,
                                    }));
                                }}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                    {reportDetails?.data?.viewAccessList === "specified" &&
                        <div
                            className={`dropdown btn s bR cC g nS lH`}
                            onClick={(e) => {
                            }}
                        >
                            <div className="f cC bold p">
                                Select Users
                            </div>
                            <ArrowDropDownIcon/>
                        </div>                                    
                    }
                    <div className="f cL s bold">
                        Who can make changes?
                    </div>
                    <div className="trackBar cC g fR">
                        <div className="f cC">
                            <CreateTwoToneIcon />
                        </div>
                        {reportEditOptions.map((option, index) => (
                            option?.type === "generatedList" ? (
                                <div
                                    key={option?.value + index}
                                    className={`option bold f cC s ${option?.inactive ? " inactive" : " p"}${
                                        JSON.stringify(reportDetails?.data?.editAccessList) === JSON.stringify(option?.value) ? " active" : ""
                                    }`}
                                    onClick={() => {
                                        reportDetails?.set((prev) => ({
                                            ...prev,
                                            editAccessList: option?.value,
                                        }));
                                    }}
                                >
                                    <div className="f cC">
                                        {option?.label + " |" + option?.inactive}
                                    </div>
                                    <ArrowDropDownIcon/>
                                </div>
                            ) : (
                                <div
                                    key={option?.value}
                                    className={`option bold f cC ${option?.inactive ? " inactive" : " p"}${
                                        JSON.stringify(reportDetails?.data?.editAccessList) === JSON.stringify(option?.value) ? " active" : ""
                                    }`}
                                    onClick={option?.inactive ? null : () => {
                                        reportDetails?.set((prev) => ({
                                            ...prev,
                                            editAccessList: option?.value,
                                        }));
                                    }}
                                >
                                    {option?.label}
                                </div>
                            )
                        ))}
                    </div>
                    {reportDetails?.data?.editAccessList === "specified" &&
                        <div className="f cR s">
                            <div
                                className={`dropdown btn s bR cC g nS lH`}
                                onClick={(e) => {
                                }}
                            >
                                <div className="f cC bold p">
                                    Select Users
                                </div>
                                <ArrowDropDownIcon/>
                            </div>
                        </div>
                    }
                    {selectedReport?.stem === "summary" &&
                        <>
                            <div className="f cL s bold" onClick={()=>{console.log(selectedReport)}}>
                                What Report Style?
                            </div>
                            <div className="trackBar cC g fR">
                                <div className="f cC">
                                    <LibraryBooksIcon />
                                </div>
                                {displayTypeOptions.map((option, index) => (
                                    <div
                                        key={option?.value + index}
                                        className={`option alt bold f cC e p${
                                            reportDetails?.data?.displayType === option?.value ? " active" : ''
                                        }`}
                                        onClick={() => {
                                            reportDetails?.set((prev) => ({
                                                ...prev,
                                                displayType: option?.value,
                                            }));
                                        }}
                                    >
                                        <div className="f cC">
                                            {option?.label}
                                        </div>
                                        {option?.icon}
                                    </div>
                                ))}
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    );
};

export default PropertiesView;