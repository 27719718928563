import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import InputField from '../Components/InputField.js';
import ToggleOption from '../Components/ToggleOption.js';
import Preferences from './Preferences.js';

import { parseJWT } from '../envFunctions.js';
import { convertIntToCurrency } from '../envFunctions.js';

import './Profile.css';
import '../Login/Login.css';

import SettingsIcon from '@mui/icons-material/Settings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PolicyIcon from '@mui/icons-material/Policy';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import StorefrontIcon from '@mui/icons-material/Storefront';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import FlakyIcon from '@mui/icons-material/Flaky';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import CelebrationIcon from '@mui/icons-material/Celebration';
import SecurityIcon from '@mui/icons-material/Security';
import ShieldIcon from '@mui/icons-material/Shield';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { red, orange, green, blue, yellow, purple } from '@mui/material/colors';

function Profile(props){
    const [readOnlyStatus, setReadOnlyStatus] = useState(true);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [manageList, setManageList] = useState("affiliates");
    const [profileSearchVal, setProfileSearchVal] = useState('');
    const [tempProfileData, setTempProfileData] = useState();
    const [saveableStatus, setSaveableStatus] = useState('');
    const [profileActivityView, setProfileActivityView] = useState("settings");
    const [showConfirmationModule, setShowConfirmationModule] = useState(false);
    const [affiliatesBoard, setAffiliatesBoard] = useState();
    const [usersBoard, setUsersBoard] = useState();

    function updateReadOnlyStatus() {
        setReadOnlyStatus(readOnlyStatus === true ? false : true);
    }

    function toggleOption(currentToggleStatus, option){
        var newToggleStatus = null;
        if(currentToggleStatus === 'true' || currentToggleStatus === true){
            newToggleStatus = 'false';
        }else{
            newToggleStatus = 'true';
        }

        props.updateSessionProfile(newToggleStatus, option);
        setTempProfileData(tempProfileData => ({...tempProfileData, [option] : newToggleStatus}));
        window.localStorage.setItem( 'sessionInfo', JSON.stringify({[option] : newToggleStatus}));
        props.setSessionInfo(JSON.parse(localStorage.getItem('sessionInfo')));
        var toggleData = {
            field : [option],
            value : newToggleStatus
        };
        updateUserProfile(toggleData);
    }

    function togglePasswordModule(){
        if(showConfirmationModule){
            setShowConfirmationModule(false);
        }else{
            setShowConfirmationModule(true);
        }
    }

    function inputFieldValue(key){
        if(key in props?.profile && readOnlyStatus && showConfirmationModule === false){
            return props?.profile?.[key];
        }

        if(tempProfileData?.[key]){
            return tempProfileData?.[key];
        }
    }

    function resetPasswordConfirmation(){
        setTempProfileData(tempProfileData => ({...tempProfileData, passwordConfirmation: '' }));
    }

    function updateTempProfileData(profileData, initialize){
        if(initialize){
            setTempProfileData(profileData);
        }else{
            setTempProfileData(tempProfileData => ({...tempProfileData, [profileData.target.name]: profileData.target.value }));
        }
    }

    function changeHandler(){
        let status = '';
        let currentPasswordStatus = false;

        if(tempProfileData?.firstName !== props?.profile?.firstName){
            status = ' active';
        }
        if(tempProfileData?.lastName !== props?.profile?.lastName){
            status = ' active';
        }
        if(tempProfileData?.email !== props?.profile?.email){
            status = ' active';
        }

        if(saveableStatus !== status){
            setSaveableStatus(status);
        }
    }

    function loadProfileInfo(){
        if(Object.keys(props.profile).length > 1){
            updateTempProfileData(props.profile, true);
            setLoadingStatus(false);
        }else{
            const paramVals = {
                "userID" : props.userID,
            };

            props.session?.env?.functions?.buildFetchRequest("pullUserInfo", paramVals)
                .then(response => response.json())
                .then(resData => {
                    if(resData.status === 200){
                        props.updateSessionProfile(resData.profileData);
                        updateTempProfileData(resData.profileData, true);
                    }

                    if(resData.status === 500){
                        console.log("No policy found by this id?" + props.userID);
                    }
                    setLoadingStatus(false);
            });   
        }
    }

    function updateUserProfile(toggle){

        if(toggle){
            var reqBody = {
                "field" : toggle.field,
                "value" : toggle.value,
                "toggle" : true,
                "userID" : props.userID
            };
        }else{
            var reqBody = {
                "userID" : props.userID,
                "firstName" : tempProfileData.firstName.trim(),
                "lastName" : tempProfileData.lastName.trim()
            };
        }

        if(tempProfileData.password === tempProfileData.passwordConfirmation && tempProfileData.password !== ''){
            reqBody.password = tempProfileData.password;
        }

        props.session?.env?.functions?.buildFetchRequest("updateUserProfile", reqBody)
            .then(response => response.json())
            .then(resData => {

                if(resData.status === 200){
                    if(toggle === null || toggle === undefined){
                        props.updateSessionProfile(resData.profileData.firstName, "firstName");
                        props.updateSessionProfile(resData.profileData.lastName, "lastName");
                    }
                }
                if(resData.status === 500){
                    console.log("No policy found by this id?" + props.userID);
                }

                resetPasswordConfirmation();
        });
    }

    function hardResetChanges(){
        setShowConfirmationModule(false);
    }

    function confirmPasswordMatch(){
        if(tempProfileData.password === tempProfileData.passwordConfirmation && tempProfileData.password !== ''){
            return true;
        }

        return false;
    }

    function currentActivity(activityView){
        if(profileActivityView === activityView){
            return " selected";
        }

        return "";
    }

    function updateActivityView(activityView){
        var activityViewString = activityView.replace('#', '');
        setProfileActivityView(activityViewString);
    }

    function policyBlockHandler(account){

        if(["Broker", "Agent", "Origination", "Marketing"].includes(account.type)){
            return (
                <div className="pageBlock g bR">
                    <div className="icon f cC p">
                        <ShieldIcon sx={{color:"#00BFFF"}}/>
                    </div>
                    <div className="f cC">
                        {account.closedCaseCount + account.activeCaseCount + account.inactiveCaseCount + " Policies"}
                    </div>
                    <div className="stat f cC g fR">
                        <div className="cC">
                            <AccountTreeIcon sx={{color: green[400]}}/>
                        </div>
                        <div className="bold cL f s">
                            <div className="gCW">
                                Active:
                            </div>
                        </div>
                        <div className="cC f">
                            {account.activeCaseCount}
                        </div>
                        <div className="cR f e">
                            {convertIntToCurrency(account.activeFaceValue, true, true)}
                        </div>
                    </div>
                    <div className="stat f cC g fR">
                        <div className="cC">
                            <CelebrationIcon sx={{color: orange[900]}}/>
                        </div>
                        <div className="bold cL f s">
                            <div className="gCW">
                                Closed:
                            </div>
                        </div>
                        <div className="cC f">
                            {account.closedCaseCount}
                        </div>
                        <div className="cR f e">
                            {convertIntToCurrency(account.closedFaceValue, true, true)}
                        </div>
                    </div>
                    <div className="stat f cC g fR">
                        <div className="cC">
                            <FlakyIcon sx={{color: "#bdbdbd"}}/>
                        </div>
                        <div className="bold cL f s">
                            Inactive:
                        </div>
                        <div className="cC f">
                            {account.inactiveCaseCount}
                        </div>
                        <div className="cR f e">
                            {convertIntToCurrency(account.inactiveFaceValue, true, true)}
                        </div>
                    </div>
                </div>
            )
        }

        if(account.type === "Fund"){
            return (
                <div className="pageBlock g bR">
                    <div className="icon f cC p">
                        <SecurityIcon sx={{color:"#ff9800"}}/>
                    </div>
                    <div className="f cC">
                        {account.closedCaseCount + account.activeCaseCount + account.inactiveCaseCount + " Policies"}
                    </div>
                    <div className="stat f cC g fR">
                        <div className="cC">
                            <AccountTreeIcon sx={{color: green[400]}}/>
                        </div>
                        <div className="bold cL f s">
                            <div className="gCW">
                                Active:
                            </div>
                        </div>
                        <div className="cC f">
                            {account.activeCaseCount}
                        </div>
                        <div className="cR f e">
                            {convertIntToCurrency(account.activeFaceValue, true, true)}
                        </div>
                    </div>
                    <div className="stat f cC g fR">
                        <div className="cC">
                            <FlakyIcon sx={{color: blue[600]}}/>
                        </div>
                        <div className="bold cL f s">
                            <div className="gCW">
                                Closed:
                            </div>
                        </div>
                        <div className="cC f">
                            {account.closedCaseCount}
                        </div>
                        <div className="cR f e">
                            {convertIntToCurrency(account.closedFaceValue, true, true)}
                        </div>
                    </div>
                    <div className="stat f cC g fR">
                        <div className="cC">
                            <HistoryToggleOffIcon sx={{color: yellow[900]}}/>
                        </div>
                        <div className="bold cL f s">
                            Inactive:
                        </div>
                        <div className="cC f">
                            {account.inactiveCaseCount}
                        </div>
                        <div className="cR f e">
                            {convertIntToCurrency(account.inactiveFaceValue, true, true)}
                        </div>
                    </div>
                </div>
            )
        }
    }

    function leadBlockHandler(account){
        return (
            <div className="pageBlock g bR">
                <div className="icon f cC p">
                    <RingVolumeIcon sx={{color : red[500]}}/>
                </div>
                <div className="f cC">
                    Leads
                </div>
                <div className="stat f cC g fR">
                    <div className="cC">
                        <ViewKanbanIcon sx={{color: green[400]}}/>
                    </div>
                    <div className="bold cL f s">
                        <div className="gCW">
                            Active:
                        </div>
                    </div>
                    <div className="cC f">
                        {account.numActiveLeads}
                    </div>
                    <div className="cR f e">
                        {convertIntToCurrency(account.totalValActiveLeads, true, true)}
                    </div>
                </div>
                <div className="stat f cC g fR">
                    <div className="cC">
                        <RestartAltIcon sx={{color: "#004d40"}}/>
                    </div>
                    <div className="bold cL f s">
                        <div className="gCW">
                            Sent to Abacus:
                        </div>
                    </div>
                    <div className="cC f">
                        {account.numSentToAbacusLeads}
                    </div>
                    <div className="cR f e">
                        {convertIntToCurrency(account.totalValSentToAbacusLeads, true, true)}
                    </div>
                </div>
                <div className="stat f cC g fR">
                    <div className="cC">
                        <DoNotDisturbOnIcon sx={{color: "#ef5350"}}/>
                    </div>
                    <div className="bold cL f s">
                        <div className="gCW">
                            Closed/Lost:
                        </div>
                    </div>
                    <div className="cC f">
                        {account.inactiveCaseCount}
                    </div>
                    <div className="cR f e">
                        {convertIntToCurrency(account.inactiveFaceValue, true, true)}
                    </div>
                </div>
            </div>
        )
    }

    function bidBlockHandler(account){
        return (
            <div className="pageBlock g bR">
                <div className="icon f cC p">
                    <StorefrontIcon sx={{color: "#6a1b9a"}}/>
                </div>
                <div className="f cC">
                    Bids
                </div>
                <div className="stat f cC g fR">
                    <div className="cC">
                        <ViewKanbanIcon sx={{color: green[400]}}/>
                    </div>
                    <div className="bold cL f s">
                        <div className="gCW">
                            Active:
                        </div>
                    </div>
                    <div className="cC f">
                        {account.numActiveLeads}
                    </div>
                    <div className="cR f e">
                        {convertIntToCurrency(account.totalValActiveLeads, true, true)}
                    </div>
                </div>
                <div className="stat f cC g fR">
                    <div className="cC">
                        <CelebrationIcon sx={{color: orange[900]}}/>
                    </div>
                    <div className="bold cL f s">
                        <div className="gCW">
                            Closed Won
                        </div>
                    </div>
                    <div className="cC f">
                        {account.numSentToAbacusLeads}
                    </div>
                    <div className="cR f e">
                        {convertIntToCurrency(account.totalValSentToAbacusLeads, true, true)}
                    </div>
                </div>
                <div className="stat f cC g fR">
                    <div className="cC">
                        <DoNotDisturbOnIcon sx={{color: "#ef5350"}}/>
                    </div>
                    <div className="bold cL f s">
                        <div className="gCW">
                            Closed/Lost:
                        </div>
                    </div>
                    <div className="cC f">
                        {account.inactiveCaseCount}
                    </div>
                    <div className="cR f e">
                        {convertIntToCurrency(account.inactiveFaceValue, true, true)}
                    </div>
                </div>
            </div>
        )
    }

    function decideClassName(accountID, returnOnly){
        var counter = 0;
        var leadsCounter = 0;

        if(!props.filterHandler?.affiliates?.[accountID]?.active){
            counter++;
            if(returnOnly === "policies"){
                return false;
            }
        }

        if(!props.profileProps.kanbanToggles.includes(accountID)){
            counter++;
            leadsCounter++;
        }

        if(!props.profileProps.listToggles.includes(accountID)){
            counter++;
            leadsCounter++;
        }

        if(returnOnly === "leads" && leadsCounter == 2){
            return false;
        }

        if(counter === 3){
            return false;
        }

        return true;
    }

    function printBlock(account, page){
        const activeHandler = props?.profileProps?.sessionVars?.envVars?.adminHandler?.[account?.accountID]?.activeHandler;
        //console.log(activeHandler);

        if(page === "policies"){
            let activeStatus = "inactive";
            let pointerFunction = () => toggleOriginationPolicies(account?.accountID);
            if(activeHandler?.policies){
                activeStatus = "active";
            }

            if(account?.type === "Fund"){
                pointerFunction = () => toggleFundPolicies(account?.accountID);
            }

            if(account?.views.includes("Marketplace") || account?.views.includes("Servicing") || account?.views.includes("Marketing")){
                return (
                    <div className={"pageBlockWrap f g fR " + activeStatus}>
                        <div className="actionBar f g bR p">
                            <div className="f cC option" onClick={()=>{alert("This feature is not yet available and currently under construction.")}}>
                                <StackedBarChartIcon sx={{color: purple[400]}}/>
                            </div>
                            <div className="cC f option bold" onClick={pointerFunction}>
                                {activeStatus === "active" ?
                                    <div className="on cC bR">
                                        Filtered On
                                    </div>
                                    :
                                    <div className="off cC bR">
                                        Filtered Off
                                    </div>
                                }
                            </div>
                        </div>
                        {policyBlockHandler(account)}
                    </div>
                )
            }
        }

        if(page === "leads"){
            let activeStatus = "inactive";
            // if(props?.profileProps?.kanbanToggles?.includes(account?.accountID.toString()) && 
            //     props?.profileProps?.listToggles?.includes(account?.accountID.toString())){
                    
            if(props?.profileProps?.kanbanToggles?.includes(account?.accountID)){
                activeStatus = "active";
            }
            
            if(account?.views.includes("Leads")){
                return (
                    <div className={"pageBlockWrap f g fR " + activeStatus}>
                        <div className="actionBar f g bR p">
                            <div className="f cC option" onClick={()=>{alert("This feature is not yet available and currently under construction.")}}>
                                <StackedBarChartIcon sx={{color: purple[400]}}/>
                            </div>
                            <div className="cC f option bold" onClick={()=>{toggleLeads(account?.accountID, activeStatus)}}>
                                {activeStatus === "active" ?
                                    <div className="on cC bR">
                                        Filtered On
                                    </div>
                                    :
                                    <div className="off cC bR">
                                        Filtered Off
                                    </div>
                                }
                            </div>
                        </div>
                        {leadBlockHandler(account)}
                    </div>
                )
            }
        }

        if(page === "bids"){
            let activeStatus = "inactive";
            if(activeHandler?.bids){
                activeStatus = "active";
            }

            if(account?.views.includes("Bids")){
                return (
                    <div className={"pageBlockWrap f g fR " + activeStatus}>
                        <div className="actionBar f g bR p">
                            <div className="f cC option" onClick={()=>{alert("This feature is not yet available and currently under construction.")}}>
                                <StackedBarChartIcon sx={{color: purple[400]}}/>
                            </div>
                            <div className={"cC f option bold " + activeStatus} onClick={()=>{toggleBids(account?.accountID)}}>
                                {activeStatus === "active" ?
                                    <div className="on cC bR">
                                        Filtered On
                                    </div>
                                    :
                                    <div className="off cC bR">
                                        Filtered Off
                                    </div>
                                }
                            </div>
                        </div>
                        {bidBlockHandler(account)}
                    </div>
                )
            }
        }
    }

    function loadManageList() {
        //let returningBlock = [];
        if (manageList === "affiliates") {
            return affiliatesBoard
        }

        if (manageList === "users") {
            return usersBoard
        }

        //return returningBlock;
    }

    function toggleLeads(accountID, activeStatus) {
        let active = false;
        if (activeStatus === "active") {
          active = true;
        }
      
        if (active) {
          if (props?.profileProps?.kanbanToggles?.includes(accountID)) {
            const updatedArray = props?.profileProps?.kanbanToggles.filter(item => item !== accountID);
            props?.profileProps?.setKanbanToggles(updatedArray);
          }
      
          if (props?.profileProps?.listToggles?.includes(accountID)) {
            const updatedArray = props?.profileProps?.listToggles.filter(item => item !== accountID);
            props?.profileProps?.setListToggles(updatedArray);
          }
        } else {
          if (!props?.profileProps?.kanbanToggles?.includes(accountID)) {
            const updatedArray = props?.profileProps?.kanbanToggles.concat(accountID);
            props?.profileProps?.setKanbanToggles(updatedArray);
          }
      
          if (!props?.profileProps?.listToggles?.includes(accountID)) {
            const updatedArray = props?.profileProps?.listToggles.concat(accountID);
            props?.profileProps?.setListToggles(updatedArray);
          }
        }
    }

    function toggleBids(accountID){
        props?.profileProps?.sessionVars?.envVars?.setAdminHandler(prevState => ({
            ...prevState,
            [accountID]: {
              ...prevState[accountID],
              activeHandler: {
                ...prevState[accountID]?.activeHandler,
                bids: !prevState[accountID]?.activeHandler?.bids
              }
            }
        }));
    }
    function toggleAllAffiliates(){
        let activeCounter = [];
        let affiliates = {...props.filterHandler.affiliates};
        const accountData = props.sessionUser?.accountData;

        //for(let accountID in accountData) {
        for (const accountID of Object.keys(props.sessionUser.accountData)){
            activeCounter[accountID] = true;
            let counter = 0;
            //const account = props.sessionUser.accountData[accountID];
            
            if (affiliates[accountID]?.active === true) {
                counter++;
            }
            
            if (!props.profileProps.kanbanToggles.includes(accountID)) {
                counter++;
            }
            
            if (!props.profileProps.listToggles.includes(accountID)) {
                counter++;
            }
            
            if (counter !== 3) {
                activeCounter[accountID] = false;
                break;
            }
        }

        Object.keys(props.sessionUser.accountData).forEach(function(accountID){
            if(activeCounter[accountID]){
                affiliates[accountID].active = false;
                affiliates.activeCount = affiliates.activeCount - affiliates[accountID].count;
                if(props.filterHandler.affiliates[accountID]?.accountType === "fund"){
                    if(props.profileProps.kanbanToggles.includes(accountID)){
                        props.profileProps.leadsCategoryToggle(parseInt(accountID), null, "kanban");
                    }
                    if(props.profileProps.listToggles.includes(accountID)){
                        props.profileProps.leadsCategoryToggle(parseInt(accountID), null, "list");
                    }
                }
            }else{
                affiliates[accountID].active = true
                affiliates.activeCount = affiliates.activeCount + affiliates[accountID].count;

                if(props.filterHandler.affiliates[accountID]?.accountType === "fund"){
                    if(props.profileProps.kanbanToggles.includes(parseInt(accountID))){
                        props.profileProps.leadsCategoryToggle(parseInt(accountID), null, "kanban");
                    }
                    if(props.profileProps.listToggles.includes(parseInt(accountID))){
                        props.profileProps.leadsCategoryToggle(parseInt(accountID), null, "list");
                    }
                }
            }
        });

        props.setFilterHandler({
            ...props.filterHandler,
            affiliates: {
              ...props.filterHandler.affiliates, ...affiliates
            },
        });
    }

    function currentAdminBoard(view){
        if(manageList === view){
            return "profileAdminSelectionOption selected cC";
        }

        return "profileAdminSelectionOption cC";
    }

    function selectAdminBoard(view){
        setManageList(view);
    }

    function updateSearchVal(evt, reset){
        if(reset){
          profileSearchVal('');
        }else{
          setProfileSearchVal(evt.target.value);
        }
    }

    function toggleFiltersSubGroup(type, accountType, focus, nested) {
        const newStatusGroup = { ...props.filterHandler[type] };
        let shouldToggle = true;

        Object.keys(newStatusGroup).forEach((key) => {
            if (type === "affiliates" && typeof newStatusGroup[key] === "object" && newStatusGroup[key].accountType === "fund" && nested === "funds"){
                Object.keys(newStatusGroup[key].funds).forEach((fundKey) => {
                    if (typeof newStatusGroup[key].funds[fundKey] === "object" && newStatusGroup[key].funds[fundKey].active) {
                        shouldToggle = false;
                      }
                });
            }

            if (newStatusGroup[key].active && newStatusGroup[key].accountType === accountType) {
              shouldToggle = false;
            }
        });

        Object.keys(newStatusGroup).forEach((key) => {
            if (JSON.stringify(key) === JSON.stringify(focus)) {
                newStatusGroup[key].active = !shouldToggle;
            }else if(type === "affiliates" && typeof newStatusGroup[key] === "object" && newStatusGroup[key].accountType === "fund" && nested === "funds" && focus){
                Object.keys(newStatusGroup[key].funds).forEach((fundKey) => {
                    if (typeof newStatusGroup[key].funds[fundKey] === "object") {
                        if(parseInt(fundKey) === parseInt(focus)){
                            newStatusGroup[key].funds[fundKey].active = !shouldToggle;
                        }else{
                            newStatusGroup[key].funds[fundKey].active = shouldToggle;
                        }
                    }
                });
            }else{
                if (type === "affiliates" && typeof newStatusGroup[key] === "object" && newStatusGroup[key].accountType === "fund" && nested === "funds"){
                    Object.keys(newStatusGroup[key].funds).forEach((fundKey) => {
                        if (typeof newStatusGroup[key].funds[fundKey] === "object") {
                            newStatusGroup[key].funds[fundKey].active = shouldToggle;
                        }
                    });
                }

                if (newStatusGroup[key].accountType === accountType) {
                    newStatusGroup[key].active = shouldToggle;
                };
            }
        });

        props.setFilterHandler({
          ...props.filterHandler,
          [type]: newStatusGroup,
        });
    }

    function toggleOriginationPolicies(key) {
        let toggleData = props?.filterHandler?.affiliates?.[key];
        let activeStatus = !toggleData?.active;

        //console.log(props?.profileProps?.sessionVars?.envVars?.adminHandler?.[account?.accountID].activeHandler);
        props.setFilterHandler({
            ...props.filterHandler,
            affiliates: {
                ...props?.filterHandler?.affiliates,
                [key]: {
                    ...props?.filterHandler?.affiliates[key],
                    active: activeStatus
                }
            }
        });

        props?.profileProps?.sessionVars?.envVars?.setAdminHandler(prevState => ({
            ...prevState,
            [key]: {
              ...prevState[key],
              activeHandler: {
                ...prevState[key]?.activeHandler,
                policies: activeStatus
              }
            }
        }));
    }

    function toggleFundPolicies(key) {
        const newStatusGroup = { ...props.filterHandler?.affiliates?.[key] };
        let shouldToggle = true;

        if(props.filterHandler?.affiliates?.[key]?.active){
            shouldToggle = false;
        }

        Object.keys(newStatusGroup.funds).forEach((fundKey) => {
            if (typeof newStatusGroup.funds[fundKey] === "object"){
                newStatusGroup.funds[fundKey].active = shouldToggle;
            }
        });

        props.setFilterHandler({
            ...props.filterHandler,
            affiliates: {
                ...props.filterHandler?.affiliates,
                [key]: {
                    ...props.filterHandler?.affiliates?.[key],
                    funds: {
                        ...newStatusGroup.funds  // Update the funds object
                    }
                }
            }
        });

        props.setFilterHandler({
            ...props.filterHandler,
            affiliates: {
                ...props.filterHandler?.affiliates,
                [key]: {
                    ...props.filterHandler?.affiliates?.[key],
                    active: shouldToggle  // Update the active attribute
                }
            }
        });

        props?.profileProps?.sessionVars?.envVars?.setAdminHandler(prevState => ({
            ...prevState,
            [key]: {
              ...prevState[key],
              activeHandler: {
                ...prevState[key]?.activeHandler,
                policies: shouldToggle
              }
            }
        }));
    }

    function loadAffiliates(){
        let returningBlock = [];

        Object.keys(props.sessionUser?.accountData).forEach(function(accountID) {
            const account = props.sessionUser?.accountData?.[accountID];
            if(account?.name?.toLowerCase().includes(profileSearchVal.toLocaleLowerCase())){
                returningBlock.push(
                    <div key={"profileBlock" + accountID} className="profileAccBlock cC g fMP bR fC dP">
                        <div className="header f g">
                            <div className="cL s bold">
                                {account.name}
                            </div>
                            <div className="cC">
                                <div className="cL f s">
                                    {"Account Type: " + account.type}
                                </div>
                                <div className="cC f">
                                    {"Team Members: " + account.teamList?.length}
                                </div>
                                <div className="cR f e">
                                    {account.shareLink && <a className="link" href={account.shareLink} target="_blank">Drive Link</a>}
                                </div>
                            </div>
                        </div>
                        <div className="divider">
                        </div>
                        <div className="body f g cL dP fR bR">
                            {printBlock(account, "policies")}
                            {printBlock(account, "leads")}
                            {printBlock(account, "bids")}
                        </div>
                    </div>
                )
            }
          });

          setAffiliatesBoard(returningBlock);
    }

    function loadUsers(){
        let returningBlock = [];

        Object.entries(props.sessionUser?.accountData).forEach(([accountID, account]) => {
            let returningBlockBit = [];
            Object.entries(account).forEach(([key, value]) => {

                if (key === "teamList") {
                    value.map((teamMember, key) => {
                        if(key !== 0){
                            returningBlockBit.push(
                                <div
                                    key={"divider"+key}
                                    className="divider"
                                >
                                </div>
                            );
                        }

                        returningBlockBit.push(
                            <div key={teamMember.email} className="g">
                                <div className="cL s">
                                    {teamMember.firstName + " " + teamMember.lastName}
                                </div>
                                <div className="cL s">
                                    {teamMember.email}
                                </div>
                                <div className="cR e moreBtn">
                                    <MoreHorizIcon/>
                                </div>
                            </div>
                        );
                    });
                }
            });

            returningBlock.push(
                <div key={account.name + "UserList"} className="managedUserBlock dP">
                    <div className="bold g cL">
                        {account.name}
                    </div>
                    <div className="g cC">
                        {returningBlockBit}
                    </div>
                </div>
            );
        });

        setUsersBoard(returningBlock);

    }

    useEffect(() => {
        loadProfileInfo();
    }, []);

    useEffect(() => {
        //console.log(tempProfileData);
        changeHandler();
    }, [tempProfileData]);

    useEffect(() => {
        loadAffiliates();
        loadUsers();
    }, [props?.sessionUser?.accountData,
        props?.profileProps?.kanbanToggles,
        props?.profileProps?.sessionVars?.envVars,
        profileSearchVal
    ]);

    return (
        <div className="profilePage">
            { showConfirmationModule &&
                <div className="confirmationModule moduleBlock">
                    <div className="profileModuleTitle">
                        Password Change Confirmation
                    </div>
                    <div className="confirmationModulePrompt">
                        To change your password, please type your new password two times to confirm.
                    </div>
                    <InputField
                        loadingStatus={false}
                        value={inputFieldValue("password")}
                        functionPointer={updateTempProfileData}
                        placeholder={"New Password"}
                        name={"password"}
                        isPassword={true}
                        readOnly={false}
                        autoComplete={false}
                        requirements={true}
                    />
                    <InputField
                        loadingStatus={false}
                        value={inputFieldValue("passwordConfirmation")}
                        functionPointer={updateTempProfileData}
                        placeholder={"Retype Password"}
                        name={"passwordConfirmation"}
                        isPassword={true}
                        readOnly={false}
                        autoComplete={false}
                    />
                    <div
                        className="changeProfileData selected"
                        onClick={ () => { hardResetChanges() }}>
                        Cancel
                    </div>
                    <div
                        className={confirmPasswordMatch() ? "saveProfileData active" : "saveProfileData"}
                        onClick={confirmPasswordMatch() ? () => {
                            updateUserProfile();
                            setShowConfirmationModule(false);
                        } : null }>
                        Confirm
                    </div>
                </div>
            }

            <div className="profileModule moduleBlock">
                <div className="profileWrap g">
                    <div className="inlineActionBar g cL f fR">
                        {props?.session?.user?.data?.views?.length === 1 &&
                            <Link
                                to={props?.session?.env?.functions?.homepage()}
                                className="inlineNav g cC"
                            >
                                <div className="goBackBtn">
                                    <div className="goBackBtnInner cC g f fR fC dP">
                                        <ArrowBackIosNewIcon/>
                                    </div>
                                </div>
                                <div className="inlineNavDirection">
                                    Back to {props?.session?.user?.data?.views}
                                </div>
                            </Link>
                        }
                        {parseJWT(props.sessionUser?.sessionToken)?.managedAccounts?.length > 0 &&
                            <div className="profileTab f g gC2">
                                <div className={"switchViewBtn f g cC s e" + currentActivity("settings")} onClick={() => {updateActivityView("settings")}}>
                                    <div className="switchViewBtnOption">
                                        Settings
                                    </div>
                                    <div className="inlineBtnIcon cC">
                                        <SettingsIcon/>
                                    </div>
                                </div>
                                <div className={"switchViewBtn f g cC s e" + currentActivity("admin")} onClick={() => updateActivityView("admin")}>
                                    <div className="switchViewBtnOption">
                                        Admin
                                    </div>
                                    <div className="inlineBtnIcon cC">
                                        <ManageAccountsIcon/>
                                    </div>
                                </div>
                            </div>
                        }
                        {!parseJWT(props.sessionUser?.sessionToken)?.managedAccounts?.length > 0 &&
                            <div className="header f bold cC gC2">
                                Profile Settings
                            </div>
                        }                       
                    </div>
                    {currentActivity("settings") &&
                        <div className="profileCurrentView profile g s e b fC">
                           <div className="profileModuleTitle cL">
                                <div className="bold f cL">
                                    Profile
                                </div>
                            </div>
                            <div className="content f g">
                                <div className="profileDetails g dG">
                                    <div className="profileDetailsHeader">
                                        Personal Information
                                    </div>
                                    <div className="profileDtailsSection g dG">
                                        <div className="fieldCouple g dG">
                                            <InputField
                                                loadingStatus={loadingStatus}
                                                value={ inputFieldValue("firstName") }
                                                functionPointer={ updateTempProfileData }
                                                placeholder={ "First Name" }
                                                name={ "firstName" }
                                                readOnly={ readOnlyStatus }
                                                autoComplete={false}
                                            />
                                            <InputField
                                                loadingStatus={loadingStatus}
                                                value={ inputFieldValue("lastName") }
                                                functionPointer={ updateTempProfileData }
                                                placeholder={ "Last Name" }
                                                name={ "lastName" }
                                                readOnly={ readOnlyStatus }
                                                autoComplete={false}
                                            />
                                        </div>
                                        <div className="fieldCouple g dG">
                                            <InputField
                                                loadingStatus={loadingStatus}
                                                value={ inputFieldValue("email") }
                                                functionPointer={ updateTempProfileData }
                                                placeholder={"Email"}
                                                name={"email"}
                                                readOnly={true}
                                                autoComplete={false}
                                            />
                                            <div className="changePassword cR"
                                                onClick={()=>{props?.session?.env?.setOverlay("passwordChange")}}
                                                // onClick={()=>{togglePasswordModule()}}
                                            >
                                                Change Password
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profileDetailsHeader">
                                        Account Information
                                    </div>
                                    <InputField
                                        loadingStatus={loadingStatus}
                                        value={ inputFieldValue("companyName") }
                                        placeholder={ "Company" }
                                        name={ "companyName" }
                                        readOnly={ true }
                                        autoComplete={false}
                                    />
                                    <InputField
                                        loadingStatus={loadingStatus}
                                        value={ inputFieldValue("pointOfContact") }
                                        placeholder={ "Point of Contact" }
                                        name={ "pointOfContact" }
                                        readOnly={ true }
                                        autoComplete={false}
                                    />
                                    <div className="fieldCouple">
                                        <div className={ readOnlyStatus ? "changeProfileData" : "changeProfileData selected" } onClick={ updateReadOnlyStatus }>
                                            { readOnlyStatus ? "Make Changes" : "Undo" }
                                        </div> {
                                        readOnlyStatus ?
                                        null :
                                        <div className={"saveProfileData" + saveableStatus} onClick={ saveableStatus !== '' ? () => {updateReadOnlyStatus(); updateUserProfile(); } : null }>
                                            Save changes
                                        </div> }                            
                                    </div>
                                </div>
                                <div className="profileAlterSection g">
                                    <Preferences
                                        session={props?.session}
                                    />
                                    {/* <div className="profileToggles">
                                        <ToggleOption
                                            toggleStatus={ tempProfileData?.emailNotifications }
                                            option="emailNotifications"
                                            optionString={ "Receive Emails Notifications" }
                                            functionPointer={ toggleOption }
                                        />
                                        <ToggleOption
                                             toggleStatus={ tempProfileData.darkMode }
                                             option="darkMode"
                                             optionString={ "Dark Mode" }
                                             functionPointer ={ toggleOption }
                                         />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    }
                    {currentActivity("admin") &&
                        <div className="profileCurrentView admin g s e b">
                            <div className="f">
                                <div className="profileModuleTitle cL">
                                    <div className="bold f cL">
                                        Admin Controls
                                    </div>
                                </div>
                                <div className="profileDetails g dG">
                                    <div className="profileDetailsHeader">Under Management</div>
                                    <div className="profileDtailsSection t s">
                                        <div className="cL g">
                                            <div
                                                className={currentAdminBoard("affiliates")}
                                                onClick={() => {selectAdminBoard("affiliates")}}>
                                                <span>
                                                    Affiliates
                                                </span>
                                                <SwitchAccountIcon/>
                                            </div>
                                            <div
                                                className={currentAdminBoard("users")}
                                                onClick={() => {selectAdminBoard("users")}}>
                                                <span>
                                                    Users
                                                </span>
                                                <SupervisedUserCircleIcon/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content f g">
                                <div className="profileAlterSection">
                                    <div className="manageHandlerContainer g bR cC fC tC">
                                        { manageList === "affiliates" && 
                                            <div className="f cC">
                                                <div className="manageActionBar cC bR dP">
                                                    <div className="searchBar">
                                                        <input
                                                            value={profileSearchVal}
                                                            onChange={updateSearchVal}
                                                            placeholder="Search Affiliates by name"
                                                        >
                                                        </input>
                                                    </div>
                                                    {/* <div className="gC3 cC g">
                                                        <div className="defaultBtn alt ns cC" onClick={()=>{toggleAllAffiliates()}}>
                                                            Toggle All
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        }
                                        <div className="manageHandler t b cC bR tO gR2 fC">
                                            {loadManageList()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Profile;
/*
<ToggleOption toggleStatus={ tempProfileData.rememberWorkspace } option="rememberWorkspace" optionString={ "Remember Workspace" } functionPointer = { toggleOption }  />
*/