import React, { useEffect, useState, useRef } from 'react';

function DefaultColumnBlock({
    attr,
    attrKey,
    blockID,
    addOrRemoveColumns,
    classNames,
    attributeTypeIcon,
    statusIcon,
    currentView,
    currentAlteringAttr,
    selectAttribute,
    transitionIcon
  }) {

    return (
        <div
            key={blockID}
            className={`row default g p cC bR s e fR nS bold${
                currentAlteringAttr ? " actionReady" : ''}${classNames}`}
            onClick={() => currentView === "requirements" ?
                selectAttribute(attrKey, null, currentAlteringAttr)
            :
                addOrRemoveColumns({
                    objectKey : attrKey,
                })}
        >
            {statusIcon}
            {attributeTypeIcon}
            <div className={`f cL${currentView === "properties" ? " gC3" : " gC2"}`}>
                {attr?.friendlyTerm}
            </div>
            <div className="icon f cC">
                {transitionIcon(attrKey)}
            </div>
        </div>
    );
};

export default DefaultColumnBlock;