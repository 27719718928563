import React, { useEffect, useState } from 'react';

import * as XLSX from 'xlsx';

import ResultsRenderHandler from '../Components/Reporting/SummaryReport/ResultsRenderHandler.js';
import PrintingFunctions from '../Components/Reporting/Functions/PrintingFunctions.js';

function Download({ session }){
    const reporting = session?.reporting;
    // const selectedReport = reporting?.data?.selectedReport;
    // const updateReporting = session?.reporting?.setData;

    function downloadReport({selectedReport, baseData, fetchPath, params, fileName}){
        console.log(selectedReport, baseData);
        if(baseData?.downloadFileType === "CSV"){
            downloadCSVReport(selectedReport);
        }

        if(baseData?.downloadFileType === "XLSX"){
            downloadXLSXReport(selectedReport);
        }

        if(baseData?.downloadFileType === "TXT"){
            downloadCSVReport(selectedReport, "txt");
        }
    }

    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    function getSummarizedData(selectedReport, attributeData) {
        const cachedFormattedDates = new Map();

        const resultsHandler = ResultsRenderHandler({
            session,
            attributeData,
            renderFormattedColumnValue : PrintingFunctions({session, cachedFormattedDates})?.functions?.renderFormattedColumnValue,
            // updateReporting,
        })?.functions;

        const activeCriteria = selectedReport?.criteria?.groupBy?.filter(criterion => 
            !criterion?.inactive && 
            typeof criterion?.attr === 'string' && criterion?.attr?.trim() !== '' &&
            typeof criterion?.formType === 'string' && criterion?.formType?.trim() !== '' &&
            typeof criterion?.combineBy === 'string' && criterion?.combineBy?.trim() !== ''
        ) ?? [];

        const summaryColumns = selectedReport?.columns.filter(column => column?.summarizeBy);
        
        const summarizedData = resultsHandler?.applyHierarchicalGrouping(
            // selectedReport?.list,
            selectedReport?.filteredList ?? selectedReport?.list,
            activeCriteria,
            summaryColumns
        );
        
        return summarizedData || [];
    }

    function generateColumns(selectedReport) {
        const attributeData = session?.[reporting?.data?.dataPointer]?.data?.attributeData;
        const defaultColumns = reporting?.data?.defaultColumns?.[selectedReport?.referenceStem]?.detailedArray || [];
        const existingColumnNames = new Set(selectedReport.columns.map(col => col.columnName));

        const prepareColumns = columns => columns
            ?.filter(col => (col?.fieldBy || col?.columnName) !== (reporting?.data?.selectAllVar || ""))
            .map(col => ({
                columnName: col?.fieldBy || col?.columnName,
                friendlyTerm: attributeData?.[col?.fieldBy || col?.columnName]?.friendlyTerm || col?.columnName
            }));

        const combinedColumns = prepareColumns(selectedReport.columns.filter(col => !col.summarizeBy))
            .concat(prepareColumns(defaultColumns.filter(col => !existingColumnNames.has(col?.columnName))));

        const finalSummaryColumns = prepareColumns(selectedReport.columns.filter(col => col.summarizeBy));
        
        return [...combinedColumns, ...finalSummaryColumns];
    }

    function generateName(selectedReport){
        const isSummaryReport = selectedReport?.stem === "summary";
        const subReportName = selectedReport?.subReport?.name;
        return subReportName ? subReportName.replace(/^ > /, '') : isSummaryReport ? "All Results" : selectedReport?.details?.name;
    }

    function downloadXLSXReport(selectedReport) {
        const isSummaryReport = selectedReport?.stem === "summary";
        const workbook = XLSX.utils.book_new();
        const attributeData = session?.[reporting?.data?.dataPointer]?.data?.attributeData;

        const fetchChildLevelResults = (groupedData, results = []) => {
            if (Array.isArray(groupedData)) {
                results.push(...groupedData);
            } else if (typeof groupedData === 'object' && groupedData !== null) {
                Object.values(groupedData).forEach(subData => {
                    if (subData.list && typeof subData.list === 'object') {
                        fetchChildLevelResults(subData.list, results);
                    }
                });
            }
            return results;
        };

        function flattenGroupedDataForXLSX(groupedData, columnHeaders, level = 0, path = '') {
            const rows = [];
            if(level === 0){
                rows.push(columnHeaders.map(col => col?.friendlyTerm));
            }

            Object.entries(groupedData).forEach(([key, group], index) => {
                const currentPath = path ? `${path} > ${key}` : key;
                const summaryRow = columnHeaders.map(column => {
                    if (column.id === group.id) {
                        return key;
                    } else {
                        const summary = group.summaries && group.summaries[column.fieldBy];
                        if (summary && column.summarizeBy) {
                            return summary[column.summarizeBy];
                        }
                    }
                    return '';
                });

                rows.push(summaryRow);

                if (group.list && typeof group.list === 'object' && !Array.isArray(group.list)) {
                    rows.push(...flattenGroupedDataForXLSX(group.list, columnHeaders, level + 1, currentPath));
                }
            });
            return rows;
        }

        // Function to add a sheet to the workbook
        const addSheet = (data, columns, sheetName, type) => {
            let sheetData;
            if (Array.isArray(data)) {
                sheetData = [columns.map(col => col.friendlyTerm), ...data.map(item => columns.map(col => item[col.columnName] || ''))];
            } else if (typeof data === 'object') {
                if (type === "dynamic") {
                    const flattenNestedLists = (obj) => {
                        let result = [];
                        Object.values(obj).forEach(value => {
                            if (Array.isArray(value.list)) {
                                result = result.concat(value.list);
                            } else if (typeof value === 'object') {
                                result = result.concat(flattenNestedLists(value));
                            }
                        });
                        return result;
                    };

                    sheetData = [
                        columns
                        ?.map(col => col?.friendlyTerm),
                        ...flattenNestedLists(data).map(item =>
                        columns.map(col => item[col?.columnName] || '')
                        )
                    ];
                } else {
                    sheetData = flattenGroupedDataForXLSX(data, selectedReport.columns);
                }
            }
            const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName?.substring(0, 31));
        };

        if(isSummaryReport){
            addSheet(getSummarizedData(selectedReport, attributeData), selectedReport?.columns, "Summary");
        }
        
        const subReportName = selectedReport?.subReport?.name;

        addSheet(isSummaryReport && !subReportName ?
            // getSummarizedData(selectedReport, attributeData) : selectedReport?.list,
            getSummarizedData(selectedReport, attributeData) : selectedReport?.filteredListist ?? selectedReport?.list,
            generateColumns(selectedReport),
            generateName(selectedReport),
            "dynamic"
        );

        // Write the workbook to a file
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        const url = URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }));
        const link = document.createElement("a");
        link.href = url;
        link.download = `${selectedReport.details.name ?? "Unnamed"}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function downloadCSVReport(selectedReport, returnType = "csv") {
        const isSummaryReport = selectedReport?.stem === "summary";
        const attributeData = session?.[reporting?.data?.dataPointer]?.data?.attributeData;

        const generateContent = (columns, format) => {
            // const data = selectedReport?.list;
            const data = selectedReport?.filteredList ?? selectedReport?.list;
            let header = columns.map(columnName => {
                const columnConfigItem = columns.find(config => config?.columnName === columnName);

                return attributeData?.[columnName]?.friendlyTerm ?? `"${columnConfigItem ? columnConfigItem?.friendlyTerm?.replace(/"/g, '""') : columnName}"`
            }).join(format === 'txt' ? "\t" : ",");

            let rows = data.map(row => {
                return columns.map(columnName => {
                    let value = row[columnName] || '';
                    if (typeof value !== 'string') {
                        value = String(value);
                    }

                    if (format === 'csv' && /^\d+$/.test(value)) {
                        value = `="${value}"`;
                    } else {
                        value = `"${value.replace(/"/g, '""')}"`;
                    }
                    return value;
                }).join(format === 'txt' ? "\t" : ",");
            }).join("\n");

            return `data:text/${format};charset=utf-8,${header}\n${rows}`;
        };

        const downloadFile = (content, filename, format) => {
            const encodedUri = encodeURI(content);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `${filename ?? "Unnamed_Report"}${format === 'txt' ? '.txt' : '.csv'}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        const columnNames = selectedReport.columns.map(c => c.columnName);
        const detailsContent = generateContent(columnNames, returnType === 'txt' ? 'txt' : 'csv');

        // const detailsCSVContent = generateContent(sortedListResults, columnNames, returnType);

        if (selectedReport?.stem === "summary") {
            function flattenGroupedDataForCSV(groupedData, columnHeaders, level = 0, path = '') {
                const rows = [];
                Object.entries(groupedData).forEach(([key, group], index) => {
                    const currentPath = path ? `${path} > ${key}` : key;
                    
                    const summaryRow = columnHeaders.map(column => {
                        if (column.id === group.id) {
                            // If the key contains commas or double quotes, enclose it in double quotes and escape double quotes inside the key
                            const needsQuotes = /[, "]/.test(key);
                            return needsQuotes ? `"${key.replace(/"/g, '""')}"` : key;
                        } else {
                            const summary = group.summaries && group.summaries[column.fieldBy];
                            if (summary && column.summarizeBy) {
                                // If the summary value contains commas or double quotes, enclose it in double quotes and escape double quotes inside the value
                                const needsQuotes = /[, "]/.test(summary[column.summarizeBy]);
                                return needsQuotes ? `"${summary[column.summarizeBy].replace(/"/g, '""')}"` : summary[column.summarizeBy];
                            }
                        }
                        return '""';
                    }).join(',');
            
                    rows.push(summaryRow);
            
                    if (group.list && typeof group.list === 'object' && !Array.isArray(group.list)) {
                        rows.push(...flattenGroupedDataForCSV(group.list, columnHeaders, level + 1, currentPath));
                    }
                });
                return rows;
            }

            const generateSubReport = (data, columnHeaders) => {
                const flattenNestedLists = (obj) => {
                    let result = [];
                    Object.values(obj).forEach(value => {
                        if (Array.isArray(value.list)) {
                            result = result.concat(value.list);
                        } else if (typeof value === 'object') {
                            result = result.concat(flattenNestedLists(value));
                        }
                    });
                    return result;
                };
            
                return flattenNestedLists(data).map(item =>
                    columnHeaders.map(col => {
                        const value = item[col?.columnName] || '';
                        const needsQuotes = /[, "]/.test(value);
                        return needsQuotes ? `"${value.replace(/"/g, '""')}"` : `"${value}"`;
                    })
                );
            }

            function downloadSummaryReport(summarizedData, name, type, returnType = 'csv') {
                const columnHeaders = selectedReport.columns;
                const isDynamic = type === "dynamic";
                const dataRows = isDynamic ?
                    generateSubReport(summarizedData, generateColumns())
                :
                    flattenGroupedDataForCSV(summarizedData, columnHeaders);
                const formatHeaders = (columns) => columns.map(column => {
                    return returnType === 'txt' ?
                        column.friendlyTerm
                    :
                        `"${column.friendlyTerm.replace(/"/g, '""')}"`;
                }).join(returnType === 'txt' ? "\t" : ',');
            
                const reportHeaders = isDynamic ? formatHeaders(generateColumns()) : formatHeaders(columnHeaders);
                const content = `data:text/${returnType === 'txt' ? 'plain' : 'csv'};charset=utf-8,${reportHeaders}\n${dataRows.join('\n')}`;
            
                const encodedUri = encodeURI(content);
                const link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", `${name}.${returnType}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            
            const summarizedData = getSummarizedData(selectedReport, attributeData);
            const subReportName = selectedReport?.subReport?.name;

            downloadSummaryReport(summarizedData, `${selectedReport?.details?.name} Summary`, undefined, returnType);

            downloadSummaryReport(
                summarizedData
                `All Results`,
                "dynamic",
                returnType
            );

            if(isSummaryReport && subReportName){
                const columnNames = generateColumns().map(c => c?.columnName);
                const subReportCSVContent = generateContent(columnNames, returnType);
                downloadFile (subReportCSVContent, `${generateName(selectedReport)}`, returnType);
            }
        } else {
            downloadFile (detailsContent, `${selectedReport.details.name}`, returnType);
        }
    }

    const triggerDownload = (fileContent, fileType, fileName) => {
        const blob = new Blob([fileContent], { type: fileType });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      };
      
      // Generate file content based on type
    const generateFileContent = (data, fileType) => {
        switch (fileType) {
            case 'XLSX':
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Premium Schedule");
            return XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                case 'CSV':
            return [
                ['Date', 'Premium', 'Death Benefit'],
                ...data.map(item => [item.date, item.premium, item.deathBenefit])
            ].map(row => row.join(',')).join('\n');
            case 'TXT':
                return data.map(item => `Date: ${item.date}, Premium: ${item.premium}, Death Benefit: ${item.deathBenefit}`).join('\n');
            default:
                throw new Error("Unsupported file type");
        }
    };
      
      // Main download function
    const downloadPremiumSchedule = (selectedReport, fileType) => {
        if (!selectedReport?.length) return console.error("No data available for download.");
        const fileContent = generateFileContent(selectedReport, fileType);
        const fileTypes = {
            'XLSX': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'CSV': 'text/csv',
            'TXT': 'text/plain'
        };
        triggerDownload(fileContent, fileTypes[fileType], `premium_schedule.${fileType.toLowerCase()}`);
    };

    function downloadLineGraph(canvas, reportName) {
        const dataUrl = canvas.toDataURL("image/png"); // Convert canvas to image
        const link = document.createElement('a'); // Create a temporary link element
        link.href = dataUrl;
        link.download = `${reportName ?? "Unnamed"}_Illustration.png`; // Filename for the downloaded image
        link.click(); // Programmatically trigger the download
    }

    function generateLineGraph(session, selectedReport){
        const { reportName, graphData, plotPointAttr, xAxisLabel, yAxisLabel, cycles } = selectedReport;
        const canvas = document.createElement('canvas'); // Create an offscreen canvas
        const width = 800;
        const height = 500;
        canvas.width = width;
        canvas.height = height;
    
        const ctx = canvas.getContext('2d');
        const labelPadding = 10;
        const gridLineWidth = 1;
        const borderRadius = 5;
      
        const illustrationData = graphData.slice(0, cycles);
      
        if (!illustrationData || !plotPointAttr) return;
    
        const maxValue = Math.max(1, ...illustrationData.map(item => item?.[plotPointAttr])) * 1.1;
        const minValue = 0;
      
        const largestLabel = session?.env?.functions?.convertIntToCurrency(maxValue.toFixed(2));
      
        const calculateTextWidth = (ctx, text, font) => {
          ctx.font = font;
          return ctx.measureText(text).width;
        };
      
        const paddingLeft = calculateTextWidth(ctx, largestLabel, "12px system-ui") + 40;
        const paddingBottom = 40;
        const paddingRight = 40;
        const paddingTop = 20;
      
        const scaleX = (index) => paddingLeft + (index / (illustrationData.length - 1)) * (width - paddingLeft - paddingRight);
        const scaleY = (plotPoint) => height - paddingBottom - ((plotPoint - minValue) / (maxValue - minValue)) * (height - paddingBottom - paddingTop);
      
        ctx.clearRect(0, 0, width, height);
        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, width, height);
      
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(paddingLeft + borderRadius, paddingTop);
        ctx.lineTo(width - paddingRight - borderRadius, paddingTop);
        ctx.quadraticCurveTo(width - paddingRight, paddingTop, width - paddingRight, paddingTop + borderRadius);
        ctx.lineTo(width - paddingRight, height - paddingBottom - borderRadius);
        ctx.quadraticCurveTo(width - paddingRight, height - paddingBottom, width - paddingRight - borderRadius, height - paddingBottom);
        ctx.lineTo(paddingLeft + borderRadius, height - paddingBottom);
        ctx.quadraticCurveTo(paddingLeft, height - paddingBottom, paddingLeft, height - paddingBottom - borderRadius);
        ctx.lineTo(paddingLeft, paddingTop + borderRadius);
        ctx.quadraticCurveTo(paddingLeft, paddingTop, paddingLeft + borderRadius, paddingTop);
        ctx.clip();
    
        ctx.strokeStyle = "#EFEFEF";
        ctx.lineWidth = gridLineWidth;
      
        const numberOfGridLinesY = 5;
        for (let i = 0; i <= numberOfGridLinesY; i++) {
          const value = minValue + ((maxValue - minValue) / numberOfGridLinesY) * i;
          const y = scaleY(value);
          ctx.beginPath();
          ctx.moveTo(paddingLeft, y);
          ctx.lineTo(width - paddingRight, y);
          ctx.stroke();
        }
      
        ctx.beginPath();
        ctx.moveTo(paddingLeft, height - paddingBottom);
        ctx.lineTo(width - paddingRight, height - paddingBottom);
        ctx.moveTo(paddingLeft, height - paddingBottom);
        ctx.lineTo(paddingLeft, paddingTop);
        ctx.strokeStyle = "#E2E2E2";
        ctx.lineWidth = 2;
        ctx.stroke();
      
        ctx.restore();
      
        ctx.fillStyle = "#000";
        ctx.font = "12px system-ui";
        ctx.textAlign = "right";
        for (let i = 0; i <= numberOfGridLinesY; i++) {
          const value = minValue + ((maxValue - minValue) / numberOfGridLinesY) * i;
          const y = scaleY(value);
          ctx.fillText(`${session?.env?.functions?.convertIntToCurrency(value.toFixed(2))}`, paddingLeft - labelPadding * 2, y + 3);
        }
      
        const maxXLabels = 24;
        const step = Math.ceil(illustrationData.length / maxXLabels);
    
        ctx.textAlign = "center";
        ctx.font = "10px system-ui";
        illustrationData.forEach((item, index) => {
          if (index % step === 0) {
            const x = scaleX(index);
            const date = new Date(item?.[xAxisLabel]);
            const isNewYear = date.getMonth() === 0;
    
            ctx.save();
            ctx.translate(x, height - paddingBottom + labelPadding * 2);
            ctx.rotate(-Math.PI / 6);
    
            if (isNewYear) {
              ctx.font = "bold 10px system-ui";
            }
            ctx.fillText(session?.env?.functions?.reformatDate(item?.[xAxisLabel]), 0, 0);
            ctx.restore();
          }
        });
    
        ctx.beginPath();
        illustrationData.forEach((item, index) => {
          const x = scaleX(index);
          const y = scaleY(item?.[plotPointAttr] || 0);
          if (index === 0) {
            ctx.moveTo(x, y);
          } else {
            ctx.lineTo(x, y);
          }
        });
        ctx.strokeStyle = "#00aaff";
        ctx.lineWidth = 2;
        ctx.stroke();
      
        if (cycles <= 48) {
          illustrationData.forEach((item, index) => {
            const x = scaleX(index);
            const y = scaleY(item?.[plotPointAttr]);
            ctx.beginPath();
            ctx.arc(x, y, 3, 0, 2 * Math.PI);
            ctx.fillStyle = "#00aaff";
            ctx.fill();
          });
        }
    
        const dataUrl = canvas.toDataURL("image/png");
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `${reportName ?? "Unnamed"}_Graph.png`;
        link.click();
    };

    function downloadPDF(fetchPath, params, fileName){
        function generatePDF(bitFile, fileName){
            const linkSource = "data:application/pdf;base64," + bitFile;
            const downloadLink = document.createElement("a");
            var fileNameString = null;
                fileNameString = fileName + ".pdf";
    
            downloadLink.href = linkSource;
            downloadLink.download = fileNameString;
            downloadLink.click();
        }

        session?.env?.functions?.buildFetchRequest(fetchPath, params)
        .then(response => response.json())
        .then(resData => {
            if(resData.status === 200){
                generatePDF(resData?.bitFile, fileName);
            }
        }, (error) => {
            if (error) {
                console.log(error);
            }
        });
    }

    const functions = {
        downloadReport,
        downloadPremiumSchedule,
        downloadLineGraph,
        generateLineGraph,
        downloadPDF,
    }
    
    const DownloadFunctions = {
        functions,
    }
    
    return DownloadFunctions;
};
    
export default Download;