import React, { useState, useRef, useEffect } from 'react';

function ReportHeaderBlock(props) {
    const {
        session,
        column,
        index,
        baseModule,
        allColumns,
        dragging,
        inputRef,
        handleUpdate,
        inputs,
        findSubExistingItem,
        remotePath,
        baseData
    } = props;
    const localInputRef = useRef(null);
    const inputReference = inputRef || localInputRef;
    const columnWidth = baseData?.selectedReport?.columns?.[index - allColumns("count")]?.width;
    const width = (columnWidth !== undefined && columnWidth !== null ? columnWidth : 64) - 64;

    const [isFocused, setIsFocused] = useState(false);
    const [initialClick, setInitialClick] = useState(false);
    const [inputValue, setInputValue] = useState(findSubExistingItem(column?.id)?.criterion?.contain ?? '');

    const handleFocus = () => {
        if(!dragging){
            setIsFocused(true);
            setInitialClick(true);
        }
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleChange = (event) => {
        const value = event?.target?.value;
        setInputValue(value);
        handleUpdate(index, value);
    };

    const handleClick = () => {
        if (!inputValue && isFocused && !initialClick) {
            inputReference.current.blur();
            session?.set(baseModule, `${remotePath ?? ''}selectedReport.columns.${index - allColumns("count")}.join`, false);
        }
        setInitialClick(false);
    };

    useEffect(() => {
        setInputValue(inputs?.data?.[index] ?? '');
    }, [inputs?.data, index]);

    return (
        <form autoComplete="off" className="gC2 cC g dG" style={{ width: width }}>
            <div className="g dG" style={{ width: width }}>
                {(isFocused || inputValue) &&
                    <div
                        className="headerPlaceholder cL bold gCW"
                        title={column?.title ?? column?.friendlyTerm ?? "*Unnamed Column"}
                    >
                        {column?.title ?? column?.friendlyTerm ?? "*Unnamed Column"}
                    </div>
                }
                <input
                    ref={inputReference}
                    onClick={handleClick}
                    className={`g gCW${column?.friendlyTerm ? " bold" : " italics"}${inputValue ? " value" : ''}`}
                    value={inputValue}
                    onChange={handleChange}
                    placeholder={(isFocused || inputValue) ? '' : column?.title ?? column?.friendlyTerm ?? "*Unnamed Column"}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    autoComplete="off"
                    style={{ width: width }}
                    title={column?.title ?? column?.friendlyTerm ?? "*Unnamed Column"}
                    readOnly={dragging.current}
                />
            </div>
        </form>
    );
}

export default ReportHeaderBlock;