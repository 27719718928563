import React from 'react';
import { useRef } from 'react';
import { useState, useEffect } from 'react';

// import LeadRangeSlider from './LeadRangeSlider';

import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CallEndIcon from '@mui/icons-material/CallEnd';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import ForwardIcon from '@mui/icons-material/Forward';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import InboxIcon from '@mui/icons-material/Inbox';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import CampaignIcon from '@mui/icons-material/Campaign';
import ExtensionOffIcon from '@mui/icons-material/ExtensionOff';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import CelebrationIcon from '@mui/icons-material/Celebration';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import SearchIcon from '@mui/icons-material/Search';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import GavelIcon from '@mui/icons-material/Gavel';
import { ThumbsUpDown } from '@mui/icons-material';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';

function BidsListAll(props){
    const session = props?.session;
    const bidsData = session?.bids?.data;
    const listAllBoard = useRef(null);

    useEffect(() => {
        if(listAllBoard?.current){
            listAllBoard.current.scrollTop = props.bidProps?.yScrollBidList;
        }
    }, []);

    useEffect(() => {
        if(props.bidProps?.activityView === "list" && props.bidProps?.bidBlockKanbanData){
            if(props.bidProps?.yScrollBidList === 0 && listAllBoard.current.scrollTop !== 0){
                listAllBoard.current.scrollTop = 0;
            }
        }
    }, [props.bidProps?.yScrollBidList]);


    function bidsListCategoryClass(category, topLevel){
        if(topLevel){
            function containsAllElements(array1, array2) {
                return array2.every(item => array1.includes(item));
            }

            if(category === "newCase"){
                if(containsAllElements(props.listToggles, ["Shipped"])){
                    return " inactive";
                }
            }

            if(category === "underReview"){
                if(containsAllElements(props.listToggles, ["Under Review","Requested Info"])){
                    return " inactive";
                }
            }

            if(category === "bidding"){
                if(containsAllElements(props.listToggles, ["Requested Info", "Bidding"])){
                    return " inactive";
                }
            }

            if(category === "closed"){
                if(containsAllElements(props.listToggles, ["No Bid", "Lost with bid", "In Conflict", "Does Not Fit Parameters"])){
                    return " inactive";
                } 
            }

            if(category === "success"){
                if(containsAllElements(props.listToggles, ["Won", "Closing"])){
                    return " inactive";
                } 
            }

            if(category === "all"){
                if(containsAllElements
                    (props.listToggles,
                    ["Shipped", "Under Review", "Bidding", "Requested Info", "No Bid", "Lost with bid", "In Conflict", "Won", "Closing", "Does Not Fit Parameters"])){
                        return true;
                }
            }
        }else{
            if(props.listToggles !== undefined && props.listToggles.includes(category)){
                return " inactive";
            }
        }

        return '';
    }

    function displayTotalCategoryCountStatus(currentVal, maxVal){
        var prompt = " cases";
        if(currentVal === 1){
            prompt = " case";
        }

        if(currentVal === maxVal){
            return [maxVal, prompt];
        }else{
            return currentVal + "/" + maxVal + " " + prompt;
        }
    }

    function handleScroll(event) {
        const element = event.target;

        props.bidProps?.setYScrollBidList(element.scrollTop);
        if(props.listAllVisibleThreshold > props.bidBlockListData.total.count){
            return;
        }

        if (element.scrollHeight - element.scrollTop <= element.clientHeight + 100) {
            props.setListAllVisibleThreshold(props.listAllVisibleThreshold + 48);
        }
    }

    function printCategory(icon, prompt, param, parentKey){
        return (
            <div className="header f success p" onClick={()=>{props.bidProps.bidsCategoryToggle(param, true, "list")}}>
                <div className="cC small">
                    {displayTotalCategoryCountStatus(props.bidBlockListData?.[parentKey]?.visibleCount, props.bidBlockListData?.[parentKey]?.count)}
                </div>
                <div className="f cC gC2">
                    {prompt}
                </div>
                <div className="icon cC">
                    {icon}
                </div>
            </div>
        )
    }

    function printSubCategory(icon, prompt, param, parentKey, key){
        return(
            <div className={"subCategory won cC p" + bidsListCategoryClass(param)} onClick={()=>{props.bidProps.bidsCategoryToggle(param, null, "list")}}>
                {icon}
                <div className="f cL oH">
                    <div className="gCW">
                        {prompt}
                    </div>
                </div>
                <div className="count">
                    {displayTotalCategoryCountStatus(props.bidBlockListData?.[parentKey]?.[key]?.visibleCount, props.bidBlockListData?.[parentKey]?.[key]?.count)}
                </div>
            </div>
        )
    }

    return (
        <div className="kanbanListAll g fMP">
            <div className="f oH dP">
                <div className="listAllToggles f g ns">
                    <div className="f cC">
                        {props.bidBlockListData?.total?.visibleCount + "/" + props.bidBlockListData?.total?.count + " Cases Total"}
                        {/* <div className="leadsListAttrFilters">
                            <LeadRangeSlider
                                rangeData = {props.rangeData}
                                functionPointer={props.functionPointer}
                                fixedMin={props.fixedMin}
                                fixedMax={props.fixedMax}
                                />
                        </div> */}
                    </div>
                    <div className="toggleOptions f oH">
                        <div className="options f g">
                            {/* Success */}
                            <div className={"category g cC s e success" + bidsListCategoryClass("success", true)}>
                                {printCategory(<CelebrationIcon/>, "Closed / Success", "SUCCESS", "success")}
                                <div className="divider success">
                                </div>
                                <div className="categoryGroup g f">
                                    {printSubCategory(<CelebrationIcon sx={{color: "#004d40"}}/>, "Won", "Won", "success", "closedWon")}
                                    {printSubCategory(<GavelIcon sx={{color: "#ff9800"}}/>, "Closing", "Closing", "success", "closing")}
                                </div>
                            </div>
                            {/* New Case */}
                            <div className={"category g cC s e lead" + bidsListCategoryClass("newCase", true)}>
                                {printCategory(<InboxIcon/>, "New Case", "NEW", "newCase")}
                                <div className="divider lead">
                                </div>
                                <div className="categoryGroup g f">
                                    {printSubCategory(<BeenhereIcon/>, "New Case", "Shipped", "newCase", "newCase")}
                                </div>
                            </div>
                            {/* Under Review */}
                            <div className={"category g cC s e contactAttempted" + bidsListCategoryClass("underReview", true)}>
                                {printCategory(<FlagCircleIcon/>, "Under Review", "UNDER REVIEW", "underReview")}
                                <div className="divider contactAttempted">
                                </div>
                                <div className="categoryGroup g f">
                                    {printSubCategory(<SearchIcon/>, "Under Review", "Under Review", "underReview", "underReview")}
                                    {printSubCategory(<PendingActionsIcon/>, "Requested Info", "Requested Info", "underReview", "requestedInfo")}
                                </div>
                            </div>
                            {/* Bidding */}
                            <div className={"category g cC s e inProgress" + bidsListCategoryClass("bidding", true)}>
                                {printCategory(<CampaignIcon/>, "Bidding", "BIDDING", "bidding")}
                                <div className="divider inProgress">
                                </div>
                                <div className="categoryGroup g f">
                                    {printSubCategory(<ThumbsUpDownIcon/>, "Bidding", "Bidding", "bidding", "bidding")}
                                    {printSubCategory(<PendingActionsIcon/>, "Requested Info", "Requested Info", "bidding", "requestedInfo")}
                                </div>
                            </div>
                            {/* Closed */}
                            <div className={"category g cC s e lost" + bidsListCategoryClass("closed", true)}>
                                {printCategory(<DoDisturbOnIcon/>, "Closed / Lost", "CLOSED", "closed")}
                                <div className="divider lost">
                                </div>
                                <div className="categoryGroup g f">
                                    {printSubCategory(<ThumbDownOffAltIcon/>, "No Bid", "No Bid", "closed", "noBid")}
                                    {printSubCategory(<SentimentVeryDissatisfiedIcon/>, "Lost with Bid", "Lost with bid", "closed", "lostWithBid")}
                                    {printSubCategory(<NearbyErrorIcon/>, "In Conflict", "In Conflict", "closed", "inConflict")}
                                    {printSubCategory(<ExtensionOffIcon/>, "Invalid Params", "Does Not Fit Parameters", "closed", "doesNotFitParameters")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottomBar f g cC">
                        <div className="g cL s">
                            <div className="toggleAllLeadFiltersBtn" onClick={()=>{props.bidProps.bidsCategoryToggle("clear", "all", "list")}}>
                                { bidsListCategoryClass("all", true) ?
                                    "Select All"
                                    : "Toggle All"
                                }
                            </div>
                        </div>
                        {  Object.keys(props.sessionUser?.accountData)?.length > 1 &&
                            <div className="g cC" onClick={() => {props.bidProps?.toggleBidBidFilters("toggle")}}>
                                <div className="p">
                                    Affiliates
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="f oH">
                <div
                    ref={listAllBoard}
                    // className={Object.keys(props?.viewAllList)?.length > 9 ? "listAllResults g f tO t b" : "listAllResults alt g f tO t b"}
                    className={`listAllResults ${bidsData?.list?.listType} ${Object.keys(props?.viewAllList)?.length > 9 ? 'g' : 'alt g'} f tO t b ${props?.session?.env?.viewport?.viewType}`}
                    onScroll={handleScroll}
                >
                    { props.viewAllList }
                </div>
            </div>
        </div>
    )
}

export default BidsListAll;
