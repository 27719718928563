import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PaidIcon from '@mui/icons-material/Paid';
import CallEndIcon from '@mui/icons-material/CallEnd';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import TagIcon from '@mui/icons-material/Tag';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ForwardIcon from '@mui/icons-material/Forward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import CancelIcon from '@mui/icons-material/Cancel';

import { red, purple, blue, blueGrey, green, grey } from '@mui/material/colors';

function LeadBlock(props) {
    const [leadBlock, setLeadBlock] = useState(props.leadBlockInfo || {});
    var loadBlockClass = leadBlock.currentPosition;

    if(props.leadBlockType && props.leadBlockType !== "result"){
        loadBlockClass = props.leadBlockType;
    }

    const [timeDifference, setTimeDifference] = useState('');
    const [followUpTimeDifference, setFollowUpTimeDifference] = useState('');

    const formatter = new Intl.NumberFormat('en-US', {  style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
    });

    function convertIntToCurrency(value){
        let currencyValue = "N/A";
    
        if(value !== null){
            currencyValue = formatter.format(value);
        }
    
        return currencyValue.substring(1);
    }

    function displayClosedType(){

        if(leadBlock.leadStatus === "New Insurance Interest"){
            loadBlockClass = "insurance";
            return (
                <div className="leadStatusIcon cL">
                    <SwitchAccessShortcutIcon/>{props.closedCount}
                    <div className="cL">
                        New Insurance
                    </div>
                </div>
            )
        }
        if(leadBlock.leadStatus === "Sent to Abacus"){
            loadBlockClass = "abacus";
            return (
                <div className="leadStatusIcon cL">
                    <RestartAltIcon/>{props.closedCount}
                    <div className="cL">
                        Sent to Abacus
                    </div>
                </div>
            )
        }
        if(leadBlock.leadStatus === "Unqualified"){
            loadBlockClass = "lost";
            return (
                <div className="leadStatusIcon cL">
                    <CancelIcon sx={{ color : red[400] }}/>{props.closedCount}
                    <div className="cL">
                        Unqualified
                    </div>
                </div>
            )
        }
        if(leadBlock.leadStatus === "Client not Interested"){
            loadBlockClass = "lost";
            return (
                <div className="leadStatusIcon cL">
                    <PersonOffIcon sx={{ color : red[400] }}/>
                        <div className="cL">
                            Client Not Interested
                        </div>
                </div>
            )
        }
        if(leadBlock.leadStatus === "Failed to Contact"){
            loadBlockClass = "lost";
            return (
                <div className="leadStatusIcon cL">
                    <PhoneDisabledIcon sx={{ color : red[400] }}/>
                    <div className="cL">
                        Failed to Contact
                    </div>
                </div>
            )
        }

        return (
            <div className="leadStatusIcon cL">
                <DoDisturbOnIcon sx={{ color : red[400] }}/>
                <div className="cL">
                    Closed
                </div>
            </div>
        )
    }

    function displayInProgressType(){
        if(leadBlock.leadStatus === "Application Sent"){
            return (
                <div className="leadStatusIcon cL">
                    <ForwardIcon sx={{ color: purple[800] }}/>
                    <div className="cL">
                        Application Sent
                    </div>
                </div>
            )
        }

        if(leadBlock.leadStatus === "Application Received"){
            return (
                <div className="leadStatusIcon cL">
                    <CallReceivedIcon sx={{ color: purple[800] }}/>
                    <div className="cL">
                        Application Received
                    </div>
                </div>
            )
        }

        if(leadBlock.leadStatus === "Follow Up"){
            return (
                <div className="leadStatusIcon cL">
                    <AccessAlarmIcon sx={{ color: purple[800] }}/>
                    <div className="cL">
                        Follow Up
                    </div>
                </div>
            )
        }

        return (
            <div className="leadStatusIcon cL">
                <FlagOutlinedIcon sx={{ color: purple[800] }}/>
                <div className="cL">
                    In Progress
                </div>
            </div>
        )
    }

    const convertTimeStamp = (directTime) => {
        if (!leadBlock) {
            return null;
        }

        var timestamp = leadBlock.lastUpdateStatusTimeRaw;
            timestamp = Date.parse(timestamp);
            timestamp = timestamp / 1000;
        var seconds = Math.floor((new Date().getTime() / 1000) - timestamp);

        if(directTime){
            timestamp = directTime;
            timestamp = Date.parse(timestamp);
            timestamp = timestamp / 1000;
            seconds = Math.floor(timestamp - (new Date().getTime() / 1000));
        }

        var interval = Math.floor(seconds / 31536000);

        if(timestamp > (new Date().getTime() / 1000) && directTime === undefined || directTime && timestamp < (new Date().getTime() / 1000)){
            return false;
        }

        if (interval > 1) return interval + " yrs";

        interval = Math.floor(seconds / 2592000);
        if (interval > 1) return interval + " mo";

        interval = Math.floor(seconds / 86400);
        if (interval >= 1){
            if(interval === 1){
                return "1 day";
            }else{
                return interval + " days";
            }
        }

        interval = Math.floor(seconds / 3600);
        if (interval >= 1) return interval + "h";

        interval = Math.floor(seconds / 60);
        if (interval > 1) return interval + "m ";

        if(0 < seconds < 30 ){
            if(directTime){
                return "Soon";
            }else{
                return "Moments";
            }
        }
        return Math.floor(seconds) + "s";
    };

    function displayLeadNotifications(){
        var returningNotifications = [];
        var notificationCount = 0;
        var validFollowUpTime = confirmValidFollowUpTime();

        if(props.detailedView && props.singleLeadView !== true){
            notificationCount++;
        }

        if(leadBlock.numMissedFollowUps > 0){
            notificationCount++;
        }

        if(leadBlock.nextFollowUp > 0 || validFollowUpTime){
            if(displayFollowUpTime()){
                notificationCount++;
            }
        }

        if(leadBlock.numMissedFollowUps){
            returningNotifications.push(displayMissedFollowUps(notificationCount));
        }

        if(leadBlock.nextFollowUp || validFollowUpTime){
            returningNotifications.push(displayFollowUpTime(notificationCount));
        }

        if(notificationCount === 0){
            return false;
        }else{
            return returningNotifications;
        }
    }

    function displayFollowUpTime(){
        var followUpRemainingTimeClass = "followUpRemainingTime";

        if(props.detailedView && props.singleLeadView !== true){
            followUpRemainingTimeClass = "followUpRemainingTime inline";
        }

        if(followUpTimeDifference){
            return  <div key={followUpRemainingTimeClass + leadBlock.leadID}className={followUpRemainingTimeClass}>
                        <NotificationsActiveIcon sx={{ color: purple[800] }}/>
                        <div className="followUpTimer">{followUpTimeDifference}</div>
                    </div>
        }

        return;
    }

    function displayMissedFollowUps(inline){

        var missedFollowUpClass = "missedFollowUp";
        if(inline === 1){
            missedFollowUpClass = "missedFollowUp";
        }

        if(inline === 2){
            missedFollowUpClass = "missedFollowUp inline";
        }

        if(inline === 3){
            missedFollowUpClass = "missedFollowUp inlineX2";
        }

        if(inline === 2 && props.detailedView && !props.singleLeadView){
            missedFollowUpClass = "missedFollowUp detailOnly";
        }

        return  <div key={missedFollowUpClass + leadBlock.leadID}className={missedFollowUpClass}>
                    <CallMissedIcon sx={{ color: red[400] }}/>
                    <div className="missedFollowUpCount">
                        {leadBlock.numMissedFollowUps + " Missed"}
                    </div>
                </div>
    }

    function confirmValidFollowUpTime(){
        try{
            if(leadBlock.nextFollowUp !== '' && (Date.parse(leadBlock.nextFollowUp) > new Date().getTime())){
                return true;
            }
            if(props.followUpTime){
                return(isIsoDate(props.followUpTime.toISOString()));
            }

            return false;
        } catch (error) {
            return false;
        }
    }

    function isIsoDate(str) {
        if(str === null || str === undefined || str === ''){
            return false;
        }
        try {
            if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
            const d = new Date(str); 
            return d instanceof Date && !isNaN(d) && d.toISOString()===str;
        }catch(error){
            return false;
        } 
    }

    function displayLeadBlockAttr(attr){
        if(attr === "fullName"){
            var firstName = leadBlock.leadFirstName;
            var lastName = leadBlock.leadLastName;

            if(leadBlock.leadFirstName === '' || leadBlock.leadFirstName === null || leadBlock.leadFirstName === undefined){
                firstName = "Unknown";
            }
            if(leadBlock.leadLastName === '' || leadBlock.leadLastName === null || leadBlock.leadLastName === undefined){
                lastName = "Unknown";
            }

            if(firstName === "Unknown" && lastName === "Unknown"){
                return "N/A";
            }

            return firstName + " " + lastName;
        }

        if(attr === "currency"){
            function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

            if(leadBlock.leadDeathBenefit === '' || leadBlock.leadDeathBenefit === null || leadBlock.leadDeathBenefit === undefined){
                return "N/A";
            }
            if(!isNumber(leadBlock.leadDeathBenefit)){
                return "N/A";
            }

            return (convertIntToCurrency(leadBlock.leadDeathBenefit));
        }

        if(attr === "age"){
            if(leadBlock.leadBirthDate === '' || leadBlock.leadBirthDate === null || leadBlock.leadBirthDate === undefined){
                return "N/A";
            }

            return leadBlock.leadBirthDate + " Years Old";
        }

        if(attr === '' || attr === null || attr === undefined){
            return "N/A";
        }

        return attr;
    }

    const handlePhoneNumber = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        setLeadBlock(props.leadBlockInfo || {});
    }, [props.leadBlockInfo]);

    
    useEffect(() => {
        if (props.leadBlockInfo !== undefined && props.leadBlockInfo.lastUpdateStatusTimeRaw !== undefined || props.followUpTime !== null) {
            setTimeDifference(convertTimeStamp());
            if(confirmValidFollowUpTime()){
                if(props.leadBlockInfo.nextFollowUp === '' && props.followUpTime || (Date.parse(props.leadBlockInfo.nextFollowUp) < new Date().getTime()) && props.followUpTime){
                    setFollowUpTimeDifference(convertTimeStamp(props.followUpTime.toISOString()));
                    if(!convertTimeStamp(props.followUpTime.toISOString())){
                        props.resetFollowUpTimer();
                    }
                }else{
                    if(Date.parse(props.leadBlockInfo.nextFollowUp) > new Date().getTime()){
                        setFollowUpTimeDifference(convertTimeStamp(props.leadBlockInfo.nextFollowUp));
                    }
                }
            }
        }
    }, []);

    useEffect(() => {
        if (props.leadBlockInfo !== undefined && props.leadBlockInfo.lastUpdateStatusTimeRaw !== undefined || props.followUpTime !== null) {
            let intervalTimer = setInterval(() => {
                setTimeDifference(convertTimeStamp());
                if(confirmValidFollowUpTime()){
                    if(props.leadBlockInfo.nextFollowUp === '' && props.followUpTime || (Date.parse(props.leadBlockInfo.nextFollowUp) < new Date().getTime()) && props.followUpTime){
                        setFollowUpTimeDifference(convertTimeStamp(props.followUpTime.toISOString()));
                        if(!convertTimeStamp(props.followUpTime.toISOString())){
                            props.resetFollowUpTimer();
                        }
                    }else{
                        if(Date.parse(props.leadBlockInfo.nextFollowUp) > new Date().getTime()){
                            setFollowUpTimeDifference(convertTimeStamp(props.leadBlockInfo.nextFollowUp));
                        }
                    }
                }
            }, 1000);

            return () => clearInterval(intervalTimer);
        }
    }, [leadBlock, props.followUpTime]);

    if(loadBlockClass === "closed" && !props.detailedView && props.closedType){
        loadBlockClass = props.closedType;

        var listPath = '';
        if(props.closedType === "abacus"){
            listPath = "/leads/"
        }
        if(props.closedType === "insurance"){   
        }
        if(props.closedType === "lost"){
        }

        return (
            <Link className={"leadBlock " + loadBlockClass} to={"/leads"}  onClick={ () => {
                props.setActivityView("list");
                props.onClick(props.closedType, "only", "list")
                } }>
                <div className="leadInfoBar">
                    { props.closedType === "abacus" ?
                        <div className="leadStatusIcon"><RestartAltIcon/>{props.closedCount} Sent to Abacus</div>
                        : null }
                    { props.closedType === "insurance" ?
                        <div className="leadStatusIcon"><SwitchAccessShortcutIcon/>{props.closedCount} New Insurance</div>
                        : null }
                    { props.closedType === "lost" ?
                        <div className="leadStatusIcon"><DoDisturbOnIcon/>{props.closedCount} Closed/Lost</div>
                        : null }
                </div>
                <div className="leadName"><AttachMoneyIcon/> {convertIntToCurrency(props.totalDeathBenefit)} </div>
            </Link>
        )
    }

    var leadBlockInner = [
        <div key={"leadInfoBar" + leadBlock.leadID} className="leadInfoBar">
            <div className="cL">
                { loadBlockClass === "lead" ?
                    <div className="leadStatusIcon">
                        <BeenhereIcon sx={{ color: green[500] }}/>
                        <div className="cL">
                            New
                        </div>
                    </div> 
                    : null }
                { loadBlockClass === "contactAttempted" ?
                    <div className="leadStatusIcon">
                        <CallEndIcon sx={{ color: blue[500] }}/>
                        <div className="cL">
                            {leadBlock.contactAttemptCount === 0 ? "Contact Attempted" : leadBlock.contactAttemptCount > 1 ? leadBlock.contactAttemptCount + " Calls" : "1 Call"}
                        </div>
                    </div>
                    : null }
                { loadBlockClass === "inProgress" ?
                    displayInProgressType()
                    : null }
                { loadBlockClass === "closed" ?
                    displayClosedType()
                    : null }
            </div>
            <div className="leadTimestamp cR">
                {timeDifference === "0s" ? "Just Now" : timeDifference + " ago" }
            </div>
        </div>,
        <div key={"leadDivider" + leadBlock.leadID} className="divider"/>,
        <div key={"leadPhoneNumber" + leadBlock.leadID} className="leadPhoneNumber">
            <TagIcon sx={{ color: blueGrey[500] }}/>
            <div className="leadData">
                <span onClick={handlePhoneNumber}>
                    <input type="tel" readOnly={true} value={displayLeadBlockAttr(leadBlock.leadPhoneNumber)}/>
                </span>
            </div>
        </div>,
        <div key={"leadName" + leadBlock.leadID} className="leadName">
            <PersonOutlineIcon sx={{ color: grey[800] }}/>
            <div className="leadData">
                {displayLeadBlockAttr("fullName")}
            </div>
        </div>,
        <div key={"leadHealth" + leadBlock.leadID} className="leadHealth">
            <FavoriteBorderIcon sx={{ color: red[500] }}/>
            <div className="leadData">
                {displayLeadBlockAttr(leadBlock.leadHealthStatus)}
            </div>
        </div>,
        <div key={"leadDeathBenefit" + leadBlock.leadID} className="leadDeathBenefit">
            <PaidIcon sx={{ color: green[500] }}/>
            <div className="leadData">
                {displayLeadBlockAttr("currency")}
            </div>
        </div>,
        <div key={"leadPolicyType" + leadBlock.leadID} className="leadPolicyType">
            <CalendarMonthIcon  sx={{ color: purple[500] }}/>
            <div className="leadData">
                {displayLeadBlockAttr("age")}
            </div>
        </div>
    ];

    if(props.detailedView){
        if(props.singleLeadView){
            return (
                <div className="leadBlock">
                    { leadBlockInner }
                    {displayLeadNotifications()}
                </div>
            )
        }else{
            return (
                <div className="leadBlock">
                    { leadBlockInner }
                    { leadBlock.leadStatus === "Follow Up" ?
                        displayLeadNotifications()
                    : null }
                    <Link
                        className={"viewMoreLead ns "}
                        to={"/leads/" + leadBlock.leadID}
                        onClick={()=>{
                            props.navigation.setCurrentLead(leadBlock);
                            props.navigation.setCurrentActivity("leadList");
                        }}
                    >
                        View Details
                    </Link>
                </div>
            )
        }
    }else{
        if(props.leadBlockType === "result"){
            return (
                <Link
                    className={"leadBlock result " + loadBlockClass}
                    to={"/leads/" + leadBlock.leadID}
                    onClick={()=>{
                        props.navigation.setCurrentLead(leadBlock);
                        props.navigation.setCurrentActivity("singleLead");
                    }}
                >
                    {leadBlockInner}
                    {displayLeadNotifications()}
                </Link>
            )
        }

        if(displayLeadNotifications()){
            leadBlockInner.push(displayLeadNotifications());
        }

        return (
            <Link
                className={leadBlock.uploadError ? "leadBlock error" : "leadBlock"}
                to={"/leads/" + leadBlock.leadID}
                onClick={()=>{
                    props.navigation.setCurrentLead(leadBlock);
                    props.navigation.setCurrentActivity("singleLead");
                }}
            >
                {leadBlockInner}
            </Link>
        )
    }
}

export default LeadBlock;