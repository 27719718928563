import React, { useEffect, useState } from 'react';

function ShareLink(props){
    const [data, setData] = useState();

    function loadShareLink(shareLinkData){
        setData(shareLinkData);
    }

    const functions = {
        loadShareLink
    }

    const shareLink = {
        data,
        setData,
        functions
    }

    return shareLink;
};

export default ShareLink;