
import React from 'react';
import { useState, useEffect, useRef } from 'react';

import InboxIcon from '@mui/icons-material/Inbox';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import CampaignIcon from '@mui/icons-material/Campaign';
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import PhoneMissedTwoToneIcon from '@mui/icons-material/PhoneMissedTwoTone';
import TrendingUpTwoToneIcon from '@mui/icons-material/TrendingUpTwoTone';
import DirectionsWalkTwoToneIcon from '@mui/icons-material/DirectionsWalkTwoTone';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';
import GavelTwoToneIcon from '@mui/icons-material/GavelTwoTone';
function LoadingScreen(props){
    const pageLoadingHandler = {
        "tertiary" : {
            "icons" : [<InboxIcon/>, <FlagCircleIcon/>, <CampaignIcon/>],
            "prompt" : "Loading Tertiary Data",
        },
        "leads" : {
            "icons" : [<InboxTwoToneIcon/>, <PhoneMissedTwoToneIcon/>, <TrendingUpTwoToneIcon/>],
            "prompt" : "Loading Lead Data",
        },
        "policies" : {
            "icons" : [<DirectionsWalkTwoToneIcon/>, <HandshakeTwoToneIcon/>, <GavelTwoToneIcon/>],
            "prompt" : "Loading Policy Data",
        }
    }

    return (
        <div className="f cC loading bR">
            <div className="loader cC g">
                <div className="cC g fR">
                    <div className="icon cC">
                        {pageLoadingHandler?.[props?.page]?.icons?.[0]}
                    </div>
                    <div className="icon cC">
                        {pageLoadingHandler?.[props?.page]?.icons?.[1]}
                    </div>
                    <div className="icon cC">
                        {pageLoadingHandler?.[props?.page]?.icons?.[2]}
                    </div>
                </div>
                <div className="cC">
                    {pageLoadingHandler?.[props?.page]?.prompt}
                </div>
            </div>
        </div>
    )
}

export default LoadingScreen;