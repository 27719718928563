import React, { useEffect, useState, useMemo, useRef } from 'react';

import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
import FiberNewTwoToneIcon from '@mui/icons-material/FiberNewTwoTone';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TimelineTwoToneIcon from '@mui/icons-material/TimelineTwoTone';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import LineAxisTwoToneIcon from '@mui/icons-material/LineAxisTwoTone';
import HistoryTwoToneIcon from '@mui/icons-material/HistoryTwoTone';
import VerifiedTwoToneIcon from '@mui/icons-material/VerifiedTwoTone';
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone';
import ThumbsUpDownTwoToneIcon from '@mui/icons-material/ThumbsUpDownTwoTone';

import ReportSheet from '../Components/Reporting/ReportSheet.js';
import ReportTopbar from '../Components/Reporting/ReportTopbar.js';
import CurrentActivity from '../InstanceTabs/CurrentActivity.js';
import DetailsTab from '../InstanceTabs/DetailsTab.js';
import TertiaryReportToggles from '../Components/Tertiary/TertiaryReportToggles.js';
import SingleTertiary from '../Components/SingleView.js';
import FetchHandler from '../InstanceTabs/FetchHandler.js';
import LifeExpectancies from '../InstanceTabs/LifeExpectancies.js';

function SingleView(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const casePath = session?.[reporting?.data?.dataPointer]?.data?.[props?.branch]?.[props?.stem];

    const location = useLocation();
    const currentPath = session?.env?.functions?.currentPath(location);

    // const [currentCase, setCurrentCase] = useState(retrieveCase(3));
    const [currentCase, setCurrentCase] = useState({data : undefined, index : undefined});
    const [invalidCase, setInvalidCase] = useState(false);

    const initialCaseLoading = useRef(false);

    const [dropdowns, setDropdowns] = useState({
        premiums : {
            showMenu : false,
            onClick : (value, data) => {
                session?.set("case", `${props?.branch}.${props?.stem}[${currentCase?.index}].premiums.selectedPremiumKey`, data?.premiumKey);
            }
        },
        premiumsDownload : {
            showMenu : false,
        },
        timeline : {
            showMenu : false,
        }
    });

    const updateDropdown = (key, attributes) => {
        setDropdowns((prev) => {
            const updatedDropdowns = {
                ...prev,
                [key]: {
                    ...prev[key],
                    ...attributes, // Spread the incoming attributes to update multiple fields
                },
            };
            return updatedDropdowns;
        });
    };

    const resetDropdowns = () => {
        setDropdowns((prev) => {
            const updated = Object.keys(prev).reduce((acc, key) => {
                acc[key] = { ...prev[key], showMenu: false };
                return acc;
            }, {});
            return updated;
        });
    };

    const [loadingHandler, setLoadingHandler] = useState(() => ({
        timeline: !currentCase?.timeline,
        shareLink: !currentCase?.generatedShareLink,
        les: !currentCase?.lifeExpectancies,
        premiums: !currentCase?.premiums,
        bids : !currentCase?.bids,
        messages : !currentCase?.messages,
        read : !currentCase?.read,
    }));

    const loaders = {
        leads : ["timeline"],
        origination : ["read", "timeline", "shareLink", "bids", "messages"],
        servicing : ["read", "timeline", "shareLink", "premiums", "messages"],
        bids : ["read", "timeline", "shareLink", "les", "premiums", "messages"],
        tertiary : ["read", "timeline", "shareLink", "les", "premiums"]
    };

    const tabComponents = {
        "timeline": {
            key: "timeline",
            label: "Timeline",
            icon: <TimelineTwoToneIcon />,
            activity: "timeline",
            notification: undefined,
            notificationIcon: <FiberNewTwoToneIcon />
        },
        "documents": {
            key: "shareLink",
            label: "Documents",
            icon: <HistoryEduTwoToneIcon />,
            activity: "documents"
        },
        "les": {
            key: "les",
            label: "Life Expectancies",
            icon: <LineAxisTwoToneIcon />,
            activity: "les"
        },
        "premiums": {
            key: "premiums",
            label: "Premium Schedule",
            icon: <HistoryTwoToneIcon />,
            activity: "premiums"
        },
        "messages": {
            key: "messages",
            label: "Messages",
            icon: <QuestionAnswerTwoToneIcon />,
            activity: "messages"
        },
        "closing": {
            key: "closing",
            label: "Closing",
            icon: <VerifiedTwoToneIcon />,
            activity: "closing",
            conditional: [
                "Offer Letter Out / CRF",
                "Offer Letter In",
                "Contracts Out",
                "Contracts Back (Digital)",
                "Contracts Back",
                "Escrow / At Carrier",
                "Rescission",
                "Closed Won"
            ].includes(currentCase?.data?.policyStatus)
        },
        "bids": {
            key: "bids",
            label: "Bids",
            icon: <ThumbsUpDownTwoToneIcon />,
            activity: "bids",
        },
        // "all-timeline": {
        //     key: "allTimeline",
        //     label: "Timeline",
        //     icon: <TimelineTwoToneIcon />,
        //     activity: "timeline",
        //     notification: undefined,
        //     notificationIcon: <FiberNewTwoToneIcon />
        // },
        // "all-documents": {
        //     key: "allDocumentsBtn",
        //     label: "Documents",
        //     icon: <HistoryEduTwoToneIcon />,
        //     activity: "documents"
        // },
    };

    const instancePermissions = session?.user?.data?.pages?.[props?.stem]?.viewPermissions || [];
    const approvedTabs = (
        Object.entries({
            // origination: ["timeline", "bids", "documents", "messages", "closing"],
            origination: ["timeline", "bids", "documents", "messages"],
            servicing: ["timeline", "premiums", "documents", "messages"],
            tertiary: ["timeline", "documents", "les", "premiums"],
            bids: ["timeline", "documents", "les", "premiums", "messages"],
            leads: ["timeline"],
        })
        .find(([key]) => key === props?.stem)?.[1] // Get tabs for the current stem
        ?.filter(tab => instancePermissions.includes(tab)) // Filter by permissions
        .filter(tab => {
            const component = tabComponents[tab];
            return component?.conditional === undefined || component.conditional === true;
        }) || []
    );

    const [currentActivityView, setCurrentActivityView] = useState(() => {
        const { activity: tab, key: permission } = tabComponents[`${approvedTabs?.[0]}`] || {};
        return { tab, permission };
    });

    const active = currentCase?.data !== undefined;

    const updateLoadingHandler = (keys, value) => {
        setLoadingHandler((prevState) => {
          // Check if keys is an array or a single string
          if (Array.isArray(keys)) {
            // Create a new object with updated values for all keys
            const updatedState = keys.reduce((acc, key) => {
              acc[key] = value;
              return acc;
            }, {});
      
            return {
              ...prevState,
              ...updatedState,
            };
          } else if (typeof keys === "string") {
            // Update a single key
            return {
              ...prevState,
              [keys]: value,
            };
          }
      
          return prevState; // Return previous state if keys is not valid
        });
    };

    const fetchHandler = useMemo(() => FetchHandler({
        session,
        loadingHandler,
        updateLoadingHandler,
        branch: props?.branch,
        stem: props?.stem,
        currentCase: currentCase,
    }), [session, currentCase]);

    function retrieveCase(call) {
        // return {data : session?.case?.data?.policies?.servicing?.[0], index : 0};
        const opportunities = session?.case?.data?.[props?.branch]?.[props?.stem];
        const index = opportunities?.findIndex(data => {
            return Number(data?.recordID) === Number(props?.recordID);
        });
    
        if (index !== -1 && index !== undefined) {
            return { data: opportunities[index], index };
        } else {
            return { data: undefined, index: undefined };
        }
    }

    const handleMouseDown = (e) => {
        e.preventDefault();
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        const containerWidth = document.querySelector('.tertiaryHub').offsetWidth;
        const newWidth = containerWidth - e.clientX + 12; // Adjust this calculation as needed
        const minWidth = 300; // Minimum width in pixels
        const maxWidth = containerWidth - minWidth; // Maximum width in pixels
    
        if (newWidth < minWidth) {
            // setThirdColumnWidth(`${minWidth}px`);
            session?.set('bids', 'tertiary.layout.dimensions', `1fr 8px ${minWidth}px`);
        } else if (newWidth > maxWidth) {
            // setThirdColumnWidth(`${maxWidth}px`);
            session?.set('bids', 'tertiary.layout.dimensions', `1fr 8px ${maxWidth}px`);
        } else {
            console.log(newWidth, containerWidth, maxWidth);
            // setThirdColumnWidth(`${newWidth}px`);
            session?.set('bids', 'tertiary.layout.dimensions', `1fr 8px ${newWidth}px`);
        }
    };
    
    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    function printTabs() {
        let tabBar = [];
        const single = approvedTabs?.length === 1;

        approvedTabs?.forEach(activity => {
            const permissionKey = `${activity}`;
            const tab = tabComponents?.[permissionKey];

            if (tab) {
                const selected = currentActivityView?.tab === tab?.activity;
                const loaded = loadingHandler?.[tabComponents?.[activity]?.key] !== "pending";
                const readyTab = active && loaded;
                const pointer = readyTab ? " p" : '';

                tabBar.push(
                    <div
                        key={tab?.key}
                        className={`switchTab fR f g cC e pR${
                            !readyTab ? " inactive" : ''}${
                                selected ? " selected bold nS" : pointer}${
                                    single ? " single" : ''
                                }`}
                        onClick={() => { if (readyTab) { setCurrentActivityView({ "tab": tab?.activity, "permission": permissionKey }) }}}
                        title={tab?.label}
                    >
                        {tab?.notification &&
                            <div className="notificationBubble">
                                <FiberNewTwoToneIcon />
                            </div>
                        }
                        <div className={`f cC gCW s${single ? " gC2" : ''}`}>
                            {tab?.label}
                        </div>
                        <div className={`icon oH f cC${single ? " gR1" : ''}`}>
                            {tab?.icon}
                        </div>
                        {!single && <div className="highlight"/>}
                    </div>
                );
            }
        });

        return (
            <div
                className={`tabs g f zTPC${single ? " cC" : ''}`}
                style={{ gridTemplateColumns: `repeat(${approvedTabs?.length}, 1fr)`}}
            >
                {tabBar}
            </div>
        );
    }

    useEffect(() => {
        if (!currentCase?.data || !casePath){
            if(casePath && !currentCase?.data){
                // updateLoadingHandler(loaders?.[currentPath?.stem], false);
                setInvalidCase(true);
            }
            return;
        }else{
            if(invalidCase){
                // updateLoadingHandler(loaders?.[currentPath?.stem], true);
                setInvalidCase(false);
            }
        }

        const loadData = async () => {
            if (initialCaseLoading.current) {
                return;
            }

            initialCaseLoading.current = true;
            const tempCurrentCase = { ...currentCase?.data };
            const affectedLoaders = [];
            const stemLoaders = loaders?.[props?.stem] || [];

            try {
                const promises = [];
    
                // Load premiums if needed
                if (!tempCurrentCase?.premiums && stemLoaders.includes("premiums")) {
                    promises.push(fetchHandler?.loadServicingPremiums().then(data => {
                        tempCurrentCase.premiums = data;
                        affectedLoaders.push("premiums");
                    }));
                } else {
                    updateLoadingHandler("premiums", false);
                }
    
                // Load life expectancies if needed
                if (!tempCurrentCase?.lifeExpectancies && loadingHandler?.les && stemLoaders.includes("les")) {
                    promises.push(fetchHandler?.loadLifeExpectancies().then(data => {
                        tempCurrentCase.lifeExpectancies = data;
                        affectedLoaders.push("les");
                    }));
                } else {
                    updateLoadingHandler("les", false);
                }

                // Load timeline if needed
                if (!tempCurrentCase?.timeline && loadingHandler?.timeline && stemLoaders.includes("timeline")) {
                    promises.push(fetchHandler?.loadTimeline().then(data => {
                        tempCurrentCase.vocData = data?.vocData;
                        tempCurrentCase.timeline = data?.timeline;
                        affectedLoaders.push("timeline");
                    }));
                } else {
                    updateLoadingHandler("timeline", false);
                }

                // Load share link if needed
                if (!tempCurrentCase?.generatedShareLink && loadingHandler?.shareLink && stemLoaders.includes("shareLink")) {
                    promises.push(fetchHandler?.loadShareLink().then(data => {
                        tempCurrentCase.generatedShareLink = data;
                        affectedLoaders.push("shareLink");
                    }));
                } else {
                    updateLoadingHandler("shareLink", false);
                }

                // Load origination bid data if needed
                if (!tempCurrentCase?.bids && loadingHandler?.bids && stemLoaders.includes("bids")) {
                    promises.push(fetchHandler?.loadBids().then(data => {
                        tempCurrentCase.bids = data;
                        affectedLoaders.push("bids");
                    }));
                } else {
                    updateLoadingHandler("bids", false);
                }

                // Load origination bid data if needed
                if (!tempCurrentCase?.messages && loadingHandler?.messages && stemLoaders?.includes("messages") && approvedTabs?.includes("messages")) {
                    promises.push(fetchHandler?.loadMessages().then(data => {
                        tempCurrentCase.messages = data;
                        affectedLoaders.push("messages");
                    }));
                } else {
                    updateLoadingHandler("messages", false);
                }

                if (!tempCurrentCase?.unread && loadingHandler?.read && stemLoaders?.includes("read")) {
                    promises.push(fetchHandler?.updateReadStatus().then(data => {
                        tempCurrentCase.unread = data;
                        affectedLoaders.push("read");
                    }));
                } else {
                    updateLoadingHandler("read", false);
                }

                // Wait for all the promises to resolve
                await Promise.all(promises);

                // Only update if the data has changed
                if (JSON.stringify(tempCurrentCase) !== JSON.stringify(currentCase?.data)) {
                    session?.set("case", `${props?.branch}.${props?.stem}[${currentCase?.index}]`, tempCurrentCase);
                    setCurrentCase({data : tempCurrentCase, index : currentCase?.index});
                }
                updateLoadingHandler(affectedLoaders, false);
            } catch (error) {
                console.error("Error loading data:", error);
            } finally {
                session?.set(props?.branch, `${props?.remotePath ?? ''}selectedReport.loaded`, false);
                initialCaseLoading.current = false;
            }
        };

        loadData();
    }, [currentCase?.index, casePath]);

    useEffect(() => {
        if (JSON.stringify(currentCase) !== JSON.stringify(retrieveCase(1))) {
            setCurrentCase(retrieveCase(2));
        }

        if(Number(currentCase?.data?.recordID) !== Number(props?.recordID) && currentCase?.data){
            if(!currentCase?.data?.timeline){
                updateLoadingHandler("timeline", true);
            }
            if(!currentCase?.data?.lifeExpectancies){
                updateLoadingHandler("les", true);
            }
            if(!currentCase?.data?.generatedShareLink){
                updateLoadingHandler("shareLink", true);
            }
        }
        // Set timeline loading handler based on whether timelineData exists
    }, [
        casePath,
        props?.recordID,
        casePath?.[currentCase?.index],
    ]);

    return (
        <div
            key="singleView"
            className="singleView g zTPC"
            onClick={()=>{resetDropdowns()}}
        >
            <div className="staticTab g oH zTPC">
                <Link
                    className="inlineNavBar g cL fR"
                    key="home"
                    to={`/${session?.navigation?.functions?.inlineNavigation("back")?.view}`}
                    onClick={() => {
                        if(session?.navigation?.history?.length > 1) window.history.back();
                    }}
                >
                    <div className="f cC">
                        <ArrowBackIosNewTwoToneIcon/>
                    </div>
                    <div className="f cL e">
                        {session?.navigation?.functions?.inlineNavigation("back")?.prompt}
                    </div>
                </Link>
                <DetailsTab
                    session={session}
                    branch={props?.branch}
                    stem={props?.stem}
                    currentCase={currentCase}
                    invalidCase={invalidCase}
                />
            </div>
            <div className="alignmentContainer pR g fR fC">
                <div
                    // className={`columnDrag g cC${dragging?.current ? " dragging" : ''}`}
                    className={`columnDrag g cC fR fC`}
                    onMouseDown={handleMouseDown}
                    // onMouseDown={(e) => setupDragHandlers(column, index - allColumns("count")).handleMouseDown(e)}
                    // onDoubleClick={columnDoubleClickHandler}
                >
                    <div className="bar">
                    </div>
                </div>
            </div>
            <div className="activity f g fC b e dG">
                <div className="topBar g fR f">
                    {printTabs()}
                    <div className="moreBtn cC f p">
                        <MoreHorizIcon/>
                    </div>
                </div>
                <CurrentActivity
                    session={session}
                    activityView={{data: currentActivityView, set : setCurrentActivityView}}
                    currentCase={currentCase}
                    loadingHandler={loadingHandler}
                    branch={props?.branch}
                    stem={props?.stem}
                    approvedTabs={approvedTabs}
                    casePath={casePath}
                    remotePath={props?.remotePath}
                    // resetDropdowns={resetDropdowns}
                    dropdowns={{data : dropdowns, set : updateDropdown}}
                />
            </div>
        </div>
    )
};

export default SingleView;