import React, { useEffect, useState } from 'react';

function SessionUser(props){
    const [data, setData] = useState();

    const logout = () => {
        window.localStorage.removeItem("sessionUser");
        window.localStorage.removeItem("navigation");
        setData({reset: true });
    };

    const functions = {
        logout,
    }

    const sessionUser = {
        data,
        setData,
        functions
    }

    return sessionUser;
};

export default SessionUser;