import React from 'react';

import PersonOffIcon from '@mui/icons-material/PersonOff';
import PanToolIcon from '@mui/icons-material/PanTool';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import ForwardIcon from '@mui/icons-material/Forward';
import NextPlanOutlinedIcon from '@mui/icons-material/NextPlanOutlined';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import MessageIcon from '@mui/icons-material/Message';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

import { red, green, lightGreen, lightBlue, grey, purple, orange} from '@mui/material/colors';

export function returnStatusData(currentStatus, type, returnDataOnly, noColor){
    if(currentStatus === "Lead"){
        if(returnDataOnly === true){
            return {    color : "#9ccc65",
                        icon : <PanToolIcon sx={{ color: noColor ? "#FFFFFF" : lightGreen[400] }}/>,
                        position : "active"
                        }
        }
        return(<PanToolIcon sx={{ color: noColor ? "#FFFFFF" : lightGreen[400] }}/>)
    }
    if(currentStatus === "Client not Interested"){
        if(returnDataOnly === true){
            return {    color : "#ef5350",
                        icon : <PersonOffIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>,
                        position : "inactive"
                        }
        }
        return(<PersonOffIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>)
    }
    if(currentStatus === "Unqualified"){
        if(returnDataOnly === true){
            return {    color : "#ef5350",
                        icon : <DoNotDisturbOnIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>,
                        position : "closed"
                        }
        }
        return(<DoNotDisturbOnIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>)
    }
    if(currentStatus === "Failed to Contact"){
        if(returnDataOnly === true){
            return {    color : "#ef5350",
                        icon : <PhoneDisabledIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>,
                        position : "closed"
                        }
        }
        return(<PhoneDisabledIcon sx={{ color: noColor ? "#FFFFFF" : red[400] }}/>)
    }
    if(currentStatus === "Application Sent"){
        if(returnDataOnly === true){
            return {    color : "#4527a0",
                        icon : <ForwardIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>,
                        position : "active"
                        }
        }
        return(<ForwardIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>)
    }
    if(currentStatus === "Application Received"){
        if(returnDataOnly === true){
            return {    color : "#66bb6a",
                        icon : <CallReceivedIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>,
                        position : "active"
                        }
        }
        return(<CallReceivedIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>)
    }
    if(currentStatus === "Contact Attempted 1" || currentStatus === "Contact Attempted 2" || currentStatus === "Contact Attempted 3" || currentStatus === "Contact Attempted 4" || currentStatus === "Contact Attempted 5"){
        if(returnDataOnly === true){
            return {    color : "#0277bd",
                        icon : <PhoneMissedIcon sx={{ color: noColor ? "#FFFFFF" : lightBlue[800] }}/>,
                        position : "active"
                        }
        }
        return(<PhoneMissedIcon sx={{ color: noColor ? "#FFFFFF" : lightBlue[800] }}/>)
    }
    if(currentStatus === "Sent to Abacus"){
        if(returnDataOnly === true){
            return {    color : "#2e7d32",
                        icon : <EmojiFlagsIcon sx={{ color: noColor ? "#FFFFFF" : green[800] }}/>,
                        position : "active"
                        }
        }
        return(<EmojiFlagsIcon sx={{ color: noColor ? "#FFFFFF" : green[800] }}/>)
    }
    if(currentStatus === "New Insurance Interest"){
        if(returnDataOnly === true){
            return {    color : "#ff9800",
                        icon : <EmojiFlagsIcon sx={{ color: noColor ? "#FFFFFF" : orange[500] }}/>,
                        position : "active"
                        }
        }
        return(<EmojiFlagsIcon sx={{ color: noColor ? "#FFFFFF" : orange[500] }}/>)
    }
    if(currentStatus === "Follow Up"){
        if(returnDataOnly === true){
            return {    color : "#4527a0",
                        icon : <PhoneEnabledIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>,
                        position : "active"
                        }
        }
        return(<PhoneEnabledIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>)
    }
    if(type === "Follow Up"){
        if(returnDataOnly === true){
            return {    color : "#4527a0",
                        icon : <AccessAlarmIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>
                        };
        }
        return (<AccessAlarmIcon sx={{ color: noColor ? "#FFFFFF" : purple[800] }}/>)
    }
    if(type === "Note"){
        if(returnDataOnly === true){
            return {    color : "#81d4fa",
                        icon : <MessageIcon sx={{ color: noColor ? "#FFFFFF" : lightBlue[500] }}/>
                        };
        }
        return (<MessageIcon sx={{ color: noColor ? "#FFFFFF" : lightBlue[500] }}/>)
    }

    return(<NextPlanOutlinedIcon sx={{ color: noColor ? "#FFFFFF" : grey[500] }}/>)
    
}

function StatusIcon(props){
    return returnStatusData(props.currentStatus, props.type, null, props.noColor);
}

export default StatusIcon;