import React from 'react';
// import { Link } from 'react-router-dom';

import StatusIcon from '../StatusIcons';
// import LeadsIcon from './LeadsIcons';

// import CircleIcon from '@mui/icons-material/Circle';
// import HtmlReactParser from 'html-react-parser';
// import xss from 'xss';

import { timeStamp, convertIntToCurrency } from '../../envFunctions.js';
// import { dateFormat } from '../envFunctions.js';

import './TimelinePost.css';

import ErrorIcon from '@mui/icons-material/Error';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ReplyIcon from '@mui/icons-material/Reply';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import { useState, useEffect } from 'react';

function BidTimelinePost(props){
    const [alertType, setAlertType] = useState(calcAlertType());
    const [notificationStatus, setNotificationStatus] = useState();
    const [bidVerbiage, setBidVerbiage] = useState("Bid");

    let originator = "Internal";
    let bidData;
    let currentStatus = props.status;

    if(props.type === "Bid"){
        bidData = gatherBid();
    }

    if(currentStatus === "Shipped"){
        currentStatus = "Shipped - Bid";
    }

    if(currentStatus === "Lost with bid"){
        currentStatus = "Lost with Bid";
    }

    if(props?.data?.taskShippingStatus === "Closing"){
        currentStatus = "Contracts";
    }

    const status = [
        "Shipped - Bid",
        "Under Review",
        "Bidding",
        "In Conflict",
        "Does Not Fit Parameters",
        "No Bid",
        "Lost with Bid",
        "Contracts"
    ];

    useEffect(() => {
        //console.log(props.data);
    }, []);

    function calcAlertType(){
        if(props.data?.type === "Bid"){
            return " bidAlert " + props.data?.checkStatus;
        }

        // if(props.data?.taskShippingStatus === "Requested Info"){
        if(props.data?.type === "Request"){
            if(props.data?.taskShippingStatus === "Under Review"){
                return " bidAlert underReview " + props.data?.checkStatus;
            }

            return " bidAlert " + props.data?.checkStatus;
        }

        return '';
    }

    function displayActivityType(){
        if(props.type === "Note"){
            return "Note";
        }

        if(props.type === "Bid"){
            originator = "Customer";
            return [<div
                    key="bidAlert"
                    className="e">
                        {bidVerbiage === "Interest" ? "Interest Alert" : "Bid Alert"}
                    </div>,
                    <ErrorIcon key="icon"/>
                ];
        }

        if(props.data.type === "Request"){
            originator = "Customer";
            return [<div
                    key="bidAlert"
                    className="e">
                        Request
                    </div>,
                    <PsychologyAltIcon key="icon"/>
                ];
        }

        if(props.type === "Response - Request"){
            originator = "Internal";
            return [<div
                    key="bidAlert"
                    className="e">
                        Request Response
                    </div>,
                    <ReplyIcon key="icon"/>
                ];
        }

        if(props.type === "Response - Bid"){
            originator = "Internal";
            return [<div
                    key="bidAlert"
                    className="e">
                        {bidVerbiage === "Interest" ? "Interest Response" : "Bid Response"}
                    </div>,
                    <ReplyIcon key="icon"/>
                ];
        }


        if(status.includes(currentStatus)){
            return "Status Update";
        }
    }

    function displayHeader(){
        let returningStatus = [];
        let returningHeader = [
            <StatusIcon
                key="statusIcon"
                currentStatus={props.data?.type === "Note" ? "Note" : currentStatus}
                type={props.type}
                originator={originator}
            />
        ];

        if(props.data?.type === "Request"){
            if(props.data?.checkStatus === "Open"){
                returningHeader = [
                    <StatusIcon
                        key="statusIcon"
                        currentStatus={"Requested Info"}
                        type={props.type}
                        originator={originator}
                    />
                ];
            }

            if(props.data?.checkStatus === "Completed"){
                returningHeader = [
                    <FolderSharedIcon key="icon"/>
                ];
            }
        }

        if(props.data?.type === "Bid"){
            if(props.data?.checkStatus === "Open"){
                returningHeader = [
                    <StatusIcon
                        key="statusIcon"
                        currentStatus={props.data?.type === "Note" ? "Note" : currentStatus}
                        type={props.type}
                        originator={originator}
                    />
                ];
            }
            if(props.data?.checkStatus === "Completed"){
                returningHeader = <RecommendIcon/>
                returningStatus = "Bid Approved";


            }
            if(props.data?.checkStatus === "Denied"){
                returningHeader = <ThumbDownIcon/>
                returningStatus = "Bid Declined";
            }
        }

        if(props.type === "Note"){
            returningStatus = "Note";
            returningHeader = [
                <StatusIcon
                    key="statusIcon"
                    currentStatus={props.data?.type === "Note" ? "Note" : currentStatus}
                    type={props.type}
                    originator={originator}
                />
            ];
        }

        if(props.data?.type === "Response - Request"){
            returningStatus = props?.data?.content;

            returningHeader = [
                <TextSnippetIcon
                    key="statusIcon"
                />
            ];
        }

        if(props.data?.type === "Response - Bid"){
            returningStatus = props?.data?.content;

            returningHeader = [
                <TextSnippetIcon
                    key="statusIcon"
                />
            ];
        }

        return [
            <div key="header" className="cC">
                {returningHeader}
            </div>,
            <div key="status" className={"cL s dP"}>
                {displayActivityStatus()}
            </div>
        ]
    }

    function displayActivityStatus(){
        if(props?.data?.type === "Request"){
            if(props?.data.checkStatus === "Open"){
                return "Requested Information";
            }
            if(props?.data.checkStatus === "Completed"){
                return "Provided Information";
            }
        }

        if(props?.type === "Bid"){
            if(props?.data.checkStatus === "Open"){
                return bidVerbiage === "Interest" ? "Active Interest" : "Active Bid"
            }
            if(props?.data.checkStatus === "Completed"){
                return bidVerbiage === "Interest" ? "Interest Approved" : "Bid Approved"
            }
            if(props?.data.checkStatus === "Denied"){
                return bidVerbiage === "Interest" ? "Interest Declined" : "Bid Declined"
            }
        }

        if(props?.type === "Response - Request" || props?.type === "Response - Bid"){
            return props?.data?.content;
        }

        if(props?.type === "Note"){
            return props?.data?.content;
        }

        if(props.status === "Lost with bid"){
            return bidVerbiage === "Interest" ? "Lost with Interest" : "Lost with Bid"
        }

        if(props.status === "Bidding" && bidVerbiage === "Interest"){
            return "Interested"
        }

        return props.status;
    }

    function gatherBid() {
        const responseString = props.data.content;
      
        const firstCommaIndex = responseString.indexOf(",");
        const bidAmount = responseString.slice(0, firstCommaIndex);
      
        const secondCommaIndex = responseString.indexOf(",", firstCommaIndex + 1);
        const bidRDB = responseString.slice(firstCommaIndex + 1, secondCommaIndex);
      
        const bidNote = responseString.slice(secondCommaIndex + 1);
      
        return {
          bidAmount: bidAmount.trim(),
          bidNote: bidNote.trim(),
          bidRDB: bidRDB.trim(),
        };
    }

    function calculateContentWrap(){
        if(props.type === "Bid"){
            return "content bidContent g e";
        }

        if(props.type === "Note" || props.type === "Requested Info"){
            return "content bidNote g e";
        }

        return "content g";
    }

    useEffect(() => {
        if(props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]?.isNetworker){
            setBidVerbiage("Interest");
        }
    }, [props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]]);

    return (
        <div className={"bid timelinePostBlock g bR p ns" + alertType} onClick={()=>{props.setShowDetails(true); props.setCurrentTimelinePost(props.data)}}>
            {alertType &&
                <div className="timelineRequestTreeline">
                </div>
            }
            <div className="header g">
                <div className="cC">
                    {props.data?.readStatus === false && props.data?.originator === "Internal" &&
                        <div className="cC">
                            <div className="notificationAlert">
                            </div>
                        </div>
                    }
                </div>
                <div className="cL f bold">
                    {displayActivityType()}
                </div>
                <div className="cR f e">
                    {timeStamp(props.timeStamp)}
                </div>
            </div>
            <div className="divider">
            </div>
            <div className={calculateContentWrap()}>
                {displayHeader()}
                {props.type === "Bid" &&
                    <div className="cR bidData g dP">
                        <div className="data g f cC">
                            <div className="cR e f">
                                Amount: 
                            </div>
                            <div className="f cC">
                                <div className="cover cC bold e s">
                                    {convertIntToCurrency(bidData?.bidAmount)}
                                </div>
                            </div>
                        </div>
                        <div className="data g f cC">
                            <div className="cR e f">
                                RDB: 
                            </div>
                            <div className="f cC">
                                <div className="cover cC bold e s">
                                    {convertIntToCurrency(bidData?.bidRDB)}
                                </div>
                            </div>
                        </div>     
                    </div>
                }
            </div>
            {props.type === "Bid" &&
                <div className="bidNote cL g b">
                    <div className="vDivider">
                    </div>
                    <div className="cL f s e">
                        {bidData?.bidNote}
                    </div>
                </div>
            }
            {props.data?.type === "Request" &&
                <div className="bidNote cL g b">
                    <div className="vDivider">
                    </div>
                    <div className="cL f s e">
                        {props.data?.content}
                    </div>
                </div>
            }
        </div>
    )
}

export default BidTimelinePost;