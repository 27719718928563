import React, { useCallback } from 'react';
import { useState, useEffect, useRef } from 'react';
import Dropdown from '../Dropdown';
import CircularProgress from '@mui/material/CircularProgress';

function ReportSheetEditableCell(props) {

    // Props
    const session = props?.session;
    const reporting = session?.reporting;
    const selectedReport = reporting?.data?.selectedReport;
    let validEdit = props?.validEdit;
    const isCellError = props?.isCellError;
    const index = props?.index;
    const rowKey = props?.rowKey;
    const colIndex = props?.colIndex;
    const columnValue = props?.columnValue;
    const column = props?.column;
    const isEditing = props?.isEditing;
    const memoizedRenderFormattedColumnValue = props?.memoizedRenderFormattedColumnValue;
    const attributeData = props?.attributeData;
    // const searchData = props?.searchData;
    const frozen = props?.frozen;
    const isScrollableX = props?.isScrollableX;
    const stickyPosition = props?.stickyPosition;

    // EditVars Functions
    const uploadCount = session?.edit?.data?.uploadCount;
    const displayErrors = session?.edit?.data?.displayErrors;
    const validateEdit = session?.edit?.functions?.validateEdit;
    const saveEdit = session?.edit?.functions?.saveEdit;
    const editValue = session?.edit?.data?.editHandler?.position?.[index]?.[colIndex];

    // UploadVars Functions
    const incrementErrorCount = session?.upload?.functions?.incrementErrorCount;
    const decrementErrorCount = session?.upload?.functions?.decrementErrorCount;
    

    // Local Vars
    const editInputRef = useRef(null);

    //const [selectedGroup, setSelectedGroup] = useState({showMenu: props?.selectedDropdown?.data?.showMenu ?? undefined});
    const [showMenu, setShowMenu] = useState(props?.editCellDropdownHandler?.data?.showMenu ?? undefined);

    const [tempDate, setTempDate] = useState(columnValue ?? new Date(2024, 1, 26));
    const [tempInput, setTempInput] = useState(columnValue);
    const [animate, setAnimate] = useState(false);
    const [editLoading, setEditLoading] = useState(false);

    if(session?.[reporting?.data?.dataPointer]?.data?.attributeData?.[column?.columnName].formType !== 'date' && session?.[reporting?.data?.dataPointer]?.data?.attributeData?.[column?.columnName].formType !== 'dropdown' && isEditing ){
        validEdit = validateEdit(tempInput, column?.columnName);
    }

    const debounce = (func, wait) => {
        let timeout;
    
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
    
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    };

    const debouncedDropdown = useCallback(debounce((value) => {
        props?.editCellDropdownHandler?.set(value)
    }, 0), []);

    useEffect(() => {
        const isActive = props?.editCellDropdownHandler?.data?.showMenu === `${index}-${colIndex}`;
        if(showMenu !== isActive){
            setShowMenu(isActive);
        }

    }, [props?.editCellDropdownHandler?.data]);
    
    useEffect(() => {
        if(!isEditing){
            // Trigger animation
            setEditLoading(false);
            setAnimate(true);       
            const timer = setTimeout(() => setAnimate(false), 500); 

            return () => clearTimeout(timer);
        }    
    }, [isEditing, columnValue]); 

    // useEffect(() => {
    //     props?.selectedDropdown?.set({showMenu: props?.selectedDropdown?.data?.showMenu});
    //     setSelectedGroup({showMenu: props?.selectedDropdown?.data?.showMenu});
    //     console.log("reportdrop",props?.selectedDropdown?.data)
    // }, [props?.selectedDropdown?.data]); 

    function handleDateChange(e) {
        session?.edit?.functions?.updateReport(
            `editHandler.position[${index}]`, 
            `${colIndex}`,
            tempDate,
        )
        saveEdit(e.target.value, rowKey, column?.columnName, "scroll-" + index + "-" + colIndex, incrementErrorCount, decrementErrorCount);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            editInputRef.current.blur();
        }
    };

    const handleCellClick = () => {
        session?.edit?.functions?.updateReport(`editHandler`, {
            "position": {
                [index]: {
                    [colIndex]: columnValue == '-' ? '' : columnValue
                }
            }
        });
        {(columnValue == '-') ? setTempInput('') : setTempInput(columnValue);}
        setTimeout(() => {if (editInputRef.current) {editInputRef.current.focus();}}, 1); 
    };

    const debouncedSaveEdit = uploadCount > 50000 ? debounce(saveEdit, 500) : debounce(saveEdit, 0);

    return (
        (session?.edit?.data?.pointer == 'upload' ? displayErrors : true) ?
            (session?.edit?.data?.pointer == 'upload' ? isCellError : true) ?              
                    <div key={index + "-" + colIndex + "Editable"} className='g f dP'> 
                        <span className={`${!validEdit ? 'greenCell' : 'redCell'} ${animate ? !validEdit ? 'animateGreenCell' : 'animateRedCell' : ''} bR f cC s e bold${!isEditing && validEdit ? ' redCellHover' : !validEdit ? ' greenCellHover' : ''}`} 
                            onClick={() => {     
                                handleCellClick();
                            }}
                        >
                            {(isEditing) ? (
                                // Check if form type is 'date'
                                (session?.[reporting?.data?.dataPointer]?.data?.attributeData?.[column?.columnName].formType === 'date') ?
                                    <input
                                        className="f bold lH"
                                        type="date"
                                        value={tempDate ?? ''}
                                        onChange={(e) =>{
                                            setTempDate(e.target.value)
                                        }}
                                        onKeyDown={handleKeyDown}
                                        onBlur={(e) => {
                                            handleDateChange(e);
                                        }}
                                        ref={editInputRef}
                                    />
                                :
                                // Check if form type is 'dropdown'
                                (session?.[reporting?.data?.dataPointer]?.data.attributeData?.[column?.columnName].formType === 'dropdown') ?
                                    <Dropdown 
                                        list={session?.[reporting?.data?.dataPointer]?.data.attributeData?.[column?.columnName].list}
                                        setShowMenu={(input) => {
                                            const newState = input ?? (showMenu ? undefined : `${index}-${colIndex}`);
                                            setShowMenu(!newState)
                                            debouncedDropdown(newState);
                                        }}
                                        default={columnValue}
                                        showMenu={showMenu}
                                        onClick={(value) => {
                                            saveEdit(value, rowKey, column?.columnName,"scroll-" + index + "-" + colIndex, incrementErrorCount, decrementErrorCount);                                    
                                        }}
                                        reset={props?.reset}
                                    />
                                    :
                                    // Set to text input
                                    <input
                                        className="f bold lH"
                                        type="text"
                                        value={tempInput}
                                        onChange={(e) => {
                                            setTempInput(e.target.value);
                                            // session?.edit?.functions?.updateReport(`importedList.list[${index}]`, `${column?.columnName}`, e.target.value);
                                            // session?.edit?.functions?.updateReport(
                                            //     `editHandler.position[${index}]`, 
                                            //     `${colIndex}`,
                                            //     e.target.value
                                            // )
                                        }}
                                        onKeyDown={handleKeyDown}
                                        onBlur={() => {   
                                            //index
                                            setEditLoading(true);
                                            debouncedSaveEdit(tempInput, rowKey, column?.columnName,"scroll-" + index + "-" + colIndex, incrementErrorCount, decrementErrorCount);             
                                        }}
                                        ref={editInputRef}
                                    />
                                )
                                : // If not editing
                                memoizedRenderFormattedColumnValue(
                                    columnValue,
                                    attributeData[column.columnName]?.returnType,
                                    selectedReport?.search,
                                    props?.importedList && validEdit ? 'excel': undefined
                                )
                            }
                            {editLoading && uploadCount > 50000 && validEdit && <CircularProgress style={{width: '16px', height: '16px', color: '#b71c1c' }} />}
                            {editLoading && uploadCount > 50000 && !validEdit && <CircularProgress style={{width: '16px', height: '16px', color: '#2E7D32' }} />}
                        </span> 
                    </div>                                  
            :
            //If no error for that cell
                <div
                    key={index + "-" + colIndex + "Editable"}
                    className={`f g cC zT fR s e${frozen && isScrollableX ? " frozen" : ''}`}
                    style={stickyPosition}
                >
                    <div className='g f'>                
                        <span className='cC f'>
                            {memoizedRenderFormattedColumnValue(
                                columnValue,
                                attributeData[column.columnName]?.returnType,
                                selectedReport?.search,
                                props?.importedList ? undefined : undefined
                            )}
                        </span>
                    </div>
                </div>
        :
        // If Display errors is off
        <div
                key={index + "-" + colIndex + "Editable"}
                className={`f g cC zT fR s e${frozen && isScrollableX ? " frozen" : ''}`}
                style={stickyPosition}
            >
                <div className='g f'>                
                    <span className='cC f'>
                        {memoizedRenderFormattedColumnValue(
                            columnValue,
                            attributeData[column.columnName]?.returnType,
                            selectedReport?.search,
                            props?.importedList && validEdit ? 'excel': undefined
                        )}
                    </span>
                </div>
            </div>
    )
}

export default ReportSheetEditableCell;