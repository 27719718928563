import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import ForumIcon from '@mui/icons-material/Forum';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TimelineIcon from '@mui/icons-material/Timeline';
import RecentActorsTwoToneIcon from '@mui/icons-material/RecentActorsTwoTone';

import ReportSheet from '../Components/Reporting/ReportSheet.js';
import ReportTopbar from '../Components/Reporting/ReportTopbar.js';
import CurrentActivity from '../InstanceTabs/CurrentActivity.js';
import TertiaryReportToggles from '../Components/Tertiary/TertiaryReportToggles.js';
import SingleView from '../Components/SingleView.js';
import LoadingScreen from '../Components/LoadingScreen.js';
import PolicyBlock from '../Components/Policies/PolicyBlock.js';

function Policies(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const policies = props?.session?.policies;
    const baseData = policies?.data;
    const selectedReport = policies?.data?.selectedReport;
    const active = !!session?.case?.data?.policies?.origination && !!session?.case?.data?.policies?.servicing;
    const allPolicies = (
        [...(session?.case?.data?.policies?.origination || []), ...(session?.case?.data?.policies?.servicing || [])]
            .length ? [...(session?.case?.data?.policies?.origination || []), ...(session?.case?.data?.policies?.servicing || [])] : undefined
    );    

    const reportType = reporting?.functions?.reportType(selectedReport?.stem);
    const viewableReport = selectedReport?.query && selectedReport?.columns?.length > 0;
    const editableReport = selectedReport?.editable === "yes" || selectedReport?.editable === "always";

    const [reportingView, setReportingView] = useState("list");
    const [loadingHandler, setLoadingHandler] = useState({ timeline: true, portfolios : true, shareLink : true,});

    const location = useLocation();
    const currentPath = session?.env?.functions?.currentPath(location);

    const [dropdowns, setDropdowns] = useState({
        reportDownload : {
            showMenu : true,
        },
        pagination : {
            showMenu : false,
            rowMax : props?.session?.storage?.reporting?.rowMax ?? 100,
            index : selectedReport?.lastPageIndex ?? 1,
        },
    });

    const [search, setSearch] = useState({
        searching : props?.viewType !== "mobile" ? true : undefined,
        searchValue : selectedReport?.search ?? ''
    });

    const updateDropdown = (key, attributes) => {
        setDropdowns((prev) => {
            const updatedDropdowns = {
                ...prev,
                [key]: {
                    ...prev[key],
                    ...attributes, // Spread the incoming attributes to update multiple fields
                },
            };
            return updatedDropdowns;
        });
    };

    const handleSearchChange = (event) => {
        const value = event?.target?.value;
        setSearch((prev) => ({
            ...prev,
            searchValue: value,
        }));
    };

    const resetDropdowns = () => {
        setDropdowns((prev) => {
            const updated = Object.keys(prev).reduce((acc, key) => {
                acc[key] = { ...prev[key], showMenu: false };
                return acc;
            }, {});
            return updated;
        });
    };

    function printPolicies() {
        const maxVisible = session?.policies?.data?.maxVisible || 4;
    
        return allPolicies.slice(0, maxVisible).map(policy => {
            return (
                <PolicyBlock
                    session={session}
                    currentCase={policy}
                />
            ) 
        });
    }

    function printColumnHeaders(){
        const columnHeaders = [
            "Status", "Policy Number", "Insured Name", "Death Benefit", "Insurance Co."
        ]

        return (
            <div className="columnHeaders g bR bold">
                {columnHeaders.map((column, index) => (
                    <div
                        key={index}
                        className={`columnBlock ${index === 0 ? "cL" : index === columnHeaders?.length - 1 ? "cR e" : "cC" }`}
                    >
                        {column}
                    </div>
                ))}
            </div>
        )
    }

    useEffect(() => {
        // if (session?.env?.isTyping || !typing) return;
        if (session?.env?.isTyping) return;
        session?.set('policies', 'selectedReport.search', search?.searchValue);

        // setTyping(false);
    }, [session?.env?.isTyping, search.searchValue]);

    return (
        <div
            className="policies g bR oH"
            onClick={resetDropdowns}
        >
            {!active ?
                <LoadingScreen
                    page="policies"
                />
            :
                currentPath?.recordID ? 
                    <SingleView
                        session={session}
                        branch={"policies"}
                        stem={currentPath?.stem}
                        recordID={currentPath?.recordID}
                    />
                :
                    <div className="policiesHub g oH bR s e b t">
                        <div className="header g">
                            <ReportTopbar
                                session={props?.session}
                                branch={props?.branch}
                                stem={props?.stem}
                                reportingView={"report"}
                                handleSearchChange={handleSearchChange}
                                search={{data : search, set : setSearch}}
                                selectedReport={selectedReport}
                                remote={true}
                                baseModule={"policies"}
                                baseData={baseData}
                                reportType={"inherited"}
                                exclude={{"download" : true, "options" : true}}
                                include={{"filters" : true}}
                                dropdowns={{data : dropdowns, set : updateDropdown}}
                            />
                        </div>
                        <ReportSheet
                            session={session}
                            remoteReport={selectedReport ?? undefined}
                            reportType={"inherited"}
                            dropdowns={{data : dropdowns, set : updateDropdown}}
                            baseModule={"policies"}
                            baseData={baseData}
                            rowType={PolicyBlock}
                            headers={{presetColumnWidth : "repeat(6, 1fr)"}}
                            fixedHeader={true}
                            iterableData={allPolicies}
                        />
                    </div>
                }
            </div>
    )
}

export default Policies;