import React from 'react';
import { useState, useEffect, useRef } from 'react';

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

import Timelinetab from './TimelineTab.js';
import DocumentsTab from './DocumentsTab.js';
import MessageBoard from '../Components/MessageBoard/MessageBoard';
import LifeExpectancies from './LifeExpectancies.js';
import PremiumSchedule from './PremiumSchedule.js';
import ClosingTab from './ClosingTab.js';
import BidsTab from './BidsTab.js';
import Messages from './Messages.js';

function CurrentActivity(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const bids = props?.session?.bids;
    const activityView = props?.activityView;
    const currentCase = props?.currentCase;
    const casePath = props?.casePath;
    const invalidCase = !currentCase?.data && casePath;
    const approvedTabs = props?.approvedTabs;
    const active = props?.active ?? true;
    const currentCaseData = session?.case?.data?.[props?.branch]?.[props?.stem]?.[currentCase?.index];

    const currentActivityRef = useRef(null);

    const padded = ["timeline", "premiums", "les", "bids"];

    const tabView = (tab) => {
        return activityView?.data?.tab === tab && approvedTabs?.includes(tab)
    }

    return (
        active ? (
            <div
                key={activityView?.data?.tab}
                ref={currentActivityRef}
                // className={`currentActivity g f tC fC fR bR oA${
                className={`currentActivity g f tC fC bR dG oA${
                    padded?.includes(activityView?.data?.tab) ? " dP" : " fR"}${
                        !active ? " inactive" : ''} ${
                            activityView?.data?.tab
                            }${
                                props?.loadingHandler?.[activityView?.data?.tab] ? " loading" : ''}`}
            >
                {tabView("timeline") && (
                    <Timelinetab
                        session={session}
                        branch={props?.branch}
                        stem={props?.stem}
                        currentCase={props?.currentCase}
                        loadingStatus={props?.loadingHandler?.timeline}
                        activityView={activityView}
                        active={active}
                        instance={props?.instance}
                        timelineData={props?.timelineData}
                        invalidCase={invalidCase}
                        remotePath={props?.remotePath}
                    />
                )}
                {tabView("documents") && (
                    <DocumentsTab
                        session={session}
                        branch={props?.branch}
                        stem={props?.stem}
                        shareLink={props?.shareLink ?? currentCaseData?.generatedShareLink}
                        currentCase={props?.currentCase}
                        invalidCase={invalidCase}
                    />
                )}
                {tabView("les") && (
                    <LifeExpectancies
                        session={session}
                        branch={props?.branch}
                        stem={props?.stem}
                        leData={currentCaseData?.lifeExpectancies}
                        loadingStatus={props?.loadingHandler?.les}
                        currentCase={props?.currentCase}
                        invalidCase={invalidCase}
                    />
                )}
                {tabView("premiums") && (
                    <PremiumSchedule
                        session={session}
                        branch={props?.branch}
                        stem={props?.stem}
                        premiumScheduleData={currentCaseData?.premiums}
                        loadingStatus={props?.loadingHandler?.premiums}
                        dropdowns={props?.dropdowns}
                        resetDropdowns={props?.resetDropdowns}
                        currentCase={props?.currentCase}
                        invalidCase={invalidCase}
                    />
                )}
                {tabView("messages") && (
                    <Messages
                        session={session}
                        branch={props?.branch}
                        stem={props?.stem}
                        loadingStatus={props?.loadingHandler?.messages}
                        dropdowns={props?.dropdowns}
                        resetDropdowns={props?.resetDropdowns}
                        messages={currentCaseData?.messages}
                        currentCase={currentCase}
                        invalidCase={invalidCase}
                    />
                )}
                {tabView("bids") && (
                    <BidsTab
                        session={session}
                        branch={props?.branch}
                        stem={props?.stem}
                        loadingStatus={props?.loadingHandler?.bids}
                        dropdowns={props?.dropdowns}
                        resetDropdowns={props?.resetDropdowns}
                        bids={currentCaseData?.bids}
                        currentCase={currentCase}
                        invalidCase={invalidCase}
                    />
                )}
                {tabView("closing") && (
                    <ClosingTab
                        session={session}
                        branch={props?.branch}
                        stem={props?.stem}
                        loadingStatus={props?.loadingHandler?.closing}
                        dropdowns={props?.dropdowns}
                        resetDropdowns={props?.resetDropdowns}
                        invalidCase={invalidCase}
                    />
                )}
            </div> )
        :
            <div className="loading f cC g bR">
                {props?.requirementPrompt}
            </div>
    );
}

export default CurrentActivity;