import React, { useEffect, useState, useRef } from 'react';

function GroupedColumnBlock({
    attr,
    blockID,
    groupColumnHover,
    columnObject,
    index,
    addOrRemoveColumns,
    classNames,
    attributeTypeIcon,
    statusIcon,
    deleteIcon,
    currentView,
    firstActiveIndex,
  }) {

    const isHovered = groupColumnHover?.data === blockID;
    const handleIconClick = () => {
        addOrRemoveColumns({
          pointer: 'id',
          objectKey: columnObject?.id,
        });
        groupColumnHover?.set(undefined);
    };

    return (
        <div
            key={blockID}
            className={`row group g cC fC bR nS bold s e dG dP${classNames}${isHovered ? " hovered" : ''}`}
        >
            <div className="topBar g cC f dG">
                {statusIcon}
                {attributeTypeIcon}
                <div className={`f gCW cL${currentView === "properties" ? " gC3" : " gC2"}`}>
                    {attr?.friendlyTerm}
                </div>
                <div
                    className="icon f cC p"
                    onMouseEnter={() => groupColumnHover?.set(blockID)}
                    onMouseLeave={() => groupColumnHover?.set(undefined)}
                    onClick={handleIconClick}
                >
                    {deleteIcon}
                </div>
            </div>
            <div className="details g f cC dG">
                <div className="f cC">
                    {`${firstActiveIndex === index ? "Combined " : "Then "} By:`}
                </div>
                <div className={`f cL${!columnObject?.combineBy ? " thin italics" : ''}`}>
                    {columnObject?.combineBy ?? "Empty Selection"}
                </div>
            </div>
        </div>
    );
};

export default GroupedColumnBlock;