import React from 'react';
import { useState } from 'react';

import MovingIcon from '@mui/icons-material/Moving';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import InboxIcon from '@mui/icons-material/Inbox';

function MobileKanbanHeader(props){
    return (
        <div className="headerBar fR">
            <div className="leadCategory lead s e">
                <div className="kanbanNotifications cC">
                    {props.leadBlockKanbanData?.totalMissedLeads > 0 ?
                        <div className={"missedFollowUpsBubble" + props.notificationStatus("leadMissed")} onClick={()=>{props.toggleNotification("leadMissed")}}>
                            {props.leadBlockKanbanData?.totalMissedLeads}
                        </div>
                        : <div className="kanbanSpace"></div>
                    }
                    {props.leadBlockKanbanData?.totalLeadsFollowUps > 0 ?
                        <div className={"activeFollowUpsBubble" + props.notificationStatus("leadActive")} onClick={()=>{props.toggleNotification("leadActive")}}>
                            {props.leadBlockKanbanData?.totalLeadsFollowUps}
                        </div>
                        : <div className="kanbanSpace"></div>
                    }
                </div>
                <div className="kanbanHeaderCategory">
                    Lead
                </div>
                <div className="leadCategoryIcon">
                    <InboxIcon/>
                </div>
            </div>
            <div className="leadCategory contactAttempted s e">
                <div className="kanbanNotifications cC">
                    {props.leadBlockKanbanData?.totalMissedContactAttempt > 0 ?
                        <div className={"missedFollowUpsBubble" + props.notificationStatus("contactAttemptedMissed")} onClick={()=>{props.toggleNotification("contactAttemptedMissed")}}>
                            {props.leadBlockKanbanData?.totalMissedContactAttempt}
                        </div>
                        : <span></span>
                    }
                    {props.leadBlockKanbanData?.totalContactAttemptFollowUps > 0 ?
                        <div className={"activeFollowUpsBubble" + props.notificationStatus("contactAttemptedActive")} onClick={()=>{props.toggleNotification("contactAttemptedActive")}}>
                            {props.leadBlockKanbanData?.totalContactAttemptFollowUps}
                        </div>
                        : <span></span>
                    }
                </div>
                <div className="kanbanHeaderCategory">
                    Contact Attempted
                </div>
                <div className="leadCategoryIcon">
                    <PhoneMissedIcon/>
                </div>
            </div>
            <div className="leadCategory inProgress s e">
                <div className="kanbanNotifications cC">
                    { props.leadBlockKanbanData?.totalMissedInProgress > 0 ?
                        <div className={"missedFollowUpsBubble" + props.notificationStatus("inProgressMissed")} onClick={()=>{props.toggleNotification("inProgressMissed")}}>
                            {props.leadBlockKanbanData?.totalMissedInProgress}
                        </div>
                    : <span></span>
                    }
                    { props.leadBlockKanbanData?.totalInProgressFollowUps > 0 ?
                        <div className={"activeFollowUpsBubble" + props.notificationStatus("inProgressActive")} onClick={()=>{props.toggleNotification("inProgressActive")}}>
                            {props.leadBlockKanbanData?.totalInProgressFollowUps}
                        </div>
                    : <span></span>
                    }
                </div>
                <div className="kanbanHeaderCategory">
                    In Progress
                </div>
                <div className="leadCategoryIcon">
                    <MovingIcon/>
                </div>
            </div>
            <div className="leadCategory closed s e">
                <div></div>
                <div className="kanbanHeaderCategory">
                    Closed
                </div>
                <div className="leadCategoryIcon">
                    <CheckCircleIcon/>
                </div>
            </div>
        </div>
    )
}

export default MobileKanbanHeader;