import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';

import './Notifications.css';
import StatusIcon from '../Components/StatusIcons.js';

import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import CloseIcon from '@mui/icons-material/Close';

function NotificationBoard(props){
    const [notificationBlock, setNotificationBlock] = useState([]);

    function printNotifications(){
        let x = 0;
        let returningNotifications = [];
        for (var policy in props.notifications) {
            var messageCount = 0;

            for (var policyStatus in props.notifications[policy].message) {
                messageCount++;
            }

            for (var policyStatus in props.notifications[policy].status) {
                returningNotifications.push(
                <Link   key={x + props.notifications[policy].status[policyStatus].policyNumber} to={"/policies/" + props.notifications[policy].status[policyStatus].relatedPolicyID }
                    onClick={ () => {props.changeActivityView("singlePolicy", "timeline")}}>
                    <div className="notificationRow">
                        <div className="notificationName">
                            {props.notifications[policy].status[policyStatus].firstName + " " + props.notifications[policy].status[policyStatus].lastName}
                        </div>
                        <div className="notificationPolicyNumber">
                            {props.notifications[policy].status[policyStatus].policyNumber}
                        </div>
                        <div className="notificationIcon">
                            <StatusIcon currentStatus={props.notifications[policy].status[policyStatus].content} type="Status"/>
                        </div>
                        <div className="notificationStatus">
                            {"New Step: " + props.notifications[policy].status[policyStatus].content}
                        </div>
                    </div>
                </Link>);
                x++;
            }

            for (var policyRequest in props.notifications[policy].request) {
                returningNotifications.push(
                <Link   key={x +props.notifications[policy].request[policyRequest].policyNumber} to={"/policies/" + props.notifications[policy].request[policyRequest].relatedPolicyID }
                    onClick={ () => {props.changeActivityView("singlePolicy", "timeline") } }>
                    <div className="notificationRow">
                        <div className="notificationName">
                            {props.notifications[policy].request[policyRequest].firstName + " " + props.notifications[policy].request[policyRequest].lastName}
                        </div>
                        <div className="notificationPolicyNumber">
                            {props.notifications[policy].request[policyRequest].policyNumber}
                        </div>
                        <div className="notificationIcon">
                            <StatusIcon currentStatus={props.notifications[policy].request[policyRequest].content} type="Request"/>
                        </div>
                        <div className="notificationStatus">
                            {"New Request"}
                        </div>
                    </div>
                </Link>);
                x++;
            }

            for (var policyNote in props.notifications[policy].note) {
                returningNotifications.push(
                <Link   key={x + props.notifications[policy].note[policyNote].policyNumber} to={"/policies/" + props.notifications[policy].note[policyNote].relatedPolicyID }
                    onClick={ () => {props.changeActivityView("singlePolicy", "timeline") } }>
                    <div className="notificationRow">
                        <div className="notificationName">
                            {props.notifications[policy].note[policyNote].firstName + " " + props.notifications[policy].note[policyNote].lastName}
                        </div>
                        <div className="notificationPolicyNumber">
                            {props.notifications[policy].note[policyNote].policyNumber}
                        </div>
                        <div className="notificationIcon">
                            <StatusIcon currentStatus={props.notifications[policy].note[policyNote].content} type="Note"/>
                        </div>
                        <div className="notificationStatus">
                            {"New Note"}
                        </div>
                    </div>
                </Link>);
            }

            if(messageCount > 0){
                let messageCountString = "messages";
                if(messageCount > 1){
                    messageCountString = "messages";
                }
                returningNotifications.push(
                    <Link key={x + props.notifications[policy].policyNumber} to={"/policies/" + props.notifications[policy].relatedPolicyID + "#messages" }
                        onClick={ () => {props.changeActivityView("singlePolicy", "messages") } }>
                        <div className="notificationRow">
                            <div className="notificationName">
                                {props.notifications[policy].firstName + " " + props.notifications[policy].lastName}
                            </div>
                            <div className="notificationPolicyNumber">
                                {props.notifications[policy].policyNumber}
                            </div>
                            <div className="notificationIcon">
                                <StatusIcon type="Message"/>
                            </div>
                            <div className="notificationStatus">
                                {messageCount + " New " + messageCountString}
                            </div>
                        </div>
                    </Link>);
                x++;
            }
            x++;
        }
    
        setNotificationBlock(returningNotifications);
    }


    function handleClose(evt){
        if(evt.target.className === "notificationBoard"){
            props.setShowNotifications(props.showNotifications ? false : true);
        }
    }

    function clearAllNotifications(){
        const paramVals = {
            "relatedID" : props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.accountID,
            "type" : "all"
        };

        props.session?.env?.functions?.buildFetchRequest("updateReadStatus", paramVals)
            .then(response => response.json())
            .then(resData => {
                if(resData.status === 200){
                    props.setShowNotifications(false);
                    props.loadNotifications();
                }else{
                    console.log("Error trying to clear notifications.");
                }
        });
    }

    useEffect(() => {
        console.log(props);
        printNotifications();
    }, []);

    return (<div className="notificationBoard" onClick={(evt)=> {handleClose(evt)}}>
                <div className="notificationBoardInner moduleBlock">
                    <div className="notificationBoardBlock">
                        <div className="notificationBoardBlockInner">
                            <div className="notificationHeader">
                                <div className="notificationDisplayBubble">
                                    <CircleNotificationsIcon color={"color:#d2ab66"}/>
                                </div>
                                <div className="notificationHeaderTitle">
                                    Notifications
                                </div>
                                <div className="closeBtn" onClick={()=> {props.setShowNotifications(false)}}>
                                    <CloseIcon/>
                                </div>
                            </div>
                            <div className="notificationTimelineWrap dP">
                                {notificationBlock.length > 0 ? 
                                    <div className="notificationTimeline f bR tO">
                                        {notificationBlock} 
                                    </div>
                                :
                                <div className="noNotifications">
                                    You have no unread notifications
                                </div>
                                } 

                            </div>
                            {notificationBlock.length > 0 ?
                                <div className="toggleAllBtn" onClick={()=>{clearAllNotifications()}}>
                                    Clear All
                                </div>
                            :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
            )
}

export default NotificationBoard;

/*

import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';

import './Notifications.css';
import fetchBase from '../envHandler.js';
import { authorizeRequest } from '../envHandler.js';
import StatusIcon from '../Components/StatusIcons.js';

import BidTimelinePost from '../Components/Timeline/BidTimelinePost.js';
import TimelinePostDetails from '../Components/Timeline/TimelinePostDetails.js';

import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import CloseIcon from '@mui/icons-material/Close';

function NotificationBoard(props){
    const [notificationBlock, setNotificationBlock] = useState([]);

    function printNotifications(){
        let x = 0;
        let returningNotifications = [];
        for (var policy in props.notifications){
            var messageCount = 0;
            console.log(props.notifications[policy]);
            for (var policyStatus in props.notifications[policy].message) {
                messageCount++;
            }

            for (var policyStatus in props.notifications[policy].status) {
                returningNotifications.push(
                <Link   key={x + props.notifications[policy].status[policyStatus].policyNumber} to={"/policies/" + props.notifications[policy].status[policyStatus].relatedPolicyID }
                    onClick={ () => { props.updatePage("policies"); props.changeActivityView("singlePolicy", "timeline") } }>
                    <div className="notificationRow">
                        <div className="notificationName">
                            {props.notifications[policy].status[policyStatus].firstName + " " + props.notifications[policy].status[policyStatus].lastName}
                        </div>
                        <div className="notificationPolicyNumber">
                            {props.notifications[policy].status[policyStatus].policyNumber}
                        </div>
                        <div className="notificationIcon">
                            <StatusIcon currentStatus={props.notifications[policy].status[policyStatus].content} type="Status"/>
                        </div>
                        <div className="notificationStatus">
                            {"New Step: " + props.notifications[policy].status[policyStatus].content}
                        </div>
                    </div>
                </Link>);
                x++;
            }

            for (var policyRequest in props.notifications[policy].request) {
                returningNotifications.push(
                <Link   key={x +props.notifications[policy].request[policyRequest].policyNumber} to={"/policies/" + props.notifications[policy].request[policyRequest].relatedPolicyID }
                    onClick={ () => { props.updatePage("policies"); props.changeActivityView("singlePolicy", "timeline") } }>
                    <div className="notificationRow">
                        <div className="notificationName">
                            {props.notifications[policy].request[policyRequest].firstName + " " + props.notifications[policy].request[policyRequest].lastName}
                        </div>
                        <div className="notificationPolicyNumber">
                            {props.notifications[policy].request[policyRequest].policyNumber}
                        </div>
                        <div className="notificationIcon">
                            <StatusIcon currentStatus={props.notifications[policy].request[policyRequest].content} type="Request"/>
                        </div>
                        <div className="notificationStatus">
                            {"New Request"}
                        </div>
                    </div>
                </Link>);
                x++;
            }

            for (var policyNote in props.notifications[policy].note) {
                returningNotifications.push(
                <Link   key={x + props.notifications[policy].note[policyNote].policyNumber} to={"/policies/" + props.notifications[policy].note[policyNote].relatedPolicyID }
                    onClick={ () => { props.updatePage("policies"); props.changeActivityView("singlePolicy", "timeline") } }>
                    <div className="notificationRow">
                        <div className="notificationName">
                            {props.notifications[policy].note[policyNote].firstName + " " + props.notifications[policy].note[policyNote].lastName}
                        </div>
                        <div className="notificationPolicyNumber">
                            {props.notifications[policy].note[policyNote].policyNumber}
                        </div>
                        <div className="notificationIcon">
                            <StatusIcon currentStatus={props.notifications[policy].note[policyNote].content} type="Note"/>
                        </div>
                        <div className="notificationStatus">
                            {"New Note"}
                        </div>
                    </div>
                </Link>);
            }

            if(messageCount > 0){
                let messageCountString = "messages";
                if(messageCount > 1){
                    messageCountString = "messages";
                }

                returningNotifications.push(
                    <Link key={x + props.notifications[policy].policyNumber} to={"/policies/" + props.notifications[policy].relatedPolicyID + "#messages" }
                        onClick={ () => { props.updatePage("policies"); props.changeActivityView("singlePolicy", "messages") } }>
                        <div className="notificationRow">
                            <div className="notificationName">
                                {props.notifications[policy].firstName + " " + props.notifications[policy].lastName}
                            </div>
                            <div className="notificationPolicyNumber">
                                {props.notifications[policy].policyNumber}
                            </div>
                            <div className="notificationIcon">
                                <StatusIcon type="Message"/>
                            </div>
                            <div className="notificationStatus">
                                {messageCount + " New " + messageCountString}
                            </div>
                        </div>
                    </Link>);
                x++;
            }
            x++;
        }
    
        setNotificationBlock(returningNotifications);
    }


    function handleClose(evt){
        if(evt.target.className === "notificationBoard"){
            props.setShowNotifications(props.showNotifications ? false : true);
        }
    }

    function clearAllNotifications(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({  "relatedID" : props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.accountID,
                                    "type" : "all",
                                    "sessionToken" : authorizeRequest(props.sessionUser, props.updatePage)})
        };

        var x = 0;
        fetch(fetchBase + 'updateReadStatus', requestOptions)
                .then(response => response.json())
                .then(resData => {
                    if(resData.status === 200){
                        props.setShowNotifications(false);
                        props.loadNotifications();
                    }else{
                        console.log("Error trying to clear notifications.");
                    }
            });
    }

    useEffect(() => {
        printNotifications();
    }, []);

    return (<div className="overlay g cC fC fR" onClick={(evt)=> {handleClose(evt)}}>
                <div className="notificationBoard moduleBlock g cC bR fC">
                    <div className="header f g fR cC">
                        <div className="notificationDisplayBubble">
                            <CircleNotificationsIcon color={"color:#d2ab66"}/>
                        </div>
                        <div className="notificationHeaderTitle">
                            Notifications
                        </div>
                        <div className="btn close cC p" onClick={()=> {props.setShowNotifications(false)}}>
                            <CloseIcon/>
                        </div>
                    </div>
                    <div className="cC g f fC">
                        {notificationBlock.length > 0 ? 
                            <div className="notificationTimeline">
                                {notificationBlock} 
                            </div>
                        :
                            <div className="noNotifications">
                                You have no unread notifications
                            </div>
                        }
                    </div>
                    <div className="actionBar cC g f fC">
                        {notificationBlock.length > 0 &&
                            <div className="defaultBtn" onClick={()=>{clearAllNotifications()}}>
                                Clear All
                            </div>
                        }
                    </div>
                </div>
            </div>
            )
}

export default NotificationBoard;
*/
