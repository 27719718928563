import React from 'react';
import { useState, useEffect } from 'react';

import StatusIcon from '../StatusIcons';


import '../Timeline/TimelinePost.css';

import ErrorIcon from '@mui/icons-material/Error';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ReplyIcon from '@mui/icons-material/Reply';
import PsychologyAltTwoToneIcon from '@mui/icons-material/PsychologyAltTwoTone';
import TextSnippetTwoToneIcon from '@mui/icons-material/TextSnippetTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ThumbDownAltTwoToneIcon from '@mui/icons-material/ThumbDownAltTwoTone';
import TaskTwoToneIcon from '@mui/icons-material/TaskTwoTone';
import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone';

function Payment(props){
    const session = props?.session;
    const timelineData = props?.timelineData;
    const timelineItem = props?.timelineItem;
    const timeAgo = props?.timeAgo;
    const readStatus = props?.readStatus ?? timelineItem?.readStatus;
    const checkStatus = timelineItem?.checkStatus

    const preExistingPayment = !timelineItem?.datePremiumApplied && props?.currentCase?.data?.firstVOCDate > props?.currentCase?.data?.dateCreated;
    const expiredPolicy = !timelineItem?.datePremiumApplied && timelineItem?.soldDate &&
        (props?.currentCase?.data?.firstVOCDate < timelineItem?.dateCreated || !props?.currentCase?.data?.firstVOCDate);
    const verified = timelineItem?.datePremiumApplied;

    return (
        <div
            className={`newTimelinePost g f bR p ${props?.stem} payment${props?.detailed ? " detailed" : ''}`}
            onClick={props?.onClick}
        >
            <div className="header g f fR e dG">
                {readStatus === false && timelineItem?.originator === "Internal" &&
                    <div className="f cC">
                        <div className="notificationAlert">
                        </div>
                    </div>
                }
                <div className="cL gC2 bold dG">
                    Payment
                    <PaymentsTwoToneIcon/>
                </div>
                <div className="cR gC3">
                    {timeAgo}
                </div>
            </div>
            <div className="divider dT">
            </div>
            <div className="content g f cC dG">
                <div className="subHeader g f s e dG">
                    <div className="f cL dG lH">
                        {<PaidTwoToneIcon/>}
                        Amount: <span className="bold">{session?.env?.functions?.convertIntToCurrency(timelineItem?.amountPaid)}</span>
                    </div>
                    {timelineItem?.datePremiumApplied &&
                        <div className="cC g lH">
                            <div className="f cL dG">
                                <TodayTwoToneIcon/>
                                <div className="gC2">
                                    Applied: <span className="bold">{session?.env?.functions?.reformatDate(timelineItem?.datePremiumApplied)}</span>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="f cR fR gC3 lH">
                        <div className="dataPoint bold cC dT bR fR">
                            {verified &&
                                "Confirmed"
                            }
                            {!verified && (
                                (preExistingPayment && "Prior to Servicing") || 
                                (!expiredPolicy && "Pending VOC")
                            )}
                        </div>
                    </div>
                </div>
                {/* <div className="vDivider dT gC1">
                </div>
                <div className="f cL gC2">
                    {verified ? <TodayTwoToneIcon/> : <PendingActionsTwoToneIcon/>}
                    {verified ? 
                        session?.env?.functions?.reformatDate(timelineItem?.datePremiumApplied)
                    :
                        "Pending VOC"
                    }
                </div> */}
            </div>
        </div>
    );
}

export default Payment;