import React from 'react';
import { useState, useEffect } from 'react';

import LeadsIcons from '../Components/LeadsIcons.js';

import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

function ImportLeads(props){
    const [noteContent, setNoteContent] = useState("");
    const [uploadLeadsSearch, setUploadLeadsSearch] = useState("");
    const [leadBlockList, setLeadBlockList] = useState([]);
    const [visibleThreshold, setVisibleThreshold] = useState(48);
    const [selectedLead, setSelectedLead] = useState({});

    function toggleLeadNotesPanel(){
        props.setShowImportLeads(false);
    }

    function leadRowClass(leadInfo){
        var selectedStatus = '';
        if(leadInfo.leadID === selectedLead.leadID){
            selectedStatus = " selected";
        }

        if(leadInfo.currentPosition === "closed"){
            if(leadInfo.leadStatus === "Sent to Abacus"){
                return "abacus" + selectedStatus;
            }

            if(leadInfo.leadStatus === "New Insurance"){
                return "insurance" + selectedStatus;
            }

            return "lost" + selectedStatus;
        }

        return leadInfo.currentPosition + selectedStatus;
    }

    function convertCurrency(int){
        const formatCash = n => {
            if (n < 1e3) return n;
            if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
            if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
            if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
            if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
          };

          if(formatCash(int) === 0 || !int){
            return "-";
          }else{
            return formatCash(int);
          }
    }

    function convertTimeStamp(timeStamp) {
    
        timeStamp = Date.parse(timeStamp);
        timeStamp = timeStamp/1000;

        var seconds = Math.floor(((new Date().getTime()/1000) - timeStamp)),
        interval = Math.floor(seconds / 31536000);
    
        if (interval > 1) return interval + " yrs";
    
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) return interval + " mo";
    
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) return interval + " days";
    
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) return interval + "h";
    
        interval = Math.floor(seconds / 60);
        if (interval > 1) return interval + "m ";
    
        return Math.floor(seconds) + "s";
    }

    function selectToImport(lead){
        setSelectedLead(lead)
    }

    function loadLeadsList(){
        const searchVal = uploadLeadsSearch.toLowerCase();
        var returningLeadList = [];

        if(leadBlockList === undefined || leadBlockList.length === 0){
            return;
        }

        const sortLeadBlockList = leadBlockList => {
            return leadBlockList.sort((a, b) => {
                return new Date(b.lastUpdateStatusTimeRaw) - new Date(a.lastUpdateStatusTimeRaw);
            });
        };

        const sortedLeadBlockList = sortLeadBlockList(leadBlockList);
        const filteredLeadBlockList = sortedLeadBlockList.filter(leadBlock => {
            const fullName = (leadBlock.leadFirstName + " " + leadBlock.leadLastName).toLowerCase();
            return fullName.includes(searchVal);
        });

        var visibleCount = 0;
        for (let leadBlock of filteredLeadBlockList){
            if(visibleCount <= visibleThreshold){
                returningLeadList.push(
                    <div key={leadBlock.leadID} className={"leadRow ns " + leadRowClass(leadBlock)} onClick={()=>{selectToImport(leadBlock)}}>
                        <div className="cC">
                            <LeadsIcons currentStatus={leadBlock.leadStatus} type={"Lead"}/>
                        </div>
                        <div className="cL">
                            {leadBlock.leadFirstName + " " + leadBlock.leadLastName}
                        </div>
                        <div className="cC">
                            {convertCurrency(leadBlock.leadDeathBenefit)}
                        </div>
                        <div className="cR">
                            {convertTimeStamp(leadBlock.lastUpdateStatusTimeRaw)}
                        </div>
                    </div>
                );
                visibleCount++;
            }
        }

        if(visibleCount === 0){
            return (
                <div className="loadingBlock">
                    <div className="loadingBlockMessage">
                        No leads found
                    </div>
                </div>
            )
        }

        return returningLeadList;
    }

    function handleScroll(event) {
        if(visibleThreshold > props.leadsList.length){
            return;
        }

        const element = event.target;
        if (element.scrollHeight - element.scrollTop <= element.clientHeight + 100) {
            setVisibleThreshold(visibleThreshold + 48);
        }
    }

    function updateUploadLeadsSearchVal(evt, reset){
        if(reset){
            setUploadLeadsSearch('');
        }else{
            setUploadLeadsSearch(evt.target.value);
        }
    }

    useEffect(() => {
        setLeadBlockList(props.leadsList);
    }, [props.leadsList]);

    return (
        <div className="uploadModuleBoard" onClick={noteContent === '' ? (evt)=> {props.handleClose(evt)} : null }>
            <div className="leadOptionsBoardInnerWrap moduleBlock">
                <div className="miniModuleHeader">
                    <div className="notificationHeaderTitle">
                        Leads
                    </div>
                    <div className="closeBtn" onClick={()=> {toggleLeadNotesPanel()}}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className="miniModuleBody">

                    <div className="uploadLeadsListWrap">
                        <div className="uploadLeadsListSearchContainer">
                            <input className="uploadLeadsListSearchBar"
                                placeholder="Search by Client Name"
                                onChange={(evt) => {updateUploadLeadsSearchVal(evt)}}
                                value={uploadLeadsSearch}/>
                        </div>
                            <div className="uploadLeadsListContainer">
                                <div className="uploadLeadsList"  onScroll={(evt)=>{handleScroll(evt)}}>
                                {leadBlockList === undefined || leadBlockList.length === 0 ?
                                    <div className="loadingBlock">
                                        <div className="loadingBlockMessage">
                                            <CircularProgress/>
                                        </div>
                                    </div>
                                :
                                    loadLeadsList()
                                }
                                </div>
                            </div>
                        </div>
                    {Object.keys(selectedLead).length === 0 ?
                        null
                    : 
                        <div className="selectedLeadInfo">
                            { "Selected: " + selectedLead.leadFirstName + " " + selectedLead.leadLastName }
                        </div>
                    }
                    <div className={Object.keys(selectedLead).length === 0 ? "updateLeadQuickAccess ns inactive" : "updateLeadQuickAccess ns"}
                        onClick={Object.keys(selectedLead).length === 0 ? null : ()=>{props.setImportedUploadPolicy(selectedLead); toggleLeadNotesPanel()}}>
                        Import
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImportLeads;