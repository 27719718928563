import React, { useEffect, useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import TableRowsIcon from '@mui/icons-material/TableRows';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FileOpenIcon from '@mui/icons-material/FileOpen';

import Dropdown from '../Dropdown.js';

function ReportOptions(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const baseData = props?.baseData;
    // const selectedReport = props?.baseData?.selectedReport;
    // const selectedReport = props?.selectedReport;
    // const selectedReport = session?.[props?.baseModule]?.data?.selectedReport;
    const baseModule = props?.baseModule;
    const selectedReport = props?.selectedReport;
    const updateReporting = reporting?.setData;
    // const currentPreferences = reporting?.data?.specialColumns;
    // const currentPreferences = selectedReport?.specialColumns;
    const viewableReport = selectedReport?.query && selectedReport?.columns?.length > 0;

    const [dropdownHandler, setDropdownHandler] = useState({ showMenu : undefined });

    const [preferences, setPreferences] = useState(selectedReport?.specialColumns);
    const [downloadFileType, setDownloadFileType] = useState(baseData?.downloadFileType);

    function downloadSelectedRowStatus(){
        if(selectedReport?.specialColumns?.select && baseData?.selectionState?.selectedRows?.length > 0){
            return true
        }

        return false;
    }

    function ToggleOption(key, label, Icon){
        return (
            <div className="option g dG cC">
                <div
                    className={`toggle s e p${preferences?.[key] ? " active cL" : " cR"}`}
                    onClick={() => {
                        setPreferences(prev => ({
                            ...prev,
                            [key]: !prev[key],
                        }));
                        session?.set(baseModule, `${props?.remotePath ?? ''}selectedReport.specialColumns.${key}`, !preferences?.[key])
                    }}
                    // onClick={()=>{console.log(selectedReport)}}
                >
                    <div className="status">
                    </div>
                </div>
                <div className="f cL g">
                    <div className="prompt f g dG">
                        <div className="f cC">
                            <Icon />
                        </div>
                        <div className="f cL bold">
                            {label}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const resetDropdowns = () => {
        if(dropdownHandler?.showMenu){
            resetShowMenu(setDropdownHandler);
        }
    };

    const resetShowMenu = (setDropdownState) => {
        setDropdownState((prev) => {
            if (prev?.showMenu) {
                return { ...prev, showMenu: false };
            }

            return prev;
        });
    };

    // useEffect(() => {
    //     console.log("CHANGE!");
    //     setPreferences(selectedReport?.specialColumns);
    // }, [selectedReport?.specialColumns]);

    return (
        <div className="preferences g f dP nS tC fC" onClick={()=>{resetDropdowns()}}>
            <div className="downloads f g dG" onClick={()=>{console.log(props)}}>
                <div className="header f cL bold">
                    Downloads
                </div>
                <div className="option g dG cC">
                    <div
                        className={`btn default g bold bR f s e cC ns${viewableReport && selectedReport?.list?.length ? " p" : " inactive"}`}
                        onClick={viewableReport && selectedReport?.list?.length ? ()=>{
                            reporting?.functions?.downloadReport(session, selectedReport)} : null}
                    >
                        <div className="f cC lH">
                            Download
                        </div>
                        <DownloadIcon/>
                    </div>
                    <div className="f cC">
                        <ListAltIcon/>
                    </div>
                    <div className="f cL bold">
                        Report Sheet
                    </div>
                </div>
                <div className="option g dG cC">
                    <div
                        className={`btn default bold bR f s e cC ns p${!downloadSelectedRowStatus() ? " inactive" : ''}`}
                        onClick={() => downloadSelectedRowStatus() && reporting?.functions?.downloadSelectedFiles(
                            props?.baseData?.selectionState?.selectedRows, session
                        )}
                    >
                        <div className="f cC lH">
                            Download
                        </div>
                        <DownloadIcon/>
                    </div>
                    <div className="f cC">
                        <FolderZipIcon/>
                    </div>
                    <div className="f cL bold">
                        {`Files of Selected Rows`}
                    </div>
                    <div className="f cR">
                        {`${selectedReport?.specialColumns?.select ? " ( " + props?.baseData?.selectionState?.selectedRows?.length + " ) Rows Selected" : ''}`}
                    </div>
                </div>
                <div className="option g dG" onClick={()=>{console.log(baseData);}}>
                    <Dropdown
                        key={`downloadFileType`}
                        setShowMenu={(input) => {
                            const newState = input ?? (dropdownHandler?.showMenu ? undefined : `downloadFileType`);
                            setDropdownHandler({
                                ...dropdownHandler,
                                showMenu: newState
                            });
                        }}
                        showMenu={dropdownHandler?.showMenu === `downloadFileType`}
                        default={downloadFileType ?? "XLSX"}
                        list={["XLSX", "CSV", "TXT"]}
                        onClick={(value) => {
                            session?.set(baseModule, `${props?.remotePath ?? ''}downloadFileType`, value);
                            session?.env?.functions?.updateLocalStorage(selectedReport?.branch, selectedReport?.stem, "downloadFileType", value);
                            setDownloadFileType(value);
                            // window.localStorage.removeItem('downloadFileType');
                            // window.localStorage.setItem('downloadFileType', value);
                        }}
                        reset={resetDropdowns}
                    />
                    <div className="f cC">
                        <FileOpenIcon/>
                    </div>
                    <div className="f cL bold">
                        Download File Type
                    </div>
                </div>
            </div>
            {selectedReport?.recordID && (selectedReport?.subReport?.name ? selectedReport?.referenceBranch !== "summary" : selectedReport?.branch !== "summary") &&
                <div className="toggles f g dG">
                    <div className="header f cL bold" onClick={()=>{console.log(reporting?.data?.reportDataHandler?.[selectedReport?.subReport?.name ? selectedReport?.referenceStem : selectedReport?.stem]?.hasPath)}}>
                        Toggles
                    </div>
                    {ToggleOption("rowIndex" ,"Row Index", TableRowsIcon)}
                    {reporting?.data?.reportDataHandler?.[selectedReport?.subReport?.name ? selectedReport?.referenceStem : selectedReport?.stem]?.folderName
                        && ToggleOption("select" ,"Row Select", HighlightAltIcon)}
                    {selectedReport?.editable === "always" && reporting?.data?.reportDataHandler?.[selectedReport?.subReport?.name ? selectedReport?.referenceStem : selectedReport?.stem]?.hasPath
                        && ToggleOption("view" ,"View Row", VisibilityIcon)}
                </div>
            }
        </div>
    )
};

export default ReportOptions;