import React, { useEffect, useState, useRef } from 'react';

function DragHandler({
    session,
    groupColumnsForm,
    columnForm
  }) {

    const reporting = session?.reporting;
    const selectedReport = reporting?.data?.selectedReport;
    const updateReporting = session?.reporting?.setData;
    const viewType = session?.env?.viewport?.viewType;

    const defaultDragStates = () => ({
        draggingIndex: undefined,
        initialIndex: undefined,
        dragDirection: undefined,
        currentColumnHover: undefined,
        draggingColumnID : undefined
    });

    const [dragState, setDragState] = useState({
        default: defaultDragStates(),
        custom: defaultDragStates()
    });

    const lastDragState = useRef({
        adjustedIndex: undefined,
        dragDirection: undefined
    });

    const updateDragState = (type, attr, value, call) => {
        setDragState(prev => {
            if (prev[type][attr] !== value) {
                return {
                    ...prev,
                    [type]: {
                        ...prev[type],
                        [attr]: value
                    }
                };
            }
            return prev;
        });
    };

    const handleDragStart = (e, index, type, colID = undefined, col) => {
        setDragState(prev => ({
            ...prev,
            [type]: {
                ...prev[type],
                draggingIndex: index,
                initialIndex: index,
                draggingColumnID : colID
            }
        }));
        e.dataTransfer.effectAllowed = 'move';
    };

    const handleDragOver = (e, index, type) => {
        e.preventDefault();
        e?.target?.classList?.add('active');

        const dragHandler = dragState[type];
        const allColumns = [...columnForm.data];
        let adjustedIndex;

        const nonCustomIndexes = allColumns.reduce((acc, column, i) => {
            if (!column.custom) acc.push(i);
            return acc;
        }, []);

        const customIndexes = allColumns.reduce((acc, column, i) => {
            if (column.custom) acc.push(i);
            return acc;
        }, []);

        if (dragHandler.draggingIndex !== undefined) {
            const allowedIndexes = type === 'custom' ? customIndexes : nonCustomIndexes;
            adjustedIndex = dragHandler.draggingIndex < index
                ? Math.min(index, allowedIndexes[allowedIndexes.length - 1] + 1)
                : Math.max(index, allowedIndexes[0]);

            const newDragDirection = dragHandler.draggingIndex < index ? "lower" : "higher";
            
            // Early return if no change
            if (adjustedIndex === lastDragState.current.adjustedIndex && newDragDirection === lastDragState.current.dragDirection) {
                return;
            }

            // Update last known state
            lastDragState.current = {
                adjustedIndex,
                dragDirection: newDragDirection
            };

            // Only update if there's a change
            const placeholderIndex = allColumns.findIndex(col => col?.placeholder);
            if (placeholderIndex !== -1) {
                allColumns.splice(placeholderIndex, 1);
            }
            const placeholder = { placeholder: true, custom: type === 'custom' ? true : undefined };
            allColumns.splice(adjustedIndex, 0, placeholder);
            columnForm.set(allColumns);

            updateDragState(type, "dragDirection", newDragDirection);
            updateDragState(type, "draggingIndex", adjustedIndex);
        }
    };
    
    const handleDragEnter = (e, index, type) => {
        const dragHandler = dragState[type];
        if (index !== dragHandler?.draggingIndex) {
            if (index < dragHandler?.draggingIndex) {
                updateDragState(type, "dragDirection", "higher");
                // setDragDirection("higher");
            } else {
                updateDragState(type, "dragDirection", "lower");
                // setDragDirection("lower");
            }
        }
    };

    const handleDrop = (e, targetIndex, type) => {
        e.preventDefault();
        const dragHandler = dragState[type];
    
        if (dragHandler?.initialIndex === undefined) return;
    
        let currentColumns = [...columnForm.data].filter(col => !col.placeholder);
        const draggedItem = currentColumns[dragHandler.initialIndex];
    
        if (!draggedItem) {
            console.error("Dragged item not found at initial index", dragHandler.initialIndex);
            return;
        }
    
        currentColumns.splice(dragHandler.initialIndex, 1);
        targetIndex = dragHandler.initialIndex < targetIndex ? targetIndex - 1 : targetIndex;
        currentColumns.splice(targetIndex, 0, draggedItem);
    
        columnForm.set(currentColumns);
        function updateGroupColumnsOrder(columnForm) {
            const columnIds = columnForm.map(col => col.id); // Extract IDs from columnForm for ordering reference
            const columnMap = new Map(columnForm.map(item => [item.id, item])); // Create a map for quick lookup
        
            let updatedGroupColumnsForm = groupColumnsForm?.data?.filter(col => columnIds.includes(col.id))
                .sort((a, b) => columnIds.indexOf(a.id) - columnIds.indexOf(b.id));
        
            const nonMatchingGroupColumns = groupColumnsForm?.data?.filter(col => !columnIds.includes(col.id));
            updatedGroupColumnsForm = updatedGroupColumnsForm.concat(nonMatchingGroupColumns);
        
            groupColumnsForm.set(updatedGroupColumnsForm); // Update state or global store
        }
        updateGroupColumnsOrder(currentColumns);

        setDragState(prev => ({
            ...prev,
            [type]: defaultDragStates()
        }));
    
        // updateReporting(type, "selectedReport", "columns", currentColumns);
        session?.set("reporting", "selectedReport.columns", currentColumns);
    };
    
    const handleDragEnd = (e, index, type) => {
        const newArray = columnForm?.data?.filter(item => !item?.placeholder);
        columnForm?.set(newArray);
        // updateReporting("selectedReport", "columns", newArray);
        session?.set("reporting", "selectedReport.columns", newArray);
        setDragState(prev => ({
            ...prev,
            [type]: defaultDragStates()
        }));
    };

    const handleDragLeave = (e, index, type) => {
        const dragHandler = dragState[type];
        e?.target?.classList?.remove('active');

        if (index !== dragHandler?.draggingIndex) {
            updateDragState(type, "draggingIndex", undefined);
        }
    };

    const handleColumnHoverStart = (e, index, type) => {
        const dragHandler = dragState[type];
        if(dragHandler?.draggingIndex !== index){
            updateDragState(type, "currentColumnHover", index);
        }
    };

    const handleColumnHoverLeave = (e, index, type) => {
        const dragHandler = dragState[type];
        if(dragHandler?.draggingIndex !== index){
            updateDragState(type, "currentColumnHover", undefined);
        }
    };

    const dragging = {
        data : dragState,
        set : setDragState,
        functions : {
            updateDragState,
            handleDragStart,
            handleDragOver,
            handleDragEnter,
            handleDrop,
            handleDragEnd,
            handleDragLeave,
            handleColumnHoverStart,
            handleColumnHoverLeave,
        }
    }

    return dragging;
};

export default DragHandler;