import React from 'react';
import { useState, useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import GavelIcon from '@mui/icons-material/Gavel';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import SummarizeIcon from '@mui/icons-material/Summarize';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// import TimelineRequest from '../../Components/TimelineRequest';
import TimelineRequest from '../Components/Timeline2/Request - Origination.js';

function ClosingTab(props) {
    const [CRF, setCRF] = useState({
                                    internalList : [],
                                    customerList :[],
                                    completedProgress : 0,
                                    submittedProgress : 0,
                                    currentStatus : "",
                                    total : 0,
                                    completed : 0,
                                    submitted : 0,
                                    sectionBlock : [] });
    const [contract, setContract] = useState({
                                    internalList : [],
                                    customerList :[],
                                    completedProgress : 0,
                                    submittedProgress : 0,
                                    currentStatus : "",
                                    total : 0,
                                    completed : 0,
                                    submitted : 0,
                                    sectionBlock : [] });
    const [deficiency, setDeficiency] = useState({
                                    internalList : [],
                                    customerList :[],
                                    completedProgress : 0,
                                    submittedProgress : 0,
                                    currentStatus : "",
                                    total : 0,
                                    completed : 0,
                                    submitted : 0,
                                    sectionBlock : [] });
    const [closePackage, setClosePackage] = useState({
                                    internalList : [],
                                    customerList :[],
                                    completedProgress : 0,
                                    submittedProgress : 0,
                                    currentStatus : "",
                                    total : 0,
                                    completed : 0,
                                    submitted : 0,
                                    sectionBlock : [] });

    const [downloadStatusBids, setDownloadStatusBids] = useState(false);
    const [downloadStatusPVS, setDownloadStatusPVS] = useState(false);
    const [downloadStatusCompliance, setDownloadStatusCompliance] = useState(false);
    const [downloadStatusPackage, setDownloadStatusPackage] = useState(false);
    const [extraResources, setExtraResources] = useState(false);

    function loadClosingSection(section){
        var tempInternalList = [];
        var tempCustomerList = [];
        var completeCount = 0;
        var openCount = 0;
        var submittedCount = 0;
        var total = 0;
        var currentStatus = "default";
        var completedProgress = 0;
        var submittedProgress = 0;

        for (var key of Object.keys(props.policyTimelineData)){
            var tempClosingObj = {};

            if(props.policyTimelineData[key].type === section){
                if(props.policyTimelineData[key].assignedTo === "Internal"){
                    tempInternalList.push(<TimelineRequest 
                                            key={key}
                                            policyKey={key}
                                            objectData={props.policyTimelineData[key]}
                                            changeActivityView={props.changeActivityView}
                                            setCurrentStatusKey={props.setCurrentStatusKey}
                                            setShowStatusDetail={props.setShowStatusDetail}
                                            updateRequest={props.updateRequest}
                                            navigateToDocument={props.navigateToDocument}
                                            closingTab={true}
                                            sessionUser={props.sessionUser}
                                            updatePage={props.updatePage}
                                            session={props?.session}
                                        />);
                }
                if(props.policyTimelineData[key].assignedTo === "Customer"){
                    tempCustomerList.push(<TimelineRequest 
                                            key={key}
                                            policyKey={key}
                                            objectData={props.policyTimelineData[key]}
                                            changeActivityView={props.changeActivityView}
                                            setCurrentStatusKey={props.setCurrentStatusKey}
                                            setShowStatusDetail={props.setShowStatusDetail}
                                            updateRequest={props.updateRequest}
                                            navigateToDocument={props.navigateToDocument}
                                            closingTab={true}
                                            sessionUser={props.sessionUser}
                                            updatePage={props.updatePage}
                                            session={props?.session}
                                        />);
                }
                if(props.policyTimelineData[key].checkStatus === "Completed"){
                    completeCount++;
                }
                if(props.policyTimelineData[key].checkStatus === "Submitted"){
                    submittedCount++;
                }
                if(props.policyTimelineData[key].checkStatus === "Open"){
                    openCount++;
                }
                total++;
            }
        }

        if(completeCount > 0){
            completedProgress = completeCount/total;
        }

        if(completedProgress === 1){
            currentStatus = "completed";
        }

        if(completedProgress < 1){
            if(openCount === total){
                currentStatus = "open";
            }else{
                currentStatus = "progress";
            }
        }

        if(total === 0){
            currentStatus = "default";
        }

        let completedRequired = false;
        if(CRF.completedProgress === 1 && contract.completedProgress === 1){
            completedRequired = true;
        }

        if(section === "Closing - Package" && completedRequired && deficiency.total > 0 && deficiency.completedProgress === 1
        || section === "Closing - Package" && completedRequired && deficiency.total === 0){
            currentStatus = "package";
        }

        submittedProgress = (completeCount + submittedCount)/total;

        tempClosingObj = {
            completedProgress : completedProgress,
            submittedProgress : submittedProgress,
            internalList : [tempInternalList],
            customerList : [tempCustomerList],
            currentStatus : currentStatus,
            total : total,
            completed : completeCount,
            submitted : submittedCount
        }

        var updatedSectionBlock = printClosingSection(section, tempClosingObj, currentStatus);
        tempClosingObj["sectionBlock"] = updatedSectionBlock;

        if(section === "Closing - CRF"){
            setCRF(tempClosingObj);
        }

        if(section === "Closing - Contract"){
            setContract(tempClosingObj);
        }

        if(section === "Closing - Deficiency"){
            setDeficiency(tempClosingObj);
        }

        if(section === "Closing - Package"){
            setClosePackage(tempClosingObj);
        }
    }

    function printClosingSection(section, tempClosingObj, currentStatus){
        var returningBlock = [];
        var emptyPrompt = "Pending";
    
        if(section === "Closing - Contract"){
            emptyPrompt = "No available contracts yet";
        }

        if(section === "Closing - Deficiency"){
            emptyPrompt = "No reported deficiencies";
        }

        if(tempClosingObj.total === 0 && section !== "Closing - Package" || section === "Closing - Package" && currentStatus !== "package"){
            returningBlock = [
                <div key={section} className="closingTabSection b">
                    <div className="closingTabTrackline">
                    </div>
                    <div className="closingStatusPending">
                        { emptyPrompt }
                    </div>
                </div>
            ]
            return returningBlock;
        }

        returningBlock = [
            <div key={section} className={"closingTabSection b " + currentStatus}>
                <div className={"closingTabTrackline " + currentStatus}>
                </div>
                {tempClosingObj.internalList[0].length > 0 ?
                    <div className="g cC f fC">
                        <div className="closingAssignment">
                            Assigned to: <b>Abacus Life</b>
                        </div>
                        { tempClosingObj.internalList}
                    </div>
                : null }
                {tempClosingObj.customerList[0].length > 0 ?
                    <div className="g cC f fC">
                        <div className="closingAssignment">
                            Assigned to: <b>{props.sessionUser.firstName + " " + props.sessionUser.lastName}</b>
                        </div>
                        { tempClosingObj.customerList}
                    </div>
                : null }
                {section === "Closing - Package" ?
                    <div className={"closingAssignment " + currentStatus}>
                        <b>Congratulations!</b>
                    </div> :
                    null
                }
                <div className={"closingAssignmentDivider "+ currentStatus}>
                    {tempClosingObj.submittedProgress > 0 && tempClosingObj.completedProgress !== 1 ?
                    <div className="closingProgressWrap">
                        <div className="closingAssingmentDividerProgress" style={{width:(tempClosingObj.submittedProgress * 100) + "%"}}></div>         
                    </div>
                        :
                        null
                    }
                </div>
                { section !== "Closing - Package" ?
                    <div className={"closingAssignment " + currentStatus}>
                        { "Completed " + ( tempClosingObj.completed + tempClosingObj.submitted ) + "/" + tempClosingObj.total }
                    </div>
                    :
                    <div>
                        <div className={"closingAssignment space " + currentStatus}>
                            You have completed everything. Click to download your packages.
                        </div>
                        <div className="closingBtnContainer">
                            <div className="downloadOptionIcon">
                                <GavelIcon/>
                            </div>
                            <div className="downloadOption">
                                Contract Package
                            </div>
                            <div>
                                <div className="closingTabDownloadBtn" onClick={()=>{downloadFromDrive("Contract Package.pdf", "Contract Package")}}>
                                    { printDownloadBtn(downloadStatusPackage) }
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className={extraResources ? "closingExtraResources active" : "closingExtraResources"}>
                            <div className="closingExtraResourcesTop p" onClick={()=>{setExtraResources(extraResources ? false : true)}}>
                                <div className="closingExtraResourcesTitle cC ns">
                                    Extra Downloadable Resources
                                </div>
                                <div className="closingExtraResourcesBtn cR e">
                                    { extraResources ?
                                        <KeyboardArrowUpIcon/>
                                        :
                                        <KeyboardArrowDownIcon/>
                                    }
                                </div>
                            </div>
                            <div className="closingBtnContainer">
                                <div className="downloadOptionIcon">
                                    <LineAxisIcon/>
                                </div>
                                <div className="downloadOption">
                                    Bid History
                                </div>
                                <div>
                                    <div className="closingTabDownloadBtn" onClick={()=>{props.downloadBidReport(setDownloadStatusBids)}}>
                                        { printDownloadBtn(downloadStatusBids) }
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="closingBtnContainer">
                                <div className="downloadOptionIcon">
                                    <SummarizeIcon/>
                                </div>
                                <div className="downloadOption">
                                    Policy Valuation Summary
                                </div>
                                <div>
                                    <div className="closingTabDownloadBtn" onClick={()=>{downloadPVS()}}>
                                        { printDownloadBtn(downloadStatusPVS) }
                                    </div>
                                </div>
                            </div>
                            {/* <hr/>
                            <div className="closingBtnContainer">
                                <div className="downloadOptionIcon">
                                    <AssuredWorkloadIcon/>
                                </div>
                                <div className="downloadOption">
                                    Compliance & Suitability Package
                                </div>
                                <div>
                                    <div className="closingTabDownloadBtn" onClick={()=>{downloadFromDrive("LS Suitability Form.pdf", "Compliance & Suitability Package")}}>
                                        { printDownloadBtn(downloadStatusCompliance) }
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                }
            </div>
        ];

        return returningBlock;

    }

    function downloadPVS(){
        setDownloadStatusPVS(true);

        const paramVals = {
            "PVSID" : props.policyData.PVSID
        };

        props.session?.env?.functions?.buildFetchRequest("downloadPVS", paramVals)
            .then(response => response.json())
            .then(resData => {
                if(resData.status === 200){
                    setDownloadStatusPVS(false);
                    props.downloadReport("pdf", resData.bitFile, "Policy Valuation Summary - " + props.policyData.lastName);
                }

                if(resData.status === 500){
                    setDownloadStatusPVS("Failed");
                    console.log("No file found.");
                }
            }, (error) => {
                if (error) {
                 console.log(error);
                }
            });
    }

    function downloadFromDrive(fileName, option){
        //fileName = encodeURIComponent(fileName);
        if(option === "Compliance & Suitability Package"){
            setDownloadStatusCompliance(true);
        }
        if(option === "Contract Package"){
            setDownloadStatusPackage(true);
        }

        const paramVals = {
            "policyNumber" : props.policyData?.policyNumber,
            "relatedAccount" : props.policyData?.relatedAccount,
            "fileName" : fileName
        };

        props.session?.env?.functions?.buildFetchRequest("downloadAbacusDriveFile", paramVals)
            .then(response => response.json())
            .then(resData => {
                if(resData.status === 200){
                    if(option === "Compliance & Suitability Package"){
                        setDownloadStatusCompliance(false);
                    }
                    if(option === "Contract Package"){
                        setDownloadStatusPackage(false);
                    }

                    props.downloadReport("pdf", resData.bitFile, option + " - " + props.policyData.lastName);
                }

                if(resData.status === 500){
                    if(option === "Compliance & Suitability Package"){
                        setDownloadStatusCompliance("Failed");
                    }
                    if(option === "Contract Package"){
                        setDownloadStatusPackage("Failed");
                    }                        
                    console.log("No file found.");
                }
            }, (error) => {
                if (error) {
                 console.log(error);
                }
            });
    }
    
    function printDownloadBtn(currentStatus){
        if(currentStatus){
            return <CircularProgress/>;
        }

        if(currentStatus === "Failed"){
            return "Retry";
        }

        return "Download";
    }

    useEffect(() => {
        loadClosingSection("Closing - Package");
    }, []);

    useEffect(() => {
        //console.log(props);
    }, [props]);

    useEffect(() => {
        loadClosingSection("Closing - CRF");
        loadClosingSection("Closing - Contract");
        loadClosingSection("Closing - Deficiency");
        loadClosingSection("Closing - Package");
    }, [props.policyTimelineData, CRF.completedProgress, contract.completedProgress, deficiency.completedProgress]);

    useEffect(() => {
        loadClosingSection("Closing - Package");
    }, [downloadStatusBids,
        downloadStatusPVS,
        downloadStatusCompliance,
        downloadStatusPackage,
        props.sessionUser?.accountData?.[props.sessionUser?.accountID],
        extraResources,
        props.policyData]);

    return (
        props.loadingStatus ?
            <div className="closingTabEmpty f">
                <div className="closingTabEmptyInner loading loadingDisplay">
                    <div>
                        <CircularProgress color="inherit"/>
                    </div>
                </div>
            </div>
        :
        CRF.total + contract.total === 0 ?
        <div className="closingTabEmpty f">
            <div className="closingTabEmptyInner">
                <div>
                    This case is not ready for the closing process yet.
                </div>
            </div>
        </div>
        :
        <div className="closingTabInnerWrap b">
            <div className={"closingTabSectionHeader " + CRF.currentStatus}>
                Contract Request Form
            </div>
            { CRF.sectionBlock }
            <div className={"closingTabSectionHeader " + contract.currentStatus}>
                <div className={"closingTabTracklineDivider " + contract.currentStatus}>
                </div>
                Contracts
            </div>
            { contract.sectionBlock }
            <div className={"closingTabSectionHeader " + deficiency.currentStatus}>
                <div className={"closingTabTracklineDivider " + deficiency.currentStatus}>
                </div>
                Deficiencies
            </div>
            { deficiency.sectionBlock }
            <div className={"closingTabSectionHeader " + closePackage.currentStatus}>
                <div className={"closingTabTracklineDivider " + closePackage.currentStatus}>
                </div>
                Compliance & Excecuted Contracts
            </div>
            { closePackage.sectionBlock }
        </div>
  )
}

export default ClosingTab;