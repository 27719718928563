import { useState, useEffect } from 'react';

import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

export default function LeadRangeSlider(props) {
    let [value, setValue] = useState([props.rangeData.minVal, props.rangeData.maxVal]);

    function convertInt(value){
        let formatter = Intl.NumberFormat('en', { notation: 'compact' });
        return formatter.format(value);
    }

    const followersMarks = [
        {
            value: props.fixedMin,
            scaledValue: props.fixedMin,
            label: convertInt(props.fixedMin)
        },
        {
            value: ( props.fixedMax / 4 ),
            scaledValue: ( props.fixedMax / 4 ),
            label: convertInt(( props.fixedMax / 4 ))
        },
        {
            value: ( props.fixedMax / 2 ),
            scaledValue: ( props.fixedMax / 2 ),
            label: convertInt(( props.fixedMax / 2 ))
        },
        {
            value: ( props.fixedMax * .75 ),
            scaledValue: ( props.fixedMax * .75 ),
            label: convertInt(( props.fixedMax * .75 ))
        },
        {
            value: props.fixedMax,
            scaledValue: props.fixedMax,
            label: convertInt(props.fixedMax)
        }
    ];

    function valuetext(value) {
        return convertInt(value);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.functionPointer(newValue[0], newValue[1]);
    };


    useEffect(() => {
        setValue([props.rangeData.minVal, props.rangeData.maxVal]);
        //props.updateDeathBenefitsRangeReset();
    }, [props.updateLeadsListPriceRange]);

    return (
        <Box sx={{ width: "90%" }} onClick={()=>{console.log(props)}}>
            <Slider
                getAriaLabel={() => 'Death Benefit Range'}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={props.fixedMin}
                valueLabelFormat={convertInt}
                step={ 50000 }
                max={props.fixedMax}
                marks={followersMarks}
            />
        </Box>
    );
}
