import React from 'react';
import { useState, useEffect, useRef } from 'react';

import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import ModeCommentTwoToneIcon from '@mui/icons-material/ModeCommentTwoTone';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/Download';
import BarChartIcon from '@mui/icons-material/BarChart';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone';
import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';
import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone';
import AutorenewTwoToneIcon from '@mui/icons-material/AutorenewTwoTone';
import NotesTwoToneIcon from '@mui/icons-material/NotesTwoTone';
import HourglassTopTwoToneIcon from '@mui/icons-material/HourglassTopTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';

import Dropdown from '../Components/Dropdown.js';
import DownloadBtn from '../Components/DownloadBtn.js';

function BidsTab(props){
    const session = props?.session;
    const stem = props?.stem;
    const bids = Object?.values(props?.bids || {}).sort((a, b) => b?.bidDate - a?.bidDate);
    const currentCase = props?.currentCase;
    const activeStatus = bids?.length ? "active" : "inactive";
    const user = session?.user?.data;

    const [showNotes, setShowNotes] = useState(undefined);
    const [overflowIndexes, setOverflowIndexes] = useState({}); // Track overflow status
    const textRefs = useRef([]); // Array of refs for each bid note

    const bidsContainer = useRef(null);
    const highestBid = (index) => {
        const highestBid = bids.reduce(
            (max, bid, idx) => (bid.bidAmount > max.data.bidAmount ? { data: bid, index: idx } : max),
            { data: bids[0], index: 0 }
        );
        
        return index !== undefined ? highestBid.index === index : highestBid.data;
    };

    const bid = (data, index, overflowStatus) => {
        // const isHighestBid = bids.reduce((max, bid, index) => 
        //     bid.bidAmount > max.data.bidAmount ? { data: bid, index: index } : max,
        //     { data: bids[0], index: 0 }
        // ).index === index;
        const isHighestBid = highestBid(index);

        return (
            <div
                key={`bid-${index}`}
                className={`row g dG bR${isHighestBid ? " highest" : ''}`}
            >
                <div className="bidDetails g">
                    <div className="cell cL f gCW dP">
                        Bid Amount: <span className="bold">{session?.env?.functions?.convertIntToCurrency(data?.bidAmount)}</span>
                    </div>
                    <div className="cell cC f gCW dP">
                        RDB Amount: <span className="bold">{data?.bidRDB ? session?.env?.functions?.convertIntToCurrency(data?.bidRDB) : "-"}</span>
                    </div>
                    <div className="cell cR f gCW dP">
                        {session?.env?.functions?.reformatDate(data?.bidDate)}
                    </div>
                </div>
                <div className="g">
                    <div className="prompt f g s e">
                        <div className="bR nS bold cC dG">
                            <NotesTwoToneIcon/>
                            Attached Notes:
                        </div>
                        {(overflowStatus || showNotes === index) && (
                            <div
                                className="cR gC3 bold link p nS"
                                onClick={() => setShowNotes(showNotes === index ? undefined : index)}
                            >
                                {showNotes === index ? "Show Less" : "Show More"}
                            </div>
                        )}
                    </div>
                    {data?.bidNotes &&
                        <div
                            ref={(el) => (textRefs.current[index] = el)} // Set the ref for this note
                            id={`bidNote-${index}`}
                            className={`bidNotes cL f s e b${showNotes === index ? '' : ' gCW'}`}
                            style={{
                                overflow: showNotes === index ? 'visible' : 'hidden',
                                whiteSpace: showNotes === index ? 'normal' : 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {data?.bidNotes}
                        </div>
                    }
                </div>
            </div>
        );
    };

    useEffect(() => {
        const newOverflowIndexes = {};
        bids.forEach((_, index) => {
            const noteElement = textRefs.current[index];
            if (noteElement) {
                const isOverflowing = noteElement.scrollWidth > noteElement.clientWidth;
                newOverflowIndexes[index] = isOverflowing;
            }
        });

        setOverflowIndexes(newOverflowIndexes)
    }, [textRefs]);

    return (
        <div
            key="bids"
            ref={bidsContainer}
            className={`bidsTable g f pR dG ${activeStatus}`}
        >
            {props?.loadingStatus || !bids?.length ?
                <div className="f cC">
                    {props?.loadingStatus ?
                        <CircularProgress/>
                    :
                        <>
                            <div className="g dG">
                                <HourglassTopTwoToneIcon/>
                                <div className="gC2">
                                    {`There is currently no bid information to display`}
                                </div>
                            </div>
                        </>
                    }
                </div>
            :
                <>
                    <div className={`header f bR fC g dP dG ${activeStatus}`}>
                        <div className="data g fR cC">
                            <div className="f cL lH">
                                <div className="point cL g dG" title={`Pricing Method: ${"N/A"}`}>
                                    <StarTwoToneIcon/>
                                    <span className="f gCW">
                                        Highest Offer:
                                        <span className="s bold">{
                                            session?.env?.functions?.convertIntToCurrency(highestBid()?.bidAmount)}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className="f cR lH">
                                <DownloadBtn
                                    session={session}
                                    inactive={!bids?.length}
                                    onClickDownload={()=>{bids?.length &&
                                        session?.env?.functions?.downloadPDF(
                                            session,
                                            "downloadBidReport",
                                            {
                                                "caseID" : currentCase?.data?.recordID,
                                                "lsID" : currentCase?.data?.relatedPolicyID,
                                            },
                                            `Abacus Life Bid Report - ${currentCase?.data?.lastName}, ${currentCase?.data?.policyNumber}`)
                                    }}
                                    reset={props?.resetDropdowns}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="reportedBids g f dG">
                        <div className="table g dG">
                            {bids?.map((data, index) => (
                                <React.Fragment key={data?.timeStamp || index}>
                                    {bid(data, index, overflowIndexes[index])}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default BidsTab;