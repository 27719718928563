import LeadBlock from './LeadBlock';

import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import AddIcCallIcon from '@mui/icons-material/AddIcCall'
import MovingIcon from '@mui/icons-material/Moving';

function LeadBlockKanban(props) {
    var leadBlockInfo = props.leadBlock;

    if(props.leadBlockType === "closed"){
        var listPath = '';

        if(props.closedType === "abacus"){
            listPath = "/leads/"
        }
        if(props.closedType === "insurance"){
            
        }
        if(props.closedType === "lost"){
        }
    }

    var kanbanActionBar = [
        <div key={"kanbanActionBar" + leadBlockInfo.leadID} className={"leadBlockHeader"}>
            <div className="leadBlockHeaderOption"
                onClick={()=>{props.toggleLeadQuickView("closed", leadBlockInfo)}}>
                <DoDisturbOnIcon/>
            </div>
            <div className="leadBlockHeaderOption"
                onClick={()=>{props.toggleLeadQuickView("contactAttempted", leadBlockInfo)}}>
                <AddIcCallIcon/>
            </div>
            <div className="leadBlockHeaderOption"
                onClick={()=>{props.toggleLeadQuickView("inProgress", leadBlockInfo)}}>
                <MovingIcon/>
            </div>
        </div>
    ];

    return (
        <div className="kanban cC">
            {kanbanActionBar}
            <LeadBlock
                changeActivityView={props.changeActivityView}
                leadBlockType={props.leadBlockType}
                time={props.time}
                leadBlockInfo={leadBlockInfo}
                navigation={props.navigation}
                session={props?.session}
            />
        </div>
    )
}

export default LeadBlockKanban;