import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

const useComplexEffect = (effects, deep = false, call) => {
    // console.log("useComplexEffect called with call:", call);
    // console.log("Effects:", effects);

    const prevDepsRef = useRef(effects.map(effect => effect.dependencies.map(dep => cloneDeep(dep))));

    useEffect(() => {
        // console.log("useEffect in useComplexEffect triggered for call:", call);
        let lastChangedIdx = -1;

        effects.forEach((effect, idx) => {
            // console.log(`Checking effect ${idx} for changes`);
            let hasChanged = false;
            const currentDeps = effect.dependencies.map(dep => cloneDeep(dep));

            for (let i = 0; i < effect.dependencies.length; i++) {
                if (deep || Array.isArray(effect.dependencies[i])) {
                    hasChanged = !isEqual(prevDepsRef.current[idx][i], currentDeps[i]);
                } else {
                    const prevKeys = Object.keys(prevDepsRef.current[idx][i] || {});
                    const currentKeys = Object.keys(currentDeps[i] || {});
                    hasChanged = prevKeys.length !== currentKeys.length || !prevKeys.every((key, index) => isEqual(key, currentKeys[index]));
                }
                if (hasChanged) {
                    const prevKeys = Object.keys(prevDepsRef.current[idx][i] || {});
                    const currentKeys = Object.keys(currentDeps[i] || {});
                    // console.log(prevKeys);
                    // console.log(currentKeys);
                    // console.log(`Change detected in effect ${idx}`);
                    lastChangedIdx = idx;
                    break;
                }
            }
        });

        if (lastChangedIdx !== -1) {
            // console.log(`Executing callback for effect ${lastChangedIdx}`);
            effects[lastChangedIdx].callback();
            prevDepsRef.current = effects.map(effect => effect.dependencies.map(dep => cloneDeep(dep)));
        }
    }, [effects, deep, call]);  // Only re-run if effects array or deep comparison setting changes
};

export default useComplexEffect;