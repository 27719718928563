import { useState, useEffect } from 'react';

function UploadFormScan(props){
    const [formFillOut, setFormFillOut] = useState(props.policyData);

    function currentFormStatus(){
        var formFieldErrors = [];

        if(formFillOut.policyNumber === ''){
            formFieldErrors.push("policyNumber");
        }

        if(formFillOut.policyInsuranceCompany === ''){
            formFieldErrors.push("policyInsuranceCompany");
        }

        if(formFillOut.policyDeathBenefit === ''){
            formFieldErrors.push("policyDeathBenefit");
        }

        if(formFillOut.issueDate === ''){
            formFieldErrors.push("issueDate");
        }

        if(formFillOut.primaryFirstName === ''){
            formFieldErrors.push("primaryFirstName");
        }

        if(formFillOut.primaryLastName === ''){
            formFieldErrors.push("primaryLastName");
        }

        if(formFillOut.primaryGender === ''){
            formFieldErrors.push("primaryGender");
        }
    
        if(formFillOut.primaryBirthDate === ''){
            formFieldErrors.push("primaryBirthDate");
        }
    
        if(formFillOut.situsState === ''){
            formFieldErrors.push("situsState");
        }
    
        if(formFillOut.ownerType === ''){
            formFieldErrors.push("ownerType");
        }
    
        if(formFillOut.ownerName === ''){
            formFieldErrors.push("ownerName");
        }
    
        if(formFillOut.leadSource === ''){
            formFieldErrors.push("leadSource");
        }

        if(props.secondaryInsured){
            if(formFillOut.secondaryFirstName === ''){
                formFieldErrors.push("secondaryFirstName");
            }

            if(formFillOut.secondaryLastName === ''){
                formFieldErrors.push("secondaryLastName");
            }

            if(formFillOut.secondaryGender === ''){
                formFieldErrors.push("secondaryGender");
            }

            if(formFillOut.secondaryBirthDate === ''){
                formFieldErrors.push("secondaryBirthDate");
            }
        }

        props.setErrorHandler(errorHandler => {
            const currentErrors = errorHandler;
            for (const field of formFieldErrors) {
                currentErrors[field + "Error"] = true;
            }
            return currentErrors;
        });
    }


    useEffect(() => {
        currentFormStatus();
    }, []);
}

export default UploadFormScan;