import React from 'react';
import { useState, useEffect } from 'react';

import '../index.css';

function ToggleOption(props){
    let currentTogglePosition = "toggleInnerWrap";

    if(props.toggleStatus === 'false' || props.toggleStatus === false){
        currentTogglePosition += " off";
    }

    useEffect(() => {
       // setToggleStatus(props.toggleStatus);
       
    }, []);

    return (
        <div className="toggleContainer">
            <div className="toggleTitle">
                { props.totalCount ?
                    <div className="toggleCount">
                        { props.totalCount }
                    </div>
                : null }
                { props.optionString }
            </div>
            <div className={ currentTogglePosition } onClick={ () => {
                props.altParameters ?
                    props.functionPointer(props.optionString, null, props.altParameters)
                    :
                    props.functionPointer(props.toggleStatus, props.option) }}>
                <div className="toggleBtn">
                </div>
            </div>
        </div>
     )
 }
   
 export default ToggleOption;