import React, { useEffect, useState } from 'react';

function Initialization(props){
    const [data, setData] = useState({
        reporting : false,
        cases : false,
        bids : false,
        tertiary : false,
        leads : false,
        insuranceCompanies : false,
    });

    function updateAttributeData(session, obj, stem, consoleLog) {
        const newData = { ...session?.case?.data?.attributeData };
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            const targetStem = obj?.stem ?? stem;
            if (newData?.[key]?.formType === "generatedList") {
              if (!newData[key].list[targetStem]) {
                newData[key].list[targetStem] = {};
              }
      
              if (!newData?.[key]?.list?.[targetStem]?.[value]) {
                newData[key].list[targetStem][value] = {
                  count: 1,
                  [stem] : true,
                };
              } else {
                newData[key].list[targetStem][value].count++;
              }
            }
          }
        }

        return newData;
    }

    async function loadReportingData(session) {
        try {
            // Set the reporting state to "pending" to indicate the loading process
            setData(prevState => ({
                ...prevState,
                reporting: "pending"
            }));

            // Await the fetch request to the "user/reports" endpoint
            const response = await session?.env?.functions?.buildFetchRequest("user/reports");
            const resData = await response.json();

            if (resData.status === 200) {
                // Process the reports to replace empty strings with undefined
                const processedReports = resData.reports.map(report => {
                    let newReport = {};
                    for (const key in report) {
                        newReport[key] = report[key] === '' ? undefined : report[key];
                    }
                    return newReport;
                });

                // Update the session data with the processed reports
                // session?.set("reporting", "allReports", processedReports);

                // Update the state to indicate successful loading
                setData(prevState => ({
                    ...prevState,
                    reporting: true
                }));

                // Return the processed reports
                return {reporting : {allReports : processedReports}};
            } else {
                // Handle failure and update the state accordingly
                setData(prevState => ({
                    ...prevState,
                    reporting: false
                }));

                return null;  // Return null on failure
            }
        } catch (error) {
            // Handle any errors during the fetch request
            console.error("Error loading reporting data:", error);
            setData(prevState => ({
                ...prevState,
                reporting: false
            }));
            return Promise.reject(error);  // Return a rejected promise to propagate the error
        }
    }

    async function loadPoliciesData(session, setAllPolicies) {
        try {
            // Set the cases state to "pending" to indicate the loading process
            setData(prevState => ({
                ...prevState,
                cases: "pending"
            }));
    
            const params = {
                accountID: session?.user?.data.accountID
            };
    
            // Await the fetch request to pull account policies
            const response = await session?.env?.functions?.buildFetchRequest("pullAccountPolicies", params);
            const resData = await response.json();
    
            if (resData.status === 200) {
                let attributeData = {};
                const fundArray = [];
                const originationArray = [];
    
                // Process the policy records and categorize them
                Object.values(resData?.policyRecords).forEach(caseItem => {
                    if (caseItem.type === "servicing") {
                        fundArray.push(caseItem);
                    } else {
                        originationArray.push(caseItem);
                    }
    
                    attributeData = updateAttributeData(session, caseItem, caseItem.type);
                });
    
                // Construct the caseModule object
                const caseModule = {...session?.case?.data} || {};
                // caseModule.allCases = Object.values(resData?.policyRecords);
                caseModule.policies = {
                    servicing: fundArray,
                    origination: originationArray
                };
                caseModule.attributeData = attributeData;
    
                // Set the fetched policies
                setAllPolicies(Object.values(resData?.policyRecords));
    
                // Update session with the new caseModule
                // session?.set("case", null, caseModule);
    
                // Update the state to indicate successful loading
                setData(prevState => ({
                    ...prevState,
                    cases: true
                }));
    
                // Return the caseModule (third parameter of session.set)
                return {
                    case : {
                        policies : {
                            servicing: fundArray,
                            origination: originationArray,
                        },
                        attributeData,
                    },
                }
                return caseModule;
            } else {
                // Handle failure and update the state accordingly
                setData(prevState => ({
                    ...prevState,
                    cases: false
                }));
    
                return null;  // Return null on failure
            }
        } catch (error) {
            // Handle any errors during the fetch request
            console.error("Error loading policies data:", error);
            setData(prevState => ({
                ...prevState,
                cases: false
            }));
            return Promise.reject(error);  // Return a rejected promise to propagate the error
        }
    }

    async function loadBidData(session) {
        try {
            // Set the bids state to "pending" to indicate the loading process
            setData(prevState => ({
                ...prevState,
                bids: "pending"
            }));
    
            const params = {
                accountID: session?.user?.data.accountID
            };
    
            // Await the fetch request to get shipped policies
            const response = await session?.env?.functions?.buildFetchRequest("marketplace/getShippedPolicies", params);
            const resData = await response.json();
    
            if (resData.status === 200) {
                let attributeData = {};
                const tasks = resData.tasks;
                const bids = Object.keys(tasks).map(key => tasks[key]);
    
                // Update attribute data for each bid
                bids.forEach(bid => {
                    attributeData = updateAttributeData(session, bid, "bids");
                });
    
                // Construct the caseModule object
                const caseModule = { ...session?.case?.data } || {};
                caseModule.bids = { bids };
                caseModule.attributeData = attributeData;
    
                // Update session with the new caseModule
                // session?.set("case", null, caseModule);
    
                // Update the state to indicate successful loading
                setData(prevState => ({
                    ...prevState,
                    bids: true
                }));
    
                // Return the caseModule (third parameter of session.set)
                return {
                    case : {
                        bids : {
                            bids : bids
                        },
                        attributeData,
                    },
                    
                }
                return caseModule;
            } else {
                // Handle failure and update the state accordingly
                setData(prevState => ({
                    ...prevState,
                    bids: false
                }));
    
                return null;  // Return null on failure
            }
        } catch (error) {
            // Handle any errors during the fetch request
            console.error("Error loading bid data:", error);
            setData(prevState => ({
                ...prevState,
                bids: false
            }));
            return Promise.reject(error);  // Return a rejected promise to propagate the error
        }
    }    

    async function loadLeadsData(session) {
        try {
            // Set the leads state to "pending" to indicate the loading process
            setData(prevState => ({
                ...prevState,
                leads: "pending"
            }));
    
            const params = {
                accountID: session?.user?.data?.accountID
            };
    
            // Await the fetch request to get leads data
            const response = await session?.env?.functions?.buildFetchRequest("pullLeadsV2", params);
            const resData = await response.json();
    
            if (resData.status === 200) {
                let attributeData = {};
                const leads = resData?.leads;
    
                // Process the leads and update attributeData
                const leadItems = Object.keys(leads).map(key => {
                    const policy = leads?.[key];
                    attributeData = updateAttributeData(session, policy, "leads");
                    return policy;
                });
    
                // Construct the caseModule object
                const caseModule = { ...session?.case?.data } || {};
                caseModule.leads = { leads: leadItems };
    
                // Update session with the new caseModule
                // session?.set("case", null, caseModule);
    
                // Update the state to indicate successful loading
                setData(prevState => ({
                    ...prevState,
                    leads: true
                }));
    
                // Return the caseModule (third parameter of session.set)
                return {
                    case : {
                        leads : {
                            leads : leadItems
                        },
                        attributeData,
                    }
                }
                return caseModule;
            } else {
                // Handle failure and update the state accordingly
                setData(prevState => ({
                    ...prevState,
                    leads: false
                }));
    
                return null;  // Return null on failure
            }
        } catch (error) {
            // Handle any errors during the fetch request
            console.error("Error loading leads data:", error);
            setData(prevState => ({
                ...prevState,
                leads: false
            }));
            return Promise.reject(error);  // Return a rejected promise to propagate the error
        }
    }
    
    async function loadTertiaryData(session) {
        try {
            setData(prevState => ({
                ...prevState,
                tertiary: "pending"
            }));
    
            const response = await session?.env?.functions?.buildFetchRequest("tertiary/getShippedPolicies");
            const resData = await response.json();
    
            if (resData.status === 200) {
                let attributeData = {};
                const bids = resData?.policies;
    
                const tertiary = Object.keys(bids).map(key => {
                    const policy = bids?.[key];
                    attributeData = updateAttributeData(session, policy, "tertiary");
                    return policy;
                });
    
                const caseModule = { ...session?.case?.data } || {};
                caseModule.bids = { ...caseModule.bids, tertiary };
    
                // Await portfolios data
                const portfolios = await loadPortfoliosData(session);

                setData(prevState => ({
                    ...prevState,
                    tertiary: true
                }));
    
                return {
                    case: {
                        bids : {
                            tertiary: tertiary,
                        },
                        attributeData,
                    },
                    bids: {
                        tertiary : {
                            portfolios : portfolios
                        }
                    },
                };
            } else {
                setData(prevState => ({
                    ...prevState,
                    tertiary: false
                }));
                return null;
            }
        } catch (error) {
            console.error("Error loading tertiary data:", error);
            setData(prevState => ({
                ...prevState,
                tertiary: false
            }));
            return Promise.reject(error);
        }
    }    
    
    async function loadPortfoliosData(session) {
        try {
            const response = await session?.env?.functions?.buildFetchRequest("tertiary/getPortfolios");
            const resData = await response.json();
            if (resData.status === 200) {
                // session?.set("bids", "tertiary.portfolios", resData?.portfolios);
                return resData?.portfolios;  // Ensure portfolios are returned
            } else {
                // session?.set("bids", "tertiary.portfolios", false);
                return null;  // Return null if not successful
            }
        } catch (error) {
            // session?.set("bids", "tertiary.portfolios", false);
            console.error("Error loading portfolios:", error);
            return null;  // Return null on error
        }
    }
    

    async function loadInsuranceCompanies(session) {
        try {
            // Set the insuranceCompanies state to "pending" to indicate loading
            setData(prevState => ({
                ...prevState,
                insuranceCompanies: "pending"
            }));
    
            const paramVals = {
                "accountID": session?.user?.data?.accountID,
            };
    
            // Await the fetch request to get the insurance companies
            const response = await session?.env?.functions?.buildFetchRequest("pullInsuranceCompanyList", paramVals);
            const resData = await response.json();
    
            if (resData.status === 200) {
                // Map the insurance companies data to the required format
                const insuranceCompanyListData = Object.values(resData.insuranceCompaniesData).map(companyData => ({
                    recordID: companyData?.recordID,
                    insuranceCompanyName: companyData?.insuranceCompanyName,
                }));
    
                // Set the session with the updated insurance companies list
                // session?.set("user", "insuranceCompanies", insuranceCompanyListData);
    
                // Update the state to indicate successful loading
                setData(prevState => ({
                    ...prevState,
                    insuranceCompanies: true
                }));
    
                // Return the insuranceCompanyListData (as returned by session.set)
                return {
                    user : {
                        insuranceCompanies : insuranceCompanyListData
                    }
                };
            } else {
                // Handle failure and update the state accordingly
                setData(prevState => ({
                    ...prevState,
                    insuranceCompanies: false
                }));
                return null; // Return null on failure
            }
        } catch (error) {
            // Handle any errors during the fetch request
            console.error("Error loading insurance companies:", error);
            setData(prevState => ({
                ...prevState,
                insuranceCompanies: false
            }));
            return Promise.reject(error); // Return a rejected promise to propagate the error
        }
    }
    
    function groupByKeys(results) {
        if (!Array.isArray(results)) {
            throw new Error("Expected an array as input");
        }
    
        const groupedData = {};
    
        const mergeObjects = (target, source) => {
            Object.keys(source).forEach((key) => {
                if (Array.isArray(source[key])) {
                    // Merge arrays and remove duplicates
                    target[key] = Array.from(new Set([...(target[key] || []), ...source[key]]));
                } else if (typeof source[key] === 'object' && source[key] !== null) {
                    // If it's an object, keep it as an object
                    if (!target[key]) target[key] = {}; 
                    mergeObjects(target[key], source[key]);
                } else {
                    // Otherwise, assign value directly
                    target[key] = source[key];
                }
            });
        };
    
        results.forEach((result) => {
            if (typeof result === 'object' && result !== null) {
                mergeObjects(groupedData, result);
            }
        });
    
        return groupedData;
    }

    function initialize({ session, results }) {
        function buildFullPaths(baseModule, currentData, currentPath = '') {
            const paths = [];
            const values = [];
    
            Object.keys(currentData).forEach((key) => {
                const fullPath = currentPath ? `${currentPath}.${key}` : key;
                const value = currentData[key];
    
                // Consolidate nested objects like tertiary.portfolios as a single path
                if (typeof value === 'object' && value !== null && !Array.isArray(value) && Object.keys(value).length > 1) {
                    // If the object has multiple keys, treat it as a single value
                    paths.push(fullPath);
                    values.push(value);
                } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                    // If it's an object with single keys, keep recursing
                    const { subPaths, subValues } = buildFullPaths(baseModule, value, fullPath);
                    paths.push(...subPaths);
                    values.push(...subValues);
                } else {
                    // Directly add simple values
                    paths.push(fullPath);
                    values.push(value);
                }
            });
    
            return { subPaths: paths, subValues: values };
        }
    
        function updateSessionWithDynamicPaths(baseModule, data) {
            const { subPaths, subValues } = buildFullPaths(baseModule, data);
        
            // Wrap subPaths and subValues in arrays if they aren't already
            session?.set(baseModule, [].concat(subPaths), [].concat(subValues));
        }
    
        function processGroupedData(groupedData) {
            Object.keys(groupedData).forEach((baseModule) => {
                const data = groupedData[baseModule];
                updateSessionWithDynamicPaths(baseModule, data);
            });
        }
    
        if (!results || !results.length) return;
    
        processGroupedData(groupByKeys(results));
    }

    function resetLoaders(){
        setData({
            reporting : false,
            cases : false,
            bids : false,
            tertiary : false,
            leads : false,
            insuranceCompanies : false,
        });
    }

    const functions = {
        loadReportingData,
        loadPoliciesData,
        loadBidData,
        loadTertiaryData,
        resetLoaders,
        loadLeadsData,
        loadInsuranceCompanies,
        initialize,
    }

    const initialization = {
        data,
        setData,
        functions,
    }

    return initialization;
};

export default Initialization;