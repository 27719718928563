import React from 'react';
import { useState } from 'react';

import MovingIcon from '@mui/icons-material/Moving';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import InboxIcon from '@mui/icons-material/Inbox';

function MobileKanbanHeader(props){

    return (
        <div className={"leadsHeaderBar selector " + props.currentColumn }>
            {props.currentColumnCheck("lead") === " inactive" ?
                <div className="leadCategorySelector lead" onClick={()=> { props.setCurrentColumn("lead") }}>
                    <div className="leadCategoryIcon cC">
                        <InboxIcon/>
                    </div>
                </div>
            :
                <div className={"leadCategory lead" + props.currentColumnCheck("lead")} onClick={()=> { props.setCurrentColumn("lead") }}>
                    <div className="kanbanNotifications">
                        {props.leadBlockKanbanData.totalMissedLeads > 0 ?
                            <div className={"missedFollowUpsBubble" + props.notificationStatus("leadMissed")} onClick={()=>{props.toggleNotification("leadMissed")}}>
                                {props.leadBlockKanbanData.totalMissedLeads}
                            </div>
                            : <div className="kanbanSpace"></div>
                        }
                        {props.leadBlockKanbanData.totalLeadsFollowUps > 0 ?
                            <div className={"activeFollowUpsBubble" + props.notificationStatus("leadActive")} onClick={()=>{props.toggleNotification("leadActive")}}>
                                {props.leadBlockKanbanData.totalLeadsFollowUps}
                            </div>
                            : <div className="kanbanSpace"></div>
                        }
                    </div>
                    <div className="kanbanHeaderCategory">
                        Lead
                    </div>
                    <div className="leadCategoryIcon cR">
                        <InboxIcon/>
                    </div>
                </div>
            }

            {props.currentColumnCheck("contactAttempted") === " inactive" ?
                <div className="leadCategorySelector contactAttempted" onClick={()=> { props.setCurrentColumn("contactAttempted") }}>
                    <div className="leadCategoryIcon cC">
                        <PhoneMissedIcon/>
                    </div>
                </div>
            :
            <div className={"leadCategory contactAttempted" + props.currentColumnCheck("contactAttempted")}>
                <div className="kanbanNotifications">
                    {props.leadBlockKanbanData.totalMissedContactAttempt > 0 ?
                        <div className={"missedFollowUpsBubble" + props.notificationStatus("contactAttemptedMissed")} onClick={()=>{props.toggleNotification("contactAttemptedMissed")}}>
                            {props.leadBlockKanbanData.totalMissedContactAttempt}
                        </div>
                        : <span></span>
                    }
                    {props.leadBlockKanbanData.totalContactAttemptFollowUps > 0 ?
                        <div className={"activeFollowUpsBubble" + props.notificationStatus("contactAttemptedActive")} onClick={()=>{props.toggleNotification("contactAttemptedActive")}}>
                            {props.leadBlockKanbanData.totalContactAttemptFollowUps}
                        </div>
                        : <span></span>
                    }
                </div>
                <div className="kanbanHeaderCategory">
                    Contact Attempted
                </div>
                <div className="leadCategoryIcon cR">
                    <PhoneMissedIcon/>
                </div>
            </div>
            }
            {props.currentColumnCheck("inProgress") === " inactive" ?
                <div className="leadCategorySelector inProgress" onClick={()=> { props.setCurrentColumn("inProgress") }}>
                    <div className="leadCategoryIcon cC">
                        <MovingIcon/>
                    </div>
                </div>
            :
                <div className={"leadCategory inProgress" + props.currentColumnCheck("inProgress")}>
                    <div className="kanbanNotifications">
                        { props.leadBlockKanbanData.totalMissedInProgress > 0 ?
                            <div className={"missedFollowUpsBubble" + props.notificationStatus("inProgressMissed")} onClick={()=>{props.toggleNotification("inProgressMissed")}}>
                                {props.leadBlockKanbanData.totalMissedInProgress}
                            </div>
                        : <span></span>
                        }
                        { props.leadBlockKanbanData.totalInProgressFollowUps > 0 ?
                            <div className={"activeFollowUpsBubble" + props.notificationStatus("inProgressActive")} onClick={()=>{props.toggleNotification("inProgressActive")}}>
                                {props.leadBlockKanbanData.totalInProgressFollowUps}
                            </div>
                        : <span></span>
                        }
                    </div>
                    <div className="kanbanHeaderCategory">
                        In Progress
                    </div>
                    <div className="leadCategoryIcon cR">
                        <MovingIcon/>
                    </div>
                </div>           
            }
            {props.currentColumnCheck("closed") === " inactive" ? 
                <div className="leadCategorySelector closed" onClick={()=> { props.setCurrentColumn("closed") }}>
                    <div className="leadCategoryIcon cC">
                        <CheckCircleIcon/>
                    </div>
                </div>
            :
                <div className={"leadCategory closed" + props.currentColumnCheck("closed")}>
                    <div></div>
                    <div className="kanbanHeaderCategory">
                        Closed
                    </div>
                    <div className="leadCategoryIcon cR">
                        <CheckCircleIcon/>
                    </div>
                </div>
            }
        </div>
    )
}

export default MobileKanbanHeader;