import React from 'react';
import { useState, useEffect, useRef } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import SpaceDashboardTwoToneIcon from '@mui/icons-material/SpaceDashboardTwoTone';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';

import InputField from "../Components/InputField.js";
import ViewBarBtn from '../Components/ViewBarBtn.js';
import ReportSheet from '../Components/Reporting/ReportSheet.js';
import ReportList from '../Components/Reporting/ReportList.js';

function Dashboard(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const dashboard = props?.session?.dashboard;
    const reportTable = useRef(null);
    const [pagination, setPagination] = useState({
        showMenu : false,
        rowMax : props?.session?.storage?.reporting?.rowMax ?? 100,
        index : props?.selectedReport?.lastPageIndex ?? 1,
    });

    const [menuStatus, setMenuStatus] = useState({
        hover : false,
        lock : false,
        currentInstance : "default",
    });

    const menuOptions = {
        "default": {
            "prompt": "Menu",
            "icon": <MenuTwoToneIcon />,
        },
        "create": {
            "prompt": "Create",
            "icon": <AddCircleTwoToneIcon />,
        },
        "dashboards": {
            "prompt": "Dashboards",
            "icon": <SpaceDashboardTwoToneIcon />,
        },
    };

    const visibleMenu = menuStatus?.hover || menuStatus?.lock;

    const updateMenuStatus = (attr, value) => {
        setMenuStatus((prevStatus) => ({
            ...prevStatus,
            [attr]: value,
        }));
    };

    const resetShowMenu = (setDropdownState) => {
        setDropdownState((prev) => {
            if (prev?.showMenu) {
                return { ...prev, showMenu: false };
            }
            return prev;
        });
    };

    const resetDropdowns = () => {
        // if(pagination?.showMenu){
        //     resetShowMenu(setPagination);
        // }
    };

    const currentComponent = 2;
    const currentReport = dashboard?.data?.selectedDashboard?.components?.[currentComponent]?.selectedReport || {};
    const items = props?.session?.[reporting?.data?.dataPointer]?.data?.
        [currentReport?.referenceBranch ?? currentReport?.branch]?.
            [currentReport?.referenceStem ?? currentReport?.stem];
    currentReport.list = reporting?.functions?.filterItemsWithQuery(currentReport?.query, items);
    const reportType = reporting?.functions?.reportType(currentReport?.stem);

    const defaultMenu = () => {
        return (
            Object.entries(menuOptions).filter(([key]) => key !== "default").map(([key, option]) => (
                <div
                    key={key}
                    className="option g bR f pR cC p fR fC bold"
                    onClick={() => updateMenuStatus("currentInstance", key)}
                >
                    <div className="hoverBlock bR" />
                    <div className="f cC">
                        {option?.icon}
                    </div>
                    {visibleMenu && option?.prompt && (
                        <div className="f cL gCW">{option?.prompt}</div>
                    )}
                </div>
            ))
        );
    };

    const createMenu = () => {
        return (
            <div className="">
                create
            </div>
        );
    };

    function printMenu() {
        const currentInstance = menuStatus?.currentInstance;
        const isDefault = currentInstance === "default";
        const menuHandler = {
            "default": defaultMenu(),
            "create": createMenu(),
            "dashboards": createMenu(),
        };

        return (
            <>
                <div className="menu g dG">
                    <div className="slideMenuBar g bR f pR cC fR fC bold">
                        <div
                            className={`f cC${!isDefault ? " p" : ''}`}
                            onClick={isDefault ? undefined : () => updateMenuStatus("currentInstance", "default")}
                        >
                            {isDefault ? <MenuTwoToneIcon/> : <ArrowBackIosNewTwoToneIcon />}
                        </div>
                        {visibleMenu && (
                            <div className={`g cC gC2${!isDefault ? " dG" : ''}`}>
                                <div className="f cC">
                                    {!isDefault && menuOptions?.[menuStatus?.currentInstance]?.icon}
                                </div>
                                <div className="f cL gCW gC2">
                                    {menuOptions?.[menuStatus?.currentInstance]?.prompt || ''}
                                </div>
                            </div>
                        )}
                    </div>
                    {menuHandler?.[currentInstance]}
                </div>
                <div className="actionBar cC g dG">
                    <div className="f cC">
                        {menuStatus?.lock ? <LockTwoToneIcon/> : <LockOpenTwoToneIcon/>}
                    </div>
                </div>
            </>
        );
    }
    
    useEffect(() => {

    }, []);

    return (
        <div className="dashboard">
            <div className="dashboardHub f g dG bR oH">
                <div
                    className={`menuBar g f dG oH ${menuStatus?.currentInstance}${visibleMenu ? " expanded" : ''}`}
                    onMouseOver={() => updateMenuStatus("hover", true)}
                    onMouseOut={() => updateMenuStatus("hover", false)}
                >
                    {printMenu()}
                </div>
                <div className="canvas">
                    {menuStatus?.currentInstance === "create"}
                </div>
            </div>
        </div>
    )
}

export default Dashboard;