import React, { useState, useEffect } from 'react';

function SuccessMessage({ message, timeout = 3000 }) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsVisible(false);
    }, timeout);

    return () => clearTimeout(timerId);
  }, [timeout]);

  return (
    <div className={`success-message ${isVisible ? 'show' : 'hide'}`}>
      {message}
    </div>
  );
}

export default SuccessMessage;