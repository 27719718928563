import React, { useState, useEffect, useRef } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import './Policies.css';

import SinglePolicy from './SinglePolicy/SinglePolicy';
import PoliciesOverview from './PoliciesOverview';
import SingleView from '../Components/SingleView.js';
import LoadingScreen from '../Components/LoadingScreen.js';

function Policies(props) {
  const session = props?.session;
  const reporting = session?.reporting;
  const policies = props?.session?.policies;
  const baseData = policies?.data?.policies;
  const location = useLocation();

  const currentPath = session?.env?.functions?.currentPath(location);
  const [timeAgo, setTimeAgo] = useState('');

  const [dropdowns, setDropdowns] = useState({
    reportDownload : {
        showMenu : true,
    },
  });

  const resetDropdowns = () => {
      setDropdowns((prev) => {
          const updated = Object.keys(prev).reduce((acc, key) => {
              acc[key] = { ...prev[key], showMenu: false };
              return acc;
          }, {});
          return updated;
      });
  };

  return (
    <div className="policiesPage g bR oH" onClick={resetDropdowns}>
      {!session?.case?.data?.policies?.origination && !session?.case?.data?.policies?.servicing ?
          <LoadingScreen
              page="policies"
          />
      :
        currentPath?.recordID ? 
            <SingleView
                session={session}
                branch={"policies"}
                stem={currentPath?.stem}
                recordID={currentPath?.recordID}
            />
        :
          <Routes>
            <Route exact path="/" element={
              <PoliciesOverview
                policies={props.policies}
                policyList={props.policyList}
                setPolicyList={props.setPolicyList}
                filters={props.filters}
                updateFiltersNew={props.updateFiltersNew}
                accountID={props.accountID}
                activityView={props.activityView}
                changeActivityView={props.changeActivityView}
                account={props.account}
                chartData={props.chartData}
                updateChartData={props.updateChartData}
                sessionUser={props.sessionUser}
                loadAccountPolicies={props.loadAccountPolicies}
                filterHandler={props.filterHandler}
                setFilterHandler={props.setFilterHandler}
                deathBenefitsRange={props.deathBenefitsRange}
                setDeathBenefitsRange={props.setDeathBenefitsRange}
                updateDeathBenefitsRange={props.updateDeathBenefitsRange}
                searchVal={props.searchVal}
                setSearchVal={props.setSearchVal}
                searchValHandler={props.searchValHandler}
                setSearchValHandler={props.setSearchValHandler}
                emptyDeathBenefit={props.emptyDeathBenefit}
                setEmptyDeathBenefit={props.setEmptyDeathBenefit}
                filterStatus={props.filterStatus}
                setFilterStatus={props.setFilterStatus}
                sortedPolicies={props.sortedPolicies}
                setSortedPolicies={props.setSortedPolicies}
                deathBenefitsRangeReset={props.deathBenefitsRangeReset}
                setDeathBenefitsRangeReset={props.setDeathBenefitsRangeReset}
                updateDeathBenefitsRangeReset={props.updateDeathBenefitsRangeReset}
                visiblePolicies={props.visiblePolicies}
                setMaxVisiblePolicies={props.setMaxVisiblePolicies}
                maxVisiblePolicies={props.maxVisiblePolicies}
                updateSearchVal={props.updateSearchVal}
                orderPolicies={props.orderPolicies}
                policyOrderDirection={props.policyOrderDirection}
                mobileDevice={props.mobileDevice}
                navigation={props.navigation}
                policiesProps={props.policiesProps}
                session={props?.session}
                />
              }
            />

        {/* } */}
        {/* <Route path="/:relatedPolicyID" element={
          <SinglePolicy
            policyNumber = {props.policyNumber}
            setPolicyNumber = {props.setPolicyNumber}
            changeActivityView = {props.changeActivityView}
            sessionUser={props.sessionUser}
            updatePage={props.updatePage}
            account={props.account}
            loadNotifications={props.loadNotifications}
            markSessionPolicyAsRead={props.markSessionPolicyAsRead}
            notifications={props.notifications}
            singlePolicyHash={props.singlePolicyHash}
            setSinglePolicyHash={props.setSinglePolicyHash}
            mobileDevice={props.mobileDevice}
            navigation={props.navigation}
            sessionVars={props.sessionVars}
            policiesProps={props.policiesProps}
            session={props?.session}
            />
            } />
        <Route path="/servicing/:relatedPolicyID" element={
          <SinglePolicy
            policyNumber = {props.policyNumber}
            setPolicyNumber = {props.setPolicyNumber}
            changeActivityView = {props.changeActivityView}
            sessionUser={props.sessionUser}
            updatePage={props.updatePage}
            account={props.account}
            loadNotifications={props.loadNotifications}
            markSessionPolicyAsRead={props.markSessionPolicyAsRead}
            notifications={props.notifications}
            singlePolicyHash={props.singlePolicyHash}
            setSinglePolicyHash={props.setSinglePolicyHash}
            mobileDevice={props.mobileDevice}
            navigation={props.navigation}
            sessionVars={props.sessionVars}
            policiesProps={props.policiesProps}
            session={props?.session}
          />
          
        }
      /> */}
      </Routes>
      }
    </div>
  );
}

export default Policies;