import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';

function SettingsMenu(props){
    const session = props?.session;
    const sessionUser = props?.sessionUser;
    const currentPath = window.location.pathname.match(/^\/([^/]+)/)?.[1] || null;

    function maintainSelectedPage(currentPage){
        if(currentPath === currentPage){
            return " selected";
        }else{
            return '';
        }
    }

    return (
        <div className="expandedPages g bR dP">
          <div className="pages g cL">
            <Link
              to="/profile"
              className={"sideBarBtn f fC fR g cC dP" + maintainSelectedPage("profile")}
              onClick={() => {
                // props.changeActivityView("dashboard");
                session?.env?.setOverlay() }}
            >
              <AccountCircleTwoToneIcon/>
              <div className="f cL">
                Profile Settings
              </div>
            </Link>
            {/* <Link to="/profile#admin" className={"sideBarBtn f fC fR g cC dP" + maintainSelectedPage("profile#admin")} onClick={() => { props.updatePage("profile"); props.changeActivityView("dashboard"); setExpandedPages(false) } }>
              <ManageAccountsTwoToneIcon/>
              <div className="f cL">
                Admin Board
              </div>
            </Link> */}
          </div>
          <div className="divider">
          </div>
          <div className="logout cC">
            <div className="sideBarBtn f fC fR g cC dP" onClick={() => {props.session?.user?.functions?.logout(session)}}>
                <CancelTwoToneIcon/>
                <div className="f cL">
                  Logout
                </div>
            </div>
          </div>
        </div>
    )
};

export default SettingsMenu;