import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';

// import StatusIcon from '../../Components/StatusIcon';

import { blue, purple } from '@mui/material/colors';

function Notifications(props){
    // const session = props?.session;
    // const envFunctions = session?.env?.functions;
    // const userFunctions = session?.user?.functions;
    // const caseFunctions = session?.case?.functions;
    // const policyHandler = session?.case?.policyHandler;
    // const policyList = session?.case?.data?.policy;
    // const sessionUser = props?.session?.user?.data;
    // const viewType = session?.env?.viewport?.viewType;
    // const policyCount = props?.policyCount;

    // function navigateToTab(notificationType, returnLink, policy){

    //     if(notificationType === "Status"){
    //         if(returnLink){
    //             return "/timeline";
    //         }else {
    //             userFunctions?.updateUserData("currentActivity", "timeline");
    //             caseFunctions?.updateCurrentPolicyKey(caseFunctions?.findMatchingPolicyKey(policyList, policy));
    //             session?.env?.setOverlay();
    //         }
    //     }

    //     if(notificationType === "Message"){
    //         if(returnLink){
    //             return "/messages";
    //         }else{
    //             userFunctions?.updateUserData("currentActivity", "messages");
    //         }
    //     }

    //     if(notificationType === "Bid"){
    //         if(returnLink){
    //             if(viewType === "full"){
    //                 return
    //             }else{
    //                 return "/details";
    //             }
    //         }else{
    //             userFunctions?.updateUserData("currentActivity", "timeline");
    //             caseFunctions?.updateCurrentPolicyKey(caseFunctions?.findMatchingPolicyKey(policyList, policy));
    //             session?.env?.setOverlay();
    //         }
    //     }
    // }

    // function printNotifications() {
    //     const existingNotifications = session?.notifications?.data && Object?.keys(session?.notifications?.data)?.length > 0;

    //     return (
    //         <div className={`${existingNotifications && policyList ? "list" : "empty cC"} f g fC dP tO`}>
    //             {existingNotifications && policyList ? (
    //                 session?.notifications?.data.map(([key, value]) => {
    //                     const notification = value;
    //                     const policy = Object?.values(policyList)?.find(
    //                         (policy) => policy.relatedPolicyID === notification?.relatedID
    //                     );

    //                     let currentStatus = session?.case?.data?.currentPolicyKey === key;
    //                     const isClosed = policyHandler?.checkClosedStatus(session?.case?.data?.trackline?.[policy?.relatedPolicyID]);
    //                     if(!policy){
    //                         return;
    //                     }

    //                     return (
    //                         <div
    //                             key={key}
    //                             className={`row f cC g fR ${isClosed ? "Closed" : policyHandler?.currentGroup(policy?.policyStatus)}${currentStatus ? " active" : ''}`}
    //                         >
    //                             <Link
    //                                 to={navigateToTab(notification?.activityType, true)} 
    //                                 className="f cC g data p bold e fR"
    //                                 onClick={()=>{navigateToTab(notification?.activityType, undefined, policy)}}
    //                             >
    //                                 <div className="f cC wIcon g">
    //                                     <div className="f cC">
    //                                         {notification?.activityType === "Status" && <StatusIcon currentStatus={notification?.content} />}
    //                                         {notification?.activityType === "Message" && <ChatBubbleIcon sx={{color: blue[600]}} />}
    //                                         {notification?.activityType === "Bid" && <ThumbsUpDownIcon sx={{color: purple[800]}} />}
    //                                     </div>
    //                                     <div className="f cL oH">
    //                                         {notification?.activityType === "Status" && (
    //                                             <div className="gCW">
    //                                                 {viewType === "mobile"
    //                                                     ? policyHandler?.insuredFriendlyStatus?.[notification?.content]
    //                                                     : `Status Update : Moved to ${policyHandler?.insuredFriendlyStatus?.[notification?.content]}`
    //                                                 }
    //                                             </div>
    //                                         )}
    //                                         {notification?.activityType === "Message" && (
    //                                             <>
    //                                                 <div className="gCW">
    //                                                     {notification?.skippedSteps !== undefined && notification?.skippedSteps > 0
    //                                                         ? notification?.skippedSteps + 1 + " "
    //                                                         : ''}
    //                                                     {`New Message${notification?.skippedSteps ? "s" : ''} "${notification?.content}`}
    //                                                 </div>
    //                                                 "
    //                                             </>
    //                                         )}
    //                                         {notification?.activityType === "Bid" && (
    //                                             <>
    //                                                 <div className="gCW">
    //                                                     {notification?.skippedSteps !== undefined && notification?.skippedSteps > 0
    //                                                         ? notification?.skippedSteps + 1 + " "
    //                                                         : ''}
    //                                                     {`New Bid${notification?.skippedSteps ? "s" : ''}`}
    //                                                 </div>
    //                                             </>
    //                                         )}
    //                                     </div>
    //                                 </div>
    //                                 <div className="f cC oH">
    //                                     <div className="gCW">
    //                                         {policy?.policyNumber ?? "N/A"}
    //                                     </div>
    //                                 </div>
    //                                 <div className="f cC oH thin">
    //                                     <div className="gCW">
    //                                         {envFunctions?.reformatDate(notification?.timeStamp, "friendly")}
    //                                     </div>
    //                                 </div>
    //                             </Link>
    //                         </div>
    //                     );
    //                 })
    //             ) : (
    //                 <div>
    //                     <div className="cC">
    //                         <BeenhereOutlinedIcon />
    //                     </div>
    //                     <div className="cC">
    //                         No unread notifications
    //                     </div>
    //                 </div>
    //             )}
    //         </div>
    //     );
    // }

    // return (
    //     <>
    //         <div className="notificationsBoard listBoard g f fC oH">
    //             <div className="columnLabels g f cC bold dP fR">
    //                 <div className={`labels g f${policyCount > 5 ? " alt" : ''}`}>
    //                     <div className="f cC">
    //                         Notification
    //                     </div>
    //                     <div className={`f cC`}>
    //                         {`Policy ${viewType === "mobile" ? "#" : "Number"}`}
    //                     </div>
    //                     <div className="f cC">
    //                         Date / Time
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="divider">
    //             </div>
    //             <div className="body f oH b dP">
    //                 <div className="content wrap f tO bR">
    //                     {printNotifications()}
    //                 </div>
    //             </div>
    //         </div>
    //     </>
    // )
};

export default Notifications;