import React from 'react';
import { useState, useEffect, useRef } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

function StringInput(props) {
    const session = props?.session;
    let value = props?.value;
    const activeStatus = props?.active ?? value;
    const inputRef = useRef(null); // Reference for the input element

    if (props?.textData?.formType === "int" && props?.textData?.returnType === "currency") {
        value = session?.env?.functions?.convertIntToCurrency(value);
        // const formatCurrencyValue = value => !isNaN(parseFloat(value)) ? `$${parseFloat(value).toLocaleString()}` : '';
        // value = formatCurrencyValue(value);
    }

    if (props?.textData?.formType === "date") {
        const formatDateValue = value => {
            if (!value) return '';
            const [year, month, day] = value.split('-');
            return `${parseInt(month)}/${parseInt(day)}/${year}`;
        };
        value = formatDateValue(value);
    }

    const onClickHandler = () => {
        if (typeof props?.onClick === 'function') {
            props.onClick(); // Invoke the passed onClick function if it exists
        }
    };

    const handleFocus = () => {
        if (inputRef?.current && !props?.readOnly) {
            inputRef?.current?.focus();
            inputRef.current.selectionStart = inputRef?.current?.value?.length;
        }
    };

    return (
        <div
            key={props?.name}
            className={`stringInput g cL f fC ${activeStatus ? "active" : "inactive"}`}
        >
            {props?.value !== null && props?.value !== '' && props?.placeholder && (
                <div
                    className={`placeholder gCW g f${props?.condensed ? " condensed" : ''}`}
                    title={props?.placeholder}
                >
                    {props?.placeholder}
                </div>
            )}
            <input
                ref={inputRef}
                className={`input gR2${props?.readOnly === false ? " editable" : ''}`}
                placeholder={props?.placeholder}
                name={props.name}
                readOnly={props?.readOnly ?? true}
                value={value}
                type={"text"}
                autoComplete={props?.autoComplete ?? "off"}
                onClick={onClickHandler}
                onFocus={handleFocus}
                title={value === '' ? props?.placeholder : value}
                onChange={props?.onChange}
                style={{
                    ...(props?.width && { width: props?.width }),
                    ...(props?.maxWidth && { maxWidth: props?.maxWidth }),
                    ...(props?.height && { height: props?.height }),
                }}
            />
        </div>
    );
}

export default StringInput;