import React, { useEffect, useState, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import StartIcon from '@mui/icons-material/Start';
import DiamondIcon from '@mui/icons-material/Diamond';
import GavelIcon from '@mui/icons-material/Gavel';
import TaskIcon from '@mui/icons-material/Task';

function MarketingBreakdown(props){
    function printPolicyBreakDown(count, type){
        if(count === undefined){
            return '';
        }

        if(type === "total"){
            if(count > 1 || count === 0){
                return count + " policies"
            }else{
                return count + " policy";
            }
        }

        function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        if(type === "value"){
            if(count === "N/A" || count === '' || count === undefined || count === null){
                return "-";
            }else{
                return "$" + numberWithCommas(count);
            } 
        }
    }

    function printCategory(position){
        const positionData = {
            first: {
                category : "Documents / Gathering",
                icon : <StartIcon/>
            },
            second : {
                category : "Valuation",
                icon : <DiamondIcon/>
            },
            third : {
                category : "Contracts",
                icon : <GavelIcon/>
            },
            fourth : {
                category : "Closing",
                icon : <TaskIcon/>
            }
        };

        let deathBenefit = 0;
        let count = 0;
        const totalActive = props?.chartData?.["active"]?.count;

        const positionObj = {
            first: [
                "Lead",
                "Application Sent",
                "Meds/Illustration Ordered",
                "Underwriting (LE's)"
            ],
            second: [
                "Pre-Priced",
                "Pricing / Valuation",
                "Shipped",
                "Bids"
            ],
            third: [
                "Offer Letter Out / CRF",
                "Offer Letter In",
                "Contracts Out",
                "Contracts Back",
                "Contracts Back (Digital)"
            ],
            fourth: [
                "Escrow / At Carrier",
                "Rescission",
                "Invoicing"
            ]
        };

        const arrayToIterate = positionObj[position];

        for (const item of arrayToIterate) {
            const deathBenefitValue = props?.chartData?.[item]?.deathBenefit;
            const countValue = props?.chartData?.[item]?.count;

            if (!isNaN(deathBenefitValue)) {
                deathBenefit += deathBenefitValue;
            }

            if (!isNaN(countValue)) {
                count += countValue;
            }
        }

        let share = (Math.round((count / totalActive) * 10000) / 100).toFixed(2) + "%";

        return (
            <div key={position} className={"row g " + position}>
                <div className="header bold gC2 cL s">
                    {positionData[position].category + " ( " + count + " )"}
                </div>
                <div className="icon gR2 cC bold">
                    {positionData[position].icon}
                </div>
                <div className="cC gR2">
                    {printGraph(count, totalActive)}
                </div>
                <div className="cC gR2 bold">
                    {share}
                </div>
            </div>
        )
    }

    function printGraph(count, totalActive){
        return (
            <div className="cL f">
                <div className="bar">
                    <div className="progress" style={{"width" : (count/ totalActive) * 100 + "%"}}>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div key="marketingBreakdown" className="policyPositionBlock moduleBlock">
            {
                !props?.chartData ?
                    <div className="cC f loading">
                        <CircularProgress color="inherit"/>
                    </div>
                :
                <div className="breakdownBlock marketing g fC cC">
                    <div className="header cC bold">
                        Policy Placement Distribution
                    </div>
                    <div className="divider">
                    </div>
                    <div className="graphContainer fMP bR tO g">
                        <div className="graph f g dP">
                            {printCategory("first")}
                            {printCategory("second")}
                            {printCategory("third")}
                            {printCategory("fourth")}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default MarketingBreakdown;