import React from 'react';
import { useState, useEffect } from 'react';

import StatusIcon from '../StatusIcons';


import '../Timeline/TimelinePost.css';

import ErrorIcon from '@mui/icons-material/Error';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ReplyIcon from '@mui/icons-material/Reply';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import PsychologyAltTwoToneIcon from '@mui/icons-material/PsychologyAltTwoTone';
import TextSnippetTwoToneIcon from '@mui/icons-material/TextSnippetTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ThumbDownAltTwoToneIcon from '@mui/icons-material/ThumbDownAltTwoTone';
import TaskTwoToneIcon from '@mui/icons-material/TaskTwoTone';
import GppGoodTwoToneIcon from '@mui/icons-material/GppGoodTwoTone';

function BidTimelinePost(props){
    const session = props?.session;
    const timelineData = props?.timelineData;
    const timelineItem = props?.timelineItem;
    const timeAgo = props?.timeAgo;
    const checkStatus = timelineItem?.taskStatus

    return (
        <div className={`newTimelinePost g f bR p ${props?.stem} voc${props?.detailed ? " detailed" : ''}`}
            onClick={props?.onClick}
        >
            <div className="header g f fR e dG">
                {timelineItem?.readStatus === false && timelineItem?.originator === "Internal" &&
                    <div className="f cC">
                        <div className="notificationAlert">
                        </div>
                    </div>
                }
                <div className="cL gC2 bold dG">
                    Statement
                    <TaskTwoToneIcon/>
                </div>
                <div className="cR gC3">
                    {timeAgo}
                </div>
            </div>
            <div className="divider dT">
            </div>
            <div className="content g f cC dG b">
                <div className="subHeader g f s e dG">
                    <div className="f cL dG">
                        <GppGoodTwoToneIcon/>
                        <div className="f cL">
                            Verification of Coverage
                        </div>
                    </div>
                    <div className="f cR fR gC3 lH">
                        <div className="dataPoint bold cC dT bR fR s e">
                            {{
                                Closed: "Completed",
                                New: "Pending",
                                Progress: "Submitted",
                                Problem: "Pending"
                            }[checkStatus]}
                        </div>
                    </div>
                </div>
                <div className="statement f cC s e">
                    <div className="f cL dG lH" title="Cost of Insurance">
                        COI:
                        <span className="bold">
                            {session?.env?.functions?.convertIntToCurrency(timelineItem?.currentCOI)}
                        </span>
                    </div>
                    <div className="f cC dG lH" title="Account Value">
                        <div>
                            AV:
                            <span className="s bold">
                                {session?.env?.functions?.convertIntToCurrency(timelineItem?.accountValue)}
                            </span>
                        </div>
                    </div>
                    <div className="f cR dG lH" title="Cash Surrender Value">
                        CSV:
                        <span className="bold">
                            {session?.env?.functions?.convertIntToCurrency(timelineItem?.cashValue)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BidTimelinePost;