import React, { useEffect, useState, useRef } from 'react';

import './Chart.css';

import Chart from 'chart.js/auto';

import { convertIntToCurrency } from '../../envFunctions.js';

import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';

function LineChart(props){
  const chartContainer = useRef(null);
  const [chart, setChart] = useState(null);
  const [chartData, setChartData] = useState();
  const [lineChartLoading, setLineChartLoading] = useState(true);

  const today = new Date();
  let currentYear = today.getFullYear();
  const numYears = 2;
  const startMonth = today.getMonth() < 11 ? today.getMonth() + 1 : 0;
  const startYear = startMonth === 0 ? currentYear + 1 : currentYear;
  const startDate = new Date(Date.UTC(startYear, startMonth, 1));
  const numMonths = numYears * 12;
  const endMonth = (startMonth + numMonths - 1) % 12;
  const endYear = startYear + Math.floor((startMonth + numMonths - 1) / 12);
  const endDate = new Date(Date.UTC(endYear, endMonth + 1, 0));

  const colorHandler = {
    0:"#2196f3",
    1:"#cddc39",
    2:"#9c27b0",
    3:"#009688",
    4:"#f44336",
    5:"#ff9800",
    6:"#1769aa",
    7:"#e91e63",
    8:"#008394",
    9:"#2c387e",
  };

  function generateLabels() {  

    if (startMonth > 11) {
      startMonth = 0;
      startYear++;
    }
    const endMonth = (startMonth + (numYears * 12)) % 12;
    const endYear = startYear + Math.floor((startMonth + (numYears * 12)) / 12);
  
    const labels = [];
  
    for (let i = 0; i < numYears * 12; i++) {
      const month = (startMonth + i) % 12;
      const year = startYear + Math.floor((startMonth + i) / 12);

      if (month === 0) {
        labels.push('Jan ' + year);
      } else {
        labels.push(new Date(year, month, 1).toLocaleString('default', { month: 'short' }) + " '" + year.toString().slice(-2));
      }
      if (month === endMonth && year === endYear) {
        break;
      }
    }
  
    return labels;
  }

  function calculateData(){
    let category = [];
    let colorKey = 0;
    var chartData = {
      labels: generateLabels(),
      datasets: [],
      xAxisLabel: 'Month',
      yAxisLabel: 'Amount'
    };

    if (props.graphData) {
      const labels = [];
      const totals = [];  
      let premiumData;
      let currentYearMonth = new Date(startYear, startMonth, 1);

      for (let i = 0; i < numMonths; i++) {
        const monthName = currentYearMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
        labels.push(monthName);
        totals.push(0);
        currentYearMonth.setMonth(currentYearMonth.getMonth() + 1);
      }

      let graphData = props.graphData;
      for (let key in graphData) {
        if (isNaN(key)) {
          continue;
        }

        colorKey++;

        if (graphData.hasOwnProperty(key)) {
          let totals = {};
          let currentMonth;
          var x = -1;

          premiumData = graphData[key]?.premiums;
          if(props.singlePremium){
            premiumData = graphData[key]
          }

          const data = [];
          for (let dataKey in premiumData) {
            if (premiumData.hasOwnProperty(dataKey)) {
              let dateStr = premiumData[dataKey].date;
              let dateObj = new Date(dateStr);

              if (dateObj.getUTCFullYear() < startDate.getUTCFullYear() || 
              (dateObj.getUTCFullYear() === startDate.getUTCFullYear() && dateObj.getUTCMonth() < startDate.getUTCMonth()) ||
              dateObj.getTime() < startDate.getTime() || 
              dateObj.getUTCFullYear() > endDate.getUTCFullYear() ||
              (dateObj.getUTCFullYear() === endDate.getUTCFullYear() && dateObj.getUTCMonth() > endDate.getUTCMonth()) ||
              dateObj.getTime() > endDate.getTime()) {
                continue;
              }

              let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
              let monthName = monthNames[dateObj.getUTCMonth()];

              if(currentMonth !== monthName){
                currentMonth = monthName;
                x++
                if(x === numYears * 12){
                  x = 0;
                }
              }

              let premiumKey = "projectedPremium";
              if(props.displayType === "shipping"){
                premiumKey = "premium";
              }
              let monthYearKey = labels[x];
              let projectedPremium = premiumData[dataKey][premiumKey];
              if (!totals[monthYearKey]) {
                totals[monthYearKey] = 0;
              }
              totals[monthYearKey] += projectedPremium ? projectedPremium : 0;
            }
          }

          for (let monthYearKey of labels) {
            data.push(totals[monthYearKey] || 0);
          }

          category.push({
            label: props.policyNumber ? props.policyNumber : props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.funds?.[key]?.fundName,
            data: data,
            borderColor: colorHandler[colorKey],
            tension: 0.1
          })
        }
      }

      setLineChartLoading(false);

      if(premiumData){
        chartData.datasets = category;
        return chartData;
      }
     }
  }

  useEffect(() => {
    if (chart) {
      chart.destroy();
    }

    if (chartContainer && chartContainer.current && chartData) {
      const chartConfig = {
        type: 'line',
        data: {
            labels: chartData?.labels,
            datasets: chartData?.datasets
        },
        options: {
          scales: {
              x: {
                  title: {
                      display: true,
                      text: chartData?.xAxisLabel
                  }
              },
              y: {
                  title: {
                      display: true,
                      text: chartData?.yAxisLabel
                  },
                  ticks: {
                    callback: function(value, index, values) {
                      return convertIntToCurrency(value, props.mobileDevice);
                    }
                  },
                  beginAtZero : true,
                  min: 0
              }
          },
          plugins: {
            legend: {
              display: props.policyNumber ? false : true,
              position: 'top',
              align: 'center',
              fullWidth: true,
              reverse: false,
              labels: {
                boxWidth: 20,
                fontColor: 'black',
                fontSize: 14,
                padding: 10,
                usePointStyle: true,
                generateLabels: function(chart) {
                  const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart);
                  labels.forEach(label => {
                    label.hidden && (label.fillStyle = 'gray');
                    label.textDecoration = label.hidden ? 'line-through' : 'none';
                  });
                  return labels;
                }
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          resize: function(chart, size) {
            if (chart) {
              chart.resize();
            }
          }
        }
      };

      const newChart = new Chart(chartContainer.current, chartConfig);
      setChart(newChart);

      return () => {
          newChart.destroy()
      };
    }

  }, [chartContainer, chartData]);

  useEffect(() => {
    //if(JSON.stringify(chartData) !== JSON.stringify(props.graphData) && props?.graphData){
    if(!chartData){
      setChartData(calculateData());
    }
    //console.log(props);
  }, [props?.graphData]);

  useEffect(() => {
    // console.log(props);
  }, []);

  return (
    <div className="lineChartContainer chartBlock moduleBlock">
      { props.policyNumber ?
        <div className="graphHeaderBar">
          <div className="graphHeader bold">
              {props.policyNumber + " Premium Schedule"}
          </div>
          <div className="closeBtn" onClick={()=> {props.closeGraph(false)}}>
              <CloseIcon/>
          </div>
        </div>
      :
        <div className="graphHeaderBar">
          <div className="timelineHeader bold">
              Premium Schedule
          </div>
        </div>
      }
      { lineChartLoading &&
        <div className="loading cC">
            <CircularProgress color="inherit"/>
            <span>Loading Premium Schedule Data</span>
        </div>
      }
      { !lineChartLoading && chartData?.datasets &&
        <canvas ref={chartContainer} className="lineChart"></canvas>
      }
      { !lineChartLoading && !chartData?.datasets &&
        <div className="cC g fMP">
          No Premium Schedule data to display.
        </div>
      }
    </div>
  );
};

export default LineChart;