import React, { useEffect, useState, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PaymentsIcon from '@mui/icons-material/Payments';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import GavelIcon from '@mui/icons-material/Gavel';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PolicyIcon from '@mui/icons-material/Policy';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DownloadIcon from '@mui/icons-material/Download';

import { authorizeRequest } from '../envHandler.js';
import { convertIntToCurrency } from '../envFunctions.js';

function FundBreakdown(props){
    const [downloadStatus, setDownloadStatus] = useState(false);

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const currentMonth = months[currentMonthIndex];

    function downloadDataTape(){
        setDownloadStatus(true);

        const paramVals = {
            "accountID" : props.sessionUser?.accountID,
        }

        props.session?.env?.functions?.buildFetchRequest('servicing/downloadDataTape', paramVals)
        .then((data) => {
            return data.blob();
        })
        .then((blob) => {
            const objUrl = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = objUrl;
            link.download = "DataTape " + props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.name + ".xlsx";
            link.click();
            setDownloadStatus(false);
        });
    }

    useEffect(() => {
        // console.log(props);
    }, [props]);

    function printFundBreakdown(){
        var x = 0;
        var returningArray = [];

        returningArray.push(
            <div key="fundBreakdownTimeline" className="fundBreakdownTimeline">
                <div className="fundBreakdownTimelineSection icon cC">
                    <div className="fundBreakdownTimelineBubbleTitle">
                        Contracts
                    </div>
                    <div className="fundBreakdownTimelineBubble">
                        <GavelIcon style={{color:"#ff5722"}}/>
                    </div>
                    <div className="fundBreakdownTimelineBubbleCount">
                        {props.chartData?.servicing?.Contracts?.count ? props.chartData?.servicing?.Contracts?.count : 0} | <span className="cashString">
                            {convertIntToCurrency(props.chartData?.servicing?.Contracts?.faceValue, true)}</span>
                    </div>
                </div>
                <div className="fundBreakdownTimelineSection">
                    <div className="fundBreakdownTimelineSeperator">
                    </div>
                </div>
                <div className="fundBreakdownTimelineSection icon cC">
                    <div className="fundBreakdownTimelineBubbleTitle">
                        Escrow
                    </div>
                    <div className="fundBreakdownTimelineBubble">
                        <HandshakeIcon style={{color:"#1976d2"}}/>
                    </div>
                    <div className="fundBreakdownTimelineBubbleCount">
                        {props.chartData?.servicing?.Escrow?.count ? props.chartData?.servicing?.Escrow?.count : 0 } | <span className="cashString">
                            {convertIntToCurrency(props.chartData?.servicing?.Escrow?.faceValue, true)}</span>
                    </div>
                </div>
                <div className="fundBreakdownTimelineSection">
                    <div className="fundBreakdownTimelineSeperator">
                    </div>
                </div>
                <div className="fundBreakdownTimelineSection icon cC">
                    <div className="fundBreakdownTimelineBubbleTitle">
                        Owned
                    </div>
                    <div className="fundBreakdownTimelineBubble">
                        <AccountBalanceWalletIcon style={{color: "#6d4c41"}}/>
                    </div>
                    <div className="fundBreakdownTimelineBubbleCount">
                        {props.chartData?.servicing?.Owned?.count ? props.chartData?.servicing?.Owned?.count : 0 } | <span className="cashString">
                            {convertIntToCurrency(props.chartData?.servicing?.Owned?.faceValue, true)}</span>
                    </div>
                </div>
            </div>
         );

        return (returningArray);
    }

    //console.log(props);
    return (
        <div className="breakdownBlock moduleBlock">
            <div className="fundSummaryBlockWrap">
            {!props?.chartData?.servicing ?
                <div className="breakdownTimeline f loading bR cC">
                    <CircularProgress color="inherit"/>
                </div>
                :
                <div className="fundSummaryBlock cC">
                    <div className="fundSummaryTopSection fR f cC">
                        <div className="fundSummaryTitle">
                            <span>
                                Portfolio Summary
                            </span>
                        </div>
                        {/* {downloadStatus ?
                            <div className="fundDataTapeDownload cC fMP ns progress alt">
                                <CircularProgress/>
                            </div>
                        :
                            <div className="fundDataTapeDownload fMP cC ns" onClick={()=>{downloadDataTape()}}>
                                Download Data Tape
                            </div>
                        } */}
                    </div>
                    <div className="divider">
                    </div>
                    <div className="fundSummaryContainer f">
                        <div className="g tC fR dP">
                            <div className="f cL fC fR g">
                                <div className="fundSummaryGroupSingle">
                                    <div className="cC fundSummaryGroupTitle bold">
                                        Total Policies
                                    </div>
                                    <div className="cC">
                                        <PolicyIcon style={{color: "#00BFFF"}}/>
                                    </div>
                                    <div className="cC fundSummaryCount">
                                        {props.chartData?.servicing?.total}
                                    </div>
                                </div>
                            </div>
                            <div className="f cC fC fR g">
                                <div className="fundSummaryGroupTrio">
                                    <div className="cC fundSummaryGroupTitle bold">
                                        Projected Premiums
                                    </div>
                                    <div className="cC">
                                        <MonetizationOnIcon style={{color: "#4caf50"}}/>
                                    </div>
                                    <div className="fundSummaryGroupRow">
                                        <div className="fundSumPremDueNML cL oH">
                                            <div className="f gCW">
                                                {currentMonth}
                                            </div>
                                        </div>
                                        <div className="fundSumPremDueNMR cR">
                                            <div className="f gCW">
                                                <span className="cashString">
                                                    {convertIntToCurrency(props.graphData?.currentMonthTotal, true)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fundSummaryGroupRow">
                                        <div className="fundSumPremDueNML cL oH">
                                            <div className="f gCW">
                                                Next Month
                                            </div>
                                        </div>
                                        <div className="fundSumPremDueNMR cR">
                                            <div className="f gCW">
                                                <span className="cashString">
                                                    {convertIntToCurrency(props.graphData?.nextMonthTotal, true)}
                                                </span>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="fundSummaryGroupRow">
                                        <div className="fundSumPremDue12ML cL oH">
                                            <div className="f gCW">
                                                Next 12 mo
                                            </div>
                                        </div>
                                        <div className="fundSumPremDue12MR cR">
                                            <div className="f gCW">
                                                <span className="cashString">
                                                    {convertIntToCurrency(props.graphData?.twelveMonthTotal, true)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="f cC fC fR g">
                                <div className="fundSummaryGroupSingle">
                                    <div className="cC fundSummaryGroupTitle bold">
                                        Active Face Value
                                    </div>
                                    <div className="cC">
                                        <PaymentsIcon style={{color: "#ba68c8"}}/>
                                    </div>
                                    <div className="cC fundSummaryCash">
                                        <span className="cashString">{convertIntToCurrency(props.chartData?.servicing?.totalActiveFaceValue, true)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="f cR fC fR g">
                                <div className="fundSummaryGroupTrio">
                                    <div className="cC fundSummaryGroupTitle bold">
                                        Closed Face Value
                                    </div>
                                    <div className="cC">
                                        <ReceiptIcon style={{color:"#66bb6a"}}/>
                                    </div>
                                    <div className="fundSummaryGroupRow">
                                        <div className="fundSumPremDueNML cL">
                                            <div className="f gCW">
                                                {props.chartData?.servicing?.Matured?.count ? props.chartData?.servicing?.Matured?.count : 0 } Matured
                                            </div>
                                        </div>
                                        <div className="fundSumPremDueNMR cR oH">
                                            <div className="f gCW">
                                                <span className="cashString">
                                                    {convertIntToCurrency(props.chartData?.servicing?.Matured?.faceValue, true)}
                                                </span>
                                            </div>
                                        </div>      
                                    </div>
                                    <div className="fundSummaryGroupRow">
                                        <div className="fundSumPremDue12ML cL">
                                            <div className="f gCW">
                                                {props.chartData?.servicing?.Sold?.count ? props.chartData?.servicing?.Sold?.count : 0 } Sold
                                            </div>
                                        </div>
                                        <div className="fundSumPremDue12MR cR">
                                            <div className="f gCW">
                                                <span className="cashString">
                                                    {convertIntToCurrency(props.chartData?.servicing?.Sold?.faceValue, true)}
                                                </span>
                                            </div>
                                        </div>                      
                                    </div>
                                    <div className="fundSummaryGroupRow">
                                        <div className="fundSumPremDue12ML cL">
                                            <div className="f gCW">
                                                {props.chartData?.servicing?.Lapsed?.count ? props.chartData?.servicing?.Lapsed?.count : 0 } Lapsed
                                            </div>
                                        </div>
                                        <div className="fundSumPremDue12MR cR">
                                            <div className="f gCW">
                                                <span className="cashString">
                                                    {convertIntToCurrency(props.chartData?.servicing?.Lapsed?.faceValue, true)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="fundSummarySectionHeader">
                        Policy Positions
                    </div>
                    <div className="f cC dP">
                        { printFundBreakdown() }
                    </div> */}
                </div>
            }
            </div>
        </div>
    );
};

export default FundBreakdown;