import React, { useEffect, useState } from 'react';

function Storage(props){
    const localStorage = JSON.parse(window.localStorage.getItem('storage'));
    const policiesFilters = localStorage?.filters?.policies;
    const bidFilters = localStorage?.filters?.bids;
    const leadsFilters = localStorage?.filters?.leads;
    const reporting = localStorage?.reporting;
    const homepagePreferences = localStorage?.preferences?.homepage;

    const storage = {
        filters : {
            policiesFilters,
            bidFilters,
            leadsFilters,
        },
        preferences : {
            homepage : homepagePreferences
        },
        reporting,
        localStorage
    }

    return storage;
};

export default Storage;