import React, { useEffect, useState } from 'react';

import InputField from '../../InputField.js';
import StringInput from '../../StringInput.js';
import DateInput from '../../DateInput.js';

import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import SearchIcon from '@mui/icons-material/Search';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CampaignIcon from '@mui/icons-material/Campaign';
import SaveIcon from '@mui/icons-material/Save';
import ThumbsUpDown from '@mui/icons-material/ThumbsUpDown';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import GradingIcon from '@mui/icons-material/Grading';

function BidTransition(props){
    const session = props?.session;
    const [selectedStatus, setSelectedStatus] = useState(props?.selectedStatus);
    const [content, setContent] = useState('');
    const [bidData, setBidData] = useState({
        bidAmount : 0,
        rdbAmount : 0,
        bidExpDate : '',
    });
    const [declineOption, setDeclineOption] = useState();
    const [loadingStatus, setLoadingStatus] = useState(false);
    const handleChange = (event) => {
        setContent(event.target.value);
    };

    function updateBid(attr) {
        return (evt) => {
            const value = attr === "bidExpDate" 
                ? evt.target.value // Handle date as string
                : parseInt(evt.target.value.substring(1).replace(/,/g, ""), 10) || 0;

            setBidData((prev) => ({
                ...prev,
                [attr]: value,
            }));
        };
    }

    function currentContentPrompt(){
        if(selectedStatus === "Requested Info"){
            return "What information are you requesting?";
        }

        if(selectedStatus === "Bidding"){
            if(session?.env?.functions?.checkSessionExceptions(session, "bidVerbiage")){
                return "Attach a note with this expression of interest.";
            }

            return "Attach a note with this bid.";
        }

        if(selectedStatus === "Declined"){
            return "Reason for declining case?";
        }
    }

    function currentDeclineStatus(status){
        if(status === declineOption){
            return "btn alt selected g cC fMP bR p";
        }

        return "btn alt g cC fMP bR p";
    }

    function splitOptions(){
        if(selectedStatus === "Bidding"){
            return "split";
        }

        if(selectedStatus === "Declined"){
            return "split";
        }

        return "fC";
    }

    function saveEligibility(){
        //if(selectedStatus === "Under Review" && props.)
        if(content === '' && selectedStatus !== "Under Review"){
            return false;
        }

        if(selectedStatus === "Bidding"){
            if(bidData?.bidAmount === 0){
                return false;
            }
        }

        if(selectedStatus === "Declined"){
            if(!declineOption){
                return false;
            }
        }

        return true;

    }

    function convertValues() {
        const statusMap = {
            "Requested Info": props.currentBidInfo?.shippingStatus === "Bidding"
                ? { stage: "BIDDING", shippingStatus: "Bidding" }
                : { stage: "UNDER REVIEW", shippingStatus: "Under Review" },
            "Under Review": { stage: "UNDER REVIEW", shippingStatus: "Under Review" },
            "Bidding": { stage: "BIDDING", shippingStatus: "Bidding" },
            "Declined": { stage: "CLOSED", shippingStatus: declineOption }
        };
    
        return statusMap[selectedStatus] || {};
    }

    function updatePortfolioStatus(){
        setLoadingStatus(true);

        const status = selectedStatus === "Declined" ? declineOption : selectedStatus;
        const paramVals = {
            "portfolioID": props?.currentCase?.data?.recordID,
            "fundID": props?.currentCase?.data?.fundID,
            "content": content,
        };

        session?.env?.functions?.buildFetchRequest(`tertiary/addPortfolioActivity`, paramVals)
        .then(response => response.json())
        .then(resData => {
            if(resData.status === 200){
                let requestUpdate = undefined;
                let bidUpdate = undefined;
                const currentTimeStamp = new Date().toISOString();
                const currentCase = {...props?.currentCase?.data}
                const conversionData = convertValues();
                const timelineData = session?.bids?.data?.tertiary?.portfolios?.[currentCase?.recordID]?.funds?.[currentCase?.fundID]?.timeline;

                // return;
                const statusUpdate = {
                    "timeStamp": currentTimeStamp,
                    "recordID": resData?.activity?.recordID,
                    "type": "Status",
                    "content": paramVals?.content,
                    "portfolioID": paramVals?.portfolioID,
                    "shippingStatus": conversionData.shippingStatus,
                    "fundID": paramVals?.fundID,
                    "bidAmount": paramVals?.bid,
                    "fundName": resData?.activity?.fundName,
                }

                requestUpdate = {
                    "timeStamp": currentTimeStamp,
                    "recordID": resData?.activity?.recordID,
                    "type": "Request",
                    "content": paramVals?.content,
                    "originator": "Customer",
                    "relatedBidID": currentCase?.relatedBidID,
                    "bidPolicyID": currentCase?.recordID,
                    "fundID": currentCase?.fundID,
                    "bidAmount": paramVals?.bid,
                    "fundName": currentCase?.fundName,
                    "shippingStatus" : paramVals?.status,
                    "checkStatus": "Open",
                    "rdb": paramVals?.rdb,
                    "readStatus": "true",
                    "temp" : true,
                }
                
                if(!timelineData){
                    timelineData = {
                        list: [],
                        openRequestCounter : 1,
                        requestCounter : 0,
                    };
                }

                // timelineData.list.push(statusUpdate);
                timelineData.list.push(requestUpdate);
                // currentCase.timelineData.list.push(statusUpdate);
                // currentCase.lastUpdate = currentTimeStamp;
                // console.log(999,currentCase);
                session?.set("bids", `tertiary.portfolios[${currentCase?.recordID}].funds[${currentCase?.fundID}].timeline`, timelineData);
                setLoadingStatus(false);
                session?.env?.setOverlay(undefined);
            }
    
            if(resData.status === 500){
                setLoadingStatus(false);
            }

        });
    }

    function updateBidStatus(){
        setLoadingStatus(true);

        const status = selectedStatus === "Declined" ?
        session?.env?.functions?.checkSessionExceptions(session, declineOption, {"accountID" : props?.currentCase?.data?.relatedAccountID}) ??
            declineOption
        :
            session?.env?.functions?.checkSessionExceptions(session, selectedStatus, {"accountID" : props?.currentCase?.data?.relatedAccountID}) ??
            selectedStatus;

        const paramVals = {
            [props?.stem === "bids" ? "taskID" : "recordBidID"]: props?.currentCase?.data?.recordID,
            status: status,
            bid: bidData?.bidAmount,
            rdb: bidData?.rdbAmount,
            bidExpDate: bidData?.bidExpDate,
            content: selectedStatus === "Under Review" ? " " : content
        };
        const conversionData = convertValues();

        session?.env?.functions?.buildFetchRequest(
            `${props?.stem === "bids" ? "marketplace" : "tertiary"}/updateBidStatus`,
            paramVals
        ).then(response => response.json())
        .then(resData => {
            if(resData.status === 200){
                let requestUpdate = undefined;
                let bidUpdate = undefined;
                const currentTimeStamp = new Date().toISOString();
                const currentCase = {...props?.currentCase?.data};
                const conversionData = convertValues();
                const statusUpdate = {
                    "timeStamp": currentTimeStamp,
                    "recordID": resData?.data?.[1]?.Activity?.[0]?.activityRecordID,
                    "type": "Status",
                    "content": paramVals?.content,
                    "relatedBidID": props?.currentCase?.relatedBidID,
                    "bidPolicyID": props?.currentCase?.recordID,
                    [props?.stem === "tertiary" ? "shippingStatus" : "taskShippingStatus"]: session?.env?.functions?.checkSessionExceptions(session, conversionData.shippingStatus, {"accountID" : props?.currentCase?.data?.relatedAccountID}) ?? conversionData.shippingStatus,
                    "fundID": props?.currentCase?.fundID,
                    "bidAmount": paramVals?.bid,
                    "fundName": props?.currentCase?.fundName,
                }

                if(paramVals?.status === "Requested Info"){
                    requestUpdate = {
                        "timeStamp": currentTimeStamp,
                        "recordID": resData?.data?.[0]?.[props?.stem === "tertiary" ? "Bid" : "Task"]?.recordID,
                        "type": "Request",
                        "content": paramVals?.content,
                        "originator": "Customer",
                        "relatedBidID": props?.currentCase?.relatedBidID,
                        "bidPolicyID": props?.currentCase?.recordID,
                        "fundID": props?.currentCase?.fundID,
                        "bidAmount": paramVals?.bid,
                        "fundName": props?.currentCase?.fundName,
                        [props?.stem === "tertiary" ? "shippingStatus" : "taskShippingStatus"]: paramVals?.status,
                        "checkStatus": "Open",
                        "rdb": paramVals?.rdb,
                        "readStatus": "true",
                        "temp" : true,
                    }
                }

                if(paramVals?.status === "Bidding"){
                    if(props?.stem === "tertiary"){
                        bidUpdate = {
                            "timeStamp": currentTimeStamp,
                            "recordID": resData?.data?.[0]?.Bid?.recordID,
                            "type": "Bid",
                            "content": paramVals?.content,
                            "originator": "Customer",
                            "fundID": props?.currentCase?.fundID,
                            "bidAmount": paramVals?.bid,
                            "rdb" : paramVals?.rdb,
                            "fundName": props?.currentCase?.fundName,
                            "shippingStatus": "Bidding",
                            "checkStatus": "Open",
                            "readStatus": true,
                            "temp" : true,
                        }
                    }

                    if(props?.stem === "bids"){
                        bidUpdate = {
                            "timeStamp": currentTimeStamp,
                            "recordID": resData?.data?.[0]?.Task?.recordID,
                            "content": paramVals?.content,
                            "taskID": props?.currentCase?.taskID,
                            "taskType": "Shipping",
                            "taskShippingStatus": "Bidding",
                            "policyAARecordID": props?.currentCase?.policyAARecordID,
                            "policyNumber": props?.currentCase?.policyNumber,
                            "fundId": props?.currentCase?.fundID,
                            "fullName": props?.currentCase?.fullName,
                            "type": "Bid",
                            "bidAmount": paramVals?.bid,
                            "rdb" : paramVals?.rdb,
                            "bidExpDate" : paramVals?.bidExpDate,
                            "policyID": props?.currentCase?.policyID,
                            "nameOfSender": `${session?.user?.data?.firstName} ${session?.user?.data?.lastName}`,
                            "originator": "Customer",
                            "checkStatus": "Open",
                            "readStatus": true,
                            "temp" : true,
                        }
                    }
                }

                if(!session?.case?.data?.[props?.branch]?.[props?.stem]?.[props?.currentCase?.index]?.timeline){
                    currentCase.timeline = { list: [] };
                }

                if(currentCase?.shippingStatus === "Bidding" && conversionData.shippingStatus === "Bidding"){
                    if(bidUpdate) currentCase.timeline.list.push(bidUpdate);
                }else if((currentCase?.shippingStatus !== "Shipped" || currentCase?.shippingStatus !== "Initial Assessment") && requestUpdate){
                    currentCase.timeline.list.push(requestUpdate);
                }else{
                    if(requestUpdate){
                        currentCase.timeline.list.push(requestUpdate);
                    }
                    if(bidUpdate){
                        currentCase.timeline.list.push(bidUpdate);
                    }
                    currentCase.shippingStatus = conversionData?.shippingStatus;
                    currentCase.stage = conversionData?.stage;
                    currentCase.timeline.list.push(statusUpdate);
                }

                currentCase.lastUpdate = currentTimeStamp;
                if(bidData?.bidAmount){
                    currentCase.grossBid = bidData?.bidAmount ?? currentCase?.grossBid;
                    currentCase.rdb = bidData?.rdbAmount ?? currentCase?.rdb;
                    currentCase.bidDate = currentTimeStamp;
                }
                session?.set("case", `${props?.branch}.${props?.stem}[${props?.currentCase?.index}]`, currentCase)
                setLoadingStatus(false);
                session?.env?.setOverlay(undefined);
            }
    
            if(resData.status === 500){
                setLoadingStatus(false);
            }

        });
    }

    function selectDeclineOption(status){
        setDeclineOption(status);
    }

    function currentStatus(status){
        if(selectedStatus === status){
            return true;
        }

        return false;
    }

    // return (
    //     <>
    //         <div className="actionBar bid alt g cC dG bold s e">
    //             <div
    //                 className={`action g cC fR bR f e ns p${currentStatus("Requested Info") ? " selected" : ''}`}
    //                 onClick={()=>{setSelectedStatus("Requested Info")}}
    //             >
    //                 <PendingActionsIcon/>
    //                 <div className="f cC gCW">
    //                     Request Info
    //                 </div>
    //             </div>
    //             <div
    //                 className={`action g cC fR bR f e ns${currentStatus("Under Review") ? " selected" : ''}${props?.instance === "portfolio" ? " inactive" : " p"}`}
    //                 onClick={()=>{props?.instance !== "portfolio" && setSelectedStatus("Under Review")}}
    //             >
    //                 <SearchIcon/>
    //                 <div className="f cC gCW">
    //                     {session?.env?.functions?.checkSessionExceptions(session, "Under Review", {"accountID" : props?.currentCase?.data?.relatedAccountID}) ?? "Under Review"}
    //                 </div>
    //             </div>
    //             <div
    //                 className={`action g cC fR bR f e ns${currentStatus("Bidding") ? " selected" : ''}${props?.instance === "portfolio" ? " inactive" : " p"}`}
    //                 onClick={()=>{props?.instance !== "portfolio" && setSelectedStatus("Bidding")}}
    //             >
    //                 <CampaignIcon/>
    //                 <div className="f cC gCW">
    //                     {session?.env?.functions?.checkSessionExceptions(session, "bidVerbiage") ?
    //                         "Show Interest"
    //                     :
    //                         "Make Bid"
    //                     }
    //                 </div>
    //             </div>
    //             <div
    //                 className={`action g cC fR bR f e ns${currentStatus("Declined") ? " selected" : ''}${props?.instance === "portfolio" ? " inactive" : " p"}`}
    //                 onClick={()=>{props?.instance !== "portfolio" && setSelectedStatus("Declined")}}
    //             >
    //                 <DoDisturbOnIcon/>
    //                 <div className="f cC gCW">
    //                     Decline
    //                 </div>
    //             </div>
    //         </div>
    //         <div className={`content g f fR fC ${selectedStatus}`}>

    //         </div>
    //         <div className="saveBar g cR fR f dP">
    //             <div
    //                 className={`btn alt g bold cC bR f fR p${loadingStatus ? " loading" : !saveEligibility() ? " inactive" : ""}`}
    //                 onClick={(e) => !loadingStatus && saveEligibility() && (props?.instance === "portfolio" ? updatePortfolioStatus() : updateBidStatus())}
    //             >
    //                 {loadingStatus ?
    //                     <CircularProgress />
    //                 :
    //                     <>
    //                         <SaveIcon />
    //                         <div className="f cC">
    //                             Save
    //                         </div>
    //                     </>
    //                 }
    //             </div>
    //         </div>
    //     </>

    // )

    return (
        <>
            <div className="actionBar bid alt g cC dG bold s e">
                <div
                    className={`action g cC fR bR f e ns p${currentStatus("Requested Info") ? " selected" : ''}`}
                    onClick={()=>{setSelectedStatus("Requested Info")}}
                >
                    <PendingActionsIcon/>
                    <div className="f cC gCW">
                        Request Info
                    </div>
                </div>
                <div
                    className={`action g cC fR bR f e ns${currentStatus("Under Review") ? " selected" : ''}${props?.instance === "portfolio" ? " inactive" : " p"}`}
                    onClick={()=>{props?.instance !== "portfolio" && setSelectedStatus("Under Review")}}
                >
                    <SearchIcon/>
                    <div className="f cC gCW">
                        {session?.env?.functions?.checkSessionExceptions(session, "Under Review", {"accountID" : props?.currentCase?.data?.relatedAccountID}) ?? "Under Review"}
                    </div>
                </div>
                <div
                    className={`action g cC fR bR f e ns${currentStatus("Bidding") ? " selected" : ''}${props?.instance === "portfolio" ? " inactive" : " p"}`}
                    onClick={()=>{props?.instance !== "portfolio" && setSelectedStatus("Bidding")}}
                >
                    <CampaignIcon/>
                    <div className="f cC gCW">
                        {session?.env?.functions?.checkSessionExceptions(session, "bidVerbiage") ?
                            "Show Interest"
                        :
                            "Make Bid"
                        }
                    </div>
                </div>
                <div
                    className={`action g cC fR bR f e ns${currentStatus("Declined") ? " selected" : ''}${props?.instance === "portfolio" ? " inactive" : " p"}`}
                    onClick={()=>{props?.instance !== "portfolio" && setSelectedStatus("Declined")}}
                >
                    <DoDisturbOnIcon/>
                    <div className="f cC gCW">
                        Decline
                    </div>
                </div>
            </div>
            <div className={"statusAlterBlock g f pR cC fR " + splitOptions()}>
                { splitOptions() !== "fC" &&
                    <div className="cC f">
                        {selectedStatus === "Bidding" &&
                            <div className="bidding g tC fC fR f s">
                                <StringInput
                                    session={session}
                                    key={`bidAmount`}
                                    name={"bidAmount"}
                                    placeholder={(session?.env?.functions?.checkSessionExceptions(session, "bidVerbiage") ?? "Bid") + " Amount"}
                                    value={session?.env?.functions?.convertIntToCurrency(bidData?.bidAmount)}
                                    onChange={updateBid("bidAmount")}
                                    readOnly={false}
                                    active={bidData?.bidAmount > 0}
                                />
                                {props?.stem === "bids" &&
                                    <StringInput
                                        session={session}
                                        key={`rdbAmount`}
                                        name={"rdbAmount"}
                                        placeholder={"RDB Amount"}
                                        value={session?.env?.functions?.convertIntToCurrency(bidData?.rdbAmount)}
                                        onChange={updateBid("rdbAmount")}
                                        readOnly={false}
                                        active={bidData?.rdbAmount > 0}
                                    />
                                }
                                {props?.stem === "bids" &&
                                    <DateInput
                                        session={session}
                                        key={`experationDate`}
                                        name={"experationDate"}
                                        placeholder={"Expiration Date"}
                                        value={bidData?.bidExpDate}
                                        onChange={updateBid("bidExpDate")}
                                        readOnly={false}
                                        futureDate={true}
                                    />
                                }
                                <div className="biddingIcon cC f gR4">
                                    <ThumbsUpDown/>
                                </div>
                            </div>
                        }
                        {selectedStatus === "Declined" &&
                            <div className="declined g f cC fC">
                                <div className="g cC fMP bR">
                                    <div className="cC gC2">
                                        Decline Reasons
                                    </div>
                                    <div className="cL f">
                                        <ThumbDownAltIcon/>
                                    </div>
                                </div>
                                <div className="divider"></div>
                                <div className={currentDeclineStatus("No Bid")} onClick={()=>{selectDeclineOption("No Bid")}}>
                                    {`No ${(session?.env?.functions?.checkSessionExceptions(session, "bidVerbiage") ?? "Bid")}`}
                                </div>
                                <div className={currentDeclineStatus("In Conflict")} onClick={()=>{selectDeclineOption("In Conflict")}}>
                                    In Conflict
                                </div>
                                <div className={currentDeclineStatus("Does Not Fit Parameters")} onClick={()=>{selectDeclineOption("Does Not Fit Parameters")}}>
                                    Invalid Params
                                </div>
                                <div className={currentDeclineStatus("Lost with bid")} onClick={()=>{selectDeclineOption("Lost with bid")}}>
                                    {`Lost with ${(session?.env?.functions?.checkSessionExceptions(session, "bidVerbiage") ?? "Bid")}`}
                                </div>
                            </div>
                        }
                    </div>
                }
                { selectedStatus === "Under Review" &&
                    <div className="cC f">
                        <div className="underReview g cC fR f">
                            <div className="content g f cL dP fC">
                                <div className="f bC bold">
                                    Under Review Period
                                </div>
                                <div className="f tL dP">
                                    By setting this shipped case to the Under Review status,
                                    it indicates that there is potential interest. At anytime,
                                    you can request information from us.
                                </div>
                            </div>
                            <div className="icon dP cC">
                                <GradingIcon/>
                            </div>
                        </div>
                    </div>
                }
                { selectedStatus && currentContentPrompt() &&
                    <textarea
                        className="contentBox bR"
                        placeholder={currentContentPrompt()}
                        onChange={handleChange}
                        rows={20}
                    />
                }
                { !selectedStatus &&
                    <div className="cC f empty">
                        {`Select ${/^[aeiouAEIOU]/.test(session?.env?.functions?.checkSessionExceptions(session, "bidVerbiage") ?? "Bid") ? "an" : "a"} ${session?.env?.functions?.checkSessionExceptions(session, "bidVerbiage") ?? "Bid"} Position`}
                    </div>
                }
            </div>
            <div className="saveBar g cR fR f dP">
                <div
                    className={`btn alt g bold cC bR f fR p${loadingStatus ? " loading" : !saveEligibility() ? " inactive" : ""}`}
                    onClick={(e) => !loadingStatus && saveEligibility() && (props?.instance === "portfolio" ? updatePortfolioStatus() : updateBidStatus())}
                >
                    {loadingStatus ?
                        <CircularProgress />
                    :
                        <>
                            <SaveIcon />
                            <div className="f cC">
                                Save
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    );
};

export default BidTransition;