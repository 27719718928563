import React from 'react';
import { useState, useEffect, useRef } from 'react';

import FilterPanel from '../../Components/FilterPanel/FilterPanel.js';

import CloseIcon from '@mui/icons-material/Close';
import RangeSlider from '../FilterPanel/RangeSlider.js';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InboxIcon from '@mui/icons-material/Inbox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import CampaignIcon from '@mui/icons-material/Campaign';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

function KanbanFilters(props) {
    const filterHandler = props?.sessionVars?.envVars?.filterHandler;
    const filterBranchStem = props?.filterBranchStem;
    const filterFunctions = props?.sessionVars?.envVars?.filterHandler?.functions;
    const filterProps = {
        filterHandler,
        ...props
    }

    const filterPanelFunctions = FilterPanel(filterProps).functions;
    const [visibleSections, setVisibleSections] = useState({});
    const showBinary = {
        category : true,
        status : true,
    }

    const stages = [
        {
            className: "stage1",
            count: props?.kanbanProps?.stage1Count,
            maxCount : filterBranchStem?.stage1MaxCount,
            icon : <InboxIcon/>
        },
        {
            className: "stage2",
            count: props?.kanbanProps?.stage2Count,
            maxCount : filterBranchStem?.stage2MaxCount,
            icon : <FlagCircleIcon/>
        },
        {
            className: "stage3",
            count: props?.kanbanProps?.stage3Count,
            maxCount : filterBranchStem?.stage3MaxCount,
            icon : <CampaignIcon/>
        }
    ];

    const filters = filterPanelFunctions.buildFilterSections(props?.sessionUser, props?.session);

    return (
        <div className="filtersBoard moduleBlock g cC fC oH bR" onClick={(e)=>{props?.handleClose(e)}}>
            <div className="header g f cC fR">
                <div className="gC2 f cC">
                    Filters
                </div>
                <div className="btn close cC p" onClick={()=>{props?.setShowFilters(false)}}>
                    <CloseIcon/>
                </div>
            </div>
            <div className="miniKanban f g dP">
                {stages.map((stage, index) => (
                    <div className="fR fC f cC g" key={index+stage}>
                        <div className={`catInfo bold g f ${stage.className}`}>
                            <div className="f cC">
                                {stage.icon}
                            </div>
                            <div className="f cC oH">
                                <div className="gCW">
                                    {stage.count ? stage.count : "0"}
                                    {" / " + stage.maxCount}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {filters}
            <div className="actionBar g f fR cC dG">
                <div className="checkBox g f cL p s" onClick={()=>{filterFunctions?.updateFilterAttr(null, props?.branch, null, null, "includeUnassigned")}}>
                    <div className={props?.sessionVars?.enVars?.filterHandler?.[props?.branch]?.includeUnassigned ? "f cC oH p" : "inactive f cC oH p"}>
                        <div className="gCW">
                            Include Unassigned
                        </div>
                    </div>
                    <div className="f cC p oH">
                        {props?.sessionVars?.envVars?.filterHandler?.[props?.branch]?.includeUnassigned ? <CheckCircleIcon sx={{ color: "#00BFFF" }} /> : <RadioButtonUncheckedIcon sx={{ color: "#E2E2E2" }} />}
                    </div>
                </div>
                <div className="checkBox g f cL p s" onClick={()=>{filterFunctions?.updateFilterAttr(null, props?.branch, null, null, "includeOpenRequests")}}>
                    <div className={props?.sessionVars?.enVars?.filterHandler?.[props?.branch]?.includeOpenRequests ? "f cC oH p" : "inactive f cC oH p"}>
                        <div className="gCW">
                            Include Opened Requests
                        </div>
                    </div>
                    <div className="f cC p oH">
                        {props?.sessionVars?.envVars?.filterHandler?.[props?.branch]?.includeOpenRequests ? <CheckCircleIcon sx={{ color: "#00BFFF" }} /> : <RadioButtonUncheckedIcon sx={{ color: "#E2E2E2" }} />}
                    </div>
                </div>
                {/* <div className="btn inactive g cC fMP bR p gC4 ns">
                    Save
                </div> */}
            </div>
        </div>
    );
}

export default KanbanFilters;