import React from 'react';
import { useState, useEffect } from 'react';

import StatusIcon from '../StatusIcons';


import '../Timeline/TimelinePost.css';

import FolderSharedIcon from '@mui/icons-material/FolderShared';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ReplyIcon from '@mui/icons-material/Reply';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import ThumbDownAltTwoToneIcon from '@mui/icons-material/ThumbDownAltTwoTone';
import ThumbsUpDownTwoToneIcon from '@mui/icons-material/ThumbsUpDownTwoTone';
import RecommendTwoToneIcon from '@mui/icons-material/RecommendTwoTone';

function BiddingOffer(props){
    const session = props?.session;
    const timelineData = props?.timelineData;
    const timelineItem = props?.timelineItem;
    const timeAgo = props?.timeAgo;
    const readStatus = props?.readStatus ?? timelineItem?.readStatus;
    const checkStatus = timelineItem?.checkStatus

    const bidData = () => {
        if (timelineItem?.temp) {
            return {
                bidAmount: session?.env?.functions?.convertIntToCurrency(timelineItem?.bidAmount),
                bidRDB: session?.env?.functions?.convertIntToCurrency(timelineItem?.rdb) === "$0" ? undefined : session?.env?.functions?.convertIntToCurrency(timelineItem?.rdb),
                bidExpDate: timelineItem?.bidExpDate,
                bidNote: timelineItem?.content,
            };
        }
    
        const content = timelineItem?.content || "";
        let bidAmount, bidRDB, bidNote, bidExpDate;
    
        const backtickMatch = content.match(/^([^,]+),([^,]+),`([^`]*)`,?(.*)$/);
    
        if (backtickMatch) {
            [, bidAmount, bidRDB, bidNote, bidExpDate] = backtickMatch.map(s => s.trim());
        } else {
            const parts = content.split(",").map(s => s.trim());
    
            [bidAmount, bidRDB] = parts;
    
            if (parts.length === 3) {
                bidNote = parts[2];
                bidExpDate = undefined;
            } else if (parts.length > 3) {
                bidExpDate = parts.slice(-1)[0];
                bidNote = parts.slice(2, -1).join(",");
            }
        }
    
        const formattedBidRDB = session?.env?.functions?.convertIntToCurrency(bidRDB);
    
        return {
            bidAmount: session?.env?.functions?.convertIntToCurrency(bidAmount),
            bidRDB: formattedBidRDB === "$0" ? undefined : formattedBidRDB,
            bidExpDate,
            bidNote,
        };
    };

    return (
        <div
            className={`newTimelinePost offer g f bR p ${timelineItem?.checkStatus ?? "Open"} ${props?.stem}${props?.detailed ? " detailed" : ''}`}
            onClick={props?.onClick}
        >
            <div className="header g f fR e dG">
                {readStatus === false && timelineItem?.originator === "Internal" &&
                    <div className="f cC">
                        <div className="notificationAlert">
                        </div>
                    </div>
                }
                <div className="cL gC2 bold dG">
                    Bid Alert
                    <ErrorTwoToneIcon/>
                </div>
                <div className="cR gC3">
                    {timeAgo}
                </div>
            </div>
            <div className="divider dT">
            </div>
            <div className="content g f cC dG e">
                {{
                    Completed: <RecommendTwoToneIcon className="dC" />,
                    Denied: <ThumbDownAltTwoToneIcon className="dC" />,
                    Open: <ThumbsUpDownTwoToneIcon className="dC" />
                }[checkStatus]}
                <div className="subHeader g f dG">
                    <div className="f cL">
                        Offer Details
                    </div>
                    <div className="f cR fR gC2">
                        <div className="dataPoint bold cC dT bR fR lH">
                            {{
                                Open: "Pending",
                                Completed: "Accepted",
                                Denied: "Declined",
                            }[checkStatus]}
                        </div>
                    </div>
                </div>
                <div className="vDivider gC1 dT"></div>
                <div className="f cL gC2">
                    {bidData()?.bidNote ?? `No existing ${session?.env?.functions?.checkSessionExceptions(session, "bidVerbiage")} Note to show.`}
                </div>
                <div className="offerData f cL dG fR gC2 g">
                    <div className={`f cL g fR dG`}>
                        <span>Amount:</span>
                        <div className="dataPoint bold cC dT bR gC2 lH">
                            {bidData()?.bidAmount}
                        </div>
                    </div>
                    {bidData()?.bidRDB &&
                        <div className="f cL g fR dG">
                            <span>RDB:</span>
                            <div className="dataPoint bold cC dT bR gC2 lH">
                                {bidData()?.bidRDB}
                            </div>
                        </div>
                    }
                    {bidData()?.bidExpDate &&
                        <div className="f cL g fR dG">
                            <span>Expiration:</span>
                            <div className="dataPoint bold cC dT bR gC2 lH">
                                {session?.env?.functions?.reformatDate(bidData()?.bidExpDate)}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default BiddingOffer;