import React from 'react';
import _ from 'lodash';
import EnvVars from './EnvVars.js';
import SessionUser from './SessionUser.js';
import SessionCase from './SessionCase.js';
import Storage from './Storage.js';
import ShareLink from './ShareLink.js';
import Notifications from './Notifications.js';
// import AnalyticVars from './AnalyticVars.js';
import BidVars from './BidVars.js';
import PolicyVars from './PolicyVars.js';
import ReportingVars from './ReportingVars.js';
import DashboardVars from './DashboardVars.js';
import Initialization from './Initialization.js';
import Navigation from './Navigation.js';
import { useState, useEffect } from 'react';

import useDeepEffect from './useDeepEffect';
import useComplexEffect from './useComplexEffect';

function SessionVars(props) {
    const userModule = SessionUser();
    const caseModule = SessionCase();
    const storageModule = Storage();
    const shareLinkModule = ShareLink();
    const notificationsModule = Notifications();
    const reportingModule = ReportingVars();
    const dashboardModule = DashboardVars();
    const bidsModule = BidVars();
    const policiesModule = PolicyVars();
    const envModule = EnvVars({user : userModule});
    const initializationModule = Initialization();
    const navigationModule = Navigation({user : userModule});

    const initialModulesState = createInitialState();
    const modules = React.useMemo(() => ({
        user: userModule,
        case: caseModule,
        storage: storageModule,
        shareLink: shareLinkModule,
        notifications: notificationsModule,
        reporting: reportingModule,
        dashboard: dashboardModule,
        policies: policiesModule,
        bids: bidsModule,
        env: envModule,
        initialization : initializationModule,
        navigation : navigationModule,
    }), [
        userModule,
        caseModule,
        storageModule,
        shareLinkModule,
        notificationsModule,
        reportingModule,
        dashboardModule,
        bidsModule,
        envModule,
        initializationModule,
        navigationModule,
    ]);

    function createInitialState() {
        return {
            user: SessionUser(),
            case: SessionCase(),
            storage: Storage(),
            shareLink: ShareLink(),
            notifications: Notifications(),
            reporting: ReportingVars(),
            dashboard: DashboardVars(),
            policies: PolicyVars(),
            bids: BidVars(),
            env: EnvVars({ user: SessionUser() }),
            initialization: Initialization(),
            navigation: Navigation({ user: SessionUser() }),
        };
    }

    function set(base, paths, values, msg) {
        const module = modules?.[base];
        if (module?.setData) {
            module.setData(prev => {
                if (paths === null) {
                    return values; // Replace entire module data
                }
        
                let newState = { ...prev };
                if (Array.isArray(paths)) {
                    paths.forEach((path, index) => {
                        newState = updateModule(newState, path, values[index], msg); // Use memoized version
                    });
                } else {
                    newState = updateModule(newState, paths, values, msg);
                }
                return newState;
            });
        } else {
            console.warn(`Unknown base: ${base}`);
        }
    }

    function updateModule(prev, path, value, msg) {
        const parts = path?.split('.').map(part => part.replace(/\[([^\]]+)\]/g, '.$1')).flatMap(part => part.split('.'));
        const newState = { ...prev }; // Shallow copy the top-level object
        let current = newState;
    
        for (let i = 0; i < parts.length - 1; i++) {
            const part = parts[i];
            if (typeof current[part] !== 'object' || current[part] === null) {
                current[part] = /^\d+$/.test(parts[i + 1]) ? [] : {};
            } else {
                current[part] = Array.isArray(current[part]) ? [...current[part]] : { ...current[part] };
            }
            current = current[part];
        }
    
        const lastPart = parts[parts.length - 1];
        if (!_.isEqualWith(current[lastPart], value, customCompare)) {
            // console.log('Difference detected:', path, current[lastPart], value, msg);
            current[lastPart] = _.cloneDeep(value); // Deep clone the value to avoid reference issues
            return newState;
        } else {
            // console.log("No difference found", path, msg);
            return prev; // Return previous state if no changes
        }
    }

    function customCompare(objValue, othValue) {
        if (React.isValidElement(objValue) && React.isValidElement(othValue)) {
            return compareReactElements(objValue, othValue);
        }
        return undefined;
    }

    function compareReactElements(elem1, elem2) {
        const significantProps1 = filterProps(elem1.props);
        const significantProps2 = filterProps(elem2.props);
        return elem1.key === elem2.key && deepEqual(significantProps1, significantProps2);
    }

    function filterProps(props) {
        const { _owner, _store, _self, _source, ...significantProps } = props;
        return significantProps;
    }

    function deepEqual(obj1, obj2) {
        if (Array.isArray(obj1) && Array.isArray(obj2)) {
            if (obj1.length !== obj2.length) return false;
            for (let i = 0; i < obj1.length; i++) {
                if (!_.isEqualWith(obj1[i], obj2[i], customCompare)) return false;
            }
            return true;
        }
        return _.isEqualWith(obj1, obj2, customCompare);
    }

    useEffect(() => {
        if(modules?.user?.data?.reset === true){
            Object.keys(initialModulesState).forEach(moduleKey => {
                if(initialModulesState?.[moduleKey]?.setData) set(moduleKey, null, initialModulesState[moduleKey].data);
            });
        }
    }, [modules?.user?.data?.reset]);

    return {
        ...modules,
        set,
        useListen: (callback, value, deep = false, call) => useDeepEffect(callback, value, deep, call, modules?.env?.isTyping),
        useComplexListen: (effects, deep = false, call) => useComplexEffect (effects, deep, call),
    };
}

export default SessionVars;