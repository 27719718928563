import React from 'react';
import { useState, useEffect, useRef } from 'react';

import '../../index.css';
import './MessageBoard.css';
import { authorizeRequest } from '../../envHandler.js';

import MessageBubble from './MessageBubble';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import CircularProgress from '@mui/material/CircularProgress';

function MessageBoard(props) {
  const [rows, setRows] = useState(1);
  const [maxRow, setMaxRow] = useState(1);
  const [messageVal, setMessageVal] = useState("");
  const [activeStatus, setActiveStatus] = useState(false);
  const scrollRef = useRef(null);
  const bottomHandle = useRef(null);
  const textBox = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScrollHeight, setMaxScrollHeight] = useState(0);
  const [adaptationTracker, setAdaptationTracker] = useState(0);
  const [policyType, setPolicyType] = useState('');
  
  const [transcript, setTranscript] = useState({});
  const [messageLoadingStatus, setMessageLoadingStatus] = useState(true);

  function sendMessage(){

    if(checkMessageEligibility(textBox?.current?.value)){
      return;
    }
  
    textBox.current.value = '';
    let int = Object.keys(transcript).length;
    let message = {};
    let tempMessage = {
      content: messageVal,
      originator : "Customer",
      loading : true,
      nameOfSender : props?.session?.user?.data?.firstName + " " + props?.session?.user?.data?.lastName,
      x : int
    }

    pullTranscript(tempMessage, policyType);

    resetMessageBox();

    let requestURL;
    let paramVals;

    if(policyType === "origination"){
      requestURL = 'sendMessage';
      paramVals = {
        "relatedPolicyID" : props.relatedPolicyID,
        "message" : messageVal,
        "nameOfSender" : props?.session?.user?.data?.firstName + " " + props?.session?.user?.data?.lastName,
        "emailOfSender" : props?.session?.user?.data?.email,
      };
    }

    if(policyType === "fund"){
      requestURL = 'servicing/sendMessage';
      paramVals = {
        "policyID" : props.relatedPolicyID,
        "message" : messageVal,
        "nameOfSender" : props?.session?.user?.data?.firstName + " " + props?.session?.user?.data?.lastName,
      };
    }

    if(policyType === "shipping"){
      requestURL = 'marketplace/sendMessage';
      paramVals = {
        "taskID" : props?.policyData?.recordID,
        "content" : messageVal,
      };
    }

    props.session?.env?.functions?.buildFetchRequest(requestURL, paramVals)
      .then(response => response.json())
      .then(resData => {
        if (resData.status === 200) {
          const dataStructure = (opportunityType) => {
            const structures = {
              origination: {
                "content": "content",
                "timeStamp": "timeStamp",
                "nameOfSender": "nameOfSender",
                "type": "type",
                "originator": "originator",
                "pointer" : "messageData",
              },
              fund: {
                "content": "message",
                "timeStamp": "dateCreated",
                "nameOfSender": "nameOfSender",
                "type": "message",
                "originator": "Customer",
                "pointer" : "data",
              },
              shipping: {
                "content": "content",
                "timeStamp": "timeStamp",
                "nameOfSender": "firstName",
                "type": "Message",
                "originator": "Customer",
                "pointer" : "data",
              },
            };
          
            return structures[opportunityType];
          };

          // const configs = {
          //     origination: ['messageData', 'originator'],
          //     fund: ['data', 'Customer', 'message', 'dateCreated', 'noteType'],
          //     shipping: ['messageData', 'Customer', 'content', 'timeStamp', 'firstName', 'noteType']
          // };
      
          // const [source, originator, ...fields] = configs[policyType] || [];
          // console.log(source, originator, fields);
          // console.log(configs, policyType);
          // if (source && resData[source]) {
              // const [contentField, timeField, nameField, typeField] = fields;
              // console.log(source, contentField);
              const messageData = resData?.[dataStructure(policyType)?.pointer];
              const message = {
                  content: messageData?.[dataStructure(policyType)?.content],
                  timeStamp: messageData?.[dataStructure(policyType)?.timeStamp],
                  nameOfSender: messageData?.[dataStructure(policyType)?.nameOfSender],
                  type: messageData?.[dataStructure(policyType)?.type],
                  originator: messageData?.[dataStructure(policyType)?.originator],
                  loading: false,
                  x: int
              };
      
              pullTranscript(message, policyType);
              scrollToBottom();
          // }
      }
    
      if(resData.status == 500){
          tempMessage.loading = "failed";
          setTranscript(transcript => ({
            ...transcript,
            [int] : tempMessage
          }));
          handleMessageBoard('');
      }
    });
  }

  function resetMessageBox(){
    setMessageVal('');
    setRows(1);
    setMaxRow(1);
    setActiveStatus(false);
  }

  function pullTranscript(nativeMessage, pullType){
    // if(props.loadingStatus || !props.policyData?.relatedAccount && !props.pullType){
    //   return;
    // }

    var transcriptData = [];

    if(nativeMessage){
      let messageObj = {
        content : nativeMessage.content,
        originator : nativeMessage.originator,
        timeStamp : nativeMessage.timeStamp,
        nameOfSender : props?.session?.user?.data?.firstName + " " + props?.session?.user?.data?.lastName,
        loading : true,
        x : nativeMessage.x                         
      }
  
      transcriptData = {...transcript};

      if(nativeMessage.loading === false){
        messageObj.loading = false;
      }
  
      setTranscript(transcript => ({ ...transcript, [nativeMessage.x] : messageObj }));
    }else{
      var x = 0;
      let requestURL;
      let paramVals;
      if(pullType === "origination"){
        requestURL = 'pullTranscript';
        paramVals = {
          "relatedPolicyID" : props.relatedPolicyID,
          "accountID" : props.policyData.relatedAccount,
          // "sessionToken" : authorizeRequest(props.sessionUser, props.updatePage)
        };
      }
      if(pullType === "fund"){
        requestURL = 'servicing/getPolicyMessages';
        paramVals = {
          "policyID" : props.relatedPolicyID,
          // "sessionToken" : authorizeRequest(props.sessionUser, props.updatePage)
        }
      }
      if(pullType === "shipping"){
        requestURL = 'marketplace/getBidMessages';
        paramVals = {
          "taskID" : props?.policyData?.recordID,
          // "sessionToken" : authorizeRequest(props.sessionUser, props.updatePage)
        }
      }

      props.session?.env?.functions?.buildFetchRequest(requestURL, paramVals)
        .then(response => response.json())
        .then(resData => {
          //console.log(resData);

          setMessageLoadingStatus(false);
          if(resData.status === 200){
            let returningFormat;
            if(policyType === "origination" || pullType === "origination"){
              returningFormat = "policyTranscriptData";
            }

            if(policyType === "fund" || pullType === "fund"){
              returningFormat = "messageLog";
            }

            if(policyType === "shipping" || pullType === "shipping"){
              returningFormat = "data";
            }

            for (var currentMessage in resData[returningFormat]){
              let formatedMessage = resData[returningFormat][currentMessage];
              let message;
              if(policyType === "origination" || pullType === "origination"){
                message = {
                  content : formatedMessage.content,
                  nameOfSender : formatedMessage.nameOfSender,
                  type : formatedMessage.type,
                  originator : formatedMessage.originator,
                  timeStamp : formatedMessage.timeStamp,
                  loading : false,
                  x : currentMessage
                };
              }

              if(policyType === "fund" || pullType === "fund"){
                message = {
                  content : formatedMessage.message,
                  nameOfSender : formatedMessage.nameOfSender,
                  type : formatedMessage.noteType,
                  originator : formatedMessage.originator,
                  timeStamp : formatedMessage.dateCreated,
                  loading : false,
                  x : currentMessage
                };
              }

              if(policyType === "shipping" || pullType === "shipping"){
                message = {
                  content : formatedMessage.content,
                  nameOfSender : formatedMessage.nameOfSender,
                  type : formatedMessage.type,
                  originator : formatedMessage.originator,
                  timeStamp : formatedMessage.timeStamp,
                  loading : false,
                  x : currentMessage
                };
              }

              transcriptData[currentMessage] = message;
            }

            setTranscript(transcriptData);
            updateMessageReadStatus();
          }

          if(resData.status === 500){
            console.log("No transcript found.");
          }
      });
    }
  }

  function printTranscript(){
    // if(props.loadingStatus || messageLoadingStatus){
    //   return(
    //     <div className="preTranscriptContainer">
    //         <div className="preTranscriptInnerWrap">
    //           <CircularProgress color="inherit"/>
    //         </div>
    //     </div>
    //   )
    // }

    if(Object.keys(transcript).length === 0 && !props.loadingStatus && !messageLoadingStatus){
      return(
        <div className="preTranscriptContainer">
            <div className="preTranscriptInnerWrap">
                <ModeCommentIcon sx={{ fontSize: 50 }}/>
                <div>
                  <p>Have any questions?</p>
                  <p>Start a conversation.</p>
                </div>
            </div>
        </div>
      )
    }

    let transcriptMessages = [];
    let lastNameOfSender = null;

    for (var key of Object.keys(transcript)) {
      transcriptMessages.push(
        <MessageBubble
          key={key}
          messageKey={key}
          messageData={transcript[key]}
          lastDate={key > 0 ? transcript[key-1].timeStamp : null}
          lastNameOfSender={lastNameOfSender}
        />);
      lastNameOfSender = transcript[key].nameOfSender;
    }

    return (
      transcriptMessages
    )
  }

  function updateMessageReadStatus(){
    let requestLink = "updateReadStatus";
    let requestOptions;
    let paramVals;

    if(props.pullType === "origination"){
        paramVals = {
          "type" : "messages",
          "relatedID" : props.relatedPolicyID,
          "unread": false
        };
    }

    if(props.pullType === "servicing"){
      requestLink = "servicing/updateReadStatus";
      paramVals = {
        "type":"messages",
          "policyID":props.relatedPolicyID,
          "unread": false
      }
    }

    if(props.pullType === "shipping"){
      requestLink = "marketplace/updateReadStatus";
      paramVals = {
          "type":"messages",
          "taskID":props.policyData?.recordID,
          "unread": false
      };
    }

    props.session?.env?.functions?.buildFetchRequest(requestLink, paramVals)
      .then(response => response.json())
      .then(resData => {
        //console.log(resData);
        if(resData.status === 200){
            // props.loadNotifications();
        }
        if(resData.readStatus !== undefined && !resData.readStatus){
          //props.markSessionPolicyAsRead(props.relatedPolicyID);
        }
        if(resData.status === 500){
            // console.log("No connection made.");
        }
    });
  }

  function handleScroll(scrollObj){
    const position = scrollObj.target.scrollTop;
    setScrollPosition(position);
    setMaxScrollHeight(scrollObj.target.clientHeight - ((maxRow * 16) + 38));
  };

  function maintainScrollPosition(adaptiveScrollTop){
    if(scrollRef.current.scrollTop > (maxScrollHeight / 2)){
      scrollRef.current.scrollTop = scrollPosition + adaptiveScrollTop;
    }
  }

  function handleMessageBoard(currentMessageVal){
    let rowCount = currentMessageVal.target.value.split("\n").length;
    let maxRowCount = rowCount;
    let adaptiveScroll = true;
    let adaption = 1;

    setMessageVal(currentMessageVal.target.value);
  
    if(currentMessageVal.target.value !== "" && !activeStatus && !checkMessageEligibility(currentMessageVal.target.value)){
      setActiveStatus(true);
    }
    if(currentMessageVal.target.value === "" && activeStatus){
      setActiveStatus(false);
    }

    if(rowCount > rows || rowCount < rows) {
      if(rowCount > 5){
        maxRowCount = 5;
        adaptiveScroll = false;
      }
      
      let rowTracker = rows;
      setRows(rowCount);
      setMaxRow(maxRowCount);

      if(rowTracker > maxRowCount){
        adaption = -1;
      }

      if(adaptiveScroll){
        setAdaptationTracker(adaption * 16);
      }
    }
  }

  function checkMessageEligibility(textVal){
    if(textVal === undefined){
      return true;
    }

    let messageText = textVal.replace(/^\s+/, '').replace(/\s+$/, '');
    if(messageText === ''){
      return true;
    }

    return false;
  }

  const scrollToBottom = () => {
    bottomHandle.current?.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    let transcriptPull = "origination";
    if(props?.policyData?.relatedFundID){
      transcriptPull = "fund";
    }

    if(props?.pullType === "shipping"){
      transcriptPull = "shipping";
    }

    setPolicyType(transcriptPull);

    // if(props.policyData && !props.loadingStatus){
    if(props.policyData){
      pullTranscript(null, transcriptPull);
    }

  }, [props.policyData, props.loadingStatus]);

  useEffect(() => {
    printTranscript();
    scrollToBottom();
  }, [transcript]);

  useEffect(() => {
    maintainScrollPosition(adaptationTracker);
  }, [maxRow]);

      return (
        <>
          <div
            key="messageBoard"
            className={messageLoadingStatus ? "policyDetailsMessageBoard loading" : "policyDetailsMessageBoard"}
            id="messageTranscript"
            style={{"bottom" : (maxRow * 16 + 42) + "px"}}
            onScroll={handleScroll} ref={scrollRef}
          >
            {printTranscript()}
            <div
              ref={bottomHandle}
              className="messageBoardBottomHandle"
            />       
            </div>
            <div className="messageBoardMsgBox bC dG fR b s">
              <textarea
                className="messageBox f"
                placeholder="Message"
                rows={rows}
                onChange={handleMessageBoard}
                ref={textBox}
                onKeyPress={(e) => {
                  if(e.key === 'Enter' && !e.shiftKey){sendMessage(); }
                  if(e.key === 'Enter' && !e.shiftKey && checkMessageEligibility(e.value)){ e.preventDefault(); }
                }}/>
              <div className="f bC g fC fR">
                <div
                  className={`messageBoxSendBtn g bC f cC${activeStatus ? " active p" : ''}`}
                  onClick={activeStatus ? () => {sendMessage()} : null}
                >
                    <SendRoundedIcon/>
                </div>
              </div>
          </div>
        </>
    )
  }

  export default MessageBoard;