import { useState, useEffect } from 'react';

import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

export default function RangeSlider(props) {
    const [value, setValue] = useState([props.rangeData.minVal, props.rangeData.maxVal]);

    function convertInt(value) {
      let formatter = Intl.NumberFormat('en', { notation: 'compact' });
      return formatter.format(value);
    }

    const followersMarks = [
        {
            key: "min",
            value: props.fixedMinVal,
            scaledValue: props.fixedMinVal,
            label: convertInt(props.fixedMinVal)
        },
        {
            key: "quarter",
            value: ( props.fixedMaxVal / 4 ),
            scaledValue: ( props.fixedMaxVal / 4 ),
            label: convertInt(( props.fixedMaxVal / 4 ))
        },
        {
            key: "half",
            value: ( props.fixedMaxVal / 2 ),
            scaledValue: ( props.fixedMaxVal / 2 ),
            label: convertInt(( props.fixedMaxVal / 2 ))
        },
        {
            key: "3rd",
            value: ( props.fixedMaxVal * .75 ),
            scaledValue: ( props.fixedMaxVal * .75 ),
            label: convertInt(( props.fixedMaxVal * .75 ))
        },
        {
            key: "max",
            value: props.fixedMaxVal,
            scaledValue: props.fixedMaxVal,
            label: convertInt(props.fixedMaxVal)
        }
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue); // Update the value state variable
        props.functionPointer(props.branch, props?.stem, props.category, [newValue[0], newValue[1]]);
    };

    // useEffect(() => {
    //     setValue([props.rangeData.minVal, props.rangeData.maxVal]);
    // }, [props.rangeData]);

    // useEffect(() => {
    //     setValue([props.rangeData.minVal, props.rangeData.maxVal]);
    // }, [props.rangeData]);

    return (
        <Box sx={{ width: "calc(100% - 64px)" }}>
            <Slider
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                valueLabelFormat={convertInt}
                min={props.fixedMinVal}
                max={props.fixedMaxVal}
                marks={followersMarks}
            />
        </Box>
    );
}