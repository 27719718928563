import React, { useEffect, useState } from 'react';

import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone';
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import RingVolumeTwoToneIcon from '@mui/icons-material/RingVolumeTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import SwapHorizontalCircleTwoToneIcon from '@mui/icons-material/SwapHorizontalCircleTwoTone';

import DownloadResources from "./Download.js";

function ReportingVars(props){
  const localReporting = JSON.parse(localStorage.getItem('reporting')) || {};

  const reportDataHandler = {
    origination: {
      icon: <ShieldTwoToneIcon />,
      branchType: "policies",
      prompt: "Origination Policies",
      stem: "origination",
      type: "inherited",
      hasPath : true,
    },
    summary: {
      icon: <AssessmentTwoToneIcon />,
      branchType: "summary",
      prompt: "Summary",
      stem: "summary",
      type: "dynamic",
      hasPath : false,
    },
    repeating: {
      icon: <EventRepeatIcon />,
      branchType: "repeat",
      prompt: "Repeating",
      stem: "repeat",
      type: "inherited",
      hasPath : true,
    },
    servicing: {
      icon: <SecurityTwoToneIcon />,
      branchType: "policies",
      prompt: "Serviced Policies",
      stem: "servicing",
      type: "inherited",
      hasPath : true,
    },
    bids: {
      icon: <StorefrontTwoToneIcon />,
      branchType: "bids",
      prompt: "Bids",
      stem: "bids",
      type: "inherited",
      driveName : "clarinetRecordID",
      folderName : (rowObj) => { 
        return (`${rowObj?.primaryLastName} ${rowObj?.policyNumber}`) },
      hasPath : true,
    },
    tertiary: {
      icon: <SwapHorizontalCircleTwoToneIcon />,
      branchType: "bids",
      prompt: "Tertiary",
      stem: "tertiary",
      type: "inherited",
      driveName : "clarinetRecordID",
      folderName : (rowObj) => { 
        return (`${rowObj?.primaryLastName} ${rowObj?.policyNumber}`) },
      hasPath : true,
    },
    leads: {
      icon: <RingVolumeTwoToneIcon />,
      branchType: "leads",
      prompt: "Leads",
      stem: "leads",
      type: "inherited",
      hasPath : true,
    },
  };

  const reportSchema = {
    branch : undefined,
    columns : undefined,
    stem : undefined,
    columns : [],
    ID : undefined,
    criteria : {
      current : {
        attr : undefined
      },
      existing : [],
      groupBy : [],
    },
    details: {
      description: undefined,
      displayType: "dynamic",
      editAccessList: ["owner"],
      endDate: undefined,
      name: undefined,
      reoccurType: false,
      scrollType: "pagination",
      shareList: undefined,
      shareType: undefined,
      startDate: undefined,
      system: false,
      viewAccessList: ["owner"],
    },
    query : '',
    subQuery : '',
    showAll : false,
    lastPageIndex : 1,
    lastRecordID : undefined,
    generated : undefined,
    generationTime : undefined,
    group : undefined,
    referenceBranch : undefined,
    referenceStem : undefined,
    referenceRecordID : undefined,
    groupColumns : undefined,
    renderedList : [],
    subReport: {
      list: undefined,
      query: "(recordID not_blank 'true')",
      name : undefined,
      columns : undefined,
    },
    sortedListResults : undefined,
    search : '',
    editingRowValues : [],
    specialColumns : {
      rowIndex : false,
      select : false,
      view : false,
    },
    selectionState : {
      selectedRows: [],
      lastClickedRowIndex: null
    },
  };

  const defaultColumns = {
    "origination": {
      plainArray : [
        "policyStatus",
        "policyNumber",
        "primaryFirstName",
        "primaryLastName",
        "deathBenefit",
        "insuranceCompany"
      ],
      detailedArray : [
        {
          id: "0",
          columnName: "policyStatus",
          friendlyTerm: "Policy Status",
          frozen: false,
          editable : true,
        },
        {
          id: "1",
          columnName: "policyNumber",
          friendlyTerm: "Policy Number",
          mobileFriendlyTerm: "Policy #",
          frozen: false,
          editable : true,
        },
        {
          id: "2",
          columnName: "primaryFirstName",
          friendlyTerm: "Primary First Name",
          frozen: false,
          editable : true,
        },
        {
          id: "3",
          columnName: "primaryLastName",
          friendlyTerm: "Primary Last Name",
          frozen: false,
          editable : true,
        },
        {
          id: "4",
          columnName: "deathBenefit",
          friendlyTerm: "Death Benefit",
          frozen: false,
          editable : true,
        },
        {
          id: "5",
          columnName: "insuranceCompany",
          friendlyTerm: "Insurance Company",
          frozen: false,
          editable : true,
        }
      ]
    },
    "servicing": {
      plainArray : [
        "policyStatus",
        "policyNumber",
        "primaryFirstName",
        "primaryLastName",
        "deathBenefit",
        "insuranceCompany"
      ],
      detailedArray : [
        {
          id: "0",
          columnName: "policyStatus",
          friendlyTerm: "Policy Status",
          frozen: false,
          editable : true,
        },
        {
          id: "1",
          columnName: "policyNumber",
          friendlyTerm: "Policy Number",
          mobileFriendlyTerm: "Policy #",
          frozen: false,
          editable : true,
        },
        {
          id: "2",
          columnName: "primaryFirstName",
          friendlyTerm: "Primary First Name",
          frozen: false,
          editable : true,
        },
        {
          id: "3",
          columnName: "primaryLastName",
          friendlyTerm: "Primary Last Name",
          frozen: false,
          editable : true,
        },
        {
          id: "4",
          columnName: "deathBenefit",
          friendlyTerm: "Death Benefit",
          frozen: false,
          editable : true,
        },
        {
          id: "5",
          columnName: "insuranceCompany",
          friendlyTerm: "Insurance Company",
          frozen: false,
          editable : true,
        }
      ]
    },
    "leads": {
      plainArray : [
        "dateCreated",
        "lastUpdate",
        "leadStatus",
        "primaryFirstName",
        "primaryLastName",
        "age",
        "primaryGender",
        "deathBenefit",
        "unqualifiedReason"
      ],
      detailedArray : [
        {
          id: "0",
          columnName: "Date Created",
          friendlyTerm: "dateCreated",
          frozen: false,
          editable : true,
        },
        {
          id: "1",
          columnName: "lastUpdate",
          friendlyTerm: "Last Update",
          frozen: false,
          editable : true,
        },
        {
          id: "2",
          columnName: "leadStatus",
          friendlyTerm: "Lead Status",
          frozen: false,
          editable : true,
        },
        {
          id: "3",
          columnName: "primaryFirstName",
          friendlyTerm: "First Name",
          frozen: false,
          editable : true,
        },
        {
          id: "4",
          columnName: "primaryLastName",
          friendlyTerm: "Last Name",
          frozen: false,
          editable : true,
        },
        {
          id: "5",
          columnName: "age",
          friendlyTerm: "Age",
          frozen: false,
          editable : true,
        },
        {
          id: "6",
          columnName: "primaryGender",
          friendlyTerm: "Gender",
          frozen: false,
          editable : true,
        },
        {
          id: "7",
          columnName: "deathBenefit",
          friendlyTerm: "Death Benefit",
          frozen: false,
          editable : true,
        },
        {
          id: "8",
          columnName: "unqualifiedReason",
          friendlyTerm: "Unqualified Reason",
          frozen: false,
          editable : true,
        },
      ]
    },
    "bids": {
      plainArray : [
        "dateCreated",
        "shippingStatus",
        "policyNumber",
        "primaryFullName",
        "deathBenefit"
      ],
      detailedArray : [
        {
          id: "0",
          columnName: "dateCreated",
          friendlyTerm: "Date Created",
          frozen: false,
          editable : false,
        },
        {
          id: "1",
          columnName: "group",
          friendlyTerm: "Group",
          frozen: false,
          editable : false,
        },
        {
          id: "2",
          columnName: "shippingStatus",
          friendlyTerm: "Shipping Status",
          frozen: false,
          editable : true,
        },
        {
          id: "3",
          columnName: "policyNumber",
          friendlyTerm: "Policy Number",
          mobileFriendlyTerm: "Policy #",
          frozen: false,
          editable : false,
        },
        {
          id: "4",
          columnName: "primaryFullName",
          friendlyTerm: "Primary Full Name",
          frozen: false,
          editable : false,
        },
        {
          id: "5",
          columnName: "deathBenefit",
          friendlyTerm: "Death Benefit",
          frozen: false,
          editable : false,
        },
      ]
    },
    "tertiary": {
      plainArray : [
        "dateCreated",
        "shippingStatus",
        "policyNumber",
        "primaryFullName",
        "faceValue"
      ],
      detailedArray : [
        {
          id: "0",
          columnName: "dateCreated",
          friendlyTerm: "Date Created",
          frozen: false,
          editable : false,
        },
        {
          id: "1",
          columnName: "group",
          friendlyTerm: "Group",
          frozen: false,
          editable : false,
        },
        {
          id: "2",
          columnName: "shippingStatus",
          friendlyTerm: "Shipping Status",
          frozen: false,
          editable : true,
        },
        {
          id: "3",
          columnName: "policyNumber",
          friendlyTerm: "Policy Number",
          mobileFriendlyTerm: "Policy #",
          frozen: false,
          editable : false,
        },
        {
          id: "4",
          columnName: "primaryFullName",
          friendlyTerm: "Primary Full Name",
          frozen: false,
          editable : false,
        },
        {
          id: "5",
          columnName: "faceValue",
          friendlyTerm: "Face Value",
          frozen: false,
          editable : false,
        },
      ]
    }
  };
  
  const groupBySummaries = {
    string : ["Equal Value", "First Letter", "First Word"],
    date : ["Equal Value", "Day", "Month", "Year", "Quarter"], 
    int : ["Equal Value", "1", "10", "100", "1,000", "10,000", "100,000", "1,000,000"],
    float: ["Equal Value","0.1", "1", "10", "100", "1,000", "10,000", "100,000", "1,000,000"],
    generatedList: ["Equal Value", "First Letter", "First Word"],
    dropdown : ["Equal Value", "First Letter", "First Word"]
  };

  const combineBySummaries = {
    string : ["Count"],
    date : ["Min", "Max", "Count"], 
    int : ["Min", "Max", "Count", "Average", "Sum"],
    float : ["Min", "Max", "Count", "Average", "Sum"],
    generatedList: ["Count"],
    dropdown : ["Count"]
  };

  const [data, setData] = useState({
    selectedReport : reportSchema,
    allReports : undefined,
    reset : reportSchema,
    sorting : [],
    defaultColumns,
    reportGroups : {},
    reportDataHandler,
    dataPointer : "case",
    selectAllVar : "recordID",
    appSourceType : "Case",
    reportList : {
      renderedReportList : [],
      reportCategory : undefined,
      expandedFolders : {},
      expandedYears : {},
      expandedMonths : {},
    },
    groupBySummaries,
    combineBySummaries,
    downloadFileType : localReporting.downloadFileType || "XLSX",
  });

  const ops = {
    "match": (a, b) => a && b && String(a).toLowerCase() === String(b).toLowerCase(),
    "not_match": (a, b) => a && b && String(a).toLowerCase() !== String(b).toLowerCase(),
    "contain": (a, b) => a && b && String(a).toLowerCase().includes(String(b).toLowerCase()),
    "not_contain": (a, b) => a && b && !String(a).toLowerCase().includes(String(b).toLowerCase()),
    "blank": (a, b) => (b === 'true' ? !a : !!a),
    "not_blank": (a, b) => (b === 'true' ? !!a : !a),
    "greater_than": (a, b) => Number(a) > Number(b),
    "less_than": (a, b) => Number(a) < Number(b),
    "before": (a, b) => new Date(a) < new Date(b),
    "after": (a, b) => new Date(a) > new Date(b),
    "in_between": (a, b) => {
      const [start, end] = b.split(" to ");
      const dateA = new Date(a);
      const startDate = new Date(start);
      startDate.setHours(0, 0, 0, 0); // Set to start of the day
      const endDate = new Date(end);
      endDate.setHours(23, 59, 59, 999); // Set to end of the day
  
      return dateA >= startDate && dateA <= endDate;
    },
    "on_or_before": (a, b) => new Date(a) <= new Date(b),
    "on_or_after": (a, b) => new Date(a) >= new Date(b),
  };

  function formulatePath(rowObj, selectedReport){
    const pathData = {
      branch : selectedReport?.referenceBranch ?? selectedReport?.branch,
      stem : selectedReport?.referenceStem ?? selectedReport?.stem,
      pointer : undefined
    }

    if(Array.isArray(selectedReport?.branch) || Array.isArray(selectedReport?.stem)){
      pathData.stem = rowObj?.type;
    }

    if(pathData?.stem === "origination"){
      pathData.branch += "/origination";
      pathData.pointer = rowObj?.recordID;
    }else if(pathData?.stem === "marketing"){
      pathData.branch += "/origination";
      pathData.pointer = rowObj?.recordID;
    }else if(pathData?.stem === "servicing"){
      pathData.branch += "/servicing";
      pathData.pointer = rowObj?.recordID;
    }else if(pathData?.stem === "bids"){
      pathData.pointer = rowObj?.recordID;
    }else if(pathData?.stem === "tertiary"){
      pathData.branch = "tertiary";
      pathData.pointer = rowObj?.recordID;
    }else if(pathData?.stem === "leads"){
      pathData.branch = "leads";
      pathData.pointer = rowObj?.recordID;
    }

    return (`/${pathData?.branch}/${pathData?.pointer}`)
  }

  const updateSelectedReport = (attr, value) => {
    setData((prevState) => ({
      ...prevState,
      selectedReport: {
        ...prevState.selectedReport,
        [attr]: value,
      },
    }));
  }

  function updateRows(session, rows) {
    // Add 'taskID' to each row, equivalent to its 'recordID'
    const updatedRows = rows.map(row => ({
      ...row,  // Spread the original row object
      taskID: row?.recordID  // Add or overwrite 'taskID' with the value of 'recordID'
    }));
  
    const paramVals = {
      tasks: updatedRows,  // Use the updated rows with 'taskID'
    };
  
  
    session?.env?.functions?.buildFetchRequest("marketplace/updateBidStatuses", paramVals)
      .then(response => response.json())
      .then(resData => {
        if (resData.status === 200) {
          // session?.case?.functions?.updateCases(resData?.results, "bids", "bids");
          session?.set?.functions?.updateCases(resData?.results, "bids", "bids");
        }
      });
  }

  function downloadSelectedFiles(rows, session) {
    // Add 'taskID' to each row, equivalent to its 'recordID'
    const updatedRows = rows.map(row => ({
      ...row,  // Spread the original row object
      taskID: row?.recordID  // Add or overwrite 'taskID' with the value of 'recordID'
    }));
  
    const paramVals = {
      tasks: updatedRows,  // Use the updated rows with 'taskID'
    };
  
    const headers = {
      Accept: "application/zip", // Ensure the server knows we expect a ZIP file response
    };

    session?.env?.functions?.buildFetchRequest("marketplace/downloadDriveFolders", paramVals, headers)
    .then(blob => {
      // Create a URL for the Blob object
      const url = window.URL.createObjectURL(blob);
  
      // Create an anchor element and set its attributes for download
      const a = document.createElement('a');
      a.href = url;
      a.download = "downloadedFiles.zip";  // Set the default filename for the download
  
      // Append the anchor to the body, click it to trigger the download, and then remove it
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  
      // Clean up by revoking the Blob URL
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });

  }
  
  const selectRowHandler = (clickedRowIndex, clickedRecordId, rowsList, stem) => (event) => {
    event.preventDefault();
    let newSelectedRows = [...data?.selectionState?.selectedRows];
    const lastClicked = data?.selectionState.lastClicked || {};
    const selectAllVar = data?.selectAllVar;
  
    const updateSelection = (rowObj, shouldSelect) => {
      const rowSelection = {
        recordID: rowObj[selectAllVar],
        folder_name: data?.reportDataHandler?.[stem]?.folderName(rowObj),
        drive_name: rowObj?.[data?.reportDataHandler?.[stem]?.driveName]
      };
      if (shouldSelect && !newSelectedRows.some(row => row.recordID === rowObj[selectAllVar])) {
        newSelectedRows = [...newSelectedRows, rowSelection];
      } else if (!shouldSelect) {
        newSelectedRows = newSelectedRows.filter(row => row.recordID !== rowObj[selectAllVar]);
      }
    };
  
    if (event.shiftKey && lastClicked.rowIndex !== undefined) {
      const rangeStart = Math.min(clickedRowIndex, lastClicked.rowIndex);
      const rangeEnd = Math.max(clickedRowIndex, lastClicked.rowIndex);
      const shouldSelect = !newSelectedRows.some(row => row.recordID === clickedRecordId);
      rowsList.slice(rangeStart, rangeEnd + 1).forEach(rowObj => {
        if (rowObj) updateSelection(rowObj, shouldSelect);
      });
    } else {
      const rowObj = rowsList.find(row => row[selectAllVar] === clickedRecordId);
      if (rowObj) updateSelection(rowObj, !newSelectedRows.some(row => row.recordID === clickedRecordId));
    }

    // Update state once after all selections are processed
    setData(prev => ({
      ...prev,
      selectionState: {
        selectedRows: newSelectedRows,
        lastClicked: { rowIndex: clickedRowIndex, recordID: clickedRecordId }
      }
    }));
  };

  const toggleAllSelectRows = (rowsList, stem, direct) => {
    const allRowIds = rowsList?.map(row => ({
      recordID: row?.[data?.selectAllVar],
      folder_name: data?.reportDataHandler?.[stem]?.folderName(row),
      drive_name: row?.[data?.reportDataHandler?.[stem]?.driveName]
    }));

    const newSelection = direct !== undefined ?
      (direct ? allRowIds : []) :
      rowsList.every(row => data?.selectionState?.selectedRows?.some(selected => selected?.recordID === row?.[data?.selectAllVar])) ? 
      [] : allRowIds;

    setData(prev => ({
      ...prev,
      selectionState: {
        ...prev.selectionState,
        selectedRows: newSelection,
        lastClicked: null
      }
    }));
  };
  
  
  useEffect(() => {
    const handleKeyUp = (event) => {
        if (event.key === 'Shift') {
            // Reset the anchor when the Shift key is released
            setData(prev => ({
                ...prev,
                selectionState: {
                    ...prev.selectionState,
                    lastClicked: null
                }
            }));
        }
    };

    window.addEventListener('keyup', handleKeyUp);

    return () => {
        window.removeEventListener('keyup', handleKeyUp);
    };
  }, [setData]); // Include setData if it's a prop or context to ensure it's the latest function

  function evaluateQuery(query, item) {
    // Handle logical OR operator
    if (query.OR) {
      return query.OR.some(subQuery => evaluateQuery(subQuery, item));
    }
    
    // Handle logical AND operator
    if (query.AND) {
      return query.AND.every(subQuery => evaluateQuery(subQuery, item));
    }

    // Handle comparison operators
    for (let operator in query) {
      if (ops[operator]) {
        for (let field in query[operator]) {
          const queryValue = query[operator][field];
          const itemValue = item[field];

          // Handle the case where the query value is an empty string
          if (queryValue === '') {
            if (operator === 'match' && itemValue !== '') {
              return false; // If 'match' and not empty, return false.
            }
            if (operator === 'not_match' && itemValue === '') {
              return false; // If 'not_match' and empty, return false.
            }
          } else {
            // Handle other comparison operators
            if (!ops[operator](itemValue, queryValue)) {
              return false; // If one comparison fails, return false for the current operator.
            }
          }
        }
      }
    }

    // If we reach this point, then all checks passed for the current query/operator.
    return true;
  }

  function downloadReport(session, selectedReport, reportView){
    const baseData = reportView ? session?.reporting?.data : session?.[selectedReport?.branch]?.data?.[selectedReport?.stem];
    const downloadResources = DownloadResources({session});
    downloadResources.functions.downloadReport({selectedReport, baseData});
  }

  function reportType(stem){
    return reportDataHandler?.[stem]?.type;
  }

  function sessionReportBranches(opportunityTypes){
    const reportablePages = [
      "origination",
      "servicing",
      "bids",
      "tertiary",
      "leads"
    ];

    return(opportunityTypes || []).filter(type => reportablePages.includes(type));
  }

  function filterItemsWithQuery(query, items) {
    if(!query){
      return;
    }

    query = parse(query);
    return items?.filter(item => evaluateQuery(query, item));
  }

  function parse(queryStr) {
    let idx = 0;
    const tokens = [];

    // Normalize query: Replace single quotes with backticks
    const normalizedQueryStr = queryStr.replace(/'([^']*?)'/g, '`$1`');

    // Validate parentheses matching in normalized query
    const openingParentheses = (normalizedQueryStr.match(/\(/g) || []).length;
    const closingParentheses = (normalizedQueryStr.match(/\)/g) || []).length;
    if (openingParentheses !== closingParentheses) {
        throw new Error("Unmatched parentheses in query");
    }

    function tokenize(queryStr) {
        const regex = /(\()|(\))|(AND)|(OR)|(\w+\s+(match|not_match|contain|not_contain|blank|not_blank|greater_than|less_than|before|after|in_between|on_or_before|on_or_after)\s+`([^`]*?)`)/gi;
        let match;
        while ((match = regex.exec(queryStr)) !== null) {
            tokens.push(match[0].trim());
        }
    }

    function parseExpression() {
        const termResult = parseTerm();
        if (tokens[idx] === "OR") {
            idx++;
            return { "OR": [termResult, parseExpression()] };
        }
        return termResult;
    }

    function parseTerm() {
        const factorResult = parseFactor();
        if (tokens[idx] === "AND") {
            idx++;
            return { "AND": [factorResult, parseTerm()] };
        }
        return factorResult;
    }

    function parseFactor() {
        if (tokens[idx] === "(") {
            idx++;
            const expr = parseExpression();
            if (tokens[idx] !== ")") {
                throw new Error("Expected closing parenthesis");
            }
            idx++;
            return expr;
        }
        return parseCondition();
    }

    function parseCondition() {
        const currentToken = tokens[idx];
        if (!currentToken) {
            throw new Error("Invalid condition");
        }

        const firstSpaceIndex = currentToken.indexOf(' ');
        const field = currentToken.substring(0, firstSpaceIndex);
        const rest = currentToken.substring(firstSpaceIndex + 1);
        const operatorMatch = rest.match(/^\s*(\w+)\s+/);

        if (!operatorMatch) {
            throw new Error("Invalid operator in condition: " + currentToken);
        }

        const operator = operatorMatch[1];
        const value = rest
            .substring(operator.length + 1)
            .replace(/^`(.*)`$/, '$1') // Strip surrounding backticks
            .replace(/\\`/g, "`")     // Unescape backticks
            .trim();

        idx++;
        return { [operator]: { [field]: value } };
    }

    try {
        tokenize(normalizedQueryStr); // Use normalized query

        // Validate parentheses within tokens
        const tokenParentheses = tokens.reduce(
            (acc, token) => {
                if (token === "(") acc++;
                if (token === ")") acc--;
                return acc;
            },
            0
        );
        if (tokenParentheses !== 0) {
            throw new Error("Mismatched parentheses in tokens");
        }

        const result = parseExpression();
        if (idx !== tokens.length) {
            console.error("Remaining tokens:", tokens.slice(idx)); // Log remaining tokens
            throw new Error("Unexpected tokens at the end");
        }
        return result;
    } catch (error) {
        console.error("Parsing error:", error.message, "at token index:", idx, "with tokens:", tokens);
        return { error: error.message };
    }
}


const isEqualWithoutAttributes = (objA, objB, pathsToIgnore = []) => {
  const shouldIgnorePath = (path, pathsToIgnore) => {
      return pathsToIgnore.some(ignorePath => {
          if (ignorePath.endsWith('.*')) {
              const basePath = ignorePath.slice(0, -2);
              return path.startsWith(basePath);
          }
          return path === ignorePath;
      });
  };

  const cloneObjectSafely = (obj, path = '', seen = new WeakSet()) => {
      if (obj === null || typeof obj !== 'object' || obj instanceof Date || obj instanceof Function) {
          return obj;
      }
      if (seen.has(obj)) {
          return {}; // Or return a suitable value indicating a circular reference
      }
      seen.add(obj);

      if (Array.isArray(obj)) {
          return obj.map((item, index) => cloneObjectSafely(item, `${path}[${index}]`, seen));
      }

      const clonedObj = {};
      for (const key of Object.keys(obj)) {
          const newPath = path ? `${path}.${key}` : key;
          if (shouldIgnorePath(newPath, pathsToIgnore)) {
              continue;
          }
          const val = obj[key];
          if (val instanceof HTMLElement || typeof val === 'function') {
              continue;
          }
          clonedObj[key] = cloneObjectSafely(val, newPath, seen);
      }
      seen.delete(obj);
      return clonedObj;
  };

  const removeObjectPropertiesByPath = (obj, paths) => {
      if (!paths?.length || !obj) return obj;
      let newObj = cloneObjectSafely(obj); // Use safe cloning method

      paths.forEach(path => {
          const parts = path.split('.');
          const removeProperty = (current, parts) => {
              if (!current || parts?.length === 0) return;
              const part = parts?.[0];
              if (part === '*' && Array.isArray(current)) {
                  current.forEach(item => removeProperty(item, parts.slice(1)));
              } else if (parts.length > 1) {
                  removeProperty(current[part], parts.slice(1));
              } else {
                  delete current[part];
              }
          };

          removeProperty(newObj, parts);
      });

      return newObj;
  };

  const cleanObjA = removeObjectPropertiesByPath(objA, pathsToIgnore);
  const cleanObjB = removeObjectPropertiesByPath(objB, pathsToIgnore);

  return deepEqual(cleanObjA, cleanObjB);
};

// Helper function to deep compare two objects
const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== typeof obj2) return false;

  if (obj1 && typeof obj1 === 'object' && obj2 && typeof obj2 === 'object') {
      if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

      for (const key in obj1) {
          if (!obj2.hasOwnProperty(key)) return false;
          if (!deepEqual(obj1[key], obj2[key])) return false;
      }

      return true;
  }

  return false;
};

  function requestResultBlock(request, requestObject){
    return;
  
    if (requestObject?.requestingBatch) {
        return;
    }

    const paramVals = {
        queryArray : parse(request?.newQuery ?? data?.selectedReport?.query) ?? undefined,
        offset : request?.newOffset ?? requestObject?.pagination?.index,
        limit : request?.newLimit ?? requestObject?.pagination?.rowMax,
        columns : request?.newColumns ?? data?.selectedReport?.columns,
        sorting : request?.newSorting ?? data?.sorting,
    };

    requestObject?.setRequestingBatch(true);

    requestObject?.session?.env?.functions?.buildFetchRequest("individual/getMany", paramVals)
    .then(response => response.json())
    .then(resData => {
        if(resData.status === 200){
            updateSelectedReport("list", resData?.individuals);
            updateSelectedReport("totalFound", resData?.totalFound);
            requestObject?.setExistingColumnsCopy(request?.newColumns ?? data?.selectedReport?.columns);
            updateSelectedReport("lastPageIndex", requestObject?.pagination?.index);
        }
        requestObject?.setRequestingBatch(false);
    });
  }

  function buildQuery(criteria) {
    let query = '';
    let openBracket = false;

    if (!criteria || !criteria?.length) {
        return;
    }

    const criteriaWithOriginalKeys = criteria?.map((attribute, index) => ({
        originalIndex: index, // Add the original index to each element
        ...attribute, // Copy the rest of the attributes
    }));

    criteriaWithOriginalKeys.sort((a, b) => a.groupID - b.groupID);
    criteriaWithOriginalKeys.map((attribute) => {
        const parentKeys = criteriaWithOriginalKeys.filter((attr) => {
            const currAttr = attr;
            const currAttrCriterion = Object?.keys(currAttr?.criterion)?.[0];
            const currAttrCriterionValue = currAttr?.criterion[currAttrCriterion];
            const isAttrInBetween = currAttrCriterion === "in_between";
            const inBetweenCatch = isAttrInBetween && (currAttrCriterionValue?.startDate && currAttrCriterionValue?.endDate);

            return currAttr?.relativeKey === undefined &&
                !currAttr?.inactive &&
                (currAttr?.formType === "generatedList" ?
                    currAttrCriterionValue !== undefined
                    :
                    (currAttrCriterionValue !== undefined && currAttrCriterionValue !== '') && (isAttrInBetween ? inBetweenCatch : true));
        });

        const firstParentKey = attribute === parentKeys?.[0];
        const index = attribute.originalIndex;
        const currentIndex = parentKeys.findIndex(item => item.originalIndex === index);
        const nextGroup = parentKeys[currentIndex + 1];
        const baseCriteria = criteria?.[index];
        const criterion = Object.keys(baseCriteria.criterion)[0];
        const criterionValue = baseCriteria?.criterion?.[criterion];

        if (baseCriteria?.relativeKey !== undefined || !baseCriteria?.attr ||
            baseCriteria?.inactive === true || !criterion || (baseCriteria?.formType === "generatedList" && criterionValue === undefined) ||
            (baseCriteria?.formType !== "generatedList" && (criterionValue === undefined || criterionValue === '')) ||
            (criterion === "in_between" && (!criterionValue?.startDate || !criterionValue?.endDate))) {
            return null;
        } else {
            if (nextGroup?.groupOperator === "AND" && !openBracket && currentIndex === 0) {
                query += "(";
                openBracket = true;
            }

            query += firstParentKey ? "(" : ` ${baseCriteria?.groupOperator} (`;
            if (nextGroup?.groupOperator === "AND" && !openBracket && currentIndex > 0) {
                query += "(";
                openBracket = true;
            }

            if (criterion === "in_between") {
                query += `${baseCriteria?.attr} ${criterion} \`${criterionValue?.startDate}\` to \`${criterionValue?.endDate}\``;
            } else {
                query += `${baseCriteria?.attr} ${criterion} \`${criterionValue}\``;
            }
        }

        const childAttributes = criteria.filter(
            (childAttribute) => childAttribute.relativeKey === index
        );

        if (childAttributes.length > 0) {
            criteria.map((childAttribute, childIndex) => {
                const siblingCriteria = criteria?.[childIndex];
                const siblingCriterion = Object.keys(siblingCriteria?.criterion)[0];
                const siblingCriterionValue = siblingCriteria?.criterion?.[siblingCriterion];

                if (!siblingCriteria || !siblingCriteria?.attr || siblingCriteria?.inactive || (siblingCriteria?.formType === "generatedList" && siblingCriterionValue === undefined) ||
                    (siblingCriteria?.formType !== "generatedList" && (siblingCriterionValue === undefined || siblingCriterionValue === '')) ||
                    siblingCriterion === "in_between" && (!siblingCriterionValue?.startDate || !siblingCriterionValue?.endDate)) {
                    return null;
                }

                if (childAttribute?.relativeKey === index) {
                    query += ` ${baseCriteria?.inlineOperator} `;
                    if (siblingCriterion === "in_between") {
                        query += `${siblingCriteria?.attr} ${siblingCriterion} \`${siblingCriterionValue.startDate}\` to \`${siblingCriterionValue.endDate}\``;
                    } else {
                        query += `${siblingCriteria?.attr} ${siblingCriterion} \`${siblingCriterionValue}\``;
                    }
                }
            });
        }

        query += ")";
        if (nextGroup?.groupOperator !== "AND" && openBracket || !nextGroup?.groupOperator && openBracket) {
            query += ")";
            openBracket = false;
        }
    });

    if (openBracket) {
        query += ")";
        openBracket = false;
    }

    return query;
  }

  //Universal Filtering/Toggles
  const defaultConfig = [{
    "attr": "recordID",
    "criterion": { "not_blank": true },
    "formType": "int",
    "groupID": 1,
    "groupOperator": "AND",
    "inlineOperator": "AND"
  }];

  function blankCriteria(config) {
    return JSON.stringify(config?.existingCriteria) === JSON.stringify(config?.blankCriteriaConfig ?? defaultConfig);
  }

  const omitRelativeKey = (obj) => {
      if (!obj) return obj;
      const { relativeKey, ...rest } = obj;
      return rest;
  };

  const groupCriteria = (valueList, attr, operator) =>
    valueList.map((value) => ({ attr, operator, value }));

  function updateCriteria(config, criteria) {
      // Normalize the input to always be an array
      const criteriaArray = Array.isArray(criteria) ? criteria : [criteria];

      let tempCriteria = [...config?.existingCriteria];

      // If blank criteria, start with an empty array
      if (blankCriteria(config)) tempCriteria = [];

      // Check if all criteria in the array exist
      const allExist = criteriaArray.every((criterion) =>
          existingCriterion(config, criterion)
      );

      if (allExist) {
          // Remove all criteria in the array
          tempCriteria = tempCriteria.filter(
              (existingCriterion) =>
                  !criteriaArray.some(
                      (criterion) =>
                          JSON.stringify(omitRelativeKey(existingCriterion)) ===
                          JSON.stringify(omitRelativeKey(criterion))
                  )
          );
      } else {
          // Add all criteria in the array
          criteriaArray.forEach((criterion) => {
              const isFirstCriterion = tempCriteria.length === 0;
              if (
                  !existingCriterion(config, criterion) &&
                  !tempCriteria.some(
                      (existingCriterion) =>
                          JSON.stringify(omitRelativeKey(existingCriterion)) ===
                          JSON.stringify(omitRelativeKey(criterion))
                  )
              ) {
                  tempCriteria.push({
                      ...criterion,
                      relativeKey: isFirstCriterion ? undefined : 0,
                  });
              }
          });
      }

      // If tempCriteria is empty, reset to blankCriteriaConfig
      if (tempCriteria.length === 0) tempCriteria = config?.blankCriteriaConfig ?? defaultConfig;

      // Reorder the criteria for consistent indices
      const reorderedCriteria = tempCriteria.map((item, index) => ({
          ...item,
          relativeKey: index === 0 ? undefined : item?.relativeKey,
      }));

      // Update the state and session
      if(config?.setExistingCriteria) config?.setExistingCriteria(reorderedCriteria);

      const query = buildQuery(reorderedCriteria);

      // console.log(config, config?.baseModule, ["selectedReport.criteria.existing", "selectedReport.query", if(config?.refresh) "selectedReport.loaded"], [reorderedCriteria, query, false]);
      config?.session?.set(
        config?.baseModule,
        ["selectedReport.criteria.existing", "selectedReport.query", ...(config?.refresh ? ["selectedReport.loaded"] : [])],
        [reorderedCriteria, query, ...(config?.refresh ? [false] : [])]
      );
  }    

  const existingCriterion = (config, criterionToCheck) => {

    const filteredExistingCriteria = Object.values(config?.existingCriteria).map(omitRelativeKey);

    // Normalize criterionToCheck to always be an array
    const criteriaToCheck = Array.isArray(criterionToCheck) ? criterionToCheck : [criterionToCheck];
    const filteredCriteriaToCheck = criteriaToCheck.map(omitRelativeKey);

    // Count how many criteria are included in existingCriteria
    return filteredCriteriaToCheck.reduce((count, filteredCriterion) => {
        const isIncluded = filteredExistingCriteria.some((existing) =>
            JSON.stringify(existing) === JSON.stringify(filteredCriterion)
        );
        return count + (isIncluded ? 1 : 0); // Increment count if the criterion is included
    }, 0);
  };

  const toggleCriteria = (config, criteria, resetBeforeUpdate = false) => {
    const criteriaArray = Array.isArray(criteria) ? criteria : [criteria];
  
    // Reset to blank criteria if the flag is set
    if (resetBeforeUpdate) {
      config.existingCriteria = config.blankCriteriaConfig ?? defaultConfig;
    }
  
    const includedCount = existingCriterion(config, criteriaArray);
    const toggleOn = includedCount < criteriaArray.length;
  
    const criteriaToUpdate = criteriaArray.map(({ attr, operator, value }) => ({
      ...config?.criterionTemplate(attr, operator, value),
      relativeKey: toggleOn && config?.existingCriteria.length > 0 ? 0 : undefined,
    }));
  
    updateCriteria(config, criteriaToUpdate); // Bulk update all criteria
  };

  const dynamicCriterionArray = (config, itemList, criterionAttr, criterionOperator) => {
    const normalizedItemList = Array.isArray(itemList)
        ? itemList.reduce((acc, item, index) => ({ ...acc, [index]: item }), {})
        : itemList || {};

    // Build the array of criterionTemplate objects
    const criteriaArray = Object.entries(normalizedItemList)
        .map(([key, value]) =>
            config?.criterionTemplate(
                criterionAttr,
                criterionOperator,
                value
            )
        );

    return criteriaArray;
  };

  const functions = {
    filterItemsWithQuery,
    parse,
    updateSelectedReport,
    reportType,
    isEqualWithoutAttributes,
    requestResultBlock,
    sessionReportBranches,
    formulatePath,
    updateRows,
    downloadSelectedFiles,
    selectRowHandler,
    downloadReport,
    toggleAllSelectRows,
    buildQuery,
    blankCriteria,
    omitRelativeKey,
    updateCriteria,
    existingCriterion,
    toggleCriteria,
    dynamicCriterionArray,
    groupCriteria,
  }

  const reportingVars = {
    data,
    setData,
    functions,
  }

  return reportingVars;
};

export default ReportingVars;