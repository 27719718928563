import React from 'react';
import { useState, useEffect, useRef } from 'react';

// import LeadBlockKanban from './LeadBlockKanban';
import LeadBlock from './LeadBlock';
import UpdateLeadPanel from './UpdateLeadPanel';
import FilterLeadPanel from './FilterLeadPanel';
import LeadsListAll from './LeadsListAll';
import MobileKanbanHeader from './MobileKanbanHeader';
import DefaultKanbanHeader from './DefaultKanbanHeader';

import PaidIcon from '@mui/icons-material/Paid';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FilterListIcon from '@mui/icons-material/FilterList';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupsIcon from '@mui/icons-material/Groups';
import { green } from '@mui/material/colors';

function LeadsOverview(props){
    const kanbanLeads = props.leadProps?.kanbanLeads;
    const kanbanContactAttempted =  props.leadProps?.kanbanContactAttempted;
    const kanbanInProgress =  props.leadProps?.kanbanInProgress;

    function handleScroll(event, kanbanColumn) {

        const element = event.target;

        if(kanbanColumn === "lead"){
            props.leadProps?.setYScrollKanban(prevState => ({
                ...prevState,
                lead: element.scrollTop
            }));
        }

        if(kanbanColumn === "contactAttempted"){
            props.leadProps?.setYScrollKanban(prevState => ({
                ...prevState,
                contactAttempt: element.scrollTop
            }));
        }

        if(kanbanColumn === "inProgress"){
            props.leadProps?.setYScrollKanban(prevState => ({
                ...prevState,
                inProgress: element.scrollTop
            }));
        }

        if(kanbanColumn === "lead" && props.leadProps?.kanbanLeadVisibleThreshold > props.leadProps?.leadBlockKanbanData?.leadCount){
            return;
        }

        if(kanbanColumn === "contactAttempted" && props.leadProps?.kanbanContactAttemptedVisibleThreshold > props.leadProps?.leadBlockKanbanData?.contactAttemptCount){
            return;
        }

        if(kanbanColumn === "inProgress" && props.leadProps?.kanbanInProgressVisibleThreshold > props.leadProps?.leadBlockKanbanData?.inProgressCount){
            return;
        }

        if(element.scrollTop === 0){
            return;
        }

        const isScrollHeightDouble = element.scrollHeight >= 2 * element.offsetHeight;

        if (element.scrollHeight - element.scrollTop <= element.clientHeight + 100 && isScrollHeightDouble) {
            if(kanbanColumn === "lead"){
                props.leadProps?.setKanbanLeadVisibleThreshold(props.leadProps?.kanbanLeadVisibleThreshold + 48);
            }

            if(kanbanColumn === "contactAttempted"){
                props.leadProps?.setKanbanContactAttemptedVisibleThreshold(props.leadProps?.kanbanContactAttemptedVisibleThreshold + 48);
            }

            if(kanbanColumn === "inProgress"){
                props.leadProps?.setKanbanInProgressVisibleThreshold(props.leadProps?.kanbanInProgressVisibleThreshold + 48);
            }
        }
    }

    function currentActivityView(view){
        if(view === props.leadProps?.activityView){
            return " selected";
        }

        return '';
    }

    function convertCurrency(int){
        const formatCash = n => {
            if (n < 1e3) return n;
            if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
            if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
            if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
            if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
          };

          if(formatCash(int) === 0){
            return "-";
          }else{
            return formatCash(int);
          }
    }

    function toggleTeamFilter(){
        if(props.leadProps?.leadTeamFilter === "assignedOnly"){
            props.leadProps?.setLeadTeamFilter("all");
        }else{
            props.leadProps?.setLeadTeamFilter("assignedOnly");
        }

        const togglesToModify = ["Lead", "Contact Attempted 1", "Contact Attempted 2", "Application Sent", "Application Received", "Follow Up"];

        props.leadProps?.setKanbanToggles(prevToggles => {
            const togglesToAdd = togglesToModify.filter(toggle => !prevToggles.includes(toggle));
            return prevToggles.concat(togglesToAdd);
        });

        props.leadProps?.setKanbanNotificationToggles([]);
    }

    function notificationStatus(notification){
        if (props.leadProps?.kanbanNotificationToggles.includes(notification)){
            return " active";
        }

        return '';
    }

    function toggleNotification(notification){
        if(notification === "leadMissed" || notification === "leadActive"){
            props.leadProps?.resetYScrolls("kanbanLead");
        }
        if(notification === "contactAttemptedMissed" ||  notification === "contactAttemptedActive"){
          props.leadProps?.resetYScrolls("kanbanContactAttempt");
        }
        if(notification === "inProgressMissed" || notification === "inProgressActive"){
            props.leadProps?.resetYScrolls("kanbanInProgress");
        }

        if (props.leadProps?.kanbanNotificationToggles.includes(notification)){
            props.leadProps?.setKanbanNotificationToggles(props.leadProps?.kanbanNotificationToggles.filter((item) => item !== notification));
        }else{
            props.leadProps?.setKanbanNotificationToggles([...props.leadProps?.kanbanNotificationToggles, notification]);
        }
    }

    useEffect(() => {
        if(props.leadProps.activityView === "kanban"){
            props.leadProps.kanbanLeads.current.scrollTop = props.leadProps?.yScrollKanban?.lead;
            props.leadProps.kanbanContactAttempted.current.scrollTop = props.leadProps?.yScrollKanban?.contactAttempt;
            props.leadProps.kanbanInProgress.current.scrollTop = props.leadProps?.yScrollKanban?.inProgress;
        }
    }, [props.leadProps.activityView]);

    useEffect(() => {
        props.leadProps?.printLeadsKanbanBoard();
        props.leadProps?.printListView();
    }, [props.leadProps?.leadsList,
        props.leadProps?.showLeadOptions,
        props.leadProps?.listAllVisibleThreshold,
        props.leadProps?.kanbanLeadVisibleThreshold,
        props.leadProps?.kanbanContactAttemptedVisibleThreshold,
        props.leadProps?.kanbanInProgressVisibleThreshold]);

    return (
        <div className="leadsOverview moduleBlock">
            { props.leadProps?.showLeadFilters !== false ?
                <FilterLeadPanel
                    showLeadFilters={props.leadProps?.showLeadFilters}
                    setShowLeadFilters={props.leadProps?.setShowLeadFilters}
                    handleClose={props.leadProps?.handleClose}
                    changeActivityView={props.leadProps?.changeActivityView}
                    leadsList={props.leadProps?.leadsList}
                    setLeadsList={props.leadProps?.setLeadsList}
                    sessionUser={props.sessionUser}
                    updatePage={props.updatePage}
                    leadTeamFilter={props.leadProps?.leadTeamFilter}
                    ignoredTeamList={props.leadProps?.ignoredTeamList}
                    setIgnoredTeamList={props.leadProps?.setIgnoredTeamList}
                    leadFilterPanelType={props.leadProps?.leadFilterPanelType}
                    kanbanToggles={props.leadProps?.kanbanToggles}
                    setKanbanToggles={props.leadProps?.setKanbanToggles}
                    leadsCategoryToggle={props.leadProps?.leadsCategoryToggle}
                    leadProps={props.leadProps}
                    session={props?.session}
                />
            : null }
            { props.leadProps?.showLeadOptions !== false ?
                <UpdateLeadPanel
                    showLeadOptions={props.leadProps?.showLeadOptions}
                    setShowLeadOptions={props.leadProps?.setShowLeadOptions}
                    currentLeadInfo={props.leadProps?.currentLeadInfo}
                    setCurrentLeadInfo={props.leadProps?.setCurrentLeadInfo}
                    handleClose={props.leadProps?.handleClose}
                    changeActivityView={props.leadProps?.changeActivityView}
                    leadsList={props.leadProps?.leadsList}
                    setLeadsList={props.leadProps?.setLeadsList}
                    sessionUser={props.sessionUser}
                    updatePage={props.updatePage}
                    session={props?.session}
                />
            : null }
            <div className="leadsTopSection ns">
                <div className="leadsViewSelector cR g fR fC f">
                    <div className={"switchViewBtn f g cC s e" + currentActivityView("kanban")}
                        onClick={()=>{props.leadProps?.changeActivityView("kanban")}}>
                        <div className="switchViewBtnOption">
                            Flow Chart
                        </div>
                        <div className="inlineBtnIcon">
                            <ViewKanbanIcon/>
                        </div>
                    </div>
                    <div className={"switchViewBtn f g cC s e" + currentActivityView("list")}
                        onClick={()=>{props.leadProps?.changeActivityView("list")}}>
                        <div className="switchViewBtnOption">
                            List All
                        </div>
                        <div className="inlineBtnIcon"><ListAltIcon/></div>
                    </div>
                </div>
                <div className="leadsSearchBarContainer s e">
                    <div className="leadsSearchBarSwitch"
                        onClick={()=>{toggleTeamFilter()}}>
                        <div className="listAltercationOptionString">
                            <div className="leadTeamFilterPrompt">
                                {props.leadProps?.leadTeamFilter === "assignedOnly" ?
                                    "My leads "
                                    :
                                    "All leads"
                                }
                            </div>
                            {props.leadProps?.leadTeamFilter === "assignedOnly" ?
                                <AccountCircleIcon className="filterBtn"/>
                            :
                                <GroupsIcon className="filterBtn"/>
                            }
                        </div>
                    </div>
                    <input className="leadsSearchBar" placeholder="Search by Client Name"
                        onChange={(evt) => {props.leadProps?.updateLeadsSearchVal(evt)}}
                        value={props.leadProps?.leadsSearchVal}/>
                </div>
                <div className="leadsViewFilters">
                    { props.leadProps?.leadTeamFilter === "all" && props.leadProps?.activityView === "kanban" ?
                        <div className="listAltercationOption" onClick={()=>{props.leadProps?.toggleLeadFilters("teamList")}}>
                            <div className="listAltercationOptionString">
                                <div className="leadTeamFilterPrompt">
                                    Team List
                                </div>
                                <AssignmentIcon className="filterBtn"/>
                            </div>
                        </div>
                    : <br/> }
                    {props.leadProps?.activityView === "kanban" ?
                        <div className="listAltercationOption" onClick={()=>{props.leadProps?.toggleLeadFilters("toggle")}}>
                            <div className="listAltercationOptionString">
                                <div className="leadTeamFilterPrompt">
                                    Filter
                                </div>
                                <FilterListIcon className="filterBtn"/>
                            </div>
                        </div>
                    :  props.leadProps?.leadTeamFilter === "all" ?
                        <div className="listAltercationOption" onClick={()=>{props.leadProps?.toggleLeadFilters("teamList")}}>
                            <div className="listAltercationOptionString">
                                <div className="leadTeamFilterPrompt">
                                    Team List
                                </div>
                                <AssignmentIcon className="filterBtn"/>
                            </div>
                        </div>
                    : null
                }
                </div>
            </div>
            {props.leadProps?.activityView === "kanban" ?
                <div className="leadsKanbanBoard">
                    { props.mobileDevice ?
                        <MobileKanbanHeader
                            notificationStatus={notificationStatus}
                            toggleNotification={toggleNotification}
                            leadBlockKanbanData={props.leadProps?.leadBlockKanbanData}
                            currentColumnCheck={props.leadProps?.currentColumnCheck}
                            currentColumn={props.leadProps?.currentColumn}
                            setCurrentColumn={props.leadProps?.setCurrentColumn}
                        />
                    :
                        <DefaultKanbanHeader
                            notificationStatus={notificationStatus}
                            toggleNotification={toggleNotification}
                            leadBlockKanbanData={props.leadProps?.leadBlockKanbanData}
                        />
                    }
                    <div className="leadsCategoryHeader">
                        <div className="leadsCategoryHeaderInnerWrap">
                            <div className={!props.mobileDevice ? "lead" : props.leadProps?.currentColumnCheck("lead") === " inactive" ? "lead inactive" : "lead selected"}>
                                <div className="categoryPrompt">
                                    {props.leadProps?.leadBlockKanbanData?.leadCount ? props.leadProps?.leadBlockKanbanData?.leadCount + "  Leads in Queue" : null}
                                </div>
                                <div className="categoryValue">
                                    <PaidIcon sx={{ color: green[500] }}/>
                                    <div className="categoryValueInt">
                                        {convertCurrency(props.leadProps?.leadBlockKanbanData?.leadTotal)}
                                    </div>
                                </div>
                            </div>
                            <div className={!props.mobileDevice ? "contactAttempted" : props.leadProps?.currentColumnCheck("contactAttempted") === " inactive" ? "contactAttempted inactive" : "contactAttempted selected"}>
                                <div className="categoryPrompt">
                                    {props.leadProps?.leadBlockKanbanData?.contactAttemptCount ? props.leadProps?.leadBlockKanbanData?.contactAttemptCount + "  Contacts Attempted" : null}
                                </div>
                                <div className="categoryValue">
                                    <PaidIcon sx={{ color: green[500] }}/>
                                    <div className="categoryValueInt">
                                        {convertCurrency(props.leadProps?.leadBlockKanbanData?.contactAttemptTotal)}
                                    </div>
                                </div>
                            </div>
                            <div className={!props.mobileDevice ? "inProgress" : props.leadProps?.currentColumnCheck("inProgress") === " inactive" ? "inProgress inactive" : "inProgress selected"}>
                                <div className="categoryPrompt">
                                    {props.leadProps?.leadBlockKanbanData?.inProgressCount ? props.leadProps?.leadBlockKanbanData?.inProgressCount + "  Leads in Progress" : null}
                                </div>
                                <div className="categoryValue">
                                    <PaidIcon sx={{ color: green[500] }}/>
                                    <div className="categoryValueInt">
                                        {convertCurrency(props.leadProps?.leadBlockKanbanData?.inProgressTotal)}
                                    </div>
                                </div>
                            </div>
                            <div className={!props.mobileDevice ? "closed" : props.leadProps?.currentColumnCheck("closed") === " inactive" ? "closed inactive" : "closed selected"}>
                                <div className="categoryPrompt">
                                    {props.leadProps?.leadBlockKanbanData?.abacusCount + props.leadProps?.leadBlockKanbanData?.insuranceCount + props.leadProps?.leadBlockKanbanData?.closedCount + " Closed"}
                                </div>
                                <div className="categoryValue">
                                    <PaidIcon sx={{ color: green[500] }}/>
                                    <div className="categoryValueInt">
                                        {convertCurrency(props.leadProps?.leadBlockKanbanData?.abacusTotal + props.leadProps?.leadBlockKanbanData?.insuranceTotal + props.leadProps?.leadBlockKanbanData?.closedTotal)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="leadsDeatilsBoard">
                        <div className={"leadBlockColumn t b lead" + props.leadProps?.currentColumnCheck("lead")} ref={kanbanLeads} onScroll={(evt)=>{handleScroll(evt, "lead")}}>
                            {!props.mobileDevice || props.mobileDevice && props.leadProps?.currentColumnCheck("lead") === " selected" ?
                                props.leadProps?.categoryLeadList?.length === 0 ?
                                <div className="emptyKanbanCategory ns">
                                    <div className="emptyKanbanMessage">
                                        No leads in the queue
                                    </div>
                                </div>
                                : props.leadProps?.categoryLeadList
                            : null
                            }
                        </div>
                        <div className={"leadBlockColumn t b contactAttempted" + props.leadProps?.currentColumnCheck("contactAttempted")} ref={kanbanContactAttempted} onScroll={(evt)=>{handleScroll(evt, "contactAttempted")}}>
                            {!props.mobileDevice || props.mobileDevice && props.leadProps?.currentColumnCheck("contactAttempted") === " selected" ?
                                props.leadProps?.categoryContactAttemptList?.length === 0 ?
                                <div className="emptyKanbanCategory ns">
                                    <div className="emptyKanbanMessage">
                                        No leads being contacted
                                    </div>
                                </div>
                                : props.leadProps?.categoryContactAttemptList                   
                            : null
                            }

                        </div>
                        <div className={"leadBlockColumn t b inProgress" + props.leadProps?.currentColumnCheck("inProgress")} ref={kanbanInProgress} onScroll={(evt)=>{handleScroll(evt, "inProgress")}}>
                            {!props.mobileDevice || props.mobileDevice && props.leadProps?.currentColumnCheck("inProgress") === " selected" ?
                                props.leadProps?.categoryInProgress?.length === 0 ?
                                <div className="emptyKanbanCategory ns">
                                    <div className="emptyKanbanMessage">
                                        No leads in progress
                                    </div>
                                </div>
                                : props.leadProps?.categoryInProgress
                            : null
                        }
                        </div>
                        {!props.mobileDevice || props.mobileDevice && props.leadProps?.currentColumnCheck("closed") === " selected" ?

                        <div className="leadBlockColumn closed">
                            <LeadBlock
                                closedCount={props.leadProps?.leadBlockKanbanData?.abacusCount}
                                setActivityView={props.leadProps?.setActivityView}
                                totalDeathBenefit={props.leadProps?.leadBlockKanbanData?.abacusTotal}
                                changeActivityView={props.leadProps?.changeActivityView}
                                leadBlockType="closed"
                                closedType="abacus"
                                onClick={props.leadProps?.leadsCategoryToggle}
                                session={props?.session}
                            />
                            <LeadBlock
                                closedCount={props.leadProps?.leadBlockKanbanData?.insuranceCount}
                                setActivityView={props.leadProps?.setActivityView}
                                totalDeathBenefit={props.leadProps?.leadBlockKanbanData?.insuranceTotal}
                                changeActivityView={props.leadProps?.changeActivityView}
                                leadBlockType="closed"
                                closedType="insurance"
                                onClick={props.leadProps?.leadsCategoryToggle}
                                session={props?.session}
                            />
                            <LeadBlock
                                closedCount={props.leadProps?.leadBlockKanbanData?.closedCount}
                                setActivityView={props.leadProps?.setActivityView}
                                totalDeathBenefit={props.leadProps?.leadBlockKanbanData?.closedTotal}
                                changeActivityView={props.leadProps?.changeActivityView}
                                leadBlockType="closed"
                                closedType="lost"
                                onClick={props.leadProps?.leadsCategoryToggle}
                                session={props?.session}
                            />
                        </div>
                        : null
                        }
                    </div>
                </div>
                : null }
            { props.leadProps?.activityView === "list" ?
                <LeadsListAll
                    rangeData = {props.leadProps?.leadsListPriceRange}
                    functionPointer={props.leadProps?.updateLeadsListPriceRange}
                    fixedMin={props.leadProps?.leadsListPriceRange?.fixedMinVal}
                    fixedMax={props.leadProps?.leadsListPriceRange?.fixedMaxVal}
                    setListToggles={props.leadProps?.setListToggles}
                    printListView={props.leadProps?.printListView}
                    listToggles={props.leadProps?.listToggles}
                    leadBlockListData={props.leadProps?.leadBlockListData}
                    viewAllList={props.leadProps?.viewAllList}
                    listAllVisibleThreshold={props.leadProps?.listAllVisibleThreshold}
                    setListAllVisibleThreshold={props.leadProps?.setListAllVisibleThreshold}
                    leadsCategoryToggle={props.leadProps?.leadsCategoryToggle}
                    sessionUser={props.sessionUser}
                    leadProps={props.leadProps}
                    session={props?.session}
                />
            : null }
        </div>
    )
}

export default LeadsOverview;