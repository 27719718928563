import React from 'react';
import { useState, useEffect, useRef } from 'react';

import './FilterPanel.css';

import { parseJWT } from '../../envFunctions.js';

import CloseIcon from '@mui/icons-material/Close';
import RangeSlider from './RangeSlider.js';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InboxIcon from '@mui/icons-material/Inbox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import CampaignIcon from '@mui/icons-material/Campaign';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import NumbersIcon from '@mui/icons-material/Numbers';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';

import { red, purple, blue, blueGrey, green, grey, orange} from '@mui/material/colors';
import FilterLeadPanel from '../../LeadsDashboard/FilterLeadPanel.js';

function FilterPanel(filterProps) {
    const filterPanelHandler = {
        functions : {
            printRangeCategory,
            iterateCategories,
            printBinaryCategory,
            sortDisplay,
            buildFilterSections,
        }
    }

    const [visibleSections, setVisibleSections] = useState({});
    const filterHandler = filterProps?.filterHandler;
    const filterFunctions = filterHandler?.functions;
    const filterBranch = filterProps?.filterBranch;
    const filterBranchStem = filterProps?.filterBranchStem;

    const showBinary = {
        category : true,
        status : true,
    }

    const stages = [
        {
            className: "stage1",
            count: filterProps?.kanbanProps?.stage1Count,
            maxCount : filterBranchStem?.stage1MaxCount,
            icon : <InboxIcon/>
        },
        {
            className: "stage2",
            count: filterProps?.kanbanProps?.stage2Count,
            maxCount : filterBranchStem?.stage2MaxCount,
            icon : <FlagCircleIcon/>
        },
        {
            className: "stage3",
            count: filterProps?.kanbanProps?.stage3Count,
            maxCount : filterBranchStem?.stage3MaxCount,
            icon : <CampaignIcon/>
        }
    ];

    function printRangeCategory(category){
        const categoryData = filterFunctions.groupData(category);

        function convertInt(value) {
            let formatter = Intl.NumberFormat('en', { notation: 'compact' });
            return formatter.format(value);
        }

        let rangeData = {
            minVal : filterBranchStem?.[category]?.minVal,
            maxVal : filterBranchStem?.[category]?.maxVal
        }

        return (
            rangeData?.minVal && rangeData?.maxVal ? (
                <div key={category} className="category range g fC f cC ns">
                    <div className="rangeHeader f g">
                        <div className="f cC">
                            {categoryData.icon}
                        </div>
                        <div className="f cL bold">
                            {categoryData.prompt + " " + convertInt(filterBranchStem?.[category]?.minVal) + " - " + convertInt(filterBranchStem?.[category]?.maxVal)}
                        </div>
                        {filterBranchStem?.[category]?.empty?.count > 0 && (
                            <>
                                <div
                                    className={filterBranchStem?.[category]?.empty?.active ? "f cR p gC4" : "inactive f cR p gC4"}
                                    onClick={() => {filterFunctions?.updateFilterAttr(null, filterProps?.branch, filterProps?.stem, category, "empty", "active") }}>
                                    {"Include " + filterBranchStem?.[category]?.empty?.count + " Empty Values"}
                                </div>
                                <div
                                    className="f cC p oH" onClick={() => { filterFunctions?.updateFilterAttr(null, filterProps?.branch, filterProps?.stem, category, "empty", "active") }}>
                                    {filterBranchStem?.[category]?.empty?.active ? <CheckCircleIcon sx={{ color: "#00BFFF" }} /> : <RadioButtonUncheckedIcon sx={{ color: "#E2E2E2" }} />}
                                </div>
                            </>
                        )}
                    </div>
                    <RangeSlider
                        rangeData={rangeData}
                        functionPointer={filterFunctions?.alterRange}
                        fixedMinVal={filterBranchStem?.[category]?.fixedMinVal}
                        fixedMaxVal={filterBranchStem?.[category]?.fixedMaxVal}
                        branch={filterProps?.branch}
                        stem={filterProps?.stem}
                        category={category}
                        reset={filterHandler?.functions?.alterRange}
                        prompt={prompt}
                    />
                </div>
            )
            : null
        );
    }

    function generateRandomKey() {
        // This generates a random string of characters
        // You can customize the length and characters as needed
        const length = 10;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
      
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters.charAt(randomIndex);
        }
      
        // console.log(result);
        return result;
    }

    function iterateCategories(group, category, categoryRows, nested) {
        let currentLetter = '';
        let subRows = [];
        let uniqueIndex = 0;
        //console.log("group: ", group, "category: ", category, "categoryRows: ", categoryRows, "nested: ", nested);
        Object.keys(nested ? group?.[nested] : group)?.forEach((key) => {
            const currentRow = nested ? group?.[nested]?.[key] : group?.[key];
            let subStatus = '';

            if(nested){
                subStatus = " nested";
            }

            if (typeof currentRow === 'object') {
                if (group.sort === 'value') {
                    let rowLetter = key.charAt(0);
                    if (!isNaN(rowLetter) && rowLetter !== '') {
                        rowLetter = '#';
                    }

                    if(currentLetter !== rowLetter && !filterBranchStem?.[category]?.nested && Object.keys(filterBranchStem?.[category]).length > 26){
                        categoryRows.push(
                            <div key={generateRandomKey()} className="f cL s bold">
                                {rowLetter}
                            </div>
                        );
                        currentLetter = rowLetter;
                    }
                }

                if (key) {
                    const activeStatus = currentRow.active ? 'on' : 'off';
                    const fragmentKey = `${category}-${key}-${uniqueIndex+currentRow?.value}`;

                    if(nested){
                        // subRows.push(
                        //     <div
                        //         key={generateRandomKey()} // Use the generated key here
                        //         className={`option cC g bR bold p ${activeStatus}${subStatus}`}
                        //         onClick={() => {
                        //             filterFunctions?.updateFilterAttr(null, filterProps?.branch, filterProps?.stem, category, key, 'active');
                        //         }}
                        //     >
                        //         <div className="cL f s oH">
                        //             <div className="gCW">
                        //                 {currentRow?.value}
                        //             </div>
                        //         </div>
                        //         <div className="cC f s oH">
                        //             <div className="gCW">
                        //                 {currentRow?.count}
                        //             </div>
                        //         </div>
                        //         <div className="cC f">
                        //             {currentRow.active ? <CheckCircleIcon sx={{ color: green[400] }} /> : <CloseIcon sx={{ color: red[400] }} />}
                        //         </div>
                        //     </div>
                        // )
                        uniqueIndex++;
                    }else{
                        categoryRows.push(
                            <div
                                key={generateRandomKey()} // Use the generated key here
                                className={`option cC g bR bold p ${activeStatus}${subStatus}`}
                                onClick={() => {
                                    filterFunctions?.updateFilterAttr(null, filterProps?.branch, filterProps?.stem, category, key, 'active');
                                }}
                            >
                                <div className="cL f s oH">
                                    <div className="gCW">
                                        {currentRow?.value}
                                    </div>
                                </div>
                                <div className="cC f s oH">
                                    <div className="gCW">
                                        {currentRow?.count}
                                    </div>
                                </div>
                                <div className="cC f">
                                    {currentRow.active ? <CheckCircleIcon sx={{ color: green[400] }} /> : <CloseIcon sx={{ color: red[400] }} />}
                                </div>
                            </div>
                        );   
                    }
                    uniqueIndex++;
                }

                // Recursively iterate through nested objects
                if(group.nested){
                  iterateCategories(currentRow, category, categoryRows, "funds");
                }
            }
        });

        if(nested && subRows.length > 1){
            categoryRows.push(
                <div
                    key={generateRandomKey()}
                    className="nestedCat g"
                >
                    <div className="vDivider">
                    </div>
                    <div className="list g fC cL">
                        {subRows}
                    </div>
                </div>     
            )
        }
    }

    function printBinaryCategory(category, exclude) {
        if(exclude && category === "leadSource"){
            return;
        }

        const categoryRows = [];
        const currentSort = filterBranchStem?.[category]?.sort;
        const categoryData = filterFunctions.groupData(category);

        iterateCategories(filterBranchStem?.[category], category, categoryRows);

        if(categoryRows.length > 0){
            return (
                <div key={category} className="category g fC">
                    <div className={visibleSections[category] ? "filterSection g bR active" : "filterSection g bR"}>
                        <div className="filterSectionHeader p ns">
                            <div className="cC">
                                <div className="defaultBtn toggle fMP cC bold" onClick={()=>{filterFunctions.resetGroup(filterProps?.branch, filterProps?.stem, category, true)}}>
                                    {filterBranchStem?.[category]?.activeCount === filterBranchStem?.[category]?.maxCount ? "Hide All" : "Show All"}
                                </div>
                            </div>
                            <div
                                className="filterSectionTitle cC"
                                onClick={()=>{
                                    setVisibleSections((prev) => ({
                                        ...prev,
                                        [category]: !prev[category]
                                    }));
                                }}
                            >
                                {categoryData.prompt}
                            </div>
                            <div
                                className="filterSectionToggle cR e"
                                onClick={()=>{
                                    setVisibleSections((prev) => ({
                                        ...prev,
                                        [category]: !prev[category]
                                    }));
                                }}
                            >
                                { !visibleSections[category] ?
                                    <KeyboardArrowUpIcon/>
                                    :
                                    <KeyboardArrowDownIcon/>
                                }
                            </div>
                        </div>
                        {visibleSections[category] &&
                            <div className="alters g ns">
                                <div className="f cC p s" onClick={()=>{filterFunctions?.reorderGroup(filterBranchStem[category], filterProps?.branch, filterProps?.stem, category, currentSort === "value" ? "count" : "value")}}>
                                    {sortDisplay(currentSort, filterBranchStem[category]?.nested)}
                                </div>
                                {filterBranchStem?.[category]?.['']?.count > 0 && (
                                    <>
                                        <div
                                            className={filterBranchStem?.[category]?.['']?.active ? "f cR p gC3" : "inactive f cR p gC3"}
                                            onClick={() => {filterFunctions?.updateFilterAttr(null, filterProps?.branch, filterProps?.stem, category, '', "active") }}>
                                            {"Include " + filterBranchStem?.[category]?.['']?.count + " Empty Values"}
                                        </div>
                                        <div
                                            className="f cC p oH" onClick={() => { filterFunctions?.updateFilterAttr(null, filterProps?.branch, filterProps?.stem, category, "empty", "active") }}>
                                            {filterBranchStem?.[category]?.['']?.active ? <CheckCircleIcon sx={{ color: "#00BFFF" }} /> : <RadioButtonUncheckedIcon sx={{ color: "#E2E2E2" }} />}
                                        </div>
                                    </>
                                )}
                            </div>
                        }
                        {visibleSections[category] &&
                            <div className="list cC g f fC dP">
                                {categoryRows}
                            </div>
                        }
                    </div>
                </div>
            )         
        }
    }

    function sortDisplay(sortType, nested){
        if(nested){
            return;
        }

        let icon;
        let prompt;

        if(sortType === "count"){
            icon = <NumbersIcon/>;
            prompt = "9 - 1";
        }

        if(sortType === "value"){
            icon = <SortByAlphaIcon/>
            prompt = "A - Z"
        }

        return (
            <div key="sortDisplay" className="sortDisplay g s bR">
                <div className="cC">
                    Sorted by 
                </div>
                <div className="f cC">
                    {icon}
                </div>
            </div>
        )
    }

    function buildFilterSections(sessionUser){
        let rangeSections = [];
        let binarySections = [];
        Object.keys(filterBranchStem)?.forEach((group, key) => {
            const currentGroup = filterBranchStem[group];

            if (typeof currentGroup === 'object'){
                if(currentGroup.type === "range"){
                    rangeSections.push(
                        <div key={group + key}>
                            {printRangeCategory(group)}
                        </div>
                    );
                }
                if (currentGroup.type === "binary") {
                  if (group !== "affiliate" || parseJWT(sessionUser?.sessionToken)?.managedAccounts?.length > 0) {
                    binarySections.push(
                        <div key={group + key}>
                            {printBinaryCategory(group, sessionUser?.accountID === 25)}
                        </div>);
                  }
                }
            }
            // Object.keys(filterBranchStem?.[category])?.forEach((key) => {
            // })
        });

        return (
            <div className="section fMP dP tO g">
                <div className="body">
                    <>
                        {rangeSections}
                        {binarySections}
                    </>
                </div>
            </div>
        )
    }

    useEffect(() => {
        // console.log(filterBranchStem);
        // console.log(filterProps);
    }, []);

    return filterPanelHandler
}

export default FilterPanel;