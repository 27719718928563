import React from 'react';
import { useRef } from 'react';
import { useState, useEffect } from 'react';

import LeadRangeSlider from './LeadRangeSlider';

import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CallEndIcon from '@mui/icons-material/CallEnd';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ForwardIcon from '@mui/icons-material/Forward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import MovingIcon from '@mui/icons-material/Moving';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import InboxIcon from '@mui/icons-material/Inbox';

function LeadsListAll(props){
    const listAllBoard = useRef(null);

    useEffect(() => {
        if(listAllBoard?.current){
            listAllBoard.current.scrollTop = props.leadProps.yScrollList;
        }
    }, []);

    function leadsListCategoryClass(category, topLevel){
        if(topLevel){
            function containsAllElements(array1, array2) {
                return array2.every(item => array1.includes(item));
            }

            if(category === "leads"){
                if(props.listToggles.includes(["Leads"])){
                    return " inactive";
                }
            }

            if(category === "contactAttempted"){
                if(containsAllElements(props.listToggles, ["Contact Attempted 1", "Contact Attempted 2"])){
                    return " inactive";
                }
            }
                
            if(category === "inProgress"){
                if(containsAllElements(props.listToggles, ["Application Sent", "Application Received", "Follow Up"])){
                    return " inactive";
                }
            }

            if(category === "lost"){
                if(containsAllElements(props.listToggles, ["Unqualified", "Failed to Contact", "Client not Interested"])){
                    return " inactive";
                } 
            }

            if(category === "success"){
                if(containsAllElements(props.listToggles, ["Sent to Abacus", "New Insurance"])){
                    return " inactive";
                } 
            }

            if(category === "all"){
                if(containsAllElements
                    (props.listToggles,
                    ["Lead",
                    "Contact Attempted 1",
                    "Contact Attempted 2",
                    "Application Sent",
                    "Application Received",
                    "Follow Up",
                    "Unqualified",
                    "Failed to Contact",
                    "Client not Interested",
                    "Sent to Abacus",
                    "New Insurance"])){
                        return true;
                }
            }
        }else{
            if(props.listToggles !== undefined && props.listToggles.includes(category)){
                return " inactive";
            }
        }

        return '';
    }

    function displayTotalCategoryCountStatus(currentVal, maxVal){
        var prompt = " leads";
        if(currentVal === 1){
            prompt = " lead";
        }

        if(currentVal === maxVal){
            return [maxVal, prompt];
        }else{
            return [currentVal + "/" + maxVal, <div key="leadsListCountPrompt" className="leadsListCountPrompt">{prompt}</div>];
        }
    }

    function handleScroll(event) {
        const element = event.target;

        props.leadProps?.setYScrollList(element.scrollTop);

        if(props.listAllVisibleThreshold > props.leadBlockListData.total.count){
            return;
        }

        if (element.scrollHeight - element.scrollTop <= element.clientHeight + 100) {
            props.setListAllVisibleThreshold(props.listAllVisibleThreshold + 48);
        }
    }

    return (
        <div className="leadsListBoard" ref={listAllBoard} onScroll={handleScroll}>
            <div className="leadsListFilterPanelWrap">
                <div className="leadsListFilterPanel ns">
                    <div className="leadsListInfo">
                        {props.leadBlockListData?.total?.visibleCount + "/" + props.leadBlockListData?.total?.count + " Leads Total"}
                        <div className="leadsListAttrFilters">
                            <LeadRangeSlider
                                rangeData = {props.rangeData}
                                functionPointer={props.functionPointer}
                                fixedMin={props.fixedMin}
                                fixedMax={props.fixedMax}
                                />
                        </div>
                    </div>
                    <div className={"leadsListCategorySection success" + leadsListCategoryClass("success", true)}>
                        <div className={"leadsListCategoryHeader success"} onClick={()=>{props.leadsCategoryToggle("success", true, "list")}}>
                            Closed / Success
                            <div className="leadCategoryIcon">
                                <CheckCircleIcon/>
                            </div>
                        </div>
                        <div className="leadListDivider success">
                        </div>
                        <div className="leadsListCategories">
                            <div className={"leadsListCategory abacus cC" + leadsListCategoryClass("Sent to Abacus")} onClick={()=>{props.leadsCategoryToggle("Sent to Abacus", null, "list")}}>
                                <div className="leadsListCategoryCount">
                                    {displayTotalCategoryCountStatus(props.leadBlockListData?.abacus?.visibleCount, props.leadBlockListData?.abacus?.count)}
                                </div>
                                <RestartAltIcon/>
                                <div className="leadsListCategoryTitle cL">
                                    <span>Sent to Abacus</span>
                                </div>
                            </div>
                            <div className={"leadsListCategory insurance cC" + leadsListCategoryClass("New Insurance")} onClick={()=>{props.leadsCategoryToggle("New Insurance", null, "list")}}>
                                <div className="leadsListCategoryCount">
                                    {displayTotalCategoryCountStatus(props.leadBlockListData?.insurance?.visibleCount, props.leadBlockListData?.insurance?.count)}
                                </div>
                                <SwitchAccessShortcutIcon/>
                                <div className="leadsListCategoryTitle cL">
                                    <span>New Insurance</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"leadsListCategorySection lead" + leadsListCategoryClass("Lead")}>
                        <div className={"leadsListCategoryHeader lead" + leadsListCategoryClass("lead", true)} onClick={()=>{props.leadsCategoryToggle("lead", true, "list")}}>
                            <div className="leadsListCategoryCount">
                                {displayTotalCategoryCountStatus(props.leadBlockListData?.lead?.visibleCount, props.leadBlockListData?.lead?.count)}
                            </div>
                            Lead
                            <div className="leadCategoryIcon">
                                <InboxIcon/>
                            </div>
                        </div>
                        <div className="leadListDivider lead">
                        </div>
                        <div className="leadsListCategories">
                            <div className={"leadsListCategory cC"} onClick={()=>{props.leadsCategoryToggle("Lead", null, "list")}}>
                                <div className="leadsListCategoryCount">
                                    {displayTotalCategoryCountStatus(props.leadBlockListData?.lead?.lead?.visibleCount, props.leadBlockListData?.lead?.count)}
                                </div>
                                <BeenhereIcon/>
                                <div className="leadsListCategoryTitle cL">
                                    <span>Lead</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"leadsListCategorySection contactAttempted" + leadsListCategoryClass("contactAttempted", true)}>
                        <div className={"leadsListCategoryHeader contactAttempted"} onClick={()=>{props.leadsCategoryToggle("contactAttempted", true, "list")}}>
                            <div className="leadsListCategoryCount">
                                {displayTotalCategoryCountStatus(props.leadBlockListData?.contactAttempt?.visibleCount, props.leadBlockListData?.contactAttempt?.count)}
                            </div>
                            Contact Attempt
                            <div className="leadCategoryIcon">
                                <PhoneMissedIcon/>
                            </div>
                        </div>
                        <div className="leadListDivider contactAttempted">
                        </div>
                        <div className="leadsListCategories">
                            <div className={"leadsListCategory cC" + leadsListCategoryClass("Contact Attempted 1")} onClick={()=>{props.leadsCategoryToggle("Contact Attempted 1", null, "list")}}>
                                <div className="leadsListCategoryCount">
                                    {displayTotalCategoryCountStatus(props.leadBlockListData?.contactAttempt?.contactAttempted1?.visibleCount, props.leadBlockListData?.contactAttempt?.contactAttempted1?.count)}
                                </div>
                                <CallEndIcon/>
                                <div className="leadsListCategoryTitle cL">
                                    <span>Contact Attempt 1</span>
                                </div>
                            </div>
                            <div className={"leadsListCategory cC" + leadsListCategoryClass("Contact Attempted 2")} onClick={()=>{props.leadsCategoryToggle("Contact Attempted 2", null, "list")}}>
                                <div className="leadsListCategoryCount">
                                    {displayTotalCategoryCountStatus(props.leadBlockListData?.contactAttempt?.contactAttempted2?.visibleCount, props.leadBlockListData?.contactAttempt?.contactAttempted2?.count)}
                                </div>
                                <CallEndIcon/>
                                <div className="leadsListCategoryTitle cL">
                                    <span>Contact Attempt 2</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"leadsListCategorySection inProgress" + leadsListCategoryClass("inProgress", true)}>
                        <div className={"leadsListCategoryHeader inProgress"} onClick={()=>{props.leadsCategoryToggle("inProgress", true, "list")}}>
                            <div className="leadsListCategoryCount">
                                {displayTotalCategoryCountStatus(props.leadBlockListData?.inProgress?.visibleCount, props.leadBlockListData?.inProgress?.count)}
                            </div>
                            In Progress
                            <div className="leadCategoryIcon">
                                <MovingIcon/>
                            </div>
                        </div>
                        <div className="leadListDivider inProgress">
                        </div>
                        <div className="leadsListCategories">
                            <div className={"leadsListCategory cC" + leadsListCategoryClass("Application Sent")} onClick={()=>{props.leadsCategoryToggle("Application Sent", null, "list")}}>
                                <div className="leadsListCategoryCount">
                                    {displayTotalCategoryCountStatus(props.leadBlockListData?.inProgress?.applicationSent?.visibleCount, props.leadBlockListData?.inProgress?.applicationSent?.count)}
                                </div>
                                <ForwardIcon/>
                                <div className="leadsListCategoryTitle cL">
                                    <span>Application Sent</span>
                                </div>
                            </div>
                            <div className={"leadsListCategory cC" + leadsListCategoryClass("Application Received")} onClick={()=>{props.leadsCategoryToggle("Application Received", null, "list")}}>
                                <div className="leadsListCategoryCount">
                                    {displayTotalCategoryCountStatus(props.leadBlockListData?.inProgress?.applicationReceived?.visibleCount, props.leadBlockListData?.inProgress?.applicationReceived?.count)}
                                </div>
                                <CallReceivedIcon/>
                                <div className="leadsListCategoryTitle cL">
                                    <span>Application Received</span>
                                </div>
                            </div>
                            <div className={"leadsListCategory cC" + leadsListCategoryClass("Follow Up")} onClick={()=>{props.leadsCategoryToggle("Follow Up", null, "list")}}>
                                <div className="leadsListCategoryCount">
                                    {displayTotalCategoryCountStatus(props.leadBlockListData?.inProgress?.followUp?.visibleCount, props.leadBlockListData?.inProgress?.followUp?.count)}
                                </div>
                                <AccessAlarmIcon/>
                                <div className="leadsListCategoryTitle cL">
                                    <span>Follow Up</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"leadsListCategorySection lost" + leadsListCategoryClass("lost", true)}>
                        <div className={"leadsListCategoryHeader lost"} onClick={()=>{props.leadsCategoryToggle("lost", true, "list")}}>
                            <div className="leadsListCategoryCount">
                                {displayTotalCategoryCountStatus(props.leadBlockListData?.closed?.visibleCount, props.leadBlockListData?.closed?.count)}
                            </div>
                            Closed / Lost
                            <div className="leadCategoryIcon">
                                <DoDisturbOnIcon/>
                            </div>
                        </div>
                        <div className="leadListDivider lost">
                        </div>
                        <div className="leadsListCategories">
                            <div className={"leadsListCategory cC" + leadsListCategoryClass("Unqualified")} onClick={()=>{props.leadsCategoryToggle("Unqualified", null , "list")}}>
                                <div className="leadsListCategoryCount">
                                    {displayTotalCategoryCountStatus(props.leadBlockListData?.closed?.unqualified?.visibleCount, props.leadBlockListData?.closed?.unqualified?.count)}
                                </div>
                                <DoDisturbOnIcon/>
                                <div className="leadsListCategoryTitle cL">
                                    <span>Unqualified</span>
                                </div>
                            </div>
                            <div className={"leadsListCategory cC" + leadsListCategoryClass("Failed to Contact")} onClick={()=>{props.leadsCategoryToggle("Failed to Contact", null, "list")}}>
                                <div className="leadsListCategoryCount">
                                    {displayTotalCategoryCountStatus(props.leadBlockListData?.closed?.failedToContact?.visibleCount, props.leadBlockListData?.closed?.failedToContact?.count)}
                                </div>
                                <DoDisturbOnIcon/>
                                <div className="leadsListCategoryTitle cL">
                                    <span>Failed to Contact</span>
                                </div>
                            </div>
                            <div className={"leadsListCategory cC" + leadsListCategoryClass("Client not Interested")} onClick={()=>{props.leadsCategoryToggle("Client not Interested", null, "list")}}>
                                <div className="leadsListCategoryCount">
                                    {displayTotalCategoryCountStatus(props.leadBlockListData?.closed?.clientNotInterested?.visibleCount, props.leadBlockListData?.closed?.clientNotInterested?.count)}
                                </div>
                                <DoDisturbOnIcon/>
                                <div className="leadsListCategoryTitle cL">
                                    <span>Client Not Interested</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="leadsListBtnSection g cC s">
                        <div className="g cL s">
                            <div className="toggleAllLeadFiltersBtn" onClick={()=>{props.leadsCategoryToggle("clear", "all", "list")}}>
                                { leadsListCategoryClass("all", true) ?
                                    "Select All"
                                    : "Toggle All"
                                }
                            </div>
                        </div>
                        {  Object.keys(props.sessionUser?.accountData)?.length > 1 &&
                            <div className="g cC" onClick={() => {props.leadProps?.toggleLeadFilters("toggle")}}>
                                <div className="p">
                                    Affiliates
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="leadsListResults"> {
                props.viewAllList }
            </div>
        </div>
    )
}

export default LeadsListAll;