import { parseJWT } from './envFunctions.js';

const fetchBase = process.env.REACT_APP_FETCH_BASE;

export default fetchBase

export function authorizeRequest(sessionUser, updatePage, call) {
    const storedSessionUser = JSON.parse(window.localStorage.getItem('sessionUser'));
    if (!sessionUser || !sessionUser.sessionToken) {
      updatePage("login");
      return "Unauthorized";
    }
  
    const currentToken = parseJWT(sessionUser.sessionToken);
  
    if (currentToken === "Unauthorized" || ["userID", "email", "accountID"].some(attrName =>
      currentToken[attrName] !== sessionUser?.[attrName] ||
      currentToken[attrName] !== storedSessionUser?.[attrName]
    )) {

      updatePage("login");
      return "Unauthorized";
    }
  
    return sessionUser.sessionToken;
}