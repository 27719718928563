import React, { useEffect, useState, useRef } from 'react';

import CopyAllIcon from '@mui/icons-material/CopyAll';

function PresetColumnBlock({
    attr,
    groupColumnHover,
    columnObject,
    blockID,
    addOrRemoveColumns,
    statusIcon,
    deleteIcon,
    currentView
  }) {

    const isHovered = groupColumnHover?.data === blockID;
    const rowClassNames = `row group custom alt g cC fC bR nS bold s e dG dP complete${isHovered ? " hovered" : ''}`;
    const handleIconClick = () => {
        addOrRemoveColumns({
          pointer: 'id',
          objectKey: columnObject?.id,
        });
        groupColumnHover?.set(undefined);
    };

    return (
        <div
            key={blockID}
            className={rowClassNames}
        >
            <div className="topBar g cC f dG">
                {statusIcon}
                <CopyAllIcon/>
                <div className={`f cL${currentView === "properties" ? " gC3" : " gC2"}`}>
                    {attr?.friendlyTerm}
                </div>
                <div
                    className="icon f cC p"
                    onMouseEnter={() => groupColumnHover?.set(blockID)}
                    onMouseLeave={() => groupColumnHover?.set(undefined)}
                    onClick={handleIconClick}
                >
                    {deleteIcon}
                </div>
            </div>
            <div className="details g f cC dG">
                <div className={`gC2 f cL${!columnObject?.combineBy ? " thin italics" : ''}`}>
                    Total count of grouped items
                </div>
            </div>
        </div>
    )
};

export default PresetColumnBlock;