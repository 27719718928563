import React from 'react';
import { useState, useEffect, useRef } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';

function DateInput(props) {
    const session = props?.session;
    let value = props?.value;
    const activeStatus = props?.active ?? value;
    const today = new Date().toISOString().split("T")[0];
    const [invalidDate, setInvalidDate] = useState(false);

    if (props?.textData?.formType === "int" && props?.textData?.returnType === "currency") {
        value = session?.env?.functions?.convertIntToCurrency(value);
        // const formatCurrencyValue = value => !isNaN(parseFloat(value)) ? `$${parseFloat(value).toLocaleString()}` : '';
        // value = formatCurrencyValue(value);
    }

    if (props?.textData?.formType === "date") {
        const formatDateValue = value => {
            if (!value) return '';
            const [year, month, day] = value.split('-');
            return `${parseInt(month)}/${parseInt(day)}/${year}`;
        };
        value = formatDateValue(value);
    }

    const onClickHandler = () => {
        if (typeof props?.onClick === 'function') {
            props.onClick(); // Invoke the passed onClick function if it exists
        }
    };

    const completeDate = (date) => {
        return /^\d{4}-\d{2}-\d{2}$/.test(date);
    };

    const onChangeHandler = (e) => {
        const inputDate = e?.target?.value;

        if (inputDate && completeDate(inputDate)) {
            // Validate for futureDate and completeness
            if (props?.futureDate && inputDate < today) {
                setInvalidDate(true); // Mark invalid if the date is earlier than today
            } else {
                setInvalidDate(false); // Reset to valid
            }
        } else {
            setInvalidDate(false); // Reset if input is incomplete or empty
        }

        if (typeof props?.onChange === 'function') {
            props.onChange(e); // Invoke the passed onChange function
        }
    };

    return (
        <div
            key={props?.name}
            className={`dateInput g cL f fC pR ${activeStatus ? "active" : "inactive"}${invalidDate ? " invalid" : ''}${!value ? " blank" : ''}`}
        >
            {/* {props?.value !== null && props?.value !== '' && props?.placeholder && ( */}
                <div
                    className={`placeholder gCW g f${props?.condensed ? " condensed" : ''}`}
                    title={props?.placeholder}
                >
                    {props?.placeholder}
                </div>
            {/* )} */}
            <input 
                className={`input gR2${props?.readOnly === false ? " editable" : ''}`}
                placeholder={props?.placeholder}
                name={props.name}
                readOnly={props?.readOnly ?? true}
                value={value}
                type={"date"}
                autoComplete={props?.autoComplete ?? "off"}
                onClick={onClickHandler}
                title={value === '' ? props?.placeholder : value}
                onChange={onChangeHandler}
                min={props?.futureDate ? today : undefined}
                style={{
                    ...(props?.width && { width: props?.width }),
                    ...(props?.maxWidth && { maxWidth: props?.maxWidth }),
                    ...(props?.height && { height: props?.height }),
                }}
            />
            <EventTwoToneIcon/>
        </div>
    );
}

export default DateInput;