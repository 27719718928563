import React from 'react';
import { useState, useEffect, useRef } from 'react';

import ToggleOption from '../Components/ToggleOption.js';

import { parseJWT } from '../envFunctions.js';

import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MovingIcon from '@mui/icons-material/Moving';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import InboxIcon from '@mui/icons-material/Inbox';
import CheckIcon from '@mui/icons-material/Check';
import CategoryIcon from '@mui/icons-material/Category';
import GroupsIcon from '@mui/icons-material/Groups';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function FilterLeadPanel(props){
    const [filtersActivityView, setFiltersActivityView] = useState("categories");

    function toggleTeamMember(userID){
        if(props.ignoredTeamList.includes(userID.toString())){
            props.setIgnoredTeamList(props.ignoredTeamList.filter(useID => useID !== userID.toString()));
        }else{
            props.setIgnoredTeamList(ignoredTeamList => [...ignoredTeamList, userID.toString()]);
        }
    }

    function printTeamList(){
        var returningListBlock = [];

        if(!props.sessionUser?.accountData?.[props.sessionUser?.accountID]){
            return (
                <div className="assignTeamMemberList">
                    <div className="loadingBlock">
                        <div className="loadingBlockMessage">
                            No team members on file
                        </div>
                    </div>
                </div>
            )
        }

        props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.teamList.forEach(function (teamMember){
            if(!teamMember.isAbacusEmployee){
                returningListBlock.push(
                    <div key={teamMember.email}
                        className={props.ignoredTeamList.includes(teamMember.userID.toString()) ?"assignTeamMemberRow ns" : "assignTeamMemberRow active ns"}
                        onClick={()=>{toggleTeamMember(teamMember.userID)}}>
                        <div className="assignTeamMemberListName">
                            <div className="assignRowText">
                                {teamMember.firstName + " " + teamMember.lastName}
                            </div>
                        </div>
                        <div className="assignTeamMemberListName">
                            <div className="assignRowText">
                                {teamMember.email}
                            </div>
                        </div>
                        <div className="assignTeamMemberListCheck">
                            {props.ignoredTeamList.includes(teamMember.userID.toString()) ?
                                <CheckCircleOutlineIcon/>
                            :
                                <CheckCircleIcon/>
                            }
                        </div>
                    </div>
                )
            }
        });

        return (
            <div className="assignTeamMemberList">
                {returningListBlock}
            </div>
        )
    }

    function decideAffiliateClass(account){
        if(parseInt(account.numActiveLeads) + parseInt(account.numOfClosedLeads) === 0){
            return '';
        }

        if(props.kanbanToggles.includes(account.accountID)){
            return "p aB";
        }

        if(!props.kanbanToggles.includes(account.accountID)){
            return "p iB";
        }
    }

    function toggleAffiliate(account){
        props.leadsCategoryToggle(account.accountID, null, "kanban")
        props.leadsCategoryToggle(account.accountID, null, "list")
    }

    function printLeadSources(){
        let returningBlock = [];

        Object.keys(props.sessionUser?.accountData).forEach(function(accountID) {
            const account = props.sessionUser?.accountData[accountID];

            returningBlock.push(
                <div
                    key={"accountID: " + accountID}
                    className={"dR bR " + decideAffiliateClass(account)}
                    onClick={decideAffiliateClass(account) === '' ? null : ()=>{toggleAffiliate(account)}}>
                    <div className="leadsAffiliateFilter cC">
                        <div className="cL g s">
                            <span>
                                {decideAffiliateClass(account) === '' ?
                                    account.name + " ( 0 leads )"
                                    :
                                    account.name
                                }
                            </span>
                        </div>
                        <div className={decideAffiliateClass(account) === '' ? "managedAccountBlockRight g cC gO" : "managedAccountBlockRight g cC"}>
                            {props.kanbanToggles.includes(account.accountID) ?
                                <VisibilityIcon/>
                                :
                                <VisibilityOffIcon/>
                            }
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div className="leadsFiltersToggleWrap">
                {returningBlock}
            </div>
        )
    }

    function clearIgnored(){
        console.log(props);
        const togglesToModify = ["Lead", "Contact Attempted 1", "Contact Attempted 2", "Application Sent", "Application Received", "Follow Up"];

        if(props.leadProps?.leadFilterPanelType === "toggle"){
            console.log(props.leadProps?.kanbanToggles);
            props.leadProps?.setKanbanToggles(prevToggles => {
                const togglesToAdd = togglesToModify.filter(toggle => !prevToggles.includes(toggle));
                return prevToggles.concat(togglesToAdd);
            });
        }else{
            props.leadProps?.setIgnoredTeamList([]);
        }
    }

    function currentActivity(activityView){
        if(filtersActivityView === activityView){
            return " selected";
        }

        return "";
    }

    function checkKanbanToggles() {
        const togglesToModify = ["Lead", "Contact Attempted 1", "Contact Attempted 2", "Application Sent", "Application Received", "Follow Up"];

        return togglesToModify.every(toggle => props.kanbanToggles.includes(toggle));
    }

    var unassignedLeadsStatus = "checkboxToggle selected";
    if(props.ignoredTeamList.includes("0")){
        unassignedLeadsStatus += "checkboxToggle";
    }

    useEffect(() => {
        if(props.leadProps?.activityView === "list"){
            setFiltersActivityView("sources");
        }
      }, []);
    
    return (
        <div className="leadOptionsBoard" onClick={(evt)=> {props.handleClose(evt)}}>
            <div className="leadsFilterModule moduleBlock">
                <div className="leadsFiltersModuleInner">
                    <div className="miniModuleHeader leadsFiltersHeadbar">
                        <div className="notificationHeaderTitle">
                            { props.leadFilterPanelType === "toggle" ?
                                "Filters"
                                :
                                "Team List"
                            }
                        </div>
                        <div className="closeBtn" onClick={()=> {props.setShowLeadFilters(false)}}>
                            <CloseIcon/>
                        </div>
                    </div>
                    {parseJWT(props.sessionUser?.sessionToken)?.managedAccounts?.length > 1 && props.leadFilterPanelType === "toggle" &&
                        <div className="filtersTabBar g">
                            {   props.leadProps?.activityView === "kanban" &&
                                <div className={"switchViewBtn f g cC s e" + currentActivity("categories")} onClick={() => {setFiltersActivityView("categories")}}>
                                    <div className="switchViewBtnOption">
                                        Categories
                                    </div>
                                    <div className="inlineBtnIcon cC">
                                        <CategoryIcon/>
                                    </div>
                                </div>
                            }
                            <div className={"switchViewBtn f g cC s e" + currentActivity("sources")} onClick={() => setFiltersActivityView("sources")}>
                                <div className="switchViewBtnOption">
                                    Affiliates
                                </div>
                                <div className="inlineBtnIcon cC">
                                    <GroupsIcon/>
                                </div>
                            </div>
                        </div>
                    }
                    { props.leadFilterPanelType === "toggle" &&
                        currentActivity("categories") &&
                        <div className="leadsFiltersToggleWrap">
                            <div className={"leadsListCategorySection lead"}>
                                <div className={"leadsListCategoryHeader lead"}>
                                    Lead
                                    <div className="leadCategoryIcon">
                                        <InboxIcon/>
                                    </div>
                                </div>
                                <div className="leadListDivider lead">
                                </div>
                            </div>
                            <div className="leadsFiltersSection lead">
                                <ToggleOption 
                                    toggleStatus = {props.kanbanToggles.includes("Lead")}
                                    functionPointer = {props.leadsCategoryToggle}
                                    optionString={"Lead"}
                                    totalCount = {null}
                                    altParameters = {"kanban"}
                                    category = {"lead"}
                                    showCount = {false}
                                />
                            </div>
                            <div className={"leadsListCategorySection contactAttempted"}>
                                <div className={"leadsListCategoryHeader contactAttempted"}>
                                    Contact Attempt
                                    <div className="leadCategoryIcon">
                                        <PhoneMissedIcon/>
                                    </div>
                                </div>
                                <div className="leadListDivider contactAttempted">
                                </div>
                            </div>
                            <div className="leadsFiltersSection contactAttempted">
                                <ToggleOption
                                    toggleStatus = {props.kanbanToggles.includes("Contact Attempted 1")}
                                    functionPointer = {props.leadsCategoryToggle}
                                    optionString={"Contact Attempted 1"}
                                    altParameters = {"kanban"}
                                    category = {"contactAttempted"}
                                    showCount = {false}
                                />
                                <ToggleOption
                                    toggleStatus = {props.kanbanToggles.includes("Contact Attempted 2")}
                                    functionPointer = {props.leadsCategoryToggle}
                                    optionString={"Contact Attempted 2"}
                                    totalCount = {null}
                                    altParameters = {"kanban"}
                                    category = {"contactAttempted"}
                                    showCount = {false}
                                />
                            </div>
                            <div className={"leadsListCategorySection inProgress"}>
                                <div className={"leadsListCategoryHeader inProgress"}>
                                    In Progress
                                    <div className="leadCategoryIcon">
                                        <MovingIcon/>
                                    </div>
                                </div>
                                <div className="leadListDivider inProgress">
                                </div>
                            </div>
                            <div className="leadsFiltersSection inProgress">
                                <ToggleOption
                                    toggleStatus = {props.kanbanToggles.includes("Application Sent")}
                                    functionPointer = {props.leadsCategoryToggle}
                                    optionString={"Application Sent"}
                                    totalCount = {null}
                                    altParameters = {"kanban"}
                                    category = {"inProgress"}
                                    showCount = {false}
                                />
                                <ToggleOption
                                    toggleStatus = {props.kanbanToggles.includes("Application Received")}
                                    functionPointer = {props.leadsCategoryToggle}
                                    optionString={"Application Received"}
                                    totalCount = {null}
                                    altParameters = {"kanban"}
                                    category = {"inProgress"}
                                    showCount = {false}
                                />
                                <ToggleOption
                                    toggleStatus = {props.kanbanToggles.includes("Follow Up")}
                                    functionPointer = {props.leadsCategoryToggle}
                                    optionString={"Follow Up"}
                                    totalCount = {null}
                                    altParameters = {"kanban"}
                                    category = {"inProgress"}
                                    showCount = {false}
                                />
                            </div>
                        </div>
                    }
                    { props.leadFilterPanelType === "toggle" &&
                        currentActivity("sources") &&
                        printLeadSources()
                    }
                    { props.leadFilterPanelType !== "toggle" &&
                        <div className="leadsListIgnoreList">
                            {printTeamList()}
                        </div>
                    }
                    <div className="leadsListIgnoreBtn cC s e">
                        <div className="cL">
                            <div className="toggleUnassignedLeads cC" onClick={ () => {toggleTeamMember("0")}}>
                                <div className="checkboxField">
                                    <div className={unassignedLeadsStatus}>
                                        {!props.ignoredTeamList.includes("0") ? <CheckIcon/> : null }
                                    </div>
                                    <div className="fieldName">
                                        Show Unassigned Leads
                                    </div>
                                </div>
                            </div>
                        </div>
                        {props.ignoredTeamList.length > 0 && props.leadFilterPanelType === "teamList" || !checkKanbanToggles() && props.leadFilterPanelType === "toggle" ?
                        <div className="cR e">
                            <div className="toggleAllLeadFiltersBtn" onClick={()=>{clearIgnored()}}>
                                Clear Selection
                            </div>
                        </div>
                        : null}
                    </div>   
                </div>
            </div>
        </div>
    )
}

export default FilterLeadPanel;