import React, { useEffect, useState } from 'react';
import _ from 'lodash';

function SessionNavigation(props) {
  const user = props?.user?.data;
  const localNavigation = JSON.parse(localStorage.getItem("navigation")) || {};

  const [data, setData] = useState(localNavigation ?? undefined);

  const pathHandler = {
    "dashboard": "/dashboard",
    "reporting": "/reporting",
    "origination": "/policies",
    "servicing": "/policies",
    "bids": "/bids",
    "tertiary": "/tertiary",
    "leads": "/leads",
    "upload": "/upload",
  };

  const validPaths = {
    "dashboard" : {
      prompt : "Dashboard",
      path : "dashboard",
    },
    "home" : {
      prompt : "Home",
      path : "home",
    },
    "policies" : {
      prompt : "View All Policies",
      path : "policies",
    },
    "leads" : {
      prompt : "View All Leads",
      path : "leads",
    },
    "bids" : {
      prompt : "View All Bids",
      path : "bids",
    },
    "reporting" : {
      prompt : "Reporting",
      path : "reporting",
    },
    "tertiary" : {
      prompt : "View All Tertiary",
      path : "tertiary",
    },
    "upload" : {
      prompt : "Upload",
      path : "upload",
    },
  }

  function isPathEligible(path) {
    const eligablePages = [
      "home",
      "policies",
      "upload",
      "leads",
      "bids",
      "profile",
      "reporting",
      "dashboard",
      "tertiary",
    ];

    const lowerCasePath = path?.toLowerCase();
    return eligablePages.some(page => page.toLowerCase() === lowerCasePath);
  }

  const homepage = ({ importedUser = {}, returnPage = false } = {}) => {
    const prioritizedViews = ["dashboard", "origination", "servicing", "bids", "tertiary", "leads", "reporting", "upload"];
    const pages = importedUser?.pages ?? user?.pages ?? {};
    const accountData = importedUser?.accountData ?? user?.accountData ?? {};
    
    if (typeof pages !== 'object' || pages === null) {
      return "/profile";
    }
  
    for (const view of prioritizedViews) {
      const userHasAccess = view in pages;
  
      const accountHasAccess = Object.values(accountData).some(account => {
        const accountPages = account.pages ?? {};
        return view in accountPages;
      });
    
      if (userHasAccess && accountHasAccess) {
        return returnPage ? view :pathHandler?.[view];
      }
    }
  
    return "/profile";
  };
  
  const verifyPageAccess = (page) => {
    const accounts = user?.accountData ?? {};
    const userHasAccess = Array.isArray(page)
      ? page.some(p => p in user?.pages)
      : page in user?.pages;
  
    if (!userHasAccess) {
      return false;
    }
  
    const accountHasAccess = Object.values(accounts).some(account => {
      const accountPages = account.pages ?? {};
      return Array.isArray(page)
        ? page.some(p => p in accountPages)
        : page in accountPages;
    });
  
    return accountHasAccess;
  };  

  function eligablePath(currentPath = window.location.pathname.match(/^\/([^/]+)/)?.[1] || null) {
    const path = currentPath;

    // Create an array of paths to verify only for verifyPageAccess
    const page = (path) => {
        if (path === "policies") return ["origination", "servicing"];
        return [path]; // Wrap in an array for consistency
    }

    // Automatically allow "profile" path
    if (path === "profile") {
        return true;
    }

    // Check if current path is eligible
    if (!isPathEligible(path)) {
        return false; // If not eligible, return false
    }

    const pathsToCheck = page(path); // Get the array of paths for verifyPageAccess

    // Check if verifyPageAccess is true for any path in the array
    if (!verifyPageAccess(pathsToCheck)) {
        return false; // If all paths fail access, return false
    }

    return true; // If eligible and access is verified, return true
  }

  function inlineNavigation(direction){
    const destination = {
      view : homepage({"returnPage" : true}),
      instance : "all",
      prompt : validPaths[homepage({"returnPage" : true})]?.prompt,
    }

    if(direction === "back"){
      const view = data?.history?.[Math.max(0, data.history.length - 2)]?.view;
      destination.view = view;
      destination.instance = "all";
      destination.prompt = validPaths?.[view]?.prompt;
    }

    return destination;
  }

  function updateInstance({ URL, direction, session }) {
    let parts = URL ? URL.split('/').filter(part => part) : [];
    
    let view = parts?.[0];
    let instance = parts?.[parts?.length - 1];

    instance = !isNaN(instance) ? "single" : "all";
    
    const currentData = session?.navigation?.data || {};
    
    let newView = view ?? "dashboard";
    let newInstance = instance ?? "all";
    let newHistory = Array.isArray(currentData?.history) ? [...currentData?.history] : [];
    const existingHistory = newHistory.length > 1;
    
    if (
        newView === currentData?.currentInstance?.view &&
        newInstance === currentData?.currentInstance?.instance &&
        _.isEqual(newHistory, currentData?.history)
    ) {
      return;
    }

    // Update history based on direction and uniqueness
    if (view && instance) {
      const lastEntry = newHistory?.[newHistory?.length - 1];

      if (direction === "back" && existingHistory) {
        newHistory = newHistory.slice(0, -1);
      } else {
        if (lastEntry?.view !== view || lastEntry?.instance !== instance) {
          newHistory = [...newHistory, { view: newView, instance: newInstance }];
        }
      }
    }

    const newValue = {
      currentInstance: {
          view: newView,
          instance: newInstance,
      },
      history: newHistory,
    };

    session?.set("navigation", null, newValue);
    localStorage.setItem("navigation", JSON.stringify(newValue));
  }

  const functions = {
    inlineNavigation,
    homepage,
    verifyPageAccess,
    eligablePath,
    isPathEligible,
    updateInstance,
  };

  return {
    data,
    setData,
    functions,
  };
}

export default SessionNavigation;