import React from 'react';
import { Link } from 'react-router-dom';

import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';

function ViewBarBtn(props){
    const session = props?.session;
    const individualData = session?.individual?.data;
    const notifications = session?.notifications?.data;

    const checkNotificationType = () => {
        if (!notifications || !props?.alertType) return false;

        const hasStatusNotification = notifications.some(
          (notificationArray) =>
            notificationArray[1]?.activityType === props?.alertType &&
            notificationArray[1]?.relatedID === props?.currentPolicy?.relatedPolicyID
        );

        const hasMessageNotification = notifications.some(
          (notificationArray) => notificationArray[1]?.activityType === "Message" &&
            props?.alertType === "Message"
        );

        return hasStatusNotification || hasMessageNotification;
    };
      
    const hasPoliciesAlert = checkNotificationType();

    return(
        <div
            className={`viewBarBtn g f cC fC${props?.isActive ? " active" : ''}${session?.user?.data ? " p" : " offline"}`}
            onClick={props?.onClick}
        >
            <div className="prompt g f cC pR fR gR2">
                {hasPoliciesAlert && (
                    <div className="alert">
                        <NewReleasesRoundedIcon/>
                    </div>
                )} 
                {(props?.viewType !== "mobile" || !props?.icon || (props?.viewType === "mobile" && !props?.condense)) && 
                    <div className="s f cC g oH">
                        <div className="f cC gCW">
                            {props?.label}
                        </div>
                    </div>
                }
                {props?.icon &&
                    <div className={`f cC icon${props?.viewType !== "mobile" ? " gC2" : ''}`}>
                        {props?.icon}
                    </div>
                }
            </div>
            <div className="status f gR3">
            </div>
        </div>
    )
}

export default ViewBarBtn;
