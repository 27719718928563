import { useState, useEffect } from 'react';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import StatusIcon from '../StatusIcons';
import Status from './Status.js';
import OriginationRequest from './Request - Origination.js';
import BiddingRequest from './Request - Bidding.js';
import Response from './Response.js';
import BiddingOffer from './BiddingOffer.js';
import Note from './Note.js';
import VOC from './VOC.js';
import Payment from './Payment.js';
import FollowUp from './FollowUp.js';

function TimelinePostHandler(props){
    const session = props?.session;
    const branch = props?.branch;
    const stem = props?.stem;
    const currentCase = props?.currentCase;
    const timelineItem = props?.timelineItem;
    const timelineData = props?.timelineData;
    const detailed = props?.detailed;
    const description = props?.description;

    const [timeAgo, setTimeAgo] = useState('');
    // const [readStatus, setReadStatus] = useState(props?.readStatus ?? timelineItem?.readStatus);
    const [checkStatusClass, setCheckStatusClass] = useState("open");

    
    const detailedTimestamp = () => {
        if(!detailed) return undefined;

        return (
            <div className="date f cR g">
                <div className="f tR">
                    {session?.env?.functions?.reformatDate(timelineItem?.dateCreated ?? timelineItem?.timeStamp)}
                </div>
                <div className="f bR">
                    {session?.env?.functions?.reformatDate(timelineItem?.dateCreated ?? timelineItem?.timeStamp, "timestamp")}
                </div>
            </div>
        )
    }

    useEffect(() => {
        const updateTime = () => {
            setTimeAgo(session?.env?.functions?.timeAgo(timelineItem?.timeStamp));
        };

        // Initial update
        updateTime();

        // Update every second
        const intervalId = setInterval(updateTime, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [timelineItem?.timeStamp]);

    const ineligibleType = (type) => {
        if (!type) return false;
        
        const ineligibleTypes = [
            "Closing - Deficiency", "Closing - Contract", "Closing - CRF"
        ];
        
        return ineligibleTypes.includes(type);
    }    

    if(ineligibleType(timelineItem?.type)) return;

    const commonProps = {
        session : session,
        branch : branch,
        stem : stem,
        currentCase : currentCase,
        activityView : props?.activityView,
        timelineData : timelineData,
        timelineItem : timelineItem,
        timeAgo : detailedTimestamp() ?? timeAgo,
        readStatus : props?.readStatus,
        detailed : detailed,
        description : description,
        remotePath : props?.remotePath,
        onClick : () => {
            session?.env?.setOverlay({
                name : "statusDetail",
                props : {
                    currentCase : currentCase,
                    branch : branch,
                    stem : stem,
                    timelineItem : timelineItem,
                }
            })
        }
    };

    let timelinePost;
    let inline = false;

    switch (timelineItem?.type) {
        case "Note":
            timelinePost = <Note {...commonProps} />;
            break;
        case "Request":
            if(stem === "origination") timelinePost = <OriginationRequest {...commonProps} />
            if(stem === "bids" || stem === "tertiary") timelinePost = <BiddingRequest {...commonProps} />; inline = true;
            break;
        case "VOC":
            timelinePost = <VOC {...commonProps} />;
            break;
        case "Payment":
            timelinePost = <Payment {...commonProps} />;
            break;
        case "Response - Bid":
        case "Response - Request":
            timelinePost = <Response {...commonProps} />;
            break;
        case "Bid":
            if (stem === "bids" || stem === "tertiary"){
                timelinePost = <BiddingOffer {...commonProps} />;
                inline = true;
            }
            break;
        case "Follow Up":
            timelinePost = <FollowUp {...commonProps} />;
            break;
        default:
            timelinePost = <Status {...commonProps} />;
    }

    return inline && !detailed ? (
        <div className="treelinePost f cC g">
            <div className="f cC s e">
                <div className="treeline"></div>
            </div>
            {timelinePost}
        </div>
    ) : (
        timelinePost
    );
}

export default TimelinePostHandler;