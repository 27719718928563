import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';

import { convertIntToCurrency, timeStamp, useMobileDetect, leadSourceTag } from '../envFunctions.js';
import { parseJWT } from '../envFunctions.js';

import StatusIcon from '../Components/StatusIcons';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import SecurityIcon from '@mui/icons-material/Security';
import TimelineIcon from '@mui/icons-material/Timeline';
import CommentIcon from '@mui/icons-material/Comment';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import ShieldIcon from '@mui/icons-material/Shield';

function PolicyRows(props){
    let notification = false;
    let displayType = "policyRowBlock";
    const viewType = props?.viewType;
    const [policyRow, setPolicyRow] = useState(printPolicyRow);

    function displayMatchingText(textVal){
        let displayTextPreNoMatch = null;
        let displayTextPostNoMatch = null;

        for (var i = 0; i < props.searchValHandler.length; i++){
            if(props.searchValHandler[i] !== undefined && textVal.toUpperCase().indexOf(props.searchValHandler[i].toUpperCase()) !== -1){
                if(textVal.toUpperCase().indexOf(props.searchValHandler[i].toUpperCase()) > 0){
                    displayTextPreNoMatch = textVal.substring(0, textVal.indexOf(props.searchValHandler[i]));
                }

                let displayTextMatch = textVal.substring(textVal.toUpperCase().indexOf(props.searchValHandler[i].toUpperCase()), ( props.searchValHandler[i].length + textVal.toUpperCase().indexOf(props.searchValHandler[i].toUpperCase())));

                if((textVal.toUpperCase().indexOf(props.searchValHandler[i].toUpperCase()) + (props.searchValHandler[i].length + props.searchValHandler[i])) !== ( textVal.length)){
                    displayTextPostNoMatch = textVal.substring(( props.searchValHandler[i].length + textVal.toUpperCase().indexOf(props.searchValHandler[i].toUpperCase())), textVal.length);
                }else{

                }

                return (matchingDisplayText(displayTextPreNoMatch, displayTextMatch, displayTextPostNoMatch))
            }
        }

        return textVal;
    }

    if(props.policyData?.readStatus === true){
        notification = true;
        displayType = "policyRowBlock active";
    }

    if(parseJWT(props.sessionUser?.sessionToken)?.managedAccounts?.length){
        displayType += " managed";
    }

    if(props.policyData?.fundID){
        displayType += " fund";
    }

    function matchingDisplayText(unmatchingPreText, matchingText, unmatchingPostText){
        let returningTextDisplay = [];
        if(unmatchingPreText){
            returningTextDisplay.push(<span key="leftText">{unmatchingPreText}</span>);
        }

        returningTextDisplay.push(<span key="matchingText" className="matchingText">{matchingText}</span>);

        if(unmatchingPostText){
            returningTextDisplay.push(<span key="rightText">{unmatchingPostText}</span>);
        }

        return (
                <span>{ returningTextDisplay }</span>
        );
    }

    function policyPath(){
        var hash = "#timeline";
        if(viewType === "mobile"){
            hash = "#details";
        }

        if(props.policyData?.fundID){
            return "/policies/servicing/" + props.policyData?.recordID + hash;
        }

        if(props.policyData?.relatedPolicyID){
            return "/policies/origination/" + props.policyData?.recordID + hash;
            // return "/policies/" + props.policyData?.relatedPolicyID + hash;
        }
    }

    function printPolicyRow(){
        if(!props.active){
            return null;
        }

        if(props?.sessionUser?.accountData?.[props?.sessionUser?.accountID]?.type === "Marketing"){
            return (
                <div className={ displayType + " alt g bR"}>
                    <div className="header g">
                        <div className={"insuredCount cC gC2"}>
                            { props.policyData.secondaryFirstName ?
                                <GroupIcon/>
                                :
                                <PersonIcon/>
                            }
                        </div>
                        {/* {!props.policyData.relatedFundID &&
                            <div className={viewType === "mobile" ? "gC3 cL" : "cL"}>
                                {leadSourceTag(props.policyData?.leadSource)}
                            </div>
                        } */}
                        {/* {parseJWT(props.sessionUser?.sessionToken)?.managedAccounts?.length > 1 && */}
                        {viewType !== "mobile" &&
                            <div className="cR gC4 account">
                                <div className="cL e">
                                    {props.policyData.sourceName}
                                </div>
                                <div className="cC">
                                    {props.policyData?.fundID ?
                                        <SecurityIcon sx={{color:"#ff9800"}}/>
                                    :
                                        <ShieldIcon sx={{color:"#00BFFF"}}/>
                                    }
                                </div>
                            </div>
                        }
                        <div className="cR e">
                            {timeStamp(props.policyData.lastUpdate)}
                        </div>
                    </div>
                    <div className="divider">
                    </div>
                    <div className="details g bold">
                        <div className="cC">
                            {<StatusIcon currentStatus={props.policyData?.policyStatus} type={"Status"} />}
                        </div>
                        {viewType !== "mobile" &&
                            <div className="cL">
                                { props.policyData?.policyStatus === "Lead" ?
                                    "Sent to Marketplace"
                                : 
                                    props.policyData?.policyStatus
                                }
                            </div> 
                        }
                        {viewType !== "mobile" &&
                            <div className="cC">
                                    {displayMatchingText(props.policyData?.policyNumber)}
                            </div>
                        }
                        <div className={viewType === "mobile" ? "cL" : "cC"}>
                            <div className="cC">
                                <span className="e">{displayMatchingText(props.policyData?.primaryFirstName ?? props.policyData?.firstName)}</span>
                                <span>{displayMatchingText(props.policyData?.lastName)}</span>
                            </div>
                        </div>
                        <div className="cC">
                            {convertIntToCurrency(props.policyData?.deathBenefit, viewType === "mobile")}
                        </div>
                        <div className="cR e">
                            {props.policyData?.insuranceCompany}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <Link className={ displayType + " g bR"}
                to={policyPath()}
                onClick={ () => {
                    props.changeActivityView("singlePolicy");
                }}>
                <div className="header g">
                    <div className="cC gC1">
                        {
                            props?.policyData?.readStatus === true &&
                            <div className="notificationAlert">
                            </div>
                        }
                    </div>
                    {viewType !== "mobile" &&
                        <div className="cC gC2">
                            <div className={props.policyData.numActivitiesUnread > 0 ? "alertStatus active cC g" : "alertStatus cC g"}>
                                <TimelineIcon/>
                                <div className="data f cL s">
                                    {props.policyData.numActivitiesUnread}
                                </div>
                            </div>
                        </div>
                    }
                    {viewType !== "mobile" &&
                        <div className="cC">
                            <div className={props.policyData.numMessagesUnread > 0 ? "alertStatus active cC g" : "alertStatus cC g"}>
                                <CommentIcon/>
                                <div className="data f cL s">
                                    {props.policyData.numMessagesUnread}
                                </div>
                            </div>
                        </div>
                    }
                    {viewType !== "mobile" && !props.policyData?.fundID &&
                        <div className="cC">
                            <div className={props.policyData.numUnreadBids > 0 ? "alertStatus active cC g" : "alertStatus cC g"}>
                                <ThumbsUpDownIcon/>
                                <div className="data f cL s">
                                    {props.policyData.numUnreadBids}
                                </div>
                            </div>
                        </div>
                    }
                    <div className={viewType === "mobile" ? "insuredCount cC gC2" : props.policyData?.fundID ? "insuredCount cC gC5" : "insuredCount cC"}>
                        { props.policyData.secondaryFirstName ?
                            <GroupIcon/>
                            :
                            <PersonIcon/>
                        }
                    </div>
                    {/* {!props.policyData.relatedFundID &&
                        <div className={viewType === "mobile" ? "gC3 cL" : "cL"}>
                            {leadSourceTag(props.policyData?.leadSource)}
                        </div>
                    } */}
                    {/* {parseJWT(props.sessionUser?.sessionToken)?.managedAccounts?.length > 1 && */}
                    {Object?.keys(props?.session?.user?.data?.accountData)?.length > 1 &&
                        viewType !== "mobile" &&
                        <div className="cR gC7 account">
                            <div className="cL e">
                                {props.policyData.sourceName}
                            </div>
                            <div className="cC">
                                {props.policyData?.fundID ?
                                    <SecurityIcon sx={{color:"#ff9800"}}/>
                                :
                                    <ShieldIcon sx={{color:"#00BFFF"}}/>
                                }
                            </div>
                        </div>
                    }
                    <div className={viewType === "mobile" ? "cR gC4 e" : "cR gC8 e"}>
                        {timeStamp(props.policyData.lastUpdate)}
                    </div>
                </div>
                <div className="divider">
                </div>
                <div className="details g bold">
                    <div className="cC">
                        {<StatusIcon currentStatus={props.policyData?.policyStatus} type={"Status"} />}
                    </div>
                    {viewType !== "mobile" &&
                        <div className="cL">
                            { props.policyData?.policyStatus === "Lead" ?
                                "Sent to Marketplace"
                            : 
                                props.policyData?.policyStatus
                            }
                        </div> 
                    }
                    {viewType !== "mobile" &&
                        <div className="cC">
                                {displayMatchingText(props.policyData?.policyNumber)}
                        </div>
                    }
                    <div className={viewType === "mobile" ? "cL" : "cC"}>
                        <div className="cC">
                            <span className="e">{displayMatchingText(props.policyData?.primaryFirstName)}</span><span>{displayMatchingText(props.policyData?.primaryLastName)}</span>
                        </div>
                    </div>
                    <div className="cC">
                        {convertIntToCurrency(props.policyData?.deathBenefit, viewType === "mobile")}
                    </div>
                    <div className="cR e">
                        {props.policyData?.insuranceCompany}
                    </div>
                </div>
            </Link>
        )
    }

    useEffect(() => {
        setPolicyRow(printPolicyRow());
     }, [props.searchValHandler, viewType]);

    return policyRow;
}
export default PolicyRows;

