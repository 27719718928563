import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

const useDeepEffect = (callback, dependencies, deep = false, call, isTyping) => {
  // console.log(call);
  const prevDepsRef = useRef(dependencies.map(dep => cloneDeep(dep)));
  const initialRun = useRef(true); // Track the first execution

  useEffect(() => {
    if(isTyping){
      return;
    }

    let hasChanged = false;
    const currentDeps = dependencies.map(dep => cloneDeep(dep));

    // Check each dependency for changes
    for (let i = 0; i < dependencies.length; i++) {
      if (deep) {
        if (!isEqual(prevDepsRef.current[i], currentDeps[i])) {
          hasChanged = true;
          break;
        }
      } else {
        const prevKeys = Array.isArray(prevDepsRef.current[i]) ? prevDepsRef.current[i] : Object.keys(prevDepsRef.current[i] || {});
        const currentKeys = Array.isArray(currentDeps[i]) ? currentDeps[i] : Object.keys(currentDeps[i] || {});
        if (prevKeys.length !== currentKeys.length || prevKeys.some((key, index) => !isEqual(key, currentKeys[index]))) {
          hasChanged = true;
          break;
        }
      }
    }

    // Special handling for the first run with an empty array included
    if (initialRun.current) {
      hasChanged = true; // Force change detection on first run
      initialRun.current = false; // Reset initial run after first execution
    }

    if (hasChanged) {
      callback();
    }

    prevDepsRef.current = currentDeps; // Update the ref with the current dependencies for next comparison
  }, [dependencies, deep, callback, call, isTyping]); // Include all necessary dependencies
};

export default useDeepEffect;
