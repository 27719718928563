import React, { useEffect, useState, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function OriginationBreakdown(props){
    const chartContainer = useRef(null);
    const [chart, setChart] = useState(null);
    const [toggleButton, setToggleButton] = useState(true);

    function printPolicyBreakDown(count, type){
        if(count === undefined){
            return '';
        }

        if(type === "total"){
            if(count > 1 || count === 0){
                return count + " policies"
            }else{
                return count + " policy";
            }
        }

        function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        if(type === "value"){
            if(count === "N/A" || count === '' || count === undefined || count === null){
                return "-";
            }else{
                return "$" + numberWithCommas(count);
            } 
        }
    }

    return (
        <div key="originationBreakdown" className="policyPositionBlock moduleBlock">
            <div className="breakdownBlock g fC cC">
                <div className="header cC bold">
                    Breakdown
                </div>
                <div className="breakdownColumnContainer g">
                    <div className="gC2 f cL bold">
                        Status
                    </div>
                    <div className="f cR e bold">
                        Count
                    </div>
                </div>
                <div className="overViewDataBottomSection g fMP bR tO">
                    <div className={props.breakdownList.length === 0 ? "breakdownTimeline cC loading" : "breakdownTimeline g fC cC t b"}>
                        {props.breakdownList.length === 0 ?
                            <div>
                                <CircularProgress color="inherit"/>
                            </div>
                            :
                            props.breakdownList
                        }
                    </div>
                </div>
            </div>
           
        </div>
    );
};

export default OriginationBreakdown;