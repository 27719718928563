import React from 'react';
import { useState, useEffect, useRef } from 'react';

import './HomePage.css';
import '../Components/DonutChart/DonutChart.css';

import StatusIcon from "../Components/StatusIcons";
import LineChart from "../Components/Chart/LineChart.js";
import DonutChart from "../Components/DonutChart/DonutChart.js";
import OriginationBreakdown from "./OriginationBreakdown";
import FundBreakdown from "./FundBreakdown";
import MarketingBreakdown from "./MarketingBreakdown";
import PolicyTrackline from "./PolicyTrackline";
import ReportSheet from '../Components/Reporting/ReportSheet.js';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CircularProgress from '@mui/material/CircularProgress';
import TimelineIcon from '@mui/icons-material/Timeline';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function HomePage(props){
    const session = props?.session;
    const accountType = props.sessionUser?.accountData?.[props.sessionUser?.accountID]?.type;
    const reporting = props?.session?.reporting;
    const statusTimeline = useRef(null);
    const statusTimelineInner = useRef(null);
    const [breakdownList, setBreakdownList] = useState([]);
    const [isOriginationView, setIsOriginationView] = useState(accountType && /Broker|Agent|Origination|Marketing/.test(accountType));
    const [isFundView, setIsFundView] = useState(accountType && /Fund/.test(accountType));
    const [timelineType, setTimelineType] = useState(props?.session?.storage?.preferences?.homepage?.timelineType || "recentActivity");
    const [dropdowns, setDropdowns] = useState({
        selectedFolder : {
            showMenu : false,
            name : "Show All",
            onClick : (value) => {
                updateDropdown("selectedFolder", {"showMenu" : false, "name" : value})
            }
        },
        pagination : {
            showMenu : false,
            rowMax : props?.session?.storage?.reporting?.rowMax ?? 100,
            index : props?.selectedReport?.lastPageIndex ?? 1,
        },
        editCellDropdownHandler : {
            showMenu : false,
            name : "Show All",
        },
    });

    const updateDropdown = (key, attributes) => {
        setDropdowns((prev) => {
            const updatedDropdowns = {
                ...prev,
                [key]: {
                    ...prev[key],
                    ...attributes, // Spread the incoming attributes to update multiple fields
                },
            };
            return updatedDropdowns;
        });
    };

    const resetDropdowns = () => {
        setDropdowns((prev) => {
            const updated = Object.keys(prev).reduce((acc, key) => {
                acc[key] = { ...prev[key], showMenu: false };
                return acc;
            }, {});
            return updated;
        });
    };

    // const [pagination, setPagination] = useState({
    //     showMenu : false,
    //     rowMax : props?.session?.storage?.reporting?.rowMax ?? 100,
    //     index : props?.selectedReport?.lastPageIndex ?? 1,
    // });
    function printTimeline(){
        // console.log(session?.case?.data?.policies?.origination);
        if((accountType === "Marketing" || timelineType === "trackline") && session?.case?.data?.policies?.origination?.length){
            if(!props?.chartData){
                return(
                    <div className="hptl homePageTimeline moduleBlock marketing loading">
                        <div className="cC f">
                            <div>
                                <CircularProgress color="inherit"/>
                            </div>
                        </div>
                    </div>
                )
            }

            return (
                <div className="hptl f cC">
                    <div className="homePageTimeline moduleBlock marketing">
                        <div className="homePageTimelineBlock g cC fC" ref={statusTimeline}>
                            <div className="timelineHeader f g cC fC">
                                <div className="header g f">
                                    <div className="gC2 cC bold">
                                        Policy Pipeline
                                    </div>
                                    {accountType !== "Marketing" && accountType !== "Fund" &&
                                        <div className="f cC">
                                            <div className="btnWIcon g bold bR fMP cC p ns oH" onClick={()=>{changeTimeline("recentActivity")}}>
                                                <div className="gCW">
                                                    Recent Activity
                                                </div>
                                                <TimelineIcon/>
                                            </div>
                                        </div>
                                    }
                                </div>   
                            </div>
                            <div className="timeline g fMP tO" ref={statusTimelineInner} onScroll={(evt)=>{handleScroll(evt)}}>
                                <div className="trackerTimeline g b bR t b fC cC">
                                    {!props?.filterHandler ?
                                        <div className="noPolicyResultsMessage">
                                            No current policy status to show
                                        </div>
                                    :
                                        <PolicyTrackline
                                            chartData={props.chartData}
                                            filterHandler={props.filterHandler}
                                            setFilterHandler={props.setFilterHandler}
                                            session={props?.session}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if(!props.homepageTimeline){
            return(
                <div className="hptl f cC">
                    <div className={homePageTimelineView() + " loading"}>
                        <div className="cC f loading">
                            <div>
                                <CircularProgress color="inherit"/>
                            </div>
                        </div>
                    </div>
                </div>

            )
        }

        return (
            <div className="hptl f cC">
                <div className={homePageTimelineView()}>
                    <div className="homePageTimelineBlock g cC fC" ref={statusTimeline}>
                        {displayTimelineHeader()}
                        <div className="timeline g fMP tO" ref={statusTimelineInner} onScroll={(evt)=>{handleScroll(evt)}}>
                            <div className="singleTimeline g b bR t b fC cC">
                                {Object.keys(props.visibleTimeline).length === 0 || Object.keys(props.homepageTimeline).length === 0 ?
                                    <div className="noPolicyResultsMessage">
                                        No current activity to show
                                    </div>
                                :
                                    props.visibleTimeline
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function printStatusBreakdown(){
        var activeListOrder = { 
            "Lead" : 3,
            "Application Sent" : 4,
            "Pre-Priced" : 5,
            "Meds/Illustration Ordered" : 6,
            "Underwriting (LE's)" : 7,
            "Shipped" : 8,
            "Bids" : 9,
            "Sent to 3rd Party" : 10,
            "Offer Letter Out / CRF" : 11,
            "Offer Letter In" : 12,
            "Contracts Out" : 13,
            "Contracts Back (Digital)" : 14,
            "Contracts Back" : 15,
            "Escrow / At Carrier" : 16,
            "Rescission" : 17,
            "Invoicing" : 18,
            "Contact Attempted" : 0,
            "Failed to Contact" : 1,
            "On Hold" : 2
        };

        var tempBreakdownList = [];

        for (var category of Object.keys(props.chartData)){
            if(props.chartData[category].status !== undefined && props.chartData[category].position === "Active"){
                tempBreakdownList[activeListOrder[props.chartData[category].status]] = (
                    <div key={props.chartData[category].status} className="breakdownTimelineRow s e">
                        <div className="breakdownTimelineRowInner">
                            <div className="breakdownTimelineValue">
                                <div className="breakdownStatusIcon" style={{backgroundColor:props.chartData[category].color}}>
                                    {props.chartData[category].icon}
                                </div>
                            </div>
                            <div className="breakdownTimelineValue status bold">
                                {props.chartData[category].status === "Lead" ? "Sent to Marketplace" : props.chartData[category].status}
                            </div>
                            <div className="breakdownTimelineValue count">
                                <b>{ props.chartData[category].count}</b>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        let returningBreakdownList = [];
        for (var i = tempBreakdownList.length - 1; i >= 0; i--) {
            returningBreakdownList.push(tempBreakdownList[i]);
        }

        setBreakdownList(returningBreakdownList);
    }

    // props?.session?.useListen(() => {
    //     console.log('Dependencies changed');
    // }, [props?.session?.dashboard, false]);
/* asjkldf;jlasdfl;jkasdf */
    function loadHomePageDetailsBlock(){
        var loadingStatus = false;
        let returningDetailsBlock = [];

        returningDetailsBlock = [
            <div key="tempBlockTop" className="tempHomePageBlock moduleBlock">
            </div>,
            <div key="tempBlockBottom" className="tempHomePageBlock moduleBlock">
            </div>
        ];

        if(isOriginationView){
            if(!props.chartData){
                loadingStatus = true;
            }

            let visibleBreakdown =
                <OriginationBreakdown
                    key="originationBreakdown"
                    sessionUser={props.sessionUser}
                    breakdownList={breakdownList}
                />;

            if(accountType === "Marketing" || timelineType === "trackline"){
                visibleBreakdown = 
                <MarketingBreakdown
                    key="marketingBreakdown"
                    chartData={props?.chartData}
                    total={loadingStatus ? 0 : props.chartData.total}
                />
            }

            returningDetailsBlock = [
                <div key="donutChart" className={loadingStatus ? "chartBlock moduleBlock g cC fC oH loading" : "chartBlock moduleBlock g cC fC oH"}>
                    <div className="header bold">Policies Overview</div>
                    <DonutChart
                        session={props?.session}
                        key="0"
                        chartData={props.chartData}
                        friendlyTotal={props?.chartData?.friendlyTotal}
                        total={loadingStatus ? 0 : props.chartData.total}
                        sessionUser={props.sessionUser}    
                    />
                </div>,
                visibleBreakdown
            ];
        }

        const currentComponent = 2;
        const currentReport = props?.session?.dashboard?.data?.selectedDashboard?.components?.[currentComponent]?.selectedReport || {};
        const items = props?.session?.[reporting?.data?.dataPointer]?.data?.
            [currentReport?.referenceBranch ?? currentReport?.branch]?.
                [currentReport?.referenceStem ?? currentReport?.stem];
        currentReport.list = reporting?.functions?.filterItemsWithQuery(currentReport?.query, items);
        const reportType = reporting?.functions?.reportType(currentReport?.stem);

        if(isFundView){
            returningDetailsBlock = [
                <FundBreakdown
                    key="fundBreakdown"
                    sessionUser={props.sessionUser}
                    breakdownList={breakdownList}
                    chartData={props.chartData}
                    graphData={props.graphData}
                    mobileDevice={props.mobileDevice}
                    session={props?.session}
                />,
                props?.session?.user?.data?.accountID === 10 ?
                    <div
                        className="reportBuilder remote g oH"
                        key="reportBuilder"
                    >
                        <div className="remoteReportTopBar g cC bold fR s e">
                            {reportType === "dynamic" && currentReport?.subReport?.list &&
                                <div className="f cL">
                                    <div
                                        className="btnWIcon gC2 bR dG cC p bold lH"
                                        onClick={()=>{
                                            const subReport = {...currentReport?.subReport};
                                            subReport.list = undefined;
                                            subReport.columns = undefined;
                                            subReport.name = undefined;
                                            session?.set("dashboard", `selectedDashboard.components.${currentComponent}.selectedReport.subReport`, subReport);
                                        }}
                                    >
                                        <ChevronLeftIcon/>
                                        <div className="f cC e">
                                            {currentReport?.details?.name ?? "Unnamed Report"}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="gC2">
                                {currentReport?.details?.name}
                            </div>
                        </div>
                        <div className="f dP oA">
                            <ReportSheet
                                session={props?.session}
                                remoteReport={currentReport ?? undefined}
                                selectedDashboard={props?.session?.dashboard?.data?.selectedDashboard?.components?.[currentComponent]}
                                remotePath={`selectedDashboard.components[${currentComponent}].`}
                                remotePathEnd={`selectedDashboard.components[${currentComponent}]`}
                                reportType={"inherited"}
                                // pagination={{data : pagination, set : setPagination}}
                                baseModule={"dashboard"}
                                baseData={props?.session?.dashboard?.data?.selectedDashboard?.components?.[currentComponent]}
                                dropdowns={{data : dropdowns, set : updateDropdown}}
                            />
                            {/* <ReportSheet
                                session={props?.session}
                                remoteReport={currentReport ?? undefined}
                                selectedDashboard={props?.session?.dashboard?.data?.selectedDashboard?.components?.[currentComponent]}
                                remotePath={`selectedDashboard.components[${currentComponent}].`}
                                remotePathEnd={`selectedDashboard.components[${currentComponent}]`}
                                reportType={"inherited"}
                                pagination={{data : pagination, set : setPagination}}
                                baseModule={"dashboard"}
                                baseData={props?.session?.dashboard?.data?.selectedDashboard?.components?.[currentComponent]} */}
                            {/* /> */}
                        </div>
                    </div>
                :
                    <LineChart
                        key="lineChart"
                        graphData={props.graphData}
                        mobileDevice={props.mobileDevice}
                        sessionUser={props.sessionUser}
                        session={props?.session}
                    />
            ]
        }

        return returningDetailsBlock
    }

    function handleScroll(evt){
        const element = evt.target;
        if(props.visibleMaxStatusCount > Object.keys(props.policies).length || element.scrollTop === 0){
            return;
        }

        const isScrollHeightDouble = element.scrollHeight >= 2 * element.offsetHeight;

        if (element.scrollHeight - element.scrollTop <= element.clientHeight + 100 && isScrollHeightDouble) {
            props.setVisibleMaxStatusCount?.(props.visibleMaxStatusCount + 20);
        }

        // const { scrollTop, scrollHeight, offsetHeight } = e.target;
        // const hasScrollReachedBottom =
        //         offsetHeight + scrollTop > scrollHeight - 40;

        // if (hasScrollReachedBottom && props.visibleMaxStatusCount < props.statusMaxCount) {
        //     props.setVisibleMaxStatusCount(props.visibleMaxStatusCount + 20);
        // }
    }

    function homePageTimelineView(){
        if(isFundView){
            return "homePageTimeline moduleBlock fundView";
        }

        if(isOriginationView){
            return "homePageTimeline moduleBlock";
        }

        return "homePageTimeline moduleBlock";
    }

    function homePageInfoPanel(){
        if(isFundView){
            return "homePageInfoPanel fundView g";
        }

        if(isOriginationView){
            return "homePageInfoPanel";
        }

        return "homePageInfoPanel";

    }

    function homePageType(){
        if(isOriginationView){
            return "origination";
        }

        if(isFundView){
            return "fund";
        }
    }

    function changeTimeline(type) {
        const storageData = JSON.parse(window?.localStorage?.getItem('storage')) || {};
        storageData.preferences = storageData?.preferences || {};
        storageData.preferences.homepage = storageData?.preferences?.homepage || {};
        storageData.preferences.homepage.timelineType = type;
        window.localStorage.setItem('storage', JSON?.stringify(storageData));
    
        setTimelineType(type);
    }

    function displayTimelineHeader(){
        return (
            <div className="timelineHeader f g cC fC">
                <div className="header g f">
                    <div className="gC2 cC bold">
                        Recent Activity
                    </div>
                    { accountType !== "Marketing" && accountType !== "Fund" &&
                        <div className="f cC">
                            <div className="btnWIcon g bold bR fMP cC p ns" onClick={()=>{changeTimeline("trackline")}}>
                                Trackline
                                <ViewTimelineIcon/>
                            </div>
                        </div>
                    }
                </div>

                { isOriginationView &&
                <div className="homePageColumnHeader bold originationView">
                    <div className="cL gC2">
                        Status
                    </div>
                    {!props.mobileDevice &&
                        <div className="cC">
                            Policy Number
                        </div>
                    }
                    <div className="cC">
                        Insured Name
                    </div>
                    <div className="cC">
                        Death Benefit
                    </div>
                    <div className="cR e">
                        <AccessTimeIcon/>
                    </div>
                </div> }
            </div>
        )
    }

    useEffect(() =>{
        //console.log(accountType, isOriginationView);
    },[])

    useEffect(() => {
        if(props?.chartData){
            printStatusBreakdown();
        }
    }, [props.chartData]);

    useEffect(() => {
        props.loadHomepageTimeline();
    }, [props.visibleMaxStatusCount, props.homepageTimeline]);

    useEffect(() => {
        if(accountType){
            if(/Broker|Agent|Origination|Marketing/.test(accountType)){
                setIsOriginationView(/Broker|Agent|Origination|Marketing/.test(accountType));
            }

            if(/Fund/.test(accountType)){
                setIsFundView(/Fund/.test(accountType));
            }
        }
    }, [props.sessionUser])

    return (
        <div className={"homePage " + homePageType()}>
            <div className={"homePageOverview"}>
                <div className={homePageInfoPanel()}>
                    {loadHomePageDetailsBlock()}
                </div>
                {printTimeline()}
            </div>
        </div>
    )
}

export default HomePage;

/*            <div className="homePagePieChart">
                <div className="homePageTotalCount">
                    <span>Total Policies</span>
                    <div>
                        {props.account.totalCaseCount}
                    </div>
                </div>
                <Canvas draw={draw} height={100} width={100} />
                <div className="bottomBlock">
                </div>
            </div>*/