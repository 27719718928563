import React from 'react';
import { useState, useEffect, useRef } from 'react';

import FolderTwoToneIcon from '@mui/icons-material/FolderTwoTone';
import FolderOffTwoToneIcon from '@mui/icons-material/FolderOffTwoTone';

function DocumentsTab(props){
    const session = props?.session;
    const shareLink = props?.shareLink;

    return (
        <div className="documentsBlock f" key="documents">
            <div className="documentsBlockInner">
                {!shareLink ? (
                    <div className="f g cC">
                        <div className="g cC dG">
                            {shareLink === undefined ?
                                <>
                                    <FolderTwoToneIcon />
                                    Loading Drive
                                </>
                            :
                                <>
                                    <FolderOffTwoToneIcon />
                                    Failed to Load Drive
                                </>
                            }
                        </div>
                    </div>
                ) : (
                    <iframe className="singlePolicyDocuments" security="restricted" src={shareLink} />
                )}
            </div>
        </div>
    );
}

export default DocumentsTab;