import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import './Leads.css';

import SingleLead from './SingleLead/SingleLead.js';
import LeadsOverview from './LeadsOverview.js';
import LoadingScreen from '../Components/LoadingScreen.js';
import SingleView from '../Components/SingleView.js';

function Leads(props){
  const session = props?.session;
  const location = useLocation();
  const currentPath = session?.env?.functions?.currentPath(location);

  return (
    <div className="leadsPage">
      {!session?.case?.data?.leads?.leads ?
          <LoadingScreen
              page="leads"
          />
      :
        currentPath?.recordID ? 
            <SingleView
                session={session}
                branch={"leads"}
                stem={currentPath?.stem}
                recordID={currentPath?.recordID}
            />
        :
          <Routes>
            <Route
              exact
              path="/"
              element={
                <LeadsOverview
                  updatePage={props.updatePage}
                  sessionUser={props.sessionUser}
                  leadProps={props.leadProps}
                  mobileDevice={props.mobileDevice}
                  navigation={props.navigation}
                  session={props?.session}
                />
              }
            />
            {/* <Route
              path="/:leadID"
              element={
                <SingleLead
                  relatedPolicyID={props.relatedPolicyID}
                  setRelatedPolicyID={props.setRelatedPolicyID}
                  policyNumber={props.policyNumber}
                  setPolicyNumber={props.setPolicyNumber}
                  changeActivityView={props.changeActivityView}
                  sessionUser={props.sessionUser}
                  updatePage={props.updatePage}
                  loadNotifications={props.loadNotifications}
                  markSessionPolicyAsRead={props.markSessionPolicyAsRead}
                  notifications={props.notifications}
                  singlePolicyHash={props.singlePolicyHash}
                  setSinglePolicyHash={props.setSinglePolicyHash}
                  mobileDevice={props.mobileDevice}
                  leadsList={props.leadsList}
                  setLeadsList={props.setLeadsList}
                  setImportedUploadPolicy={props.setImportedUploadPolicy}
                  leadProps={props.leadProps}
                  navigation={props.navigation}
                  sessionVars={props.sessionVars}
                  session={props?.session}
                />
              }
            /> */}
        </Routes>
      }
    </div>
  );
}

export default Leads;