import React from 'react';

import HourglassTopTwoToneIcon from '@mui/icons-material/HourglassTopTwoTone';
import ReceiptLongTwoToneIcon from '@mui/icons-material/ReceiptLongTwoTone';
import MedicalInformationTwoToneIcon from '@mui/icons-material/MedicalInformationTwoTone';
import NoteAltTwoToneIcon from '@mui/icons-material/NoteAltTwoTone';
import LockPersonTwoToneIcon from '@mui/icons-material/LockPersonTwoTone';
import RequestQuoteTwoToneIcon from '@mui/icons-material/RequestQuoteTwoTone';
import PriceChangeTwoToneIcon from '@mui/icons-material/PriceChangeTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import PersonOffTwoToneIcon from '@mui/icons-material/PersonOffTwoTone';
import EmojiPeopleTwoToneIcon from '@mui/icons-material/EmojiPeopleTwoTone';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';
import PanToolTwoToneIcon from '@mui/icons-material/PanToolTwoTone';
import RequestPageTwoToneIcon from '@mui/icons-material/RequestPageTwoTone';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import DoNotDisturbOnTwoToneIcon from '@mui/icons-material/DoNotDisturbOnTwoTone';
import MessageTwoToneIcon from '@mui/icons-material/MessageTwoTone'; // Already TwoTone
import ThumbDownOffAltTwoToneIcon from '@mui/icons-material/ThumbDownOffAltTwoTone';
import CelebrationTwoToneIcon from '@mui/icons-material/CelebrationTwoTone';
import ForwardTwoToneIcon from '@mui/icons-material/ForwardTwoTone';
import AddTaskTwoToneIcon from '@mui/icons-material/AddTaskTwoTone';
import AccessAlarmTwoToneIcon from '@mui/icons-material/AccessAlarmTwoTone';
import PhoneMissedTwoToneIcon from '@mui/icons-material/PhoneMissedTwoTone';
import CallReceivedTwoToneIcon from '@mui/icons-material/CallReceivedTwoTone';
import FollowTheSignsTwoToneIcon from '@mui/icons-material/FollowTheSignsTwoTone';
import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone';
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import SellTwoToneIcon from '@mui/icons-material/SellTwoTone';
import TimelapseTwoToneIcon from '@mui/icons-material/TimelapseTwoTone';
import GavelTwoToneIcon from '@mui/icons-material/GavelTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone'; // Already TwoTone
import CampaignTwoToneIcon from '@mui/icons-material/CampaignTwoTone';
import ExtensionOffTwoToneIcon from '@mui/icons-material/ExtensionOffTwoTone';
import ThumbsUpDownTwoToneIcon from '@mui/icons-material/ThumbsUpDownTwoTone'; // Already TwoTone
import SentimentVeryDissatisfiedTwoToneIcon from '@mui/icons-material/SentimentVeryDissatisfiedTwoTone';
import RunningWithErrorsTwoToneIcon from '@mui/icons-material/RunningWithErrorsTwoTone';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import PhoneDisabledTwoToneIcon from '@mui/icons-material/PhoneDisabledTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import NearbyErrorTwoToneIcon from '@mui/icons-material/NearbyErrorTwoTone';
import RestartAltTwoToneIcon from '@mui/icons-material/RestartAltTwoTone';
import NotInterestedTwoToneIcon from '@mui/icons-material/NotInterestedTwoTone';
import ThumbDownTwoToneIcon from '@mui/icons-material/ThumbDownTwoTone';
import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone';
import StartTwoToneIcon from '@mui/icons-material/StartTwoTone';
import TextSnippetTwoToneIcon from '@mui/icons-material/TextSnippetTwoTone'; // Already TwoTone
import ReplyTwoToneIcon from '@mui/icons-material/ReplyTwoTone'; // Already TwoTone
import PsychologyAltTwoToneIcon from '@mui/icons-material/PsychologyAltTwoTone'; // Already TwoTone
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone'; // Already TwoTone
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone'; // Already TwoTone
import ThumbDownAltTwoToneIcon from '@mui/icons-material/ThumbDownAltTwoTone'; // Already TwoTone
import RecommendTwoToneIcon from '@mui/icons-material/RecommendTwoTone'; // Already TwoTone
import UploadFileTwoToneIcon from '@mui/icons-material/UploadFileTwoTone'; // Already TwoTone
import NextPlanTwoToneIcon from '@mui/icons-material/NextPlanTwoTone';

const statusData = (detectedStatus, noColor, originator) => {
    const icon = (originator === "Customer" && detectedStatus?.customerIcon) 
        ? detectedStatus.customerIcon 
        : detectedStatus?.icon;

    // If `icon` is not defined, use a default icon
    if (!icon) return <NextPlanTwoToneIcon />; // Fallback to a default icon

    const color = noColor
        ? "#FFFFFF"
        : (originator === "Customer" && detectedStatus?.customerColor)
            ? detectedStatus.customerColor
            : detectedStatus?.color;

    return React.cloneElement(icon, {
        sx: { color }
    });
};

const statuses = (status) => {
    let color, icon;

    switch (status) {
        case "Invoicing":
            color = "#673ab7";
            icon = <ReceiptLongTwoToneIcon />;
            break;
        case "Rescission":
            color = "#3f51b5";
            icon = <HourglassTopTwoToneIcon />;
            break;
        case "Meds/Illustration Ordered":
            color = "#e53935";
            icon = <MedicalInformationTwoToneIcon />;
            break;
        case "Underwriting (LE's)":
            color = "#2196f3";
            icon = <NoteAltTwoToneIcon />;
            break;
        case "Contracts Back":
        case "Contracts Back (Digital)":
        case "Contracts Out":
            color = "#f9a825";
            icon = <LockPersonTwoToneIcon />;
            break;
        case "Offer Letter Out / CRF":
            color = "#455a64";
            icon = <RequestQuoteTwoToneIcon />;
            break;
        case "Offer Letter In":
            color = "#607d8b";
            icon = <RequestPageTwoToneIcon />;
            break;
        case "Bids":
            color = "#6a1b9a";
            icon = <ThumbsUpDownTwoToneIcon />;
            break;
        case "Pre-Priced":
            color = "#4caf50";
            icon = <PriceChangeTwoToneIcon />;
            break;
        case "Client Not Interested":
        case "Client not Interested":
            color = "#ef5350";
            icon = <PersonOffTwoToneIcon />;
            break;
        case "Failed to Contact":
            color = "#ef5350";
            icon = <PhoneDisabledTwoToneIcon />;
            break;
        case "Unqualified":
            color = "#ef5350";
            icon = <CancelTwoToneIcon />;
            break;
        case "Sent to 3rd Party":
            color = "#f57c00";
            icon = <EmojiPeopleTwoToneIcon />;
            break;
        case "Sent to Abacus":
            color = "#104735";
            icon = <RestartAltTwoToneIcon />;
            break;
        case "Escrow / At Carrier":
        case "Escrow":
            color = "#1976d2";
            icon = <HandshakeTwoToneIcon />;
            break;
        case "On Hold":
            color = "#ff7043";
            icon = <PanToolTwoToneIcon />;
            break;
        case "Lead":
            color = "#9ccc65";
            icon = <FollowTheSignsTwoToneIcon />;
            break;
        case "Shipped":
        case "Shipped - Bid":
            color = "#9c27b0";
            icon = <LocalShippingTwoToneIcon />;
            break;
        case "Pricing / Valuation":
            color = "#9c27b0";
            icon = <PersonSearchTwoToneIcon />;
            break;
        case "Unqualified Closed":
        case "Declined":
            color = "#ef5350";
            icon = <DoNotDisturbOnTwoToneIcon />;
            break;
        case "Closed Lost with Bid":
        case "Lost with Bid":
        case "Lost with bid":
            color = "#ef5350";
            icon = <SentimentVeryDissatisfiedTwoToneIcon />;
            break;
        case "Closed Lost":
            color = "#ef5350";
            icon = <NotInterestedTwoToneIcon />;
            break;
        case "Closed Lost no bid":
            color = "#ef5350";
            icon = <ThumbDownTwoToneIcon />;
            break;
        case "Closed Won":
            color = "#e65100";
            icon = <CelebrationTwoToneIcon />;
            break;
        case "Application Sent":
        case "Application Sent - Lead":
            color = "#66bb6a";
            icon = <ForwardTwoToneIcon />;
            break;
        case "Contact Attempted 1":
        case "Contact Attempted 2":
        case "Contact Attempted 3":
        case "Contact Attempted 4":
        case "Contact Attempted 5":
            color = "#0277bd";
            icon = <PhoneMissedTwoToneIcon />;
            break;
        case "Application Received":
            color = "#6a1b9a";
            icon = <CallReceivedTwoToneIcon />;
            break;
        case "Follow Up":
            color = "#6a1b9a";
            icon = <AccessAlarmTwoToneIcon />;
            break;
        case "Owned":
            color = "#6d4c41";
            icon = <AccountBalanceWalletTwoToneIcon />;
            break;
        case "Sold":
            color = "#4caf50";
            icon = <SellTwoToneIcon />;
            break;
        case "Matured":
            color = "#4527a0";
            icon = <RunningWithErrorsTwoToneIcon />;
            break;
        case "Lapsed":
            color = "#ff9800";
            icon = <TimelapseTwoToneIcon />;
            break;
        case "Contracts":
            color = "#ff5722";
            icon = <GavelTwoToneIcon />;
            break;
        case "Does Not Fit Parameters":
            color = "#ef5350";
            icon = <ExtensionOffTwoToneIcon />;
            break;
        case "Bidding":
            color = "#6a1b9a";
            icon = <CampaignTwoToneIcon />;
            break;
        case "In Conflict":
            color = "#ef5350";
            icon = <NearbyErrorTwoToneIcon />;
            break;
        case "No Bid":
            color = "#ef5350";
            icon = <ThumbDownOffAltTwoToneIcon />;
            break;
        case "Under Review":
        case "Phase II":
            color = "#2196f3";
            icon = <SearchTwoToneIcon />;
            break;
        case "Requested Info":
            color = "#6a1b9a";
            icon = <PendingActionsTwoToneIcon />;
            break;
        case "Service Start":
            color = "#4caf50";
            icon = <StartTwoToneIcon />;
            break;
        case "Message":
            color = "#4fc3f7";
            icon = <ForumTwoToneIcon />;
            break;
        case "Request":
            color = "#ef5350";
            icon = <AddTaskTwoToneIcon />;
            break;
        case "Portfolio Sent":
            color = "#43a047";
            icon = <UploadFileTwoToneIcon />;
            break;
        default:
            color = "dimgray";
            icon = <NextPlanTwoToneIcon />; // Ensure this is a valid fallback
            break;
    }

    return { color, icon };
};

const types = {
    "Note" : {
        color: "#03a9f4",
        customerColor : "#66bb6a",
        icon : <MessageTwoToneIcon />,
        type : "note",
    },
    "Follow Up" : {
        color: "#6a1b9a",
        icon : <AccessAlarmTwoToneIcon />,
        type : "followUp",
    },
    "VOC" : {
        color: "#4fc3f7",
        icon : <VerifiedUserTwoToneIcon />,
        type : "voc",
    },
    "Payment" : {
        color: "#4caf50",
        icon : <MonetizationOnTwoToneIcon />,
        type : "payment",
    },
    "Response - Request" : {
        color: "dimgray",
        icon : <TextSnippetTwoToneIcon />,
        altIcon : <ReplyTwoToneIcon/>,
        type : "response",
        responseType : "Request",
    },
    "Request" : {
        color : {
            Open : "#01579b",
            Completed : "#43a047",
            Denied : "#ef5350",
        },
        icon : {
            Open : <PendingActionsTwoToneIcon />,
            Completed : <CheckCircleTwoToneIcon/>,
            Denied : <ThumbDownAltTwoToneIcon/>,
        },
        altIcon : <PsychologyAltTwoToneIcon/>,
        type : "request",
    },
    "Bid" : {
        color : {
            Open : "#6a1b9a",
            Completed : "#43a047",
            Closed : "#43a047",
            Denied : "#ef5350",
        },
        icon : {
            Open : <ThumbsUpDownTwoToneIcon />,
            Completed : <RecommendTwoToneIcon/>,
            Closed : <RecommendTwoToneIcon/>,
            Denied : <ThumbDownAltTwoToneIcon/>
        },
        altIcon : <ErrorTwoToneIcon/>,
        type : "bid",
    },
}

function statusDetails({ status, type, noColor, originator, timelineType }) {
    const data = statusData(type !== "Status" ? types?.[type] : statuses(status ?? "default"), noColor, originator);
    return data;
}

export const getStatusData = (status, type) => {
    return type !== "Status" ? types?.[type] ?? statuses("default") : statuses(status ?? "default");
};

export default statusDetails;