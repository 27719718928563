import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import StatusIcon from '../StatusIcons';
import BidTimelinePost from './BidTimelinePost';
// import LeadsIcon from './LeadsIcons';

// import CircleIcon from '@mui/icons-material/Circle';
// import HtmlReactParser from 'html-react-parser';
// import xss from 'xss';

import { timeStamp, convertIntToCurrency, parseJWT, dateFormat } from '../../envFunctions.js';

// import { dateFormat } from '../envFunctions.js';

import './TimelinePost.css';

import ShieldIcon from '@mui/icons-material/Shield';
import SecurityIcon from '@mui/icons-material/Security';
import ErrorIcon from '@mui/icons-material/Error';
import StorefrontIcon from '@mui/icons-material/Storefront';

function HomePageTimelinePost(props){
    const session = props?.session;
    const isMobile = props?.session?.env?.viewport?.viewType !== "full";
    const navigation = {...session?.navigation?.data}

    function updateInstance(instance){
        navigation.history.push({view : "dashboard", "instance" : "all"})
        session?.set("navigation",
            ["currentInstance", 'history'],
            [instance, navigation.history]
        );
    }
    var followLink;
    var hash = "#timeline";
    var timelineStatus = calcTimelineStatus();

    function calcTimelineStatus(){
        if(props.type === "Note"){
            return "Note";
        }

        if(props.status === "Lead"){
            return "Sent to Marketplace";
        }

        return props.status;
    }
    
    if(isMobile){
        hash = "#details";
    }

    if(props.type === "Status" || props.type === "Note"){
        if(props.data?.objectType === "origination"){
            followLink = "/policies/" + props.data.relatedPolicyID + hash;
        }

        if(props.data?.objectType === "servicing"){
            followLink = "/policies/servicing/" + props.data.relatedPolicyID + hash;
        }

        if(props.data?.objectType === "marketplace"){
            followLink = "/bids/" + props.data?.taskID + hash;

            let originator = "Internal";
            let bidData;
            let currentStatus = props.status;
            const alertType = calcAlertType();
            const status = [
                "Shipped",
                "Under Review",
                "Bidding",
                "In Conflict",
                "Does Not Fit Parameters",
                "No Bid",
                "Lost with Bid",
                "Lost with bid"
            ];

            function calcAlertType(){
                if(props.data?.type === "Bid"){
                    return " bidAlert";
                }
        
                if(props.data?.taskShippingStatus === "Requested Info"){
                    return " bidAlert";
                }
        
                return '';
            }

            function displayActivityType(){
                if(props.type === "Note"){
                    return "Communication";
                }
        
                if(props.type === "Bid" || props.data.taskShippingStatus === "Requested Info"){
                    originator = "Customer";
                    return [<div
                                key="bidAlert"
                                className="e"
                            >
                                Bid Alert
                            </div>,
                            <ErrorIcon/>
                        ];
                }
        
                if(status.includes(props.data?.content)){
                    return "Status Update";
                }
            }

            function gatherBid(){
                const responseString = props.data.content;
                const firstCommaIndex = responseString.indexOf(",");
                const bidAmount = responseString.slice(0, firstCommaIndex);
                const bidNote = responseString.slice(firstCommaIndex + 1);

                return ({
                    bidAmount : bidAmount,
                    bidNote : bidNote
                });
            }

            function calculateContentWrap(){
                if(props.type === "Bid"){
                    return "content bidContent g e bold";
                }
            
                if(props.type === "Note" || props.type === "Requested Info"){
                    return "content bidNote g";
                }
        
                return "content g bold";
            }

            function displayActivityStatus(){
                if(props.type === "Bid"){
                    return "Bid Sent"; 
                }
        
                if(props.status === "Lost with bid"){
                    return "Lost with Bid";
                }
        
                return props.status;
            }

            if(props.type === "Bid"){
                bidData = gatherBid();
            }
        
            if(currentStatus === "Shipped"){
                currentStatus = "Shipped - Bid";
            }
        
            if(currentStatus === "Lost with bid"){
                currentStatus = "Lost with Bid";
            }

            return (
                <Link
                    className={props.data?.type === "Note" ? "homepage bid note timelinePostBlock g bR p ns" : "homepage bid timelinePostBlock g bR p ns"}
                    to={followLink}
                    // onClick={()=>{props.changeActivityView("singleBid"); props.redirect("bids")}}
                    onClick={()=>{
                        updateInstance({view : "bids", "instance" : "single"})
                    }}>                    <div className="header g">
                        <div className="cC">
                            {props.data?.readStatus === false && 
                                <div className="cC">
                                    <div className="notificationAlert">
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="cL f">
                            {displayActivityType()}
                        </div>
                        <div className="cR f e">
                                <div className="cL">
                                    <div className="cR e">
                                        {props.data?.fundName}
                                    </div>
                                    <div className="cC">
                                        <StorefrontIcon sx={{color: "#6a1b9a"}}/>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="divider">
                    </div>
                    <div className="content g bold">
                        <div className="cC">
                            <StatusIcon
                                currentStatus={props.data?.type === "Note" ? "Note" : currentStatus}
                                type={props.type}
                                originator={originator}
                            />
                        </div>
                        <div className={"cL oH"}>
                            <div className="gCW">
                                {props.type === "Note" ? "Note" : displayActivityStatus()}
                            </div>
                        </div>
                        <div className={props.type === "Note" ? "cC gC3 oH" : "cC oH"}>
                            <div className="gCW">
                                {props.data?.policyNumber}
                            </div>
                        </div>
                        <div className="cC oH">
                            <div className="gCW">
                                {props.data?.fullName}
                            </div>
                        </div>
                        <div className="cC oH">
                            <div className="gCW">
                                {convertIntToCurrency(props.data?.deathBenefit, isMobile)}
                            </div>
                        </div>
                        <div className="cR f e gC6 oH">
                            <div className="gCW">
                                {timeStamp(props.data.rawTimeStamp)}
                            </div>
                        </div>
                    </div>
                    <div className={calculateContentWrap()}>
                        {props.type === "Note" &&
                            <div className="cL dP e">
                                <div className="ellipsisText">
                                    {props.data?.content}
                                </div>
                            </div>
                        }
                    </div>
                </Link>
            )
        }

        return (
            <Link
                className="homepage timelinePostBlock g bR p ns fC"
                to={followLink}
                // onClick={()=>{props.changeActivityView("singlePolicy"); props.redirect("policies")}}>
                onClick={()=>{
                    updateInstance({view : "policies", "instance" : "single"})
            }}>
                <div className="header g">
                    {!props.data.readStatus &&
                        <div className="cC">
                            <div className="notificationAlert">
                            </div>
                        </div>
                    }
                    <div className="cL f gC2">
                        {props.type === "Note" ? "Communication" : props.type + " Update"}
                    </div>
                    <div className="cR f e">
                        {parseJWT(props.sessionUser?.sessionToken)?.managedAccounts?.length > 1 &&
                            <div className="cL">
                                <div className="cR e">
                                    {props.sessionUser?.accountData[props.data?.accountID]?.name}
                                    {props?.data?.fundName ?? props?.data?.ownerName}
                                </div>
                                <div className="cC">
                                    {props.data?.fundID && <SecurityIcon sx={{color:"#ff9800"}}/>}
                                    {!props.data?.fundID && <ShieldIcon sx={{color:"#00BFFF"}}/>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="divider">
                </div>
                <div className="details g bold">
                    <div className="cC">
                        <StatusIcon
                            currentStatus={props?.status}
                            type={props?.type}
                        />
                    </div>
                    <div className={"cL oH"}>
                        <div className="gCW">
                            {timelineStatus}
                        </div>
                    </div>
                    <div className={props.type === "Note" ? "cC gC3 oH" : "cC oH"}>
                        <div className="gCW">
                            {props?.data?.policyNumber}
                        </div>
                    </div>
                    <div className="cC oH">
                        <div className="gCW">
                           {props?.data?.insuredFullName ?? "N/A"}
                        </div>
                    </div>
                    <div className="cC oH">
                        <div className="gCW">
                            {convertIntToCurrency(props.data.deathBenefit, isMobile)}
                        </div>
                    </div>
                    <div className="cR f e gC6">
                        {timeStamp(props?.data?.rawTimeStamp) }
                    </div>
                </div>
                {props.type === "Note" &&
                    <div className="content g cL">
                        <div className={props.type === "Note" ? "cL dP e g gC2 f" : "cL s e g gC2 f"}>
                            <div className="ellipsisText">
                                {props.status}
                            </div>
                        </div>
                    </div>
                }
            </Link>
        )
    }

    if(props.data.objectType === "lead"){
        followLink = "/leads/" + props.leadID + hash;
    }

    if(props.data.objectType === "servicing"){
        let checkStatus = props.data?.taskStatus?.toLowerCase();

        if(checkStatus === "closed"){
            checkStatus = "completed";
        }
        if(checkStatus === "new"){
            checkStatus = "open";
        }
        if(checkStatus === "progress"){
            checkStatus = "submitted";
        }
        if(checkStatus === "problem"){
            checkStatus = "issue";
        }

        followLink = "/policies/servicing/" + props.data.relatedPolicy + hash;
        
        if(props.type === "VOC"){
            if(props.timelineType === "origination"){
                return (
                    <Link
                        className="voc homepage timelinePostBlock g bR p ns fC"
                        to={followLink}
                        // onClick={()=>{props.changeActivityView("singlePolicy"); props.redirect("policies")}}>
                        onClick={()=>{
                            updateInstance({view : "policies", "instance" : "single"})
                        }}>
                        <div className={"header g"}>
                            <div className="cC">
                                {/* {!props.data.readStatus &&
                                    <div className="cC">
                                        <div className="notificationAlert">
                                        </div>
                                    </div>
                                } */}
                            </div>
                            <div className="cL f ">
                                Verification
                            </div>
                            <div className="cR f e">
                                {parseJWT(props.sessionUser?.sessionToken)?.managedAccounts?.length > 1 &&
                                    <div className="cL">
                                        <div className="cR e">
                                            {props.data?.ownerName}
                                        </div>
                                        <div className="cC">
                                            {props.data?.fundID && <SecurityIcon sx={{color:"#ff9800"}}/>}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="divider">
                        </div>
                        <div className="content g bold">
                            <div className={"cC " + checkStatus}>
                                <StatusIcon
                                    currentStatus={props.status}
                                    type={props.type}
                                />
                            </div>
                            <div className="cL s oH">
                                <div className="gCW">
                                    {props.type}
                                </div>
                            </div>
                            <div className="cC oH">
                                <div className="gCW">
                                    {props.data?.policyNumber}
                                </div>
                            </div>
                            <div className="cC oH">
                                <div className="gCW">
                                    {props.data?.insuredFullName}
                                </div>
                            </div>
                            <div className="cC oH">
                                <div className="gCW">
                                    {convertIntToCurrency(props.data?.netDeathBenefit, isMobile)}
                                </div>
                            </div>
                            <div className="cR e gC6 oH">
                                <div className="g gCW">
                                    {props.data?.completedDate ?
                                        timeStamp(props.data?.completedDate, isMobile)
                                    : 
                                        timeStamp(props.data?.dateCreated, isMobile)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="details g s e oH">
                            <div className="cL g">
                                <div>
                                    COI:{'\u00A0'}
                                    {convertIntToCurrency(props.data?.currentCOI, isMobile, true)}
                                </div>
                            </div>
                            <div className="cC g">
                                <div>
                                    AV:{'\u00A0'}
                                    {convertIntToCurrency(props.data?.accountValue, isMobile, true)}
                                </div>
                            </div>
                            <div className="cR g">
                                <div>
                                    CSV:{'\u00A0'}
                                    {convertIntToCurrency(props.data?.cashValue, isMobile, true)}
                                </div>
                            </div>
                        </div>
                    </Link>
                )
            }

            return (
                <Link
                    className="voc homepage timelinePostBlock g bR p ns fC"
                    to={followLink}
                    // onClick={()=>{props.changeActivityView("singlePolicy"); props.redirect("policies")}}>
                    onClick={()=>{
                        updateInstance({view : "policies", "instance" : "single"})
                    }}>
                    <div className={"header g"}>
                        <div className="cC">
                            {/* {!props.data.readStatus &&
                                <div className="cC">
                                    <div className="notificationAlert">
                                    </div>
                                </div>
                            } */}
                        </div>
                        <div className="cL f ">
                            Verification
                        </div>
                        <div className="cR f e">
                            {parseJWT(props.sessionUser?.sessionToken)?.managedAccounts?.length > 1 &&
                                <div className="cL">
                                    <div className="cR e">
                                        {props.data?.ownerName}
                                    </div>
                                    <div className="cC">
                                        {props.data?.fundID && <SecurityIcon sx={{color:"#ff9800"}}/>}
                                    </div>
                                </div>
                            }
                        </div>
                        {/* <div className="cR f">
                            {checkStatus === "open" && <div className="cR e"><span>New</span></div>}
                            {checkStatus === "submitted" && <div className="cR e"><span>Pending</span></div>}
                            {checkStatus === "completed" && <div className="cR e"><span>Done</span></div>}
                        </div>
                        <div className="cC">
                            <div className={"cC " + checkStatus}>
                                <CheckCircleIcon/>
                            </div>
                        </div> */}
                    </div>
                    <div className="divider"></div>

                    <div className="policyInfo g s e bold">
                        <div className="cL oH">
                            <div className="gCW">
                                {props.data?.insuredFullName}
                            </div>
                        </div>
                        <div className="cR oH">
                            <div className="gCW">
                                {props.data?.policyNumber}
                            </div>
                        </div>
                    </div>
                    <div className="content g">
                        <div className={"cC " + checkStatus}>
                            <StatusIcon
                                currentStatus={props.status}
                                type={props.type}
                            />
                        </div>
                        <div className="cL s oH">
                            <div className="gCW">
                                {props.type}
                            </div>
                        </div>
                        <div className="cR e gC6 oH">
                            <div className="gCW">
                                {props.data?.completedDate ?
                                    timeStamp(props.data?.completedDate, isMobile)
                                : 
                                    timeStamp(props.data?.dateCreated, isMobile)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="details g s e">
                        <div className="cL g">
                            <div>
                                COI:{'\u00A0'}
                                {convertIntToCurrency(props.data?.currentCOI, isMobile, true)}
                            </div>
                        </div>
                        <div className="cC g">
                            <div>
                                AV:{'\u00A0'}
                                {convertIntToCurrency(props.data?.accountValue, isMobile, true)}
                            </div>
                        </div>
                        <div className="cR g">
                            <div>
                                CSV:{'\u00A0'}
                                {convertIntToCurrency(props.data?.cashValue, isMobile, true)}
                            </div>
                        </div>
                    </div>
                </Link>
            )
        }

        if(props.type === "Payment"){
            if(props.timelineType === "origination"){
                return (
                    <Link
                        className="payment homepage timelinePostBlock g bR p ns fC"
                        to={followLink}
                        // onClick={()=>{props.changeActivityView("singlePolicy"); props.redirect("policies")}}
                        onClick={()=>{
                            updateInstance({view : "policies", "instance" : "single"})
                    }}>
                        <div className="header g">
                            <div className="cC">
                                {/* <div className="notificationAlert">
                                </div> */}
                            </div>
                            <div className="cL f">
                                Payment Details
                            </div>
                            <div className="cR f e">
                                {parseJWT(props.sessionUser?.sessionToken)?.managedAccounts?.length > 1 &&
                                    <div className="cL">
                                        <div className="cR e">
                                            {props.data?.ownerName}
                                        </div>
                                        <div className="cC">
                                            {props.data?.fundID && <SecurityIcon sx={{color:"#ff9800"}}/>}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="divider">
                        </div>
                        <div className="policyInfo g e bold">
                            <div className="cC">
                                <StatusIcon type={props.type} />
                            </div>
                            <div className="cL">
                                Payment of
                                <div className="s bold">
                                    {convertIntToCurrency(props.data.amountPaid, isMobile)}
                                </div>
                            </div>
                            <div className="cC">
                                {props.data.policyNumber}
                            </div>
                            <div className="cC">
                                {props.data.insuredFullName}
                            </div>
                            <div className="cC">
                                {props.data.netDeathBenefit}
                            </div>
                            <div className="cR e gC6">
                                {props.data?.completedDate ?
                                    timeStamp(props.data?.completedDate, isMobile)
                                : 
                                    timeStamp(props.data?.dateCreated, isMobile)
                                }
                            </div>
                        </div>
                    </Link>
                )
            }

            return (
                <Link
                    className="payment homepage timelinePostBlock g bR p ns fC"
                    to={followLink}
                    // onClick={()=>{props.changeActivityView("singlePolicy"); props.redirect("policies")}}
                    onClick={()=>{
                        updateInstance({view : "policies", "instance" : "single"})
                    }}>                    <div className="header g">
                        <div className="cC">
                            {/* <div className="notificationAlert">
                            </div> */}
                        </div>
                        <div className="cL f bold">
                            Payment Details
                        </div>
                        <div className="cR f e">
                            {parseJWT(props.sessionUser?.sessionToken)?.managedAccounts?.length > 1 &&
                                <div className="cL">
                                    <div className="cR e">
                                        {props.data?.ownerName}
                                    </div>
                                    <div className="cC">
                                        {props.data?.fundID && <SecurityIcon sx={{color:"#ff9800"}}/>}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="divider">
                    </div>
                    <div className="policyInfo g s e bold">
                        <div className="cL">
                            {props.data.insuredFullName}
                        </div>
                        <div className="cR">
                            {props.data.policyNumber}
                        </div>

                    </div>
                    <div className="content g">
                        <div className="cC">
                            <StatusIcon type={props.type} />
                        </div>
                        <div className={props.type === "Note" ? "cL dP" : "cL"}>
                            Payment of
                            <div className="s bold">
                                {convertIntToCurrency(props.data.amountPaid, isMobile)}
                            </div>
                        </div>
                        <div className="cR e gC3">
                            {props.data?.completedDate ?
                                timeStamp(props.data?.completedDate, isMobile)
                            : 
                                timeStamp(props.data?.dateCreated, isMobile)
                            }
                        </div>
                    </div>
                </Link>
            )
        }

    }

    // if(props.data.objectType === "marketplace"){
    //     return (
    //         <Link
    //         className="homepage bid timelinePostBlock g bR p ns"
    //         to={followLink}
    //         onClick={()=>{props.changeActivityView("singleBid"); props.redirect("bids")}}
    //     >
    //         <div className="header g">
    //             <div className="cC">
    //                 {props.data?.readStatus === false && 
    //                     <div className="cC">
    //                         <div className="notificationAlert">
    //                         </div>
    //                     </div>
    //                 }
    //             </div>
    //             <div className="cL f">
    //                 asdf
    //             </div>
    //             <div className="cR f e">
    //                     <div className="cL">
    //                         <div className="cR e">
    //                             {props.data?.fundName}
    //                         </div>
    //                         <div className="cC">
    //                             <StorefrontIcon sx={{color: "#6a1b9a"}}/>
    //                         </div>
    //                     </div>
    //             </div>
    //         </div>
    //         <div className="divider">
    //         </div>
    //         <div className="content g bold">
    //             <div className="cC">
    //                 <StatusIcon
    //                     currentStatus={props.data?.type === "Note" ? "Note" : props.status}
    //                     type={props.type}
    //                 />
    //             </div>
    //             {props.type !== "Note" &&
    //                 <div className={"cL s e"}>
    //                     asdf
    //                 </div>
    //             }
    //             <div className="cC">
    //                 {props.data?.policyNumber}
    //             </div>
    //             <div className="cC">
    //                 {props.data?.fullName}
    //             </div>
    //             <div className="cC">
    //                 {convertIntToCurrency(props.data?.deathBenefit, props.mobileDevice)}
    //             </div>
    //             <div className="cR f e">
    //                 {timeStamp(props.data.rawTimeStamp)}
    //             </div>
    //         </div>
    //     </Link>
    //     )
    //     followLink = "/bids/" + props.data.relatedPolicy + hash;
    // }



}

export default HomePageTimelinePost;
