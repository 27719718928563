import React, { useState, useEffect } from 'react';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import SystemSecurityUpdateGoodTwoToneIcon from '@mui/icons-material/SystemSecurityUpdateGoodTwoTone';
import SystemSecurityUpdateWarningTwoToneIcon from '@mui/icons-material/SystemSecurityUpdateWarningTwoTone';
import PhonelinkEraseTwoToneIcon from '@mui/icons-material/PhonelinkEraseTwoTone';
import RadioButtonUncheckedTwoToneIcon from '@mui/icons-material/RadioButtonUncheckedTwoTone';
import RadioButtonCheckedTwoToneIcon from '@mui/icons-material/RadioButtonCheckedTwoTone';
import NotesTwoToneIcon from '@mui/icons-material/NotesTwoTone';

import StatusIcon from '../StatusIcons2';

function FollowUp(props){
    const session = props?.session;
    const timelineItem = props?.timelineItem;
    const timelineData = props?.timelineData;
    const timeAgo = props?.timeAgo;
    const readStatus = props?.readStatus ?? timelineItem?.readStatus;
    const followUpTime = timelineItem?.followUpDateTime;
    const [isHovered, setIsHovered] = useState(false);
    const checkStatus = currentCheckStatus();

    function currentCheckStatus() {
        const followUpDateTime = new Date(followUpTime); // Convert the ISO string to a Date object
        const currentDateTime = new Date(); // Get the current date and time
        const priorFollowUp = followUpDateTime < currentDateTime;
        
        if (isHovered) {
            return timelineItem?.checkStatus === "Open" ? "Completed" : (priorFollowUp ? "Missed" : "Open");
        }
    
        return priorFollowUp && timelineItem?.checkStatus === "Open" ? "Missed" : timelineItem?.checkStatus;
    }

    return (
        <div
            className={`newTimelinePost followUp g f bR p ${
                props?.stem}${
                    props?.detailed ? " detailed" : ''}${
                        checkStatus ? ` ${checkStatus}` : ''}`}
            onClick={props?.onClick}
        >
            <div className="header g f fR e dG">
                {readStatus === false && timelineItem?.originator === "Internal" &&
                    <div className="f cC">
                        <div className="notificationAlert">
                        </div>
                    </div>
                }
                <div className="cL gC2 bold dG">
                    Lead Alert
                    <ErrorTwoToneIcon/>
                </div>
                <div className="cR gC3">
                    {timeAgo}
                </div>
            </div>
            <div className="divider dT">
            </div>
            <div className="content g f cC dG e b">
                {{
                    Completed: <SystemSecurityUpdateGoodTwoToneIcon/>,
                    Missed: <PhonelinkEraseTwoToneIcon/>,
                    Open: <SystemSecurityUpdateWarningTwoToneIcon/>
                }[checkStatus]}
                <div className="subHeader g f dG">
                    <div className="f cL">
                        Follow Up
                    </div>
                    <div className="f cR fR gC2">
                        <div
                            className="dataPoint bold cC dT bR fR lH g dG"
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            {{
                                Open: "Awaiting",
                                Completed: "Completed",
                                Missed: "Missed",
                            }[checkStatus]}
                            <div className="switch g cC">
                                <div className="placeholder">
                                </div>
                                {{
                                    Open: <RadioButtonUncheckedTwoToneIcon/>,
                                    Completed: <RadioButtonCheckedTwoToneIcon/>,
                                    Missed: <RadioButtonUncheckedTwoToneIcon/>,
                                }[checkStatus]}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="vDivider gC1 dT"></div>
                <div className="details f cL g dG gC2">
                    <div className={`${!props?.detailed ? "gCW " : "dG "}g f`}>
                        {timelineItem?.followUpDateTime ?
                            `${session?.env?.functions?.reformatDate(timelineItem?.followUpDateTime)} at ${session?.env?.functions?.reformatDate(timelineItem?.followUpDateTime, "timestamp")}`
                        :
                            "Unknown follow up time."
                        }
                        {props?.detailed && timelineItem?.status &&
                            <span className="g">
                                {timelineItem?.status.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </span>
                        }
                    </div>
                    {!props?.detailed &&
                        <div className="gC2 bR nS bold cC dG">
                            <NotesTwoToneIcon/>
                            Attached Notes
                        </div>
                    }

                </div>
            </div>
        </div>
    );
}

export default FollowUp;
