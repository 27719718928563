import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import Logo from '../Images/AbacusLifeLogo.png';

import './NavBar.css';
import './SideBar.css';

import SettingsIcon from '@mui/icons-material/Settings';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import PolicyTwoToneIcon from '@mui/icons-material/PolicyTwoTone';
import RingVolumeTwoToneIcon from '@mui/icons-material/RingVolumeTwoTone';
import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import SwapHorizontalCircleTwoToneIcon from '@mui/icons-material/SwapHorizontalCircleTwoTone';

function NavBar(props){
    const session = props?.session;
    const navigation = session?.navigation;

    const navigate = useNavigate();
    const currentURL = window.location.pathname;
    const currentPath = window.location.pathname.match(/^\/([^/]+)/)?.[1] || null;
    const isBackNavigation = useRef(false);

    const pages = [
        { key: 'dashboard', icon: <DashboardTwoToneIcon />, label: 'Home', path: '/dashboard' },
        { key: 'policies', icon: <PolicyTwoToneIcon />, label: 'Policies', path: '/policies', accessKeys: ['origination', 'servicing'] },
        { key: 'upload', icon: <UploadTwoToneIcon />, label: 'Upload', path: '/upload' },
        { key: 'leads', icon: <RingVolumeTwoToneIcon />, label: 'Leads', path: '/leads' },
        { key: 'bids', icon: <StorefrontTwoToneIcon />, label: session?.env?.functions?.checkSessionExceptions(session, 'bidVerbiage') ? 'Cases' : 'Bids', path: '/bids' },
        { key: 'tertiary', icon: <SwapHorizontalCircleTwoToneIcon />, label: 'Tertiary', path: '/tertiary' },
        { key: 'reporting', icon: <AnalyticsTwoToneIcon />, label: 'Reporting', path: '/reporting' },
    ];
    let pageCount = 1;

    useEffect(() => {
        // Handler for popstate events (browser back/forward button)
        const handlePopState = () => {
            isBackNavigation.current = true; // Mark this as a back navigation
            navigation?.functions?.updateInstance({ URL: window.location.pathname, direction: 'back', session: session });
    
            // Reset `isBackNavigation` after a short delay to avoid interference
            setTimeout(() => {
                isBackNavigation.current = false;
            }, 100);
        };
    
        // Add popstate event listener
        window.addEventListener('popstate', handlePopState);
    
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigation]);

    useEffect(() => {
        const eligiblePath = session?.navigation?.functions?.eligablePath(currentPath);
        if (isBackNavigation.current) {
            navigation?.functions?.updateInstance({ URL: currentURL, direction: 'back', session: session });
        } else {
            if (eligiblePath) {
                navigation?.functions?.updateInstance({ URL: currentURL, session: session });
            } else {
                navigate(navigation?.functions?.homepage());
                window.history.replaceState(null, '', navigation?.functions?.homepage());
            }
        }
    }, [currentURL, currentPath]);

    return (
        <div className="navBar g">
            <div className="navPathHandler g">
                <div className="logo cC">
                <Link
                    className="f cC"
                    to={navigation?.functions?.homepage()}
                >
                    <img src={Logo} alt="Abacus Life Logo"></img>
                </Link>
                </div>
                <div className="path g f fR cL s">
                    {!props.mobileDevice &&
                    <div className="abacusHeader">
                        Abacus Marketplace
                    </div>
                    }
                    <div className="currentPageHandlerPath dP">
                        {navigation?.data?.currentInstance?.view && 
                            navigation.data.currentInstance.view.charAt(0).toUpperCase() + navigation.data.currentInstance.view.slice(1)
                        }
                    </div>
                </div>
            </div>
            <div className="pageSelection g fR fC cC">
                <div className="sideBar g ns fR dG">
                    {pages.map(({ key, icon, label, path, accessKeys }) => {
                        const hasAccess = accessKeys
                            ? navigation?.functions?.verifyPageAccess(accessKeys)
                            : navigation?.functions?.verifyPageAccess(key);
                        if (!hasAccess) return null;
                        const column = `gC${pageCount++}`;

                        return (
                            <Link
                                key={key}
                                to={path}
                                className={`sideBarBtn fC f g cC${navigation?.data?.currentInstance?.view === key ? ' selected' : ''} ${column}`}
                                >
                                {icon}
                                <div className="cC f oH">
                                    <div className="gCW">{label}</div>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </div>
            <div className="navHardButtons cC g">
                <div className="sideBarBtn cC" onClick={()=>{session?.env?.setOverlay("settingsMenu");}}>
                    <SettingsIcon/>
                </div>
            </div>
        </div>
    );
}

export default NavBar;